import React, { useState } from 'react';

import { useHistory } from 'react-router';
import { useDataProvider, useRefresh, useShowController, useTranslate } from 'ra-core';

import { BackButtonWithWrapper } from 'components/designSystem/BackButton';
import { ShowTitle } from 'components/designSystem/ShowTitle';
import { Lock, LockOpen, Refresh, Settings } from '@material-ui/icons';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { Tabs, Tab } from '@material-ui/core';

import { handleToggleLock, ShowClientTabOrder } from './Show.model';

import { ClientSummary } from './views/Summary';
import { Sites } from './views/Sites';
import { Apps } from './views/Apps';
import { Teams } from './views/Teams';
import { Providers } from './views/Providers';
import { Licenses } from './views/Licenses';
import { Users } from './views/Users';
import { Projects } from './views/Projects';
import { useTabs } from 'hooks/use-tabs';
import { Playground } from './views/Playground';
import { useGlobalState } from 'state';

export const Show = (props) => {
  const t = useTranslate();
  const dataProvider = useDataProvider();
  const history = useHistory();

  const { growthBook } = useGlobalState();

  const showController = useShowController(props);
  const refreshClient = useRefresh();
  const client = showController.record;

  const [tab, setTab] = useTabs(ShowClientTabOrder, { defaultTab: 'summary' });
  const [handleRefresh, setHandleRefresh] = useState(() => {});
  const showPlayground = growthBook.isOn('playground-view') ?? false;

  return (
    <div>
      <BackButtonWithWrapper />
      <ShowTitle
        name={client?.name}
        actions={[
          <TertiaryButton
            disabled={!client}
            key="client_action_settings"
            label={t('actions.settings')}
            icon={<Settings />}
            onClick={() => history.push(`/clients/${client.id}`)}
          />,
          <TertiaryButton
            disabled={!client}
            key="client_action_lock"
            label={!client || !client.locked ? 'Lock' : 'Unlock'}
            icon={!client || !client.locked ? <Lock /> : <LockOpen />}
            onClick={() => handleToggleLock(client, dataProvider, refreshClient)}
          />,
          <TertiaryButton
            disabled={!client}
            key="client_action_refresh"
            label="Refresh"
            icon={<Refresh />}
            onClick={handleRefresh}
          />,
        ]}
      />
      <Tabs value={tab} onChange={(event, value) => setTab(value)}>
        <Tab value={ShowClientTabOrder['summary']} label={t('resources.clients.tabs.summary')} />
        <Tab value={ShowClientTabOrder['projects']} label={t('resources.clients.tabs.projects')} />
        <Tab value={ShowClientTabOrder['sites']} label={t('resources.clients.tabs.sites')} />
        <Tab value={ShowClientTabOrder['teams']} label={t('resources.clients.tabs.teams')} />
        <Tab value={ShowClientTabOrder['providers']} label={t('resources.clients.tabs.providers')} />
        <Tab value={ShowClientTabOrder['apps']} label={t('resources.clients.tabs.apps')} />
        <Tab value={ShowClientTabOrder['users']} label={t('resources.clients.tabs.users')} />
        <Tab value={ShowClientTabOrder['licenses']} label={t('resources.clients.tabs.licenses')} />
        {showPlayground && <Tab value={ShowClientTabOrder['playground']} label={'Playground'} />}
      </Tabs>

      {tab === ShowClientTabOrder['summary'] && <ClientSummary client={client} setHandleRefresh={setHandleRefresh} />}
      {tab === ShowClientTabOrder['projects'] && <Projects client={client} setHandleRefresh={setHandleRefresh} />}
      {tab === ShowClientTabOrder['sites'] && <Sites client={client} setHandleRefresh={setHandleRefresh} />}
      {tab === ShowClientTabOrder['teams'] && <Teams client={client} setHandleRefresh={setHandleRefresh} />}
      {tab === ShowClientTabOrder['providers'] && <Providers client={client} setHandleRefresh={setHandleRefresh} />}
      {tab === ShowClientTabOrder['apps'] && <Apps client={client} setHandleRefresh={setHandleRefresh} />}
      {tab === ShowClientTabOrder['users'] && <Users client={client} setHandleRefresh={setHandleRefresh} />}
      {tab === ShowClientTabOrder['licenses'] && <Licenses client={client} setHandleRefresh={setHandleRefresh} />}
      {showPlayground && tab === ShowClientTabOrder['playground'] && (
        <Playground client={client} setHandleRefresh={setHandleRefresh} />
      )}
    </div>
  );
};
