import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Menu } from '@material-ui/icons';

const useHeaderStyles = makeStyles((theme) => ({
  container: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
  withBanner: {
    height: theme.spacing(13),
    minHeight: theme.spacing(13),
    maxHeight: theme.spacing(13),
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  withoutBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: theme.spacing(8),
  },
  menuButtonContainer: {
    position: 'absolute',
    top: theme.spacing(2.5),
    left: theme.spacing(1.5),
  },
  nickname: {
    maxWidth: '68%',
    wordWrap: 'break-word',
    textAlign: 'center',
  },
  minify: {
    fontSize: 8,
    marginBottom: theme.spacing(.5),
  },
  withBannerMinified: {
    height: theme.spacing(6.25),
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  withoutBannerMinified: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: theme.spacing(3.75),
  },
  menuButtonContainerMinified: {
    position: 'absolute',
    top: theme.spacing(1.25),
    left: theme.spacing(1.5),
  },
}));

export const AppPreviewHeader = ({ app, minify }) => {
  const classes = useHeaderStyles();

  const { banner, nickname, showBannerNickname } = app.config ?? {};

  const showBanner = !!banner?.url;
  const showHeader = !showBanner || showBannerNickname;

  let containerClass = `${classes.container} ${minify ? classes.minify : ''} `;
  if (showBanner) {
    containerClass += minify ? classes.withBannerMinified : classes.withBanner;
  } else {
    containerClass += minify ? classes.withoutBannerMinified : classes.withoutBanner;
  }

  return (
    <div
      className={containerClass}
      style={{
        ...(showBanner ? { backgroundImage: `url(${banner?.url})` } : {}),
      }}
    >
      <div className={minify ? classes.menuButtonContainerMinified : classes.menuButtonContainer}>
        <Menu style={{ fontSize: minify ? 10 : undefined }} />
      </div>
      {showHeader && <div className={classes.nickname}>{nickname || ''}</div>}
    </div>
  );
};
