import React from "react"
import { Show, DateField, DefaultActions } from "components"
import { useRecord } from "hooks"
import { TextField, SimpleShowLayout } from "ra-ui-materialui";


export default ({permissions, ...props}) => {
  const record = useRecord("terms") || {};

  return (
    <Show {...props} actions={<DefaultActions hasEdit={props.hasEdit} />} titleSource="title">
      <SimpleShowLayout>
        <TextField source="title" />
        <DateField addLabel={true} source="publishedAt" />
        <div dangerouslySetInnerHTML={{ __html: record.content }} />
      </SimpleShowLayout>
    </Show>
  )
}
