import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Notification } from 'ra-ui-materialui';

// Allow multi-line messages to be displayed
const cssMsg = {
  snackbarContent: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
    fontWeight: '600',
  },
};

export const Snack = withStyles(cssMsg)(({ classes, ...props }) => (
  <Notification {...props} className={classes.snackbarContent} anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}} />
));
