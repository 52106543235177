import { makeStyles } from '@material-ui/core';

export const useInputStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    // marginBottom: 8,
    // marginRight: 2,
    // marginLeft: 2,
    display: 'inline-block',
  },
  label: {
    display: 'block',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '13px',
    marginRight: 3,
    marginTop: 8,
    marginBottom: '8px',
  },
  helperIcon: {
    color: theme.palette.text.tertiary,
    fontSize: theme.spacing(2.2),
    position: 'relative',
    top: 0.75,
    cursor: 'hover',
    marginTop: 1,
  },
  defaultIcon: {
    color: theme.palette.error.main,
    fontSize: theme.spacing(2.2),
    position: 'relative',
    top: 0.75,
    cursor: 'hover',
  },
  input: {
    boxSizing: 'border-box',
    border: 'none',
    fontSize: 14,
    padding: '8px 16px',
    fontFamily: 'Inter, sans-serif',
    background: theme.palette.background.surface.secondary,
    color: theme.palette.text.secondary,
    borderRadius: theme.borderRadius,
    transition: '.2s background, .1s box-shadow',
    outline: 'none',

    '&:focus': {
      background: theme.palette.background.surface.primary,
      boxShadow: `0 0 0 2px  ${theme.palette.primary.main}`,
    },

    //   '&:focus-visible': {

    //   }

    '&:disabled': {
      background: theme.palette.background.surface.pressed,
    },
  },
  inputError: {
    boxShadow: `0 0 0 2px  ${theme.palette.error.main}`,
    background: theme.palette.error.lighter,
    color: theme.palette.text.primary,

    '&:svg': {
      color: theme.palette.error.main,
      maxWidth: 16,
      maxHeight: 16,
    },
  },

  errorMessage: {
    position: 'absolute',
    top: 'calc(100% + 2px)',
    left: 2,
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.error.main,
    marginTop: 2,
  },

  inputSuccess: {
    boxShadow: `0 0 0 2px  ${'#0CC095'}`,
    background: '#E7FDF8',
    color: theme.palette.text.primary,
  },
  successMessage: {
    position: 'absolute',
    top: 'calc(100% + 2px)',
    left: 2,
    fontSize: theme.typography.caption.fontSize,
    color: '#0CC095',
    marginTop: 2,
  },
  hintMessage: {
    position: 'absolute',
    top: 'calc(100% + 2px)',
    left: 2,
    fontSize: theme.typography.caption.fontSize,
    color: '#7F889C',
    marginTop: 2,
  },
  light: {
    background: theme.palette.background.surface.primary,
  },
  tiny: { width: 90 },
  small: { width: 180 },
  medium: { width: 350 },
  full: { width: '100%' },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    // marginTop: theme.spacing(1),
  },

  requiredFlag: {
    color: theme.palette.error.main,
  },
}));
