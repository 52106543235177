import React, { useCallback } from 'react';
import Condition from './Condition';
import { useTranslate } from 'ra-core';
import { get, isString, isObject} from "lodash"
import { useForm, useFormState } from 'react-final-form';

const config = ({
  operators: [
    { operator: 'isEmpty', label: 'isEmpty',      mode: "unary"},
    { operator: 'isNotEmpty', label: 'isNotEmpty', mode: "unary", },
    
    { operator: 'equals', label: 'equals',      mode: "binary" },
    { operator: 'notEqual', label: 'notEqual',  mode: "binary" },
    
    { operator: 'contains', label: 'contains', mode: "binary", multiple: ["checkboxes"], types: ["text", "dropdown", "dropdown_search", "radios", "checkboxes"]},
    { operator: 'notContains', label: 'notContains', mode: "binary", multiple: ["checkboxes"], types: ["text", "dropdown", "dropdown_search", "radios", "checkboxes"] },
    { operator: 'anyOf', label: 'anyOf', mode: "binary", multiple: ["dropdown", "dropdown_search", "radios", "checkboxes"], types: ["dropdown", "dropdown_search", "radios", "checkboxes"] },
    { operator: 'allOf', label: 'allOf', mode: "binary", multiple: ["checkboxes"], types: ["checkboxes"]},
    
    { operator: 'greater', label: 'greater', mode: "binary", types: ["text", "dropdown", "dropdown_search", "radios", "rating"] },
    { operator: 'lesser', label: 'lesser', mode: "binary", types: ["text", "dropdown", "dropdown_search", "radios", "rating"]},
    { operator: 'greaterOrEqual', label: 'greaterOrEqual', mode: "binary", types: ["text", "dropdown", "dropdown_search", "radios", "rating"] },
    { operator: 'lessOrEqual', label: 'lessOrEqual', mode: "binary", types: ["text", "dropdown", "dropdown_search", "radios", "rating"] },
  ],  
  combinators: [
    { combinator: 'and', label: 'AND' },
    { combinator: 'or', label: 'OR' },    
  ]
})

const combinatorsMap = {"&&": "and", "||": "or"}

function buildTree(node, nodeName = "1", config) {
  if (isObject(node)) {
    if (node.combinator) {
      return ({
        combinator: combinatorsMap[node.combinator] || node.combinator,
        nodeName: nodeName,
        rules: (node.rules || []).map((rule, index) => buildTree(rule, `${nodeName}/${index + 1}`), config)
      })
    }
    return ({ ...node, nodeName })
  }

  if (isString(node)) {
    try {
      return JSON.parse(node)
    }
    catch(error) {
      return ({ combinator: config.combinators[0].combinator, nodeName, rules: [] })
    } 
  }
  return ({ combinator: config.combinators[0].combinator, nodeName, rules: [] }) 
}
function cleanTree(node) { 
  const {nodeName, rules, ...rest} = node
  if(rules) {
    return {...rest, rules: rules.map(rule => cleanTree(rule))}
  }
  return {...rest}
}

function ReactMultiQueryBuilder({ source, readonly, fieldValues}) {  
  const form = useForm()
  const { values } = useFormState()  
  const translate = useTranslate()  
  const handleChange = useCallback(data => form.change(source, cleanTree({ ...data })), [form, source])

  return (<div>
    <Condition
      readonly={readonly}
      fieldValues={fieldValues} 
      translate={translate}
      config={config}
      nodeName="1"
      data={buildTree(get(values, source), "1", config)}
      onChange={handleChange}
    />
  </div>);
}

export default ReactMultiQueryBuilder