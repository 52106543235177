export const ShowClientTabOrder = {
  summary: 0,
  projects: 1,
  sites: 2,
  teams: 3,
  providers: 4,
  apps: 5,
  users: 6,
  licenses: 7,
  playground: 8,
};

export const handleToggleLock = (client, dataProvider, refresh) => {
  if (!client) {
    return;
  }
  dataProvider.update('clients', { id: client.id, data: { locked: !!!client.locked } }).then(() => {
    refresh();
  });
};
