import React from "react"
import { TextField, TabbedShowLayout, Tab } from "ra-ui-materialui"
import { Show as ShowComp } from "components"
import DateField from "../../../components/DateField";
import { useRecord } from "../../../hooks";
import { TranslateField } from "../../../components/TranslateField";

export const Show = ({ permissions, ...props }) => {
  const record = useRecord("license-comments/sasi") || {};

  return (
    <ShowComp {...props} titleSource="name">
      <TabbedShowLayout>
        <Tab label="tabs.summary">
          <TextField source="title" label="resources.license-comments/sasi.fields.title" />
          <TextField source="responsible" label="resources.license-comments/sasi.fields.responsible" />
          <TranslateField
            namespace='resources.license-comments/sasi.phases'
            source="phase"
            label="resources.license-comments/sasi.fields.phase"
            addLabel
          />
          {record.phaseComment && record.phase === "other" && <TextField source="phaseComment" label="resources.license-comments/sasi.fields.phaseComment" />}
          <TextField source="Client.name" label="resources.license-comments/sasi.client" />
          {record.startDate && <DateField source="startDate" label="resources.license-comments/sasi.fields.startDate" addLabel showTime={false} />}
          {record.endDate && <DateField source="endDate" label="resources.license-comments/sasi.fields.endDate" addLabel showTime={false} />}
          {record.User && <TextField source="User.username" label="resources.license-comments/sasi.fields.lastUpdatedBy" />}
          <DateField source="createdAt" label="resources.license-comments/sasi.fields.createdAt" addLabel />
          <DateField source="updatedAt" label="resources.license-comments/sasi.fields.updatedAt" addLabel />
          <TextField source="comment" label="resources.license-comments/sasi.fields.comment" style={{ whiteSpace: 'pre-wrap' }} />
        </Tab>
      </TabbedShowLayout>
    </ShowComp>
  )
}
