import React from 'react';

import { timeOptions } from 'lib/consts';
import { TableRowActionButton } from 'components/designSystem/TableRowActionButton';
import { Toggler } from 'components/designSystem/Toggler';

export const licensesColumns = (dataProvider, refresh) => (translate, locale) =>
  [
    {
      key: 'client_licenses_table_column_name',
      id: 'title',
      field: 'title',
      sortable: true,
      header: translate('resources.license-comments/sasi.fields.title'),
    },
    {
      key: 'client_licenses_table_column_provider_type',
      id: 'responsible',
      field: 'responsible',
      default: '-',
      sortable: true,
      header: translate('resources.license-comments/sasi.fields.responsible'),
    },
    {
      key: 'client_licenses_table_column_phase',
      id: 'phase',
      field: (record) => translate(`resources.license-comments/sasi.phases.${record.phase}`),
      sortable: true,
      header: translate('resources.license-comments/sasi.fields.phase'),
    },
    {
      key: 'client_licenses_table_column_updatedAt',
      id: 'updatedAt',
      field: (record) => new Date(record.updatedAt).toLocaleDateString(locale, timeOptions),
      sortable: true,
      header: translate('resources.license-comments/sasi.fields.updatedAt'),
    },
    {
      key: 'client_licenses_table_column_active',
      id: 'active',
      field: (record) => (
        <Toggler
          key={JSON.stringify(record)}
          checked={record.active}
          onClick={(evt) => {
            evt.stopPropagation();
            toggleLicenseActive(dataProvider, record, refresh);
          }}
        />
      ),
      header: translate('resources.projects.buttons.active'),
    },
    {
      key: 'client_licenses_table_column_edit_action',
      id: 'action',
      field: (record) => <TableRowActionButton action="edit" goto={`/license-comments/sasi/${record.id}`} />,
    },
  ];

const toggleLicenseActive = async (dataProvider, license, refresh) => {
  const { id, ...data } = license;
  await dataProvider.update('license-comments/sasi', { id, data: { ...data, active: !license.active } });

  if (refresh) {
    refresh();
  }
};

export const loadLicenses = (dataProvider, setLicense, setTotalLicenses, clientId, filters, dispatch, setLoading) => {
  setLoading(true);
  dataProvider
    .getList('license-comments/sasi', {
      filter: {
        ClientId: clientId,
        showInactive: filters.showInactive,
        limit: filters.limit,
        offset: filters.offset,
        ...(filters.order && filters.orderBy
          ? {
              order: `${filters.orderBy} ${filters.order.toUpperCase()}`,
            }
          : {}),
      },
    })
    .then(({ data, total }) => {
      setLoading(false);
      dispatch(data);
      setLicense(data);
      setTotalLicenses(total);
    })
    .catch((err) => {
      setLoading(false);
      throw err;
    });
};
