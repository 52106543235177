import { genericErrorHandler } from 'lib/Errors';

export function importSites({ dataProvider, setLoading, closeDialog, clientId, notify, reload }) {
  return async (values) => {
    setLoading(true);
    dataProvider
      .execute(`clients/${clientId}/upload`, values)
      .then(() => {
        setLoading(false);
        reload();
        closeDialog();
      })
      .catch((error) => {
        setLoading(false);

        if (typeof error.message === 'string') {
          if (error.message.includes('missing_data')) {
            const missingFields = error.message.split(': ')[1];
            notify('missingSiteDataOnCSVUploadError', 'warning', { fields: missingFields });
          } else {
            genericErrorHandler(notify)(error);
          }
        } else {
          notify('notifications.uploadCsvFailed', 'warning');
        }
      });
  };
}
