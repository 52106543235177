import React, { useState, useMemo } from 'react';
import { useCallback } from 'react';
import DownloadIcon from '@material-ui/icons/GetApp';
import { useDataProvider, useNotify, useTranslate } from 'ra-core';
import { Button } from "ra-ui-materialui"
import { map } from "lodash"
import jsonExport from 'jsonexport/dist'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

const downloadCSV = (csv, filename, ext = ".csv") => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  // added UTF-8 BOM
  csv = "\ufeff" + csv;
  const blob = new Blob([csv], { type: 'text/csv' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    window.navigator.msSaveOrOpenBlob(blob, `${filename}${ext}`);
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob));
    fakeLink.setAttribute('download', `${filename}${ext}`);
    fakeLink.click();
  }
};

const useStyles = makeStyles({
  root: {
    marginTop: 15,
    marginRight: 10,
    marginLeft: 10,
  },
  menu: {
    border: '1px solid #d3d4d5',
  },
})


const noop = () => {
};
const defaultFilter = {}
const exportJson = (data, options = {}) => new Promise((resolve, reject) => {
  jsonExport(data, { ...options, includeHeaders: false }, (err, csvData) => {
    if (err) reject(err)
    resolve(csvData)
  })
})


const ExportOptions = props => {
  return <Menu elevation={0} getContentAnchorEl={null}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'center',
               }}
               transformOrigin={{
                 vertical: 'top',
                 horizontal: 'center',
               }}
               {...props}
  />
}

const ExportButton = ({
  fileName,
  enableCSV = true,
  enableTSV = true,
  onProgress,
  progressRef,
  sort,
  exporter,
  filter = defaultFilter,
  perPage = 100,
  resource,
  onClick,
  label = 'ra.action.export',
  ...rest
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const dataProvider = useDataProvider();
  const notify = useNotify()
  const csvFileName = useMemo(() => [(fileName || resource), new Date().toJSON().replace(/\.\d\d\dZ$/, "")].join("-"), [fileName, resource])
  const [anchorEl, setAnchorEl] = React.useState(null)

  const getList = useCallback((page) => {
    return new Promise((resolve, reject) => {
      dataProvider
        .getList(resource, { sort, filter, pagination: { page, perPage } })
        .then(({ data, total }) => resolve({ data, total, page }))
        .catch(reject)
    })
  }, [sort, filter, dataProvider, resource, perPage])

  const progress = onProgress || (progressRef.current && progressRef.current.onProgress) || noop

  const download = useCallback(({ page = 1, ref = { total: 0, csv: '' }, options = {} }) => {
    return getList(page)
      .then(({ data, total, page }) => {
        const { schema, data: exportData } = exporter(data)
        ref.headers = ref.headers || map(schema, "title").join(options.rowDelimiter || ",")
        return exportJson(exportData, { ...options, headers: map(schema, "id") })
          .then((csv) => ref.csv = [ref.csv, csv].join("\n"))
          .then(() => ({ data, total, page }))
      })
      .then(({ data, total, page }) => {
        ref.total += data.length
        progress(ref.total * 100 / total)
        if (data.length > 0 && ref.total < total) {
          return download({ page: page + 1, ref, options })
        } else {
          progress(100)
          return [ref.headers, ref.csv].join("")
        }
      })
  }, [getList, exporter, progress])

  const downloadAndExport = useCallback(options => {
    progress(1)
    download({ options })
      .then(csv => downloadCSV(csv, csvFileName, options.fileExtension || ".csv"))
      .then(() => setTimeout(() => progress(0), 1000))
      .catch(error => {
        notify(error.message, 'warning')
        setTimeout(() => progress(0), 1000)
      })
  }, [progress, download, csvFileName, notify])

  const exportCSV = useCallback(() => downloadAndExport({
    rowDelimiter: ",",
    fileExtension: ".csv",
  }), [downloadAndExport])
  const exportTSV = useCallback(() => downloadAndExport({
    rowDelimiter: "\t",
    fileExtension: ".tsv",
  }), [downloadAndExport])


  const handleClick = useCallback((event) => {
    if (enableCSV && enableTSV) {
      setAnchorEl(event.currentTarget)
    } else {
      enableCSV && exportCSV()
      enableTSV && exportTSV()
    }
    if (typeof onClick === 'function') {
      onClick(event)
    }
  }, [setAnchorEl, onClick, enableCSV, enableTSV, exportCSV, exportTSV])
  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl])

  return <>
    <Button onClick={handleClick} label={label} {...sanitizeRestProps(rest)}>
      <DownloadIcon/>
    </Button>
    <ExportOptions anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} classes={{ paper: classes.menu }}>
      <MenuItem onClick={exportCSV}>
        <ListItemText primary={translate("titles.exportCSV")}/>
      </MenuItem>
      <MenuItem onClick={exportTSV}>
        <ListItemText primary={translate("titles.exportTSV")}/>
      </MenuItem>
    </ExportOptions>
  </>
}


const sanitizeRestProps = ({ basePath, ...rest }) => rest
export default ExportButton;


const ExportProgress = ({ progressRef, ...props }) => {
  const [progress, setProgress] = useState(0)
  const classes = useStyles()
  progressRef.current = progressRef.current || {}
  progressRef.current.onProgress = setProgress
  return progress > 0 ? <LinearProgress className={classes.root} variant="determinate" value={progress}/> : <span/>
}

export { ExportProgress }
