import React from 'react';
import { TextInput, NumberInput, SimpleForm } from 'ra-ui-materialui';
import {
  Create,
  DefaultToolbar,
  GoogleLocationInput,
  required,
} from 'components';
import MapWithMarker from './components/MapWithMarker';
import { Col, Row } from 'resources/components/Grid';
import { useLocationProps, useAutoRedirect, useAccount } from 'hooks';
import ClientGroup from 'resources/Client/components/ClientGroup';

export default ({ permissions, location, ...props }) => {
  const account = useAccount() || {};

  const record = useLocationProps(location, [
    ['ClientId', 'number', account.ClientId],
    ['ProjectId', 'number'],
  ]);
  const redirect = useAutoRedirect('list');
  const clientLocked = account.userType === 'client' || record.ClientId;
  const projectLocked = !!record.ProjectId;

  return (
    <Create {...props} titleSource="name">
      <SimpleForm
        toolbar={<DefaultToolbar />}
        redirect={redirect}
        initialValues={{ ...record, radius: 0.1 }}
      >
        <Row>
          <Col>
            <TextInput source="name" validate={required()} />
            <TextInput source="tag" />
            <ClientGroup clientLocked={clientLocked} projectLocked={projectLocked} />
          </Col>
          <Col>
            <GoogleLocationInput
              label="resources.sites.fields.location.address"
              source="location"
              validate={required()}
            />
            <TextInput source="referencePoint" />
            <NumberInput source="radius" helperText="km" />
            <MapWithMarker zoom={14} source="location" enableDrag />
          </Col>
        </Row>
      </SimpleForm>
    </Create>
  );
};
