import React from 'react';
import { Modal } from '@material-ui/core';
import ProfileImage from '../../layout/components/ProfileImage';
import { makeStyles } from '@material-ui/styles';
import { useRouter } from '../../hooks';
import { useTranslate } from 'ra-core';
import { Button } from '../Button';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.card,
    width: '472px',
    position: 'absolute',
    top: '63px',
    right: '8px',
    padding: '2rem 1rem',
    borderRadius: '4px',
    fontFamily: 'Nunito, sans-serif',
    boxSizing: 'border-box',
    outline: 'none',
    justifyContent: 'center',
  },
  modalTitle: {
    fontSize: '1.5rem',
    margin: '0',
    padding: '0',
    fontWeight: '400',
  },
  modalText: {
    fontSize: '1.125rem',
    margin: '1rem 0',
    padding: '0',
  },
  headerButton: {
    width: '24px',
    display: 'inline-block',
    height: '24px',
    background: 'white',
    position: 'absolute',
    right: '1.05rem',
    top: '16px',
    borderRadius: '100%',
    cursor: 'pointer',
    margin: '0 12px',
  },
  profileLogo: {
    width: '24px',
    height: '24px',
  },
}));

export const MFAWelcomeMessage = ({ isOpen, handleClose, account }) => {
  const classes = useStyles();
  const { history } = useRouter();
  const translate = useTranslate();

  const handleRedirect = () => {
    history.push('/profile');
    handleClose();
  };

  return (
    <Modal open={isOpen}>
      <div>
        <div className={classes.headerButton} onClick={handleRedirect}>
          <ProfileImage
            profile={account}
            variant="menu"
            className={classes.profileLogo}
          />
        </div>
        <div className={classes.modalContainer}>
          <h1 className={classes.modalTitle}>
            {translate('welcomeMessages.mfa.title')}
          </h1>
          <p className={classes.modalText}>
            {translate('welcomeMessages.mfa.text')}
          </p>
          <Button
            variant="secondary"
            onClick={handleClose}
            label={translate('welcomeMessages.mfa.close')}
          />
        </div>
      </div>
    </Modal>
  );
};
