import React, { useCallback, useState } from 'react';
import { AutocompleteInput } from 'ra-ui-materialui';
import Icon from './Icon';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import sasiIcons from 'lib/sasi-icons';
import { ReadOnlyFormCtx } from './Form';
// import { useTabs } from 'hooks/use-tabs';
// import { Tabs, Tab } from '@material-ui/core';

export const IconPanelTabOrder = {
  library: 0,
  myIcons: 1,
};

const icons = sasiIcons.map((name) => ({
  id: name,
  name,
}));

const Item = (record) => {
  return record && record.id ? (
    <span key={record.id} record={record}>
      <Icon>{record.id}</Icon>
      {record.id}
    </span>
  ) : (
    <span record={{ id: '', name: '' }}>{'\u00A0'}</span>
  );
};
const useStyles = makeStyles((theme) => ({
  suggestionsContainer: {
    zIndex: 99222,
    maxHeight: 200,

    '& > div > div': {
      maxHeight: 200,
    },
  },
  container: {
    position: 'relative',
  },
  label: {
    display: 'block',
    marginBottom: theme.spacing(1),

    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  input: {
    marginBottom: 0,
    '& input': {
      border: 'none',
      background: theme.palette.background.surface.secondary,
      color: theme.palette.text.secondary,
      borderRadius: theme.borderRadius,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
      outline: 'none',
      transition: '.2s background, .1s outline',

      '&:focus': {
        background: theme.palette.background.surface.primary,
        boxShadow: `0 0 0 2px  ${theme.palette.primary.main}`,
      },

      '&:disabled': {
        background: theme.palette.background.surface.pressed,
      },
    },
  },
  block: {
    marginTop: theme.spacing(2.2),
    display: 'block',
  },
  dense: {
    margin: 0,
    marginBottom: theme.spacing(2.8),
  },
  icon: {
    color: theme.palette.text.tertiary,
  },
}));

export const IconDropdown = ({ noPreview, full, dense, label, className, ...props }) => {
  const classes = useStyles();
  // const [tab, setTab] = useTabs(IconPanelTabOrder, { defaultTab: 'library', noURL: true });
  const matchSuggestion = useCallback((filterValue, choice) => filterValue === '' || choice.id.match(filterValue), []);
  const [icon, setIcon] = useState();

  const inputText = useCallback(
    (record) => {
      const icon = get(record, 'id');
      setIcon(icon);
      return icon;
    },
    [setIcon],
  ); //eslint-disable-line

  return (
    <label
      className={`${classes.container} ${full ? classes.block : ''} ${dense ? classes.dense : ''} ${className || ''}`}
    >
      {label && <span className={classes.label}>{label}</span>}

      {/* <Tabs value={tab} onChange={(value) => setTab(value)}>
        <Tab value={IconPanelTabOrder['library']} label={'library'} />
        <Tab value={IconPanelTabOrder['myIcons']} label={'my Icons'} />
      </Tabs> */}

      <AutocompleteInput
        {...props}
        allowEmpty
        choices={icons}
        label=""
        options={{
          suggestionsContainerProps: {
            className: classes.suggestionsContainer,
          },
          InputProps: {
            variant: 'outlined',
            disabled: props.disabled,
            className: classes.input,
            helperText: undefined,
            margin: undefined,
          },
        }}
        limitChoicesToValue={true}
        translateChoice={false}
        suggestionLimit={props.maxItems || 50}
        inputText={inputText}
        optionText={Item}
        matchSuggestion={matchSuggestion}
      />
      {icon && <Icon>{icon}</Icon>}
    </label>
  );
};

export const IconDropdownFormField = (props) => (
  <ReadOnlyFormCtx.Consumer>
    {(readOnly) => <IconDropdown {...props} disabled={props.disabled || readOnly} />}
  </ReadOnlyFormCtx.Consumer>
);
