import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import jwt from 'jsonwebtoken';
import { makeStyles } from '@material-ui/styles';
import { useNotify } from 'ra-core';
import Card from '@material-ui/core/Card';
import { MuiThemeProvider } from '@material-ui/core/styles';
import themeLogin from '../themes/login';
import { Snack } from './Snack';
import { useTranslate } from '../hooks';
import { useLoginStyles } from './Login';
import { ForgotPasswordForm } from './ForgotPasswordForm';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AUTH_QUERY_PARAM = 'authorization';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '1.5rem',
  },
}));

export const ForgotPassword = ({ history, ...props }) => {
  const query = useQuery();
  const [token, setToken] = useState(undefined);

  const loginClasses = useLoginStyles();
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();

  useEffect(() => {
    const t = token || query.get(AUTH_QUERY_PARAM);
    let valid = false;
    try {
      const decode = jwt.decode(t);
      if (decode.exp * 1000 - Date.now() <= 0) {
        notify(`httpError.authentication.JWT_EXPIRED_PASSWORD_RESET_NO_REQUEST`, 'error');
      } else {
        valid = true;
      }
    } catch (err) {} // If not valid, just go on;

    if ((!token && !query.get(AUTH_QUERY_PARAM)) || !valid) {
      history.replace('/login');
    }
    if (!token && query.get(AUTH_QUERY_PARAM)) {
      setToken(query.get(AUTH_QUERY_PARAM));
      query.delete(AUTH_QUERY_PARAM);
    }
  }, []); //eslint-disable-line

  const handleSuccess = () => {
    notify(`resources.users.passwordUpdated`, 'info');
    history.replace('/login');
  };

  return (
    <MuiThemeProvider theme={themeLogin}>
      <div className={loginClasses.main}>
        <div className={loginClasses.cardContainer}>
          <div className={loginClasses.logo}>
            <img src="/assets/logo.png" className={loginClasses.logo} alt="logo" />
            <span>{translate('titles.logoText')}</span>
          </div>
          <Card className={`${loginClasses.card} ${classes.container}`}>
            <ForgotPasswordForm token={token} onSuccess={handleSuccess} />
          </Card>
        </div>
        <Snack />
      </div>
    </MuiThemeProvider>
  );
};
