import React, { useState } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { DropdownFormField } from 'components/designSystem/Dropdown';
import { providerTypes } from 'lib/consts';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
// import { CheckCircle } from '@material-ui/icons';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { Form } from 'react-final-form';
import { required, requiredEmailField } from 'lib/formValidators';
import { useDataProvider, useTranslate, useNotify } from 'ra-core';
import { handleCreateProvider } from './CreateProviderDialog.model';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    // marginBottom: '20px',
  },

  bottomContainer: {
    display: 'flex',
    // alignItems: 'center',
    marginTop: theme.spacing(3),
    justifyContent: 'end',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    alignItems: 'center',

    '& button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginLeft: 16,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 20,
  },
}));

export const CreateProviderDialog = ({ clientId, isOpen, close, refresh }) => {
  const t = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog isOpen={isOpen} close={close} title={t('resources.monitoring-providers.create')}>
      <Form
        initialValues={{ providerType: providerTypes[0].id }}
        onSubmit={handleCreateProvider(dataProvider, clientId, close, setLoading, refresh, notify)}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.content}>
              {values.providerType === 'webhook' && <WebhookForm t={t} />}
              {values.providerType === 'email' && <EmailForm t={t} />}
              {values.providerType === 'api' && <ApiForm t={t} />}
            </div>

            <BaseForm t={t} />

            <div className={classes.bottomContainer}>
              <TertiaryButton disabled={loading} label={t('actionButtons.cancel')} onClick={close} />
              <PrimaryButton
                disabled={loading}
                type="submit"
                label={t('actionButtons.create')}
                onClick={handleSubmit}
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};

const BaseForm = ({ t }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.content}>
        <TextFormField
          name="name"
          validate={required(t)}
          full
          dense
          size="full"
          required
          label={t('resources.monitoring-providers.nameProvider')}
          placeholder={t('resources.monitoring-providers.placeholderName')}
          iconError={<WarningRoundedIcon />}
          iconPosition="right"
        />
      </div>

      <div className={classes.content}>
        <TextFormField
          name="email"
          validate={requiredEmailField(t)}
          full
          dense
          required
          size="full"
          label={t('resources.monitoring-providers.emailProvider')}
          placeholder={t('resources.monitoring-providers.placeholderEmail')}
          iconError={<WarningRoundedIcon />}
          iconPosition="right"
        />
      </div>
      <div className={classes.content}>
        <DropdownFormField
          name="providerType"
          validate={required(t)}
          full
          items={providerTypes}
          label={t('resources.monitoring-providers.typeProvider')}
          translate
        />
      </div>
    </div>
  );
};

const WebhookForm = ({ t }) => (
  <TextFormField
    name="callbackUrl"
    validate={required(t)}
    full
    dense
    size="full"
    label="Para onde os alertas devem ser enviados?"
    placeholder="Insira a URL do 'webhook' destino."
  />
);

const EmailForm = ({ t }) => (
  <TextFormField
    name="notifyEmail"
    validate={requiredEmailField(t)}
    full
    dense
    size="full"
    label="Para quem os alertas devem ser enviados?"
    placeholder="Insira o email destino."
  />
);

const ApiForm = ({ t }) => (
  <>
    <TextFormField
      name="managerName"
      validate={required(t)}
      full
      dense
      size="full"
      label="Nome do gerente"
      placeholder="Insira o nome do gerente deste provedor."
    />
    <TextFormField
      name="managerUsername"
      validate={required(t)}
      full
      dense
      size="full"
      label="Usuário do gerente"
      placeholder="Insira o nome de usuário do gerente deste provedor."
    />
    <TextFormField
      name="managerEmail"
      validate={requiredEmailField(t)}
      full
      dense
      size="full"
      label="Email do gerente"
      placeholder="Insira o email do gerente deste provedor."
    />
  </>
);
