import React from 'react';
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/styles';
import { TimedRequestButton } from '../../components/TimedRequestButton';

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

const useStyles = makeStyles(() => ({
  passwordRulesContainer: {
    color: '#8A94A8',
    transform: 'translateY(-1rem)',
    marginTop: '5px',
    fontSize: '0.813rem',
  },
  passwordRulesTitle: {
    margin: '0',
    padding: '0',
  },
  passwordRulesItems: {
    margin: '0',
    paddingLeft: '1rem',
  },
  passwordRulesItemErrored: {
    color: '#f44336',
  },
}));

const updateUserProfile = (values, authProvider) => {
  const userData = { ...values };
  if (userData.password) {
    delete userData.password;
    delete userData.passwordConfirm;
  }
  authProvider.updateProfile(userData);
};

const handleSaveUserProfile = async (id, data, authProvider, dataProvider) => {
  const { data: values } = await dataProvider.update(`users`, { id, data });
  updateUserProfile(values, authProvider);
};

export const updateNameStep = (account, authProvider, dataProvider, notify) => ({
  key: 'user_name_update',
  title: 'Mudar nome',
  fields: [
    {
      id: 'name',
      name: 'name',
      type: 'text',
      key: 'user_name_update_input',
      label: 'Novo nome',
      placeholder: account.name,
      validate: (value) => (!value || value === '' ? 'resources.users.errors.fields.required' : undefined),
    },
  ],
  buttonPosition: 'end',
  onSubmit:
    (setLoading) =>
    async ({ name }) => {
      setLoading(true);
      await handleSaveUserProfile(account.id, { name }, authProvider, dataProvider);
      notify('notifications.profileUpdated');
      setLoading(false);
    },
});

export const updatePasswordStep = (account, authProvider, dataProvider, notify) => ({
  key: 'user_password_update',
  title: 'Atualizar senha',
  fields: [
    {
      id: 'oldPassword',
      name: 'oldPassword',
      type: 'password',
      key: 'user_password_update_input_old',
      label: 'Senha atual',
      validate: (value) => (!value || value === '' ? 'resources.users.errors.fields.required' : undefined),
    },
    {
      id: 'password',
      name: 'password',
      type: 'password',
      validate: (value) => {
        if (!value) {
          return ['INVALID_LENGTH'];
        }

        const errors = [];
        if (value.length < 8 || value.length > 64) {
          errors.push('INVALID_LENGTH');
        }
        const atLeastOneUppercase = /\w*[A-Z]\w*/;
        const atLeastOneNumber = /\w*[0-9]\w*/;
        const atLeastOneLowercase = /\w*[a-z]\w*/;
        if (!value.match(atLeastOneUppercase)) {
          errors.push('NO_UPPERCASE');
        }
        if (!value.match(atLeastOneLowercase)) {
          errors.push('NO_LOWERCASE');
        }
        if (!value.match(atLeastOneNumber)) {
          errors.push('NO_NUMBER');
        }
        return errors.length > 0 ? errors : undefined;
      },
      key: 'user_password_update_input_new',
      label: 'Senha nova',
      customError: true,
      Suffix: () => {
        const state = useFormState();
        const classes = useStyles();
        const errors = state.errors.password;
        const touched = state.touched.password;

        const invalidLength = errors && errors.find((e) => e === 'INVALID_LENGTH');
        const noUppercase = errors && errors.find((e) => e === 'NO_UPPERCASE');
        const noLowercase = errors && errors.find((e) => e === 'NO_LOWERCASE');
        const noNumber = errors && errors.find((e) => e === 'NO_NUMBER');

        return (
          <div className={classes.passwordRulesContainer}>
            <p className={classes.passwordRulesTitle}>Sua senha deve conter no mínimo:</p>
            <ul className={classes.passwordRulesItems}>
              <li className={invalidLength && touched ? classes.passwordRulesItemErrored : undefined}>
                8 caracteres (não mais que 64);
              </li>
              <li className={noUppercase && touched ? classes.passwordRulesItemErrored : undefined}>
                1 letra maiúscula;
              </li>
              <li className={noLowercase && touched ? classes.passwordRulesItemErrored : undefined}>
                1 letra minúscula;
              </li>
              <li className={noNumber && touched ? classes.passwordRulesItemErrored : undefined}>1 número;</li>
            </ul>
          </div>
        );
      },
    },
    {
      id: 'passwordConfirm',
      name: 'passwordConfirm',
      type: 'password',
      validate: (value, allValues) =>
        value !== allValues.password ? 'resources.users.errors.fields.passwordsDontMatch' : undefined,
      key: 'user_password_update_input_new_confirm',
      label: 'Confirmar senha nova',
    },
  ],
  buttonPosition: 'end',
  onSubmit:
    (setLoading) =>
    async ({ oldPassword, password, passwordConfirm }) => {
      setLoading(true);
      await handleSaveUserProfile(account.id, { oldPassword, password, passwordConfirm }, authProvider, dataProvider);
      notify('notifications.profileUpdated');
      setLoading(false);
    },
});

export const validatePasswordStep = (account, authProvider, dataProvider, after) => ({
  key: 'mfa_password_validation',
  text: 'Para iniciar a configuração da autentificação multifatores, insira sua senha atual:',
  fields: [
    {
      id: 'password',
      name: 'password',
      type: 'password',
      key: 'mfa_password_validation_input',
      label: 'Senha',
      validate: (value) => (!value || value === '' ? 'resources.users.errors.fields.required' : undefined),
    },
  ],
  onSubmit:
    (setLoading) =>
    async ({ password }) => {
      setLoading(true);
      const { data } = await dataProvider.execute(`users/${account.id}/authorize/update-mfa`, { password });
      authProvider.saveUpdateMFAToken(data.token);

      if (after) {
        await after(data.token);
      }
      setLoading(false);
    },
});

export const verifyPhoneStep = (account, authProvider, dataProvider, notify, noData, resend) => ({
  back: true,
  key: 'phone_verification',
  text: 'Acabamos de enviar uma mensagem de texto com um código de verificação de 7 dígitos para seu número. Por favor, insira abaixo o código recebido:',
  fields: [
    {
      id: 'code',
      type: 'text',
      name: 'code',
      key: 'phone_verification_input',
      validate: (value) => (!value || value === '' ? 'resources.users.errors.fields.required' : undefined),
      Suffix: () => (
        <TimedRequestButton
          sendingLabel="O seu código de verificação chegará em até:"
          sentLabel="Não recebeu o código?"
          buttonLabel="Reenviar Código"
          resend={resend}
        />
      ),
    },
  ],
  title: 'Verifique seu celular',
  onSubmit:
    (setLoading) =>
    async ({ code }) => {
      setLoading(true);
      const token = authProvider.getUpdateMfaToken();
      const payload = {
        code: code.toUpperCase(),
        token,
      };
      if (!noData) {
        payload.phone = authProvider.getTempProp();
      }
      const { data } = await dataProvider.execute('users/phone/verify', payload);
      updateUserProfile(data.profile, authProvider);
      notify('notifications.profileUpdated');
      setLoading(false);
    },
});

export const verifyEmailStep = (account, authProvider, dataProvider, notify, noData, resend) => ({
  back: true,
  key: 'email_verification',
  text: 'Acabamos de enviar um email com um código de verificação de 7 dígitos para seu endereço de email. Por favor, insira abaixo o código recebido:',
  fields: [
    {
      id: 'code',
      type: 'text',
      name: 'code',
      key: 'email_verification_input',
      validate: (value) => (!value || value === '' ? 'resources.users.errors.fields.required' : undefined),
      Suffix: () => (
        <TimedRequestButton
          sendingLabel="O seu código de verificação chegará em até:"
          sentLabel="Não recebeu o código?"
          buttonLabel="Reenviar Código"
          resend={resend}
        />
      ),
    },
  ],
  title: 'Verifique seu email',
  onSubmit:
    (setLoading) =>
    async ({ code }) => {
      setLoading(true);
      const token = authProvider.getUpdateMfaToken();
      const payload = {
        code: code.toUpperCase(),
        token,
      };
      if (!noData) {
        payload.email = authProvider.getTempProp();
      }
      const { data } = await dataProvider.execute('users/email/verify', payload);
      updateUserProfile(data.profile, authProvider);
      notify('notifications.profileUpdated');
      setLoading(false);
    },
});

export const addPhoneSteps = (account, authProvider, dataProvider, notify) => [
  {
    key: 'add_mfa_method_phone',
    text: 'Insira abaixo um número de celular confiável para que você possa receber SMS com o seu código de verificação. Não esqueça do código do país e DDD, por exemplo: +5595991111111.',
    fields: [
      {
        id: 'phone',
        type: 'text',
        name: 'phone',
        key: 'add_mfa_method_phone_input',
        validate: (value) => {
          if (value && !value.match(/^\+[\d]{8,20}$/)) {
            return 'validation.phoneNumber';
          }
          return undefined;
        },
      },
    ],
    buttonLabel: 'Enviar',
    onSubmit:
      (setLoading) =>
      async ({ phone }) => {
        setLoading(true);
        const token = authProvider.getUpdateMfaToken();
        await dataProvider.execute(`users/${account.id}/update-mfa`, { phone, token });
        authProvider.setTempProp(phone);
        setLoading(false);
      },
  },
  verifyPhoneStep(account, authProvider, dataProvider, notify, undefined, async () => {
    const token = authProvider.getUpdateMfaToken();
    const phone = authProvider.getTempProp();
    await dataProvider.execute(`users/${account.id}/update-mfa`, { phone, token });
  }),
];

export const addEmailSteps = (account, authProvider, dataProvider, notify) => [
  {
    key: 'add_mfa_method_email',
    text: 'Insira abaixo seu endereço de email para que você possa receber uma mensagem com o seu código de verificação.',
    fields: [
      {
        id: 'email',
        type: 'text',
        name: 'email',
        key: 'add_mfa_method_email_input',
        validate: (value) => {
          if (!value || value.length === 0 || !EMAIL_REGEX.test(value)) {
            return 'ra.validation.email';
          }
        },
      },
    ],
    buttonLabel: 'Enviar',
    onSubmit:
      (setLoading) =>
      async ({ email }) => {
        setLoading(true);
        const token = authProvider.getUpdateMfaToken();
        await dataProvider.execute(`users/${account.id}/update-mfa`, { email, token });
        authProvider.setTempProp(email);
        setLoading(false);
      },
  },
  verifyEmailStep(account, authProvider, dataProvider, notify, undefined, async () => {
    const token = authProvider.getUpdateMfaToken();
    const email = authProvider.getTempProp();
    await dataProvider.execute(`users/${account.id}/update-mfa`, { email, token });
  }),
];

export const setupPhoneStep = (account) => ({
  back: true,
  buttonInverse: true,
  key: 'setup_phone_confirmation',
  text: `Seu número de celular configurado é ${account.phone}.`,
  fields: [],
  title: 'Autenticação via SMS ativa',
  buttonLabel: 'Atualizar',
  onSubmit: () => async () => {},
});

export const setupEmailStep = (account) => ({
  back: true,
  buttonInverse: true,
  key: 'setup_email_confirmation',
  text: `Seu email configurado é ${account.email}.`,
  fields: [],
  title: 'Autenticação via e-mail ativa',
  buttonLabel: 'Atualizar',
  onSubmit: () => async () => {},
});
