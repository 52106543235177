import React, { useEffect, useState } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { DropdownFormField } from 'components/designSystem/Dropdown';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

// import { CheckCircle } from '@material-ui/icons';

import { Form, FormSpy, useFormState } from 'react-final-form';
import { required } from 'lib/formValidators';
import { useDataProvider, useTranslate, useNotify } from 'ra-core';
import { handleCreateApp, loadClientAndProjects, loadProjects } from './CreateAppDialog.model';
import { useProjects } from 'hooks/use-resource-hooks';
import { useListFilters, useAccount } from 'hooks';
import { LabeledSwitchField } from 'components/designSystem/LabeledSwitch';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'end',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    alignItems: 'center',

    '& button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginLeft: 16,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  setDefaultContainer: {
    justifyContent: 'flex-end',
    position: 'relative',
    marginTop: 100,
    top: theme.spacing(3) * -1,
    left: theme.spacing(1) * -1,
    '& > div': {
      color: theme.palette.text.hint,
    },
  },
  content: {
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  contentDefault: {
    paddingTop: 20,
  },
  contentDropdown: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const mapProject = (project, t) => {
  let name = project.name;
  if (project.name === 'default' && project.isDefault) {
    name = t('resources.projects.default');
  }
  return { id: project.id, name, default: project.isDefault };
};

const ClientProjectFields = ({ isOpen, clientId: pClientId, form, template }) => {
  const account = useAccount();
  const t = useTranslate();
  const { values } = useFormState();
  const dataProvider = useDataProvider();

  const clientIds = account.clientIds || [];
  const clientId = account.ClientId || pClientId || (clientIds.length === 1 ? clientIds[0] : undefined);
  const hideClientId = !template || clientIds.length === 1 || (clientIds.length === 0 && account.ClientId);

  const { projects: stateProjects, dispatch } = useProjects(clientId);

  const filters = useListFilters({ orderBy: 'id', order: 'desc' });
  const [projects, setProjects] = useState(stateProjects.map((p) => mapProject(p, t)));
  const [clients, setClients] = useState([]);

  const reload = () => {
    loadClientAndProjects({
      dataProvider,
      setProjects: (projects) => setProjects(projects.map((p) => mapProject(p, t))),
      clientId,
      filters,
      dispatch,
      setClients,
      shouldLoadClients: !hideClientId,
    });
  };

  useEffect(() => {
    if (isOpen) {
      reload();
    }
  }, [isOpen]); //eslint-disable-line

  useEffect(() => {
    if (values.clientId && values.clientId !== clientId) {
      loadProjects({
        dataProvider,
        setProjects: (projects) => setProjects(projects.map((p) => mapProject(p, t))),
        clientId: values.clientId,
        filters,
        dispatch,
      });
    }
  }, [values.clientId]); //eslint-disable-line

  useEffect(() => {
    if (values.clientId) {
      form.change('projectId', projects.find((project) => project.default)?.id);
    }
  }, [projects]); //eslint-disable-line

  return (
    <>
      <FormSpy
        subscription={{ initialValues: true }}
        onChange={() => {
          form.change('clientId', clientId);
          form.change('projectId', projects.find((project) => project.default)?.id);
        }}
      />
      {!!hideClientId || (
        <DropdownFormField
          name="clientId"
          validate={required(t)}
          required
          full
          disabled={(clients || []).length === 0}
          items={clients || []}
          label={t('resources.apps.fields.client')}
          placeholder={t('resources.apps.fields.placeholderClient')}
          translate
        />
      )}
      <DropdownFormField
        name="projectId"
        validate={required(t)}
        full
        disabled={!values.clientId}
        items={projects || []}
        label={t('resources.apps.fields.project')}
        placeholder={t('resources.channels.placeholders.project')}
        translate
      />
    </>
  );
};

export const CreateAppDialog = ({ clientId, isOpen, close, onCreate, template }) => {
  const t = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();

  return (
    <Dialog isOpen={isOpen} close={close} title={t('resources.apps.fields.title')}>
      <Form
        initialValues={{
          config: template?.config || {},
        }}
        onSubmit={handleCreateApp({
          dataProvider,
          close,
          onCreate,
          template,
          notify,
        })}
        render={({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.content}>
              <TextFormField
                name="name"
                validate={required(t)}
                dense
                size="full"
                label={t('resources.apps.fields.nameApp')}
                placeholder={t('resources.apps.fields.placeholderName')}
                required
                iconError={<WarningRoundedIcon />}
                iconPosition="right"
              />
            </div>
            <div className={classes.content}>
              <ClientProjectFields isOpen={isOpen} clientId={clientId} form={form} template={template} />
            </div>

            <div className={classes.content}>
              <TextFormField
                name="config.nickname"
                dense
                size="full"
                label={t('resources.apps.fields.nickname')}
                placeholder={t('resources.apps.fields.placeholderNickname')}
              />
            </div>

            <div className={classes.content}>
              <TextFormField
                name="description"
                dense
                size="full"
                label={t('resources.apps.fields.description')}
                placeholder={t('resources.apps.fields.placeholderDescription')}
              />
            </div>

            <div className={classes.contentDefault}>
              <LabeledSwitchField
                reverse
                className={classes.setDefaultontainer}
                name="isDefault"
                label={t('resources.channels.fields.config.main')}
                caption={t('resources.channels.fields.placeholders.main')}
              />
            </div>

            <div className={classes.bottomContainer}>
              <TertiaryButton disabled={submitting} label={t('actionButtons.cancel')} onClick={close} />
              <PrimaryButton
                disabled={submitting}
                type="submit"
                label={t('actionButtons.create')}
                onClick={handleSubmit}
                // icon={<CheckCircle />}
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};
