import React, { useState } from 'react';
import {
  Datagrid,
  TextField,
  ReferenceField,
  Filter,
  EditButton,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'ra-ui-materialui';
import { useRefresh, useTranslate } from 'ra-core';
import { List, ShowLink, checkPermissions } from 'components';
import ActiveSwitch from 'resources/components/ActiveSwitch';
import FilterSwitch from 'resources/components/FilterSwitch';
import DownshiftInput from 'components/DownshiftInput';
import { useAccount } from 'hooks';
import { ExportSiteDialog } from './components/ExportSiteDialog';
import { ImportSiteDialog } from './components/ImportSiteDialog';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { GetApp, Publish } from '@material-ui/icons';

const SitesFilter = ({ hasClient, ...props }) => (
  <Filter {...props}>
    {hasClient || (
      <DownshiftInput type="text" source="ClientId" reference="clients" optionValue="id" optionText="name" alwaysOn />
    )}
    <FilterSwitch type="checkbox" source="showInactive" label="fields.showInactive" alwaysOn />
  </Filter>
);

export default ({ permissions, ...props }) => {
  const t = useTranslate();
  const account = useAccount();
  const editable = checkPermissions(permissions, 'sites:update');
  const canSeeApps = checkPermissions(permissions, 'apps:view');
  const canSeeCodes = checkPermissions(permissions, 'registration-codes:view');
  const refresh = useRefresh();
  const hasClient = !!account.ClientId;

  const [exportSiteDialogOpen, setExportSiteDialogOpen] = useState(false);
  const [importSiteDialogOpen, setImportSiteDialogOpen] = useState(false);

  return (
    <>
      <ExportSiteDialog
        clientId={account.ClientId}
        isOpen={exportSiteDialogOpen}
        close={() => setExportSiteDialogOpen(false)}
      />
      <ImportSiteDialog
        clientId={account.ClientId}
        isOpen={importSiteDialogOpen}
        reload={refresh}
        close={() => setImportSiteDialogOpen(false)}
      />
      <List
        {...props}
        filters={<SitesFilter hasClient={hasClient} />}
        filter={{ siteType: 'site' }}
        customActions={
          <>
            <TertiaryButton
              key="client_export_site_button"
              label={t('resources.sites.exportSites')}
              icon={<GetApp />}
              style={{ marginRight: 8 }}
              onClick={() => setExportSiteDialogOpen(true)}
            />
            <TertiaryButton
              key="client_import_site_button"
              label={t('resources.sites.importSites')}
              icon={<Publish />}
              style={{ marginRight: 8 }}
              onClick={() => setImportSiteDialogOpen(true)}
            />
          </>
        }
      >
        <Datagrid>
          <ShowLink source="name" />
          <TextField source="tag" />
          {!hasClient && (
            <ReferenceField
              basePath="/clients"
              source="ClientId"
              reference="clients"
              link="show"
              allowEmpty
              sortBy="Client.name"
            >
              <TextField source="name" />
            </ReferenceField>
          )}
          {canSeeCodes && (
            <ArrayField source="registrationCodes" fieldKey="id" linkType={false}>
              <SingleFieldList>
                <ChipField source="code" />
              </SingleFieldList>
            </ArrayField>
          )}
          <TextField source="location.formatted" />
          <TextField source="referencePoint" />
          <ActiveSwitch source="active" sortable={false} onUpdate={refresh} readonly={!editable} />
          {canSeeApps && (
            <ReferenceField source="appId" basePath="/apps" reference="apps" link={false} allowEmpty sortable={false}>
              <TextField source="name" />
            </ReferenceField>
          )}
          {editable && <EditButton />}
        </Datagrid>
      </List>
    </>
  );
};
