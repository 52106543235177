import { timeOptions } from 'lib/consts';

export const appsColumns = (translate, locale) => [
  {
    key: 'client_apps_table_column_name',
    id: 'name',
    field: 'name',
    header: translate('resources.apps.fields.name'),
  },
  {
    key: 'client_apps_table_column_project',
    id: 'project',
    field: (record) =>
      record.Project.isDefault && record.Project.name === 'default'
        ? translate('resources.projects.default')
        : record.Project.name,
    header: translate('resources.apps.fields.project'),
  },
  {
    key: 'client_apps_table_column_publishedVersion',
    id: 'publishedVersion',
    field: 'publishedVersion',
    header: translate('resources.apps.fields.publishedVersion'),
  },
  {
    key: 'client_apps_table_column_description',
    id: 'description',
    field: 'description',
    header: translate('resources.apps.fields.description'),
    minWidth: '300px',
    shouldBreak: true,
  },
  {
    key: 'client_apps_table_column_updatedAt',
    id: 'updatedAt',
    header: translate('resources.apps.fields.updatedAt'),
    field: (record) => new Date(record.updatedAt).toLocaleDateString(locale, timeOptions),
  },
];

export const loadApps = (dataProvider, setApps, setTotalApps, clientId, projectId, filters, dispatch, setLoading) => {
  setLoading(true);
  dataProvider
    .getList('apps', {
      filter: {
        clientId,
        projectId,
        q: filters.qSearch,
        limit: filters.limit,
        offset: filters.offset,
        withProject: true,
        withoutSchema: true,
        ...(filters.order && filters.orderBy
          ? {
              order: `${filters.orderBy} ${filters.order.toUpperCase()}`,
            }
          : {}),
      },
    })
    .then(({ data, total }) => {
      dispatch(data);
      setLoading(false);
      setApps(data);
      setTotalApps(total);
    })
    .catch((err) => {
      setLoading(false);
      throw err;
    });
};

export const loadProjects = (dataProvider, setProjects, clientId, dispatch) =>
  dataProvider
    .getList('projects', {
      filter: {
        ClientId: clientId,
        showDeleted: false,
        limit: 50,
        offset: 0,
        order: 'id DESC',
      },
    })
    .then(({ data }) => {
      dispatch(data);
      setProjects(data);
    });
