import { useContext, useState } from 'react';
import ThemeContext from 'lib/ThemeContext';
import Storage from 'lib/storage';

export default function useTheme() {
  const context = useContext(ThemeContext);
  return context;
}

export function useThemeContext() {
  const context = useTheme();
  const [theme, setTheme] = useState(Storage.getString('theme') || context.getThemeName());

  let selectedTheme = context.getThemes()[theme];
  if (!selectedTheme) {
    selectedTheme = context.getTheme();
    Storage.setString('theme', context.getThemeName());
    setTheme(context.getThemeName());
  }

  return {
    getThemes: () => context.getThemes(),
    getTheme: () => selectedTheme,
    getThemeName: () => theme,
    changeTheme: (theme) => {
      Storage.setString('theme', theme);
      setTheme(theme);
    },
  };
}
