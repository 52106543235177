import { makeStyles } from "@material-ui/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { FormFieldWrapper } from "./FormWrapper";
import React from "react";

const useStyles = makeStyles(() => ({
  fieldWithInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    '&>div': {
      minWidth: '256px',
    },
  },
  infoIcon: {
    cursor: 'inherit',
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      height: 'unset',
      width: '20px',
    },
  },
}));

export const InputWithInfo = ({ children, info, ...props }) => {
  const classes = useStyles();

  if (!children) {
    throw new Error('Invalid children amount for component');
  }

  return (
    <FormFieldWrapper className={classes.fieldWithInfoContainer}>
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { ...props });
        }
      })}
      <Tooltip title={info}>
        <IconButton onClick={noop} className={classes.infoIcon}>
          <Info/>
        </IconButton>
      </Tooltip>
    </FormFieldWrapper>
  )
}

const noop = () => null;
