import { genericErrorHandler } from 'lib/Errors';

export const handleDeleteProject =
  ({ dataProvider, close, setLoading, refresh, notify }) =>
  async ({ id }) => {
    setLoading(true);

    try {
      await dataProvider.delete('projects', { id });
      if (refresh) {
        refresh();
      }
    } catch (err) {
      genericErrorHandler(notify)(err);
    } finally {
      setLoading(false);
      close();
    }
  };
