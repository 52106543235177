import React from "react"
import { Filter} from "ra-ui-materialui"
import FilterSwitch from "resources/components/FilterSwitch";

const AlertsFilter = props => (
  <Filter {...props} initialValues={{}}>
    <FilterSwitch source="test" alwaysOn/>
  </Filter>
)
export default AlertsFilter
