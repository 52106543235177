import React from "react"
import {SaveButton, DeleteButton, Toolbar} from "ra-ui-materialui"

const DefaultToolbar = ({visible = true, ...props}) => {
  const {allowDelete, ...rest} = props
  return (visible ? <Toolbar {...rest}>
        <SaveButton redirect={props.redirect || "show"} submitOnEnter={true} />
        {allowDelete && <DeleteButton redirect="list"/>}
    </Toolbar> : <span/>)
}

export default DefaultToolbar
