import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslate } from 'ra-core';
import { Field } from './Form';
import PhoneInput from 'react-phone-number-input';
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    marginTop: 10,
  },
  label: {
    display: 'block',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.tertiary,
    marginTop: theme.spacing(0.65),
    fontWeight: theme.typography.fontWeightMedium,
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  helperIcon: {
    color: theme.palette.text.hint,
    fontSize: theme.spacing(2.2),
    marginTop: theme.spacing(0.65),
    marginLeft: theme.spacing(0.5),
    position: 'relative',
    top: 1,
    cursor: 'hover',
  },
  telephoneInputContainer: {
    background: theme.palette.background.default,
    color: theme.palette.text.secondary,
    fontSize: 14,
    boxSizing: 'border-box',
    paddingTop: 12,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 12,
    borderRadius: theme.borderRadius,
    // outline: `2px solid ${theme.palette.primary.main}`,

    '&:active': {
      background: theme.palette.background.surface.primary,
      boxShadow: `0 0 0 2px  ${theme.palette.primary.main}`,
    },

    '& input': {
      fontSize: 14,
      border: 'none',
      color: theme.palette.text.secondary,
      background: theme.palette.background.default,
      paddingLeft: 12,
      outline: 'none',

      '&:active': {
        background: theme.palette.background.surface.primary,
        // boxShadow: `0 0 0 2px  ${theme.palette.primary.main}`,
      },
    },
    '&:disabled': {
      background: theme.palette.background.surface.pressed,
    },
  },
}));

export const PhoneField = React.forwardRef(({ input, ...props }, ref) => {
  const {
    value,
    onChange,
    onClick,
    onFocus,
    onBlur,
    label,
    placeholder,
    disabled,
    className,
    required,
    helperText,
    international,
  } = { ...(input || {}), ...props };

  const formatedValue = !international ? value : value.replace('+', '');
  const classes = useStyles();
  const t = useTranslate();
  return (
    <label className={`${classes.container} ${className || ''}`}>
      {label && (
        <div className={classes.labelContainer}>
          <span className={classes.label}>
            {label}
            {required && <span className={classes.requiredFlag}>*</span>}
          </span>
          {helperText && (
            <Tooltip className={classes.helperInfo} title={helperText}>
              <InfoOutlinedIcon className={classes.helperIcon} />
            </Tooltip>
          )}
        </div>
      )}

      <PhoneInput
        className={classes.telephoneInputContainer}
        name="config.phone"
        label={t('resources.channels.fields.config.phone')}
        helperText={t('resources.channels.fields.config.helpertext')}
        value={formatedValue}
        onChange={onChange}
        international={international}
        ref={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        placeholder={placeholder}
        disabled={disabled}
      />
    </label>
  );
});

export const PhoneFormField = (props) => <Field {...props} component={PhoneField} />;
