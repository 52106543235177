import React from 'react';

import { Chip } from '@material-ui/core';
import { TableRowActionButton } from 'components/designSystem/TableRowActionButton';
import { Toggler } from 'components/designSystem/Toggler';

export const teamsColumns = (dataProvider, refresh) => (translate) =>
  [
    {
      key: 'client_teams_table_column_name',
      sortable: true,
      id: 'name',
      field: 'name',
      header: 'Nome do Time',
    },
    {
      key: 'client_teams_table_column_tag',
      sortable: true,
      id: 'tag',
      field: 'tag',
      default: '-',
      header: 'Tag',
    },
    {
      key: 'client_teams_table_column_registration_codes',
      id: 'registrationCodes',
      field: (record) => (
        <div>
          {record.registrationCodes.map((v, index) => (
            <Chip
              style={{ cursor: 'pointer' }}
              label={v.code}
              key={`client_teams_table_column_registration_codes_${v.id}_${index}`}
            />
          ))}
        </div>
      ),
      header: 'Códigos de Registro',
    },
    {
      key: 'client_teams_table_column_active',
      id: 'active',
      field: (record) => (
        <Toggler
          key={JSON.stringify(record)}
          checked={record.active}
          onClick={(evt) => {
            evt.stopPropagation();
            toggleTeamActive(dataProvider, record, refresh);
          }}
        />
      ),
      header: translate('resources.projects.buttons.active'),
    },
    {
      key: 'client_teams_table_column_app',
      id: 'appId',
      sortable: true,
      field: (record) => record.App?.name || '-',
      header: 'App',
    },
    {
      key: 'client_teams_table_column_edit_action',
      id: 'action',
      field: (record) => <TableRowActionButton action="edit" goto={`/teams/${record.id}`} />,
    },
  ];

const toggleTeamActive = async (dataProvider, site, refresh) => {
  const { id, ...data } = site;
  await dataProvider.update('sites', { id, data: { ...data, active: !site.active } });

  if (refresh) {
    refresh();
  }
};

export const handleCreate = (clientId, history) => (evt) => {
  evt.stopPropagation();
  const location = history.location;
  history.push(`/teams/create?ClientId=${clientId}&redirect=${location.pathname}${location.search || ''}`);
};

export const loadTeams = (dataProvider, setTeams, setTotalTeams, clientId, filters, dispatch, setLoading) => {
  setLoading(true);
  dataProvider
    .getList('sites', {
      filter: {
        ClientId: clientId,
        q: filters.qSearch,
        showInactive: filters.showInactive,
        exportMode: 1,
        siteType: 'team',
        limit: filters.limit,
        offset: filters.offset,
        ...(filters.order && filters.orderBy
          ? {
              order: `${filters.orderBy} ${filters.order.toUpperCase()}`,
            }
          : {}),
      },
    })
    .then(({ data, total }) => {
      setLoading(false);
      dispatch(data);
      setTeams(data);
      setTotalTeams(total);
    })
    .catch((err) => {
      setLoading(false);
      throw err;
    });
};
