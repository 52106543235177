import React, { useCallback, useEffect } from 'react';
import { Toolbar, Datagrid, FunctionField } from 'ra-ui-materialui';
import { useTranslate, useNotify, useDataProvider, ListContext } from 'ra-core';
import { QuickDeleteButton } from 'components';
import { map, uniqBy, fromPairs, flatMap } from 'lodash';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import ChannelExpandPanel from './ChannelExpandPanel';
import SampleButton from 'resources/components/SampleButton';
import { useState /*useCopyPaste*/ } from 'hooks';
import SampleEditPopover from 'resources/components/SampleEditPopover';
import { ReorderDialog } from 'resources/components/Reoder';
// import { CopyButton } from 'components/CopyPaste';
import VisibilityConfig from './VisibilityConfig';
import CreateChannelButton from './CreateChannelButton';
import ChannelRefs from './ChannelRefs';
import AssignChannels from './AssignChannels';

const useStyles = makeStyles({
  thead: {
    display: 'none',
  },
});

const defaultState = {
  anchorEl: null,
  channel: {},
};

const GroupExpandChannels = ({ record, readonly, clientOnly }) => {
  const appId = record.id;
  const ClientId = record.clientId;

  const dataProvider = useDataProvider();
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();

  // const [value] = useCopyPaste('CopyChannel');
  const [channels, setChannels] = useState([]);
  const [groupChannels, setGroupChannels] = useState([]);
  const [version, setVersion] = useState(1);
  const [current, setCurrent] = useState(defaultState);
  const [fieldValues, setFieldValues] = useState([]);

  const reloadChannels = useCallback(() => {
    const ListParams = {
      sort: {},
      pagination: {},
      filter: { appId, withSchema: true },
    };
    dataProvider.getList('channels', ListParams).then(({ data }) => {
      if (!!data) {
        setChannels(data.sort((a, b) => a.displayOrder - b.displayOrder));
        setGroupChannels(data.filter((channel) => channel.channelType === 'group'));
      } else {
        setChannels([]);
        setGroupChannels([]);
      }
    });
  }, [dataProvider, appId, setChannels]);

  const loadForms = useCallback(() => {
    dataProvider.getList('registration-forms', { filter: { ClientId } }).then(({ data }) => {
      const uniqSchemaFields = uniqBy(flatMap(data, 'schema.fields', 'name'));
      setFieldValues(uniqSchemaFields.filter((field) => !!field));
    });
  }, [dataProvider, ClientId, setFieldValues]);

  const refresh = useCallback(() => {
    setVersion(version + 1);
    reloadChannels();
  }, [setVersion, version, reloadChannels]);

  const updated = useCallback(() => {
    notify('notifications.updated');
    refresh();
  }, [notify, refresh]);

  const setChannelDisplayOrder = useCallback(
    async (channelsOrder) => {
      return dataProvider.rearrange('channels', {
        channelsOrder,
        clientId: ClientId,
      });
    },
    [dataProvider, ClientId],
  ); // eslint-disable-line

  const onReorder = useCallback(
    async (channels) => {
      const channelsOrder = channels.map((channel, index) => ({
        id: channel.id,
        schemaId: channel.schemaId,
        displayOrder: index,
      }));
      const { data } = await setChannelDisplayOrder(channelsOrder);
      setChannels(data);
      return data;
    },
    [setChannels, setChannelDisplayOrder],
  );

  // const hasBuffer = !!value;
  useEffect(() => {
    reloadChannels();
    loadForms();
  }, [reloadChannels, loadForms, version /*hasBuffer*/]);

  const channelsContext = {
    resource: 'channels',
    currentSort: {},
    hasBulkActions: false,
    selectedIds: [],
    classes,
    version: version,
    basePath: '/channels',
    data: fromPairs(map(channels, (entry) => [entry.id, entry])),
    ids: map(channels, 'id'),
    total: channels && channels.length,
    loading: channels === null,
  };

  return (
    <>
      <SampleEditPopover
        readonly={readonly}
        resource="channels"
        channel={current.channel}
        config={record.config}
        anchorEl={current.anchorEl}
        onSubmit={updated}
        onClose={() => setCurrent(defaultState)}
      />
      <Paper>
        <ListContext.Provider value={channelsContext}>
          <Datagrid
            expand={
              <ChannelExpandPanel
                config={record.config}
                readonly={readonly}
                onUpdate={updated}
                groupChannels={groupChannels}
              />
            }
          >
            <FunctionField
              render={(channel) => (
                <SampleButton
                  onClick={(event) => setCurrent({ channel, anchorEl: event.target })}
                  channel={channel}
                  config={{ ...record.config, primary: false }}
                />
              )}
            />
            <VisibilityConfig
              fieldValues={fieldValues}
              label={false}
              source="config.visibility"
              onSubmit={updated}
              readonly={readonly}
            />
            <ChannelRefs />
            {!readonly && <AssignChannels clientOnly={clientOnly} onSubmit={updated} />}
            {/* {!readonly && (
              <FunctionField
                render={(record) => (
                  <CopyButton
                    name="CopyChannel"
                    value={record}
                    notification={translate('notifications.channelCopied', record)}
                  />
                )}
              />
            )} */}
            {!readonly && (
              <ReorderDialog
                title={translate('titles.reorderChannels')}
                items={channels}
                onReorderAsync={onReorder}
                render={(channel) => <SampleButton channel={channel} config={{ ...record.config, primary: false }} />}
              />
            )}
            {!readonly && <QuickDeleteButton label="" customResource="channels" onDelete={updated} />}
          </Datagrid>
        </ListContext.Provider>
        <Toolbar>
          {!readonly && <CreateChannelButton defaultValues={{ appId, clientId: ClientId }} onSubmit={updated} />}
        </Toolbar>
      </Paper>
    </>
  );
};

export default GroupExpandChannels;
