import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    background: 'transparent',
    border: 'none',
    color: theme.palette.primary.main,
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: theme.typography.body2.fontSize,
    transition: 'background .2s',
    borderRadius: '4px',
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    padding: `${theme.spacing(1)}px ${theme.spacing(1.3)}px`,
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.secondary.hover,
    },
    '& svg': {
      maxHeight: 14,
      maxWidth: 14,
      marginRight: theme.spacing(0.7),
    },
  },
  noLabelButton: {
    height: theme.spacing(4),
    '& svg': {
      marginRight: 0,
    },
  },
}));

export const SecondaryButton = ({ label, icon, className, onClick, disabled, type, style }) => {
  const classes = useStyles();

  return (
    <button
      disabled={disabled}
      style={style}
      className={`${classes.button} ${!label ? classes.noLabelButton : ''} ${className || ''}`}
      type={type || 'button'}
      onClick={onClick}
    >
      {icon}
      {label && <span>{label}</span>}
    </button>
  );
};
