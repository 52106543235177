import React, { useCallback } from 'react';
import {
  SelectField,
  TextField,
  TabbedShowLayout,
  ReferenceManyField,
  Button,
  Datagrid,
  Tab,
} from 'ra-ui-materialui';
import { Show } from 'components';
import { DefaultActions } from 'components';
import ActionsForTab from 'resources/Common/ActionsForTab';
import { useRef, useRecord } from 'hooks';
import { useDataProvider, useRefresh } from 'ra-core';
import { providerTypes } from 'lib/consts';
import ActiveSwitch from 'resources/components/ActiveSwitch';
import GenerateTokenButton from './components/GenerateTokenButton';
import EditButton from './components/EditButton';
import { Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ApiTokens from './components/ApiTokens';
import { useGlobalState } from 'state';

export default ({ permissions, ...props }) => {
  const record = useRecord('monitoring-providers') || {};
  const dataProvider = useDataProvider();

  const { growthBook } = useGlobalState();

  const tokenV2On = growthBook.isOn('subscriber-v2-token-gen') ?? false;

  const providerType = record.providerType;
  const refresh = useRefresh();
  const containerRef = useRef();
  const hasTokens = providerType !== 'webhook';
  const listPath = record.ClientId
    ? `/clients/${record.ClientId}/show/6`
    : '/monitoring-providers';

  const handleSync = useCallback(
    () =>
      dataProvider
        .update('monitoring-providers', { id: record.id, data: { sync: true } })
        .then(refresh),
    [refresh, dataProvider, record.id],
  );

  return (
    <Show
      titleSource="name"
      actions={
        <DefaultActions
          hasList={false}
          hasEdit={false}
          portalRef={containerRef}
        />
      }
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="tabs.summary">
          <ActionsForTab
            containerRef={containerRef}
            hasList={false}
            hasEdit={false}
          >
            <Button component={Link} to={listPath} label="ra.action.list">
              <Icon>list</Icon>
            </Button>
            <EditButton record={record} />
          </ActionsForTab>
          <TextField source="name" />
          <TextField source="email" />
          <SelectField source="providerType" choices={providerTypes} />
          {providerType === 'email' && <TextField source="notifyEmail" />}
          {providerType === 'webhook' && <TextField source="callbackUrl" />}
          {providerType === 'telegram' && <TextField source="telegramCode" />}
        </Tab>
        {record.ClientId &&
          record.ClientId !== 1 && ( // ClientId = 1 => global provider
            <Tab label="tabs.channels">
              <ReferenceManyField
                addLabel={false}
                basePath="/monitoring-providers/channels"
                reference="monitoring-providers/channels"
                target="MonitoringProviderId"
              >
                <Datagrid>
                  <TextField label="fields.app" source="App.name" />
                  <TextField label="fields.name" source="name" />
                  <ActiveSwitch
                    label="fields.active"
                    source="active"
                    sortable={false}
                    data={{ MonitoringProviderId: record.id }}
                    onClick={refresh}
                  />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
          )}
        {hasTokens && (
          <Tab label="tabs.apiTokens">
            <ActionsForTab
              containerRef={containerRef}
              hasList={false}
              hasEdit={false}
            >
              {hasTokens && <GenerateTokenButton record={record} />}
              {hasTokens && tokenV2On && <GenerateTokenButton v2 record={record} />}
              {hasTokens && (
                <Button label="Sync" onClick={handleSync}>
                  <Icon>sync</Icon>
                </Button>
              )}
            </ActionsForTab>
            <ApiTokens record={record} resource="monitoring-providers" />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};
