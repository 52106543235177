import React from 'react';
import { FunctionField } from 'ra-ui-materialui';
import { get } from 'lodash';
import { useTranslate } from "ra-core";

export const TranslateField = ({ translate, namespace, ...props }) => {
  const t = useTranslate();

  return (
    <FunctionField
      {...props}
      render={(record, source) => t(`${namespace}.${get(record, source)}`)}
    />
  )
}
