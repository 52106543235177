import React from "react"
import {  TextInput, SimpleForm } from "ra-ui-materialui"
import { QuickCreateButton, required } from "components"

export default (props) => {
  return <QuickCreateButton {...props} resource="surveys"  >
    <SimpleForm toolbar={null} resource="surveys" >
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </QuickCreateButton>
}