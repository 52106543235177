import React from 'react';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  button: {
    outline: 'none',
    fontSize: '1.125rem',
    padding: '.10rem 2rem',
    background: '#16181D',
    color: 'white',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    '&:disabled': {
      background: '#4b505a',
      cursor: 'inherit',
    },
  },
  primary: {},
  secondary: {
    border: '1px solid #000',
    background: 'white',
    color: '#000',
    transition: '.2s background',
    '&:hover': {
      background: '#E2E4E9',
    },
    '&:disabled': {
      background: 'white',
      cursor: 'inherit',
      color: '#989797',
      borderColor: '#989797',
    },
  },
  full: {
    width: '100%',
  },
}));

export const Button = ({ label, variant = 'primary', fullSize, ...props }) => {
  const classes = useStyles();
  return (
    <button
      className={`${classes.button} ${classes[variant]} ${fullSize ? classes.full : ''}`}
      {...props}
    >
      {label}
    </button>
  )
}
