import React, { useEffect, useState } from 'react';
import { fetchAppDashboardData, fetchSiteDashboardData, listApps, listSite } from './Dashboard.model';
import { mapList, useDashboardStyles } from './index';
import { useDataProvider, useNotify, useTranslate } from 'ra-core';
import { Dropdown } from '../../../components/designSystem/Dropdown';
import { BigInfoCard } from '../../../components/designSystem/BigInfoCard';
import { NotificationsActive, Smartphone } from '@material-ui/icons';
import { addDots } from './index';


export const AppSiteCards = ({ clientId }) => {
  const notify = useNotify();
  const t = useTranslate();
  const dataProvider = useDataProvider();

  const [appList, setAppList] = useState([]);
  const [appId, setAppId] = useState(null);
  const [appData, setAppData] = useState({});

  const [siteList, setSiteList] = useState([]);
  const [siteId, setSiteId] = useState(null);
  const [siteData, setSiteData] = useState({});

  const classes = useDashboardStyles();

  useEffect(() => {
    listApps({ dataProvider, notify, clientId })
      .then(({ data }) => {
        setAppList(data.map(mapList));
      });
    listSite({ dataProvider, notify, clientId })
      .then(({ data }) => {
        setSiteList(data);
      });
  }, [dataProvider, notify, clientId]);

  useEffect(() => {
    if (appList.length !== 0 && (!appId || !appList.find(app => app.id === appId))) {
      setAppId(appList[0]?.id ?? 0);
    }
  }, [appId, appList]);

  useEffect(() => {
    if (appId && siteList.length !== 0 && (!siteId || !siteList.find(site => site.id === siteId && site.appId === appId))) {
      setSiteId(siteList[0]?.id ?? 0);
    }
  }, [appId, siteId, siteList]);

  useEffect(() => {
    if (appId) {
      fetchAppDashboardData({ dataProvider, notify, appId })
        .then((data) => setAppData(data));
    }
  }, [appId, dataProvider, notify]);

  useEffect(() => {
    if(siteId) {
      fetchSiteDashboardData({ dataProvider, notify, siteId })
        .then((data) => setSiteData(data));
    }
  }, [siteId, dataProvider, notify]);

  const appName = appList.find(app => app.id === appId)?.name;

  const siteName = siteList.find(site => site.id === siteId)?.name;

  const filteredSiteList = siteList.filter(site => site.appId === appId).map(mapList);
  const site = filteredSiteList.find(site => site.id === siteId);

  return (
    <div style={{ margin: '3rem 1rem' }}>
      <h2 style={{ fontSize: 'inherit', color: '#414958' }}>{t('resources.dashboard.apps')}</h2>
      <div className={classes.dropdownContainer}>
        <div className={classes.dropdownAppSiteContainer}>
          <div className={classes.dropdownName}>{t('resources.dashboard.app')}</div>
          <Dropdown
            name='appId'
            full
            items={appList}
            value={appId}
            onChange={(id) => {
              setAppId(id);
            }}
            light
          />
        </div>
        {filteredSiteList.length > 0 && appId && (
          <div className={classes.dropdownAppSiteContainer}>
            <div className={classes.dropdownName}>{t('resources.dashboard.siteAndTeam')}</div>
            <Dropdown
              name='siteId'
              full
              items={filteredSiteList}
              value={siteId}
              onChange={(id) => {
                setSiteId(id);
              }}
              light
            />
          </div>
        )}
      </div>
      <div className={classes.clientContainer}>
        <div className={classes.bigCardsContainer}>
          <div className={classes.bigCard}>
            <BigInfoCard icon={<Smartphone />} infoName={t('resources.dashboard.mobileProfiles')}
                         infoNumber={addDots(appData?.mobileProfiles?.total)}
                         infoDiscription={t('resources.dashboard.app') + ': ' + appName}
                         date={appData?.timestamp} />
          </div>
          <div className={classes.bigCard}>
            <BigInfoCard icon={<NotificationsActive />} infoName={t('resources.dashboard.alerts')}
                         infoNumber={addDots(appData?.alerts?.total)}
                         infoDiscription={t('resources.dashboard.app') + ': ' + appName}
                         type='alert' date={appData?.timestamp} />
          </div>
        </div>
        {site && (
          <div className={classes.bigCardsContainerSub}>
            <div className={classes.bigCard}>
              <BigInfoCard icon={<Smartphone />} infoName={t('resources.dashboard.mobileProfileOfThisSite')}
                           infoNumber={addDots(siteData?.mobileProfiles?.total)}
                           infoDiscription={t('resources.dashboard.siteAndTeam') + ': ' + siteName}
                           date={appData?.timestamp} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};