import React from 'react';

export const SasiAppbarLogo = ({ className }) => {
  return (
    <svg
      className={className}
      width="110"
      height="16"
      viewBox="0 0 110 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9469 12.4473C15.5209 12.4711 16.0231 12.0181 15.9992 11.4458C15.9514 10.1344 15.6883 8.84687 15.21 7.60699C14.6121 6.05714 13.6794 4.62652 12.5076 3.45817C11.3119 2.28982 9.90086 1.35991 8.34638 0.76382C7.1028 0.286944 5.81139 0.0246618 4.49606 0.000817967C3.9221 -0.0230258 3.46771 0.477694 3.49163 1.07379C3.51554 1.64604 4.01776 2.09907 4.61564 2.12292C5.64398 2.1706 6.64842 2.3852 7.60502 2.74286C8.89643 3.21973 10.0683 3.98273 11.0488 4.96033C12.0293 5.93793 12.7946 7.08243 13.2729 8.36999C13.6316 9.32374 13.8469 10.3252 13.9186 11.3505C13.9186 11.9227 14.373 12.4234 14.9469 12.4473Z"
        fill="white"
      />
      <path
        d="M11.0247 12.7809C11.5987 12.8524 12.1248 12.4471 12.1248 11.8748C12.1248 11.0164 11.9813 10.158 11.6944 9.32351C11.2878 8.17901 10.6421 7.12988 9.78116 6.24766C8.92022 5.38929 7.86796 4.72166 6.72003 4.31631C5.90692 4.03019 5.02206 3.88713 4.16112 3.86328C3.58716 3.86328 3.1806 4.38785 3.25235 4.9601C3.32409 5.53235 3.85022 5.93769 4.42419 5.96154C4.97423 5.98538 5.52428 6.1046 6.05041 6.27151C6.91136 6.55763 7.67664 7.05835 8.32235 7.70213C8.96805 8.34592 9.44635 9.10892 9.73333 9.9673C9.90074 10.4919 10.0203 11.0403 10.0442 11.5887C10.0681 12.1848 10.4508 12.7093 11.0247 12.7809Z"
        fill="white"
      />
      <path
        d="M4.06556 16.0004C6.31091 16.0004 8.13113 14.1857 8.13113 11.947C8.13113 9.70834 6.31091 7.89355 4.06556 7.89355C1.82021 7.89355 0 9.70834 0 11.947C0 14.1857 1.82021 16.0004 4.06556 16.0004Z"
        fill="white"
      />
      <path
        d="M4.0655 15.0461C5.78253 15.0461 7.17446 13.6583 7.17446 11.9464C7.17446 10.2345 5.78253 8.84668 4.0655 8.84668C2.34847 8.84668 0.956543 10.2345 0.956543 11.9464C0.956543 13.6583 2.34847 15.0461 4.0655 15.0461Z"
        fill="#E42415"
      />
      <path
        d="M22.9471 3.17167C20.842 3.17167 20 4.34478 20 5.94707C20 7.29185 20.5894 8.73677 23.1155 8.73677H25.3189C26.4837 8.73677 26.8064 9.30901 26.8064 9.93848C26.8064 10.6395 26.3995 11.1402 25.5013 11.1402H20.1684V12.8283H25.4452C27.6064 12.8283 28.4624 11.6266 28.4624 9.99571C28.4624 8.60801 27.859 7.14878 25.2767 7.14878H23.0594C21.9507 7.14878 21.656 6.59084 21.656 6.00429C21.656 5.3319 22.0349 4.8598 22.891 4.8598H27.7467V3.17167H22.9471Z"
        fill="white"
      />
      <path
        d="M34.2462 5.06009C34.3444 4.83119 34.4988 4.73104 34.7233 4.73104C34.9479 4.73104 35.1022 4.83119 35.1864 5.06009L38.0634 12.8283H39.9018L36.7021 4.24463C36.3653 3.34335 35.7197 3 34.7374 3C33.741 3 33.0954 3.34335 32.7445 4.24463L29.4185 12.8283H31.2429L34.2462 5.06009Z"
        fill="white"
      />
      <path
        d="M43.8198 3.17167C41.7147 3.17167 40.8727 4.34478 40.8727 5.94707C40.8727 7.29185 41.4621 8.73677 43.9882 8.73677H46.1915C47.3564 8.73677 47.6791 9.30901 47.6791 9.93848C47.6791 10.6395 47.2722 11.1402 46.374 11.1402H41.0411V12.8283H46.3179C48.4791 12.8283 49.3351 11.6266 49.3351 9.99571C49.3351 8.60801 48.7317 7.14878 46.1494 7.14878H43.9321C42.8234 7.14878 42.5287 6.59084 42.5287 6.00429C42.5287 5.3319 42.9076 4.8598 43.7637 4.8598H48.6194V3.17167H43.8198Z"
        fill="white"
      />
      <path
        d="M51.1753 3.17167V12.8283H52.8313V3.17167H51.1753Z"
        fill="white"
      />
      <path
        d="M62.8074 5.06009C62.9056 4.83119 63.06 4.73104 63.2845 4.73104C63.5091 4.73104 63.6634 4.83119 63.7476 5.06009L66.6246 12.8283H68.463L65.2633 4.24463C64.9265 3.34335 64.2809 3 63.2985 3C62.3021 3 61.6566 3.34335 61.3057 4.24463L57.9797 12.8283H59.8041L62.8074 5.06009Z"
        fill="white"
      />
      <path
        d="M70.6548 3.17167C70.1216 3.17167 69.8689 3.42918 69.8689 3.95851V12.0415C69.8689 12.5708 70.1216 12.8283 70.6548 12.8283H73.9949C76.7736 12.8283 78.2612 11.2546 78.2612 8.00715C78.2612 4.74535 76.7596 3.17167 73.9949 3.17167H70.6548ZM71.5249 5.03147C71.5249 4.90272 71.5811 4.8598 71.7074 4.8598H73.9949C75.8334 4.8598 76.6052 5.7897 76.6052 7.99285C76.6052 10.2103 75.8334 11.1402 73.9949 11.1402H71.7074C71.5811 11.1402 71.5249 11.0973 71.5249 10.9685V5.03147Z"
        fill="white"
      />
      <path
        d="M83.387 5.04578C83.4852 4.80257 83.6536 4.6309 83.9062 4.6309C84.1869 4.6309 84.3974 4.84549 84.3974 5.18884V10.8827C84.3974 12.1559 85.0991 13 86.4885 13C87.7515 13 88.369 12.3133 88.7339 11.2117L91.0355 5.04578C91.1197 4.80257 91.3162 4.6309 91.5547 4.6309C91.8354 4.6309 92.0319 4.84549 92.0319 5.18884V12.8283H93.7019V5.103C93.7019 3.84406 93.0002 3 91.5968 3C90.3338 3 89.7303 3.68669 89.3654 4.78827L87.0639 10.9542C86.9797 11.1974 86.7832 11.3548 86.5306 11.3548C86.2499 11.3548 86.0534 11.1402 86.0534 10.8112V5.103C86.0534 3.84406 85.3517 3 83.9483 3C82.6853 3 82.0818 3.68669 81.717 4.78827L78.7839 12.8283H80.5521L83.387 5.04578Z"
        fill="white"
      />
      <path
        d="M95.9907 3.17167V12.8283H97.6467V3.17167H95.9907Z"
        fill="white"
      />
      <path
        d="M101.58 5.18884C101.58 4.84549 101.776 4.6309 102.155 4.6309C102.45 4.6309 102.618 4.75966 102.744 5.04578L105.509 11.2117C105.902 12.3133 106.506 13 107.825 13C109.284 13 110 12.1559 110 10.8827V3.17167H108.33V10.8112C108.33 11.1402 108.119 11.3548 107.769 11.3548C107.46 11.3548 107.277 11.1831 107.165 10.9542L104.4 4.78827C104.008 3.68669 103.418 3 102.099 3C100.625 3 99.9237 3.84406 99.9237 5.103V12.8283H101.58V5.18884Z"
        fill="white"
      />
    </svg>
  );
};
