import React, {Fragment} from 'react';
import {some} from "lodash"
import { makeStyles } from '@material-ui/styles';
import classnames from "classnames"
const useStyles = makeStyles(theme => ({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 10,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginRight: 10
  },
}))

function Col({className, children}) {
  const classes = useStyles()
  return <div className={classnames(classes.column, className)}>
    {children}
  </div>
}
function Row({children, ...props}) {
  const classes = useStyles()
  const cols = React.Children.toArray(children)
  const hasContent = some(cols, ({props}) => React.Children.toArray(props.children).length > 0)
  return hasContent ? <Fragment>
    <hr/>
    <div className={classes.row}>
      {children}
    </div>
  </Fragment> : <div/>
}

export {Col, Row}
