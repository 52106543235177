import { ArrayField, ChipField, SingleFieldList } from 'ra-ui-materialui';
import React from 'react';

const ChannelRefs = ({ record, ...props }) => {
  if (record.id > 0) {
    return (
      <>
        <ArrayField
          source="MonitoringProviders"
          record={record}
          {...props}
          linkType={false}
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <br />
      </>
    );
  }

  return <span />;
};

export default ChannelRefs;
