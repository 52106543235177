import { useDataProvider, useNotify, useTranslate } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { addDots, mapList, useDashboardStyles } from './index';
import { fetchClientDashboardData, listClients } from './Dashboard.model';
import { Dropdown } from '../../../components/designSystem/Dropdown';
import { BigInfoCard } from '../../../components/designSystem/BigInfoCard';
import { NotificationsActive, Smartphone } from '@material-ui/icons';
import { Top3Apps } from '../../../components/designSystem/Top3Apps';
import { Top3Sites } from '../../../components/designSystem/top3Sites';

export const ClientCards = ({ clientId, setClientId, client, setClient }) => {
  const notify = useNotify();
  const t = useTranslate();
  const dataProvider = useDataProvider();
  const [clientList, setClientList] = useState([]);
  const [clientData, setClientData] = useState({});
  const classes = useDashboardStyles();

  useEffect(() => {
    listClients({ dataProvider, notify })
      .then(({ data }) => {
        setClientList(data.map(mapList));
        setClient(data.find(item => item.id === clientId));
      });
  }, [dataProvider, notify, clientId, setClient]);

  useEffect(() => {
    if (clientId) {
      fetchClientDashboardData({ dataProvider, notify, clientId })
        .then((data) => setClientData(data))
        .catch((error) => console.log(error));
    }
  }, [dataProvider, notify, clientId]);

  useEffect(() => {
    if (clientList.length !== 0 && !clientId) {
      setClientId(clientList[0]?.id ?? '');
    }
  }, [clientId, clientList, setClientId]);

  return (
    <div style={{ margin: '3rem 1rem' }}>
      <h2 style={{ fontSize: 'inherit', color: '#414958' }}>{t('resources.dashboard.client')}</h2>
      <div className={classes.dropdownContainer}>
        <Dropdown
          full
          key={`dashboard-client-dropdown-${JSON.stringify(clientList)}`}
          items={clientList}
          value={clientId}
          onChange={(id) => {
            localStorage.setItem('clientId', id);
            setClientId(id);
          }}
          light
        />
      </div>
      <div className={classes.clientContainer}>
        <div className={classes.bigCardsContainer}>
          <div className={classes.bigCard}>
            <BigInfoCard icon={<Smartphone />} infoName={t('resources.dashboard.mobileProfiles')}
                         infoNumber={addDots(clientData?.mobileProfiles?.total)}
                         date={clientData?.timestamp} />
          </div>
          <div className={classes.bigCard}>
            <BigInfoCard icon={<NotificationsActive />} infoName={t('resources.dashboard.alerts')}
                         infoNumber={addDots(clientData?.alerts?.total)}
                         infoDiscription={t('resources.dashboard.client') + ' ' + client?.name}
                         type='alert' date={clientData?.timestamp} />
          </div>
        </div>

        <div className={classes.top3Container}>
          {clientData?.topApps && (
            <div className={classes.top3Card}>
              <Top3Apps title={t('resources.dashboard.top3Apps')}
                        description={t('resources.dashboard.top3AppsDescription') + ' ' + client?.name}
                        top3={clientData?.topApps?.list} date={clientData?.timestamp} />
            </div>)}
          {clientData?.topSites && (
            <div className={classes.top3Card}>
              <Top3Sites title={t('resources.dashboard.top3SitesTeams')}
                         description={t('resources.dashboard.top3SitesTeamsDescription') + ' ' + client?.name}
                         top3={clientData?.topSites?.list} date={clientData?.timestamp} />
            </div>)}
        </div>

      </div>
    </div>
  );
};
