import React, { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
// import { CheckCircle } from '@material-ui/icons';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';

import { useTabs } from 'hooks/use-tabs';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { Form } from 'components/designSystem/Form';

import { usePanelStyles, saveChannel } from './ChannelPanels.model';

import { GeneralPanel } from './GeneralPanel';
import { useDataProvider, useNotify, useTranslate } from 'ra-core';
import { StylePanel } from './StylePanel';
import { StructurePanel } from './alert/StructurePanel';

export const ChannelPanelTabOrder = {
  general: 0,
  structure: 1,
  styles: 2,
};

export const ChannelExpandPanels = ({ channel: _channel, channels, setChannel, app, template, readOnly }) => {
  const classes = usePanelStyles();
  const dataProvider = useDataProvider();
  const t = useTranslate();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useTabs(ChannelPanelTabOrder, { defaultTab: 'general', noURL: true });
  const [channel, setLocalChannel] = useState(_channel);

  const parentChannels = (channels || []).filter((c) => c.channelType === 'group' && c.id !== channel.id);

  const channelType = channel.channelType || channel.schema?.channelType || '';
  const label = channel.label || channel.schema?.label;
  const primary = channel.primary || channel.schema?.primary;

  const hasStructure = channelType => channelType === 'alert' || channelType === 'face-recognition';

  return (
    <div className={classes.panel}>
      <Tabs value={tab} onChange={(event, value) => setTab(value)}>
        <Tab value={ChannelPanelTabOrder['general']} label={t('titles.general')} />
        {hasStructure(channelType) && <Tab value={ChannelPanelTabOrder['structure']} label={t('titles.structure')} />}
        <Tab value={ChannelPanelTabOrder['styles']} label={t('titles.style')} />
      </Tabs>

      <Form
        readOnly={readOnly}
        mutators={{ ...arrayMutators }}
        initialValues={{
          ...channel,
          label,
          channelType,
          primary,
          config: { alert: false, ...(channel?.config || channel?.schema?.config || {}) },
        }}
        onSubmit={saveChannel({ dataProvider, channel, setLoading, setLocalChannel, setChannel, notify })}
        render={({ handleSubmit, values, pristine, submitting, close }) => (
          <div>
            <div>
              {tab === ChannelPanelTabOrder['general'] && (
                <GeneralPanel parentChannels={parentChannels} channel={values} loading={loading} readOnly={readOnly} />
              )}
              {hasStructure(values.channelType) && tab === ChannelPanelTabOrder['structure'] && (
                <StructurePanel channel={values} loading={loading} readOnly={readOnly} />
              )}
              {tab === ChannelPanelTabOrder['styles'] && (
                <StylePanel app={app || template} channel={values} loading={loading} originalChannel={channel} />
              )}
            </div>
            {!readOnly && (
              <div className={classes.saveContainer}>
                <PrimaryButton
                  disabled={submitting}
                  type="submit"
                  label={t('actionButtons.save')}
                  onClick={handleSubmit}
                />
                <TertiaryButton disabled={submitting} label={t('actionButtons.cancel')} onClick={close} />
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};
