import React from 'react';
import { makeStyles } from '@material-ui/core';

import { usePanelStyles, ChannelTypeTextField } from '../ChannelPanels.model';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { LabeledSwitchField } from 'components/designSystem/LabeledSwitch';
import { DropdownFormField } from 'components/designSystem/Dropdown';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  wrapper: {
    display: 'flex',
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(1.5),
  },
  configContainer: {
    marginRight: theme.spacing(10),
    marginLeft: theme.spacing(0),
  },
  finishButtonsContainer: {
    flex: '1',
  },
  finishOptionsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  textFields: {
    marginBottom: theme.spacing(1),
    marginTop: 0,
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
  },
  setPrimaryContainer: {
    justifyContent: 'flex-end',
    position: 'relative',
    left: theme.spacing(1) * -1,
    marginBottom: theme.spacing(0.5),

    '& > div': {
      color: theme.palette.text.tertiary,
    },
  },
}));

export const BroadcastGeneralPanel = ({ parentChannels }) => {
  const classes = useStyles();
  const panelClasses = usePanelStyles();
  const t = useTranslate();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.configContainer}>
          <h2 className={panelClasses.sectionTitle}>{t('resources.channels.fields.config.about')}</h2>
          <div className={classes.finishOptionsContainer}>
            <div className={classes.optionsContainer}>
              <TextFormField
                className={classes.textFields}
                name="id"
                disabled
                size="full"
                full
                label={t('resources.channels.fields.config.channelId')}
                helperText={t('resources.channels.fields.config.helpertext')}
              />
              <DropdownFormField
                name="config.parentId"
                full
                items={(parentChannels || []).map((c) => ({ id: c.id, name: c.name }))}
                helperText={t('resources.channels.fields.config.helpertext')}
                label={t('resources.channels.fields.config.parentId')}
                placeholder="Selecionar"
                allowEmpty
              />
            </div>
            <div>
              <ChannelTypeTextField
                className={classes.textFields}
                name="channelType"
                disabled
                full
                size="full"
                label={t('resources.channels.fields.config.channelType')}
                helperText={t('resources.channels.fields.config.helpertext')}
              />

              <TextFormField
                className={classes.textFields}
                name="tag"
                size="full"
                full
                label="TAG"
                placeholder={t('resources.channels.fields.placeholders.insertTag')}
                helperText={t('resources.channels.fields.config.helpertext')}
              />
            </div>
          </div>
        </div>

        <div className={classes.configContainer}>
          <h2 className={panelClasses.sectionTitle}>{t('resources.channels.fields.config.settings')}</h2>
          <div className={classes.finishOptionsContainer}>
            <div className={classes.optionsContainer}>
              <LabeledSwitchField
                className={classes.setPrimaryContainer}
                reverse
                name="primary"
                label={t('resources.channels.fields.config.primary')}
                caption={t('resources.channels.fields.placeholders.primary')}
              />
              <LabeledSwitchField
                reverse
                className={classes.setPrimaryContainer}
                name="config.requireConfirm"
                label={t('resources.channels.fields.config.requireConfirm')}
                caption={t('resources.channels.fields.placeholders.requireConfirm')}
              />
              <LabeledSwitchField
                reverse
                className={classes.setPrimaryContainer}
                name="config.notifyManager"
                label={t('resources.channels.fields.config.notifyManager')}
                caption={t('resources.channels.fields.placeholders.notifyManager')}
              />
              <LabeledSwitchField
                reverse
                className={classes.setPrimaryContainer}
                name="config.siteManagerOnly"
                label={t('resources.channels.fields.config.siteManagerOnly')}
                caption={t('resources.channels.fields.placeholders.siteManagerOnly')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
