import React, { useRef } from 'react';
import {
  TextInput,
  Pagination,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  FunctionField,
  TextField,
  SelectInput,
  SimpleForm,
  Toolbar,
  EditButton,
} from 'ra-ui-materialui';

import { Show, DefaultToolbar, required, DefaultActions, QuickCreateButton, DateField } from 'components';
import { useRecord } from 'hooks';
import { BooleanInput } from 'ra-ui-materialui/lib/input';
import RegistrationCode, { Create as CreateCodeButton } from 'resources/Common/RegistrationCode';
import RegistrationForm, { Create as CreateFormButton } from 'resources/Common/RegistrationForm';
import RegistrationData, { Create as CreateRegistrationData } from 'resources/Common/RegistrationDataGrid';
import DeleteWithConfirmButton from 'components/QuickDeleteButton';
import { useRefresh, useNotify, useUpdate, SaveContextProvider } from 'ra-core';
import ActionsForTab from 'resources/Common/ActionsForTab';
import { handlerModes } from 'lib/consts';
import { SelectField } from 'ra-ui-materialui/lib/field';
import { UploadRegistrationData } from './components';
// import { CreateIconDialog } from 'resources/Icons/CreateIconDialog';
// import { PrimaryButton } from 'components/designSystem/PrimaryButton';
// import { CheckCircle } from '@material-ui/icons';
import ActiveSwitch from 'resources/components/ActiveSwitch';
import { useGlobalState } from 'state';

const ImportHandlerButton = ({ ClientId, ...props }) => (
  <QuickCreateButton title="actions.import" {...props} resource="registration-handlers" defaultValues={{ ClientId }}>
    <SimpleForm toolbar={null} resource="registration-handlers">
      <TextInput source="name" validate={required()} />
      <TextInput source="url" validate={required()} />
      <SelectInput source="mode" choices={handlerModes} validate={required()} />
    </SimpleForm>
  </QuickCreateButton>
);

const CreateRegHandlerV2Button = ({ clientId, ...props }) => (
  <QuickCreateButton title="actions.insert" {...props} resource="v2/registration-handlers" defaultValues={{ clientId }}>
    <SimpleForm toolbar={null} resource="v2/registration-handlers">
      <TextInput source="name" validate={required()} />
      <TextInput source="description" validate={required()} />
      <TextInput source="url" validate={required()} />
    </SimpleForm>
  </QuickCreateButton>
);

const EditHandler = ({ record, ...props }) => {
  const [update] = useUpdate('registration-handlers', record.id, {}, {}, {});
  const notify = useNotify();
  const save = (data) =>
    update(
      { payload: { data } },
      {
        onSuccess: () => notify('Updated', 'success'),
        onFailure: (error) => notify(error.message, 'error'),
      },
    );
  return (
    <SimpleForm resource="registration-handlers" initialValues={record} save={save}>
      <TextInput source="name" validate={required()} />
      <TextInput source="url" validate={required()} />
      <SelectInput source="mode" choices={handlerModes} validate={required()}></SelectInput>
    </SimpleForm>
  );
};

const EditRegHandlerV2 = ({ record, ...props }) => {
  const [update] = useUpdate('v2/registration-handlers', record.id, {}, {}, {});
  const notify = useNotify();
  const save = (data) =>
    update(
      { payload: { data } },
      {
        onSuccess: () => notify('Updated', 'success'),
        onFailure: (error) => notify(error.message, 'error'),
      },
    );
  return (
    <SimpleForm resource="v2/registration-handlers" initialValues={record} save={save}>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" validate={required()} />
      <TextInput source="url" validate={required()} />
    </SimpleForm>
  );
};

export const CreateTermsButton = ({ record, ClientId, ...props }) => (
  <QuickCreateButton defaultValues={{ ClientId }} record={record} {...props} resource="terms">
    <SimpleForm toolbar={null} resource="terms" record={record}>
      <TextInput source="title" />
    </SimpleForm>
  </QuickCreateButton>
);

export default ({ permissions, ...props }) => {
  const { resource, id } = props;

  const [update, { loading, error }] = useUpdate(resource, id);
  const notify = useNotify();
  const refresh = useRefresh();
  const containerRef = useRef();
  const record = useRecord('clients') || {};
  const readonly = record.locked;

  const { growthBook } = useGlobalState();
  const useRegHandlerV2 = growthBook.isOn('reg-handler-v2') ?? false;

  if (error) {
    notify(error.message, 'error');
  }

  const save = (values) => update({ payload: { data: values } }, { onSuccess: refresh });
  const saveContextValue = {
    save: save,
    saving: loading ? true : undefined,
    setOnFailure: (error) => notify(error.message, 'error'),
  };
  return (
    <Show
      {...props}
      titleSource="name"
      actions={<DefaultActions hasShow={true} hasList={false} hasEdit={false} portalRef={containerRef} />}
    >
      <TabbedShowLayout>
        <Tab label="tabs.summary">
          <SaveContextProvider value={saveContextValue}>
            <SimpleForm
              save={save}
              record={record}
              redirect={false}
              toolbar={<DefaultToolbar />}
              mutationMode="undoable"
            >
              <TextInput source="name" validate={required()} disabled={readonly} />
              <TextInput source="nickname" disabled={readonly} />
              <BooleanInput source="active" disabled={readonly} />
              <TextInput source="config.minAppVersion" defaultValue="1.0.0" disabled={readonly} />
            </SimpleForm>
          </SaveContextProvider>
        </Tab>
        <Tab label="titles.registrationCodes">
          <ActionsForTab containerRef={containerRef}>
            <CreateCodeButton
              title="titles.createCode"
              label="titles.createCode"
              mode="client"
              ClientId={record.id}
              defaultValues={{ mode: 'client', ClientId: record.id }}
              useRegHandlerV2={useRegHandlerV2}
              onSubmit={refresh}
            />
          </ActionsForTab>
          <ReferenceManyField
            addLabel={false}
            basePath="/registration-codes"
            reference="registration-codes"
            filter={{ mode: 'client' }}
            target="ClientId"
            pagination={<Pagination />}
          >
            <Datagrid expand={!readonly && RegistrationCode}>
              <TextField source="code" />
              <TextField label="fields.form" source="RegistrationForm.name" />
              <TextField label="fields.handler" source={`RegistrationHandler${useRegHandlerV2 ? 'V2' : ''}.name`} />
              <DateField source="createdAt" />
              {!readonly && <DeleteWithConfirmButton onDelete={refresh} />}
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="titles.registrationHandlers">
          <ActionsForTab containerRef={containerRef}>
            {useRegHandlerV2 ? (
              <CreateRegHandlerV2Button clientId={record.id} onSubmit={refresh} />
            ) : (
              <ImportHandlerButton ClientId={record.id} onSubmit={refresh} />
            )}
          </ActionsForTab>
          {useRegHandlerV2 ? (
            <ReferenceManyField
              record={record}
              addLabel={false}
              basePath="/v2/registration-handlers"
              reference="v2/registration-handlers"
              target="clientId"
              pagination={<Pagination />}
            >
              <Datagrid expand={!readonly && EditRegHandlerV2}>
                <TextField source="name" />
                <TextField source="description" />
                <TextField source="url" />
                {!readonly && <DeleteWithConfirmButton onDelete={refresh} />}
              </Datagrid>
            </ReferenceManyField>
          ) : (
            <ReferenceManyField
              record={record}
              addLabel={false}
              basePath="/registration-handlers"
              reference="registration-handlers"
              target="ClientId"
              pagination={<Pagination />}
            >
              <Datagrid expand={!readonly && EditHandler}>
                <TextField source="name" />
                <SelectField choices={handlerModes} source="mode" />
                <TextField source="url" />
                {!readonly && <DeleteWithConfirmButton onDelete={refresh} />}
              </Datagrid>
            </ReferenceManyField>
          )}
        </Tab>
        <Tab label="titles.registrationForms">
          <ActionsForTab containerRef={containerRef}>
            <CreateFormButton title="titles.registrationForms" ClientId={record.id} onSubmit={refresh} />
          </ActionsForTab>
          <ReferenceManyField
            record={record}
            addLabel={false}
            basePath="/registration-forms"
            reference="registration-forms"
            target="ClientId"
            pagination={<Pagination />}
          >
            <Datagrid expand={<RegistrationForm readonly={readonly} />}>
              <TextField source="name" />
              <TextField source="schema.title" />
              {!readonly && <DeleteWithConfirmButton onDelete={refresh} />}
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="titles.registrationData">
          <ReferenceManyField
            record={record}
            addLabel={false}
            basePath="/registration-data/schemas"
            reference="registration-data/schemas"
            target="ClientId"
            pagination={<Pagination />}
          >
            <Datagrid expand={<RegistrationData ClientId={record.id} readonly={readonly} />}>
              <TextField source="contentId" label="fields.contentId" />
              {!readonly && <CreateRegistrationData />}
              {!readonly && (
                <FunctionField
                  render={(cid) => (
                    <DeleteWithConfirmButton
                      resource="registration-data/schemas"
                      record={cid}
                      onDelete={refresh}
                      title="actions.delete_title"
                      translateOptions={{ name: cid.contentId }}
                    />
                  )}
                />
              )}
            </Datagrid>
          </ReferenceManyField>
          {!readonly && (
            <Toolbar>
              <UploadRegistrationData source="csvRegistrationData" />
            </Toolbar>
          )}
        </Tab>
        <Tab label="titles.terms">
          <ActionsForTab containerRef={containerRef}>
            <CreateTermsButton title="titles.terms" ClientId={record.id} onSubmit={refresh} />
          </ActionsForTab>
          <ReferenceManyField
            record={record}
            addLabel={false}
            basePath="/terms"
            reference="terms"
            target="ClientId"
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="title" label="fields.title" />
              <ActiveSwitch source="active" label="fields.active" />
              <DateField source="publishedAt" label="fields.publishedAt" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        {/* 
        <Tab label="My Icons">
          <ActionsForTab containerRef={containerRef}> */}
        {/* <PrimaryButton label={'New Icon'} icon={<CheckCircle />} onClick={CreateIconDialog} /> */}
        {/* <CreateIconDialog title="Icons" ClientId={record.id} onSubmit={refresh} /> */}
        {/* </ActionsForTab>
          <ReferenceManyField
            record={record}
            addLabel={false}
            basePath="/terms"
            reference="terms"
            target="ClientId"
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="fields.icon" label="fields.icon" />
              <TextField source="fields.name" label="fields.nameIcon" />
              <TextField source="fields.action" label="fields.actionsIcon" />
              <EditButton />
              <DeleteButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab> */}
      </TabbedShowLayout>
    </Show>
  );
};
