import { useCallback, useState } from "react"
import { useRefresh } from "ra-core"

export default function useRefreshWithVersion() {
  const [version, setVersion] = useState(1)
  const refresh = useRefresh()

  const doRefresh = useCallback(() => {
    refresh()
    setVersion(version + 1)    
  }, [setVersion, refresh, version] )
  
  return [doRefresh, version]  
}