import React from 'react';
import { TextField, SearchField } from 'components/designSystem/inputs/TextField';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import { Dropdown } from 'components/designSystem/Dropdown';
// import { ListFilter } from 'components/designSystem/ListFilter';
// import { Icon } from 'components';
// import { ColorPickFormField } from 'components/designSystem/ColorPickField';
// import { IconDropdownFormField } from 'components/designSystem/IconDropdown';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
// import { IconDropdownFormField } from 'components/designSystem/IconDropdown';
import { SecondaryButton } from 'components/designSystem/SecondaryButton';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { AddCircle, CheckCircle, Menu } from '@material-ui/icons';
import { ColorPickField } from 'components/designSystem/ColorPickField';
import { ListFilter } from 'components/designSystem/ListFilter';
import { useTabs } from 'hooks/use-tabs';
import { Tabs, Tab } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
// import { Form } from 'react-final-form';
// import { CreateChannelDialog } from 'resources/Channel/components/CreateChannelDialog';

export const IconPanelTabOrder = {
  library: 0,
  myIcons: 1,
};

export const Playground = (...props) => {
  const [tab, setTab] = useTabs(IconPanelTabOrder, { defaultTab: 'library', noURL: true });

  function handleClick(event) {
    event.preventDefault();
  }

  return (
    <div>
      <div>
        <h2>navegação estrutural</h2>
        <Breadcrumbs aria-label="#7F889C">
          <Link underline="hover" key="1" color="inherit" onClick={handleClick}>
            MUI
          </Link>
          ,
          <Link
            underline="hover"
            key="2"
            color="inherit"
            href="/material-ui/getting-started/installation/"
            onClick={handleClick}
          >
            Core
          </Link>
          ,
        </Breadcrumbs>

        <h2>default pt1</h2>
        <div style={{ background: 'white', padding: '1rem' }}>
          <TextField name="name" label={'default'} placeholder={'Placeholder'} hint="ooi" />
          <TextField name="name" label={'default'} placeholder={'placeholder'} />
        </div>

        <h2>default pt2</h2>
        <div>
          <TextField name="name" label={'default'} placeholder={'Placeholder'} hint="Add a hint." light />
          <TextField name="name" label={'default'} placeholder={'placeholder'} light />
        </div>

        <h2>conditions</h2>

        <div style={{ marginRight: '20px' }}>
          <TextField
            name="name"
            label={'name'}
            placeholder={'placeholder'}
            meta={{ touched: true, error: 'errou!' }}
            iconPosition="right"
          />
          <TextField
            name="name"
            label={'name'}
            placeholder={'placeholder'}
            meta={{ touched: true, success: 'acertou!' }}
            iconPosition="right"
          />
          <TextField
            name="name"
            label={'name'}
            placeholder={'placeholder'}
            meta={{ touched: true, error: 'errou!' }}
            iconPosition="right"
          />

          <h2>search tab</h2>
          <SearchField name="name" label={'name'} placeholder={'placeholder'} iconPosition="right" value={'aaaa'} />
          <SearchField
            name="name"
            label={'search'}
            placeholder={'placeholder'}
            iconPosition="right"
            light
            // value={qSearch}
          />

          <h2>list filter</h2>
          <ListFilter
            // setQSearch={filters.set.qSearch}

            iconPosition="right"
            actions={[<PrimaryButton key="client_create_app_button" label={'criar'} icon={<AddCircle />} />]}
          >
            <SearchField
              // value={filters.set.qSearch}
              name="name"
              label={'search'}
              placeholder={'placeholder'}
              iconPosition="right"
              light
            />
            <Dropdown label="dropdown model" full light placeholder={'Placeholder'} />
          </ListFilter>

          <h2>tabs</h2>
          <Tabs value={tab} onChange={(event, value) => setTab(value)}>
            <Tab value={IconPanelTabOrder['library']} label={'library'} />
            <Tab value={IconPanelTabOrder['myIcons']} label={'my Icons'} />
          </Tabs>

          <h2>icons tab</h2>
          <TextField
            name="name"
            label={'name'}
            placeholder={'placeholder'}
            value="barra de busca"
            light
            iconPosition="left"
            icon={<Menu />}
          />
          <TextField
            name="name"
            label={'name'}
            placeholder={'placeholder'}
            value="barra de busca"
            light
            iconPosition="right"
            icon={<Menu />}
          />
          <TextField name="name" label={'name'} placeholder={'placeholder'} light helperText="1 numero maior" />
        </div>
      </div>

      {/* <h2>icon DropdownFormField</h2> */}
      {/* <IconDropdownFormField></IconDropdownFormField> */}

      <div style={{ padding: '22px' }}>
        <h2>botoes</h2>
        <h3>botoes primarios</h3>
        <PrimaryButton label="criar" size="full" />
        <PrimaryButton label="Criar" icon={<CheckCircle />} />
        <PrimaryButton icon={<CheckCircle />} />
        <h3>botoes SecondaryButton</h3>
        <SecondaryButton label={'criar'} />
        <SecondaryButton label={'criar'} width="full" icon={<CheckCircle />} />
        <SecondaryButton icon={<CheckCircle />} />
        <h3>botoes tertiary</h3>
        <TertiaryButton label="cancel" />
        <TertiaryButton label="cancel" icon={<CheckCircle />} />
        <TertiaryButton icon={<CheckCircle />} />
      </div>

      <h2>color picked field</h2>
      <div>
        <ColorPickField label="color" />
      </div>

      <h2>dropdown</h2>

      <div>
        <Dropdown label="dropdown model" icon={<CheckCircle />} />
        <Dropdown label="alone" full />
        <Dropdown label=":(( ....................." full light />
      </div>

      <h2>phone</h2>
      <div>
        <PhoneInput label="teste telefone" />
      </div>
    </div>
  );
};
