import React, { useState } from 'react';
import {
  SimpleForm,
  FileField,
  FileInput,
  Button,
  Toolbar,
  TextInput,
} from 'ra-ui-materialui';
import { useTranslate, useDataProvider, useNotify, useRefresh } from 'ra-core';
import UploadIcon from '@material-ui/icons/CloudUpload';
//import DownloadIcon from '@material-ui/icons/Archive';
import { useForm, useFormState } from 'react-final-form';

const CsvToolbar = () => {
  const form = useForm();
  const { values } = useFormState();
  const valid = values && values.csvRegistrationData && values.contentId;
  return (
    <Toolbar>
      <Button
        disabled={!valid || form.submitting}
        type="button"
        variant="contained"
        component="button"
        color="primary"
        label="actions.importCsv"
        onClick={() => form.submit()}
      >
        <UploadIcon />
      </Button>
    </Toolbar>
  );
};

export default ({ record, client }) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [contentId, setContentId] = useState('');

  const id = (record || client).id;

  const handleSubmit = (values) =>
    dataProvider
      .execute(`clients/${id}/upload`, values)
      .then(() => {
        notify('notifications.uploadCsvComplete');
        refresh();
      })
      .catch((error) => {
        refresh();
        notify(error.message || 'notifications.uploadCsvFailed', 'warning');
      });
  const onDrop = (files) => setContentId(files[0].name);
  return (
    <SimpleForm
      basePath="/clients"
      resource="clients"
      save={handleSubmit}
      toolbar={<CsvToolbar source={'csvRegistrationData'} />}
      initialValues={{ contentId }}
    >
      <TextInput source="contentId" label="Content ID"></TextInput>
      <FileInput
        source="csvRegistrationData"
        label=""
        name="csvRegistrationData"
        options={{ onDrop }}
        placeholder={<p>{translate('titles.uploadCsvDropzone')}</p>}
      >
        <FileField source="csvRegistrationData" title="title" />
      </FileInput>
    </SimpleForm>
  );
};
