import React from "react"
import { TextField, TabbedShowLayout, SelectField, FunctionField, Tab, ReferenceField} from "ra-ui-materialui"
import {Show, DefaultActions, LoginAsButton, checkPermissions } from  "components"
import {roles}            from "lib/consts"
import { useRecord }      from "hooks"
import useCanEditUser from "./useCanEditUser"


const ShowActions = ({ userType, ...props}) => {
  return <DefaultActions {...props}>
    {checkPermissions(props.permissions, `login_as:user:${userType}`) && <LoginAsButton record={props.data} {...props}/>}
  </DefaultActions>
}
export default ({permissions, ...props}) => {
  const record     = useRecord("users") || {}
  const isClient   = record.userType === "client"
  const canEdit    = props.hasEdit && useCanEditUser(record.userType, permissions)

  return (
    <Show {...props} titleSource="email" actions={<ShowActions hasEdit={canEdit} userType={record.userType} permissions={permissions} />}>
      <TabbedShowLayout>
          <Tab label="tabs.summary" >
            <TextField source="name" />
            {record.phone && <TextField source="phone" />}
            <TextField source="username" />
            {record.email && <TextField source="email" />}
            <FunctionField source="role" render={record =>
              <SelectField record={record} source="role" choices={(roles[record.userType] || [])} />
            } />
            {isClient && <ReferenceField source="ClientId" reference="clients" link="show" allowEmpty >
              <TextField source="name" />
            </ReferenceField>}
            {isClient && <ReferenceField source="SiteId" reference="sites" link="show" allowEmpty >
              <TextField source="name" />
            </ReferenceField>}
          </Tab>
      </TabbedShowLayout>
    </Show>
)}
