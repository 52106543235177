import React, { useEffect, useState, useCallback } from 'react';

import { useDataProvider, useTranslate, useNotify } from 'ra-core';
import { makeStyles } from '@material-ui/core';

import { useChannels } from 'hooks/use-resource-hooks';

import { loadChannels, onReorder } from './Channels.model';
import { ChannelsList } from 'resources/Channel/components/ChannelsList';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { AddCircle } from '@material-ui/icons';
import ReorderOutlinedIcon from '@material-ui/icons/ReorderOutlined';
import AssignmentReturnedRoundedIcon from '@material-ui/icons/AssignmentReturnedRounded';
// import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
// import FilterNoneRoundedIcon from '@material-ui/icons/FilterNoneRounded';
import { CreateChannelDialog } from 'resources/Channel/components/CreateChannelDialog';
import { ReorderDialog } from 'resources/components/Reoder';
import { ChannelPreviewButton } from 'resources/Channel/components/ChannelPreviewButton';
import { PasteChannelDialog } from 'resources/Channel/components/PasteChannelDialog';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  addChannelWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    justifyContent: 'space-between',
    display: 'flex',
  },
  optionsChannelWrapper: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'in-line',
    paddingBottom: theme.spacing(2),
  },
  conditionsChannelWrapper: {
    display: '-webkit-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const ChannelsView = ({ app, setCustomRefresh }) => {
  const copyChannelJson = JSON.parse(localStorage.getItem('channel'));

  const t = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { channels: stateChannels, dispatch } = useChannels({ appId: app.id, clientId: app.clientId });

  const [channels, setChannels] = useState(stateChannels);
  const [createChannelDialogVisible, showCreateChannelDialog] = useState(false);
  const [pasteChannelDialogVisible, showPasteChannelDialog] = useState(false);
  const [reorderDialogVisible, showReorderDialog] = useState(false);
  const [registrationForms, setRegistrationForms] = useState([]);
  const [hasCopyChannel, setHasCopyChannel] = useState(!!copyChannelJson);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setHasCopyChannel(!!copyChannelJson);
  }, [copyChannelJson]);

  useEffect(() => {
    const channels = JSON.parse(localStorage.getItem('channels'));
    if (channels) {
      setChannels(channels);
    }
  }, []);

  const reload = () =>
    loadChannels({
      dataProvider,
      setChannels,
      appId: app.id,
      dispatch,
      setRegistrationForms,
      clientId: app.clientId,
      onFinish: () => {
        setPage(page);
      },
    });

  useEffect(() => {
    setCustomRefresh(() => reload);
    reload();
  }, []); //eslint-disable-line

  const getChannels = useCallback(() => {
    channels.sort((a, b) => a.displayOrder - b.displayOrder);
    return channels.slice(page * 20, (page + 1) * 20);
  }, [page, channels]);

  return (
    <div className={classes.container}>
      <div className={classes.addChannelWrapper}>
        <PrimaryButton
          icon={<AddCircle />}
          label={t('resources.channels.fields.createChannel')}
          outlined
          onClick={() => showCreateChannelDialog(true)}
        />
        <div className={classes.conditionsChannelWrapper}>
          {hasCopyChannel && (
            <TertiaryButton
              icon={<AssignmentReturnedRoundedIcon />}
              label={t('templates.paste')}
              outlined
              onClick={() => showPasteChannelDialog(true)}
            />
          )}
          <TertiaryButton
            icon={<ReorderOutlinedIcon />}
            label={t('titles.reorderChannels')}
            outlined
            onClick={() => showReorderDialog(true)}
          />
        </div>
      </div>
      <ChannelsList
        page={page}
        onChangePage={(page) => setPage(page)}
        channels={getChannels()}
        fullChannels={channels}
        app={app}
        refresh={reload}
        onCopyChannel={(copiedChannel) => {
          setHasCopyChannel(!!copiedChannel);
        }}
        // setSortOpen={setSortOpen}
        registrationForms={registrationForms}
      />
      <CreateChannelDialog
        clientId={app.clientId}
        appId={app.id}
        isOpen={createChannelDialogVisible}
        close={() => {
          showCreateChannelDialog(false);
          setPage(0);
        }}
        refresh={reload}
      />

      {hasCopyChannel && (
        <PasteChannelDialog
          appId={app.id}
          isOpen={pasteChannelDialogVisible}
          close={() => showPasteChannelDialog(false)}
          refresh={reload}
        />
      )}

      <ReorderDialog
        title={t('titles.reorderChannels')}
        items={channels}
        isOpen={reorderDialogVisible}
        setIsOpen={() => showReorderDialog(false)}
        noButton
        onReorderAsync={onReorder({ dataProvider, clientId: app.clientId, refresh: reload, notify })}
        render={(channel) => (
          <ChannelPreviewButton
            channel={channel}
            app={app}
            noPrimary
            style={{ padding: '24px 0 24px 16px', width: 279, maxWidth: 279, height: 72 }}
          />
        )}
      />
    </div>
  );
};

export const Channels = ({ app, setMenuItems, setCustomRefresh }) => {
  const appId = app?.id;
  useEffect(() => {
    if (app) {
      // TODO: alternate between commented and uncommented
      // setMenuItems([<TertiaryButton disabled={!app} icon={<Edit />} onClick={() => console.log('EDIT APP')} />]);
      setMenuItems([]);
    }
  }, [app, setMenuItems, appId]);

  if (!app) {
    return <div></div>;
  }
  return <ChannelsView app={app} setCustomRefresh={setCustomRefresh} />;
};
