import React from 'react';
import { Datagrid, TextField } from 'ra-ui-materialui';
import { List, DefaultActions, QuickDeleteButton } from 'components';
import { useRefresh } from 'ra-core';
import { CreateButton, EditButton } from './components';

export default (props) => {
  const refresh = useRefresh();
  return (
    <List
      {...props}
      actions={
        <DefaultActions hasCreate={false}>
          <CreateButton onSubmit={refresh} />
        </DefaultActions>
      }
    >
      <Datagrid>
        <TextField source="code" />
        <TextField source="state" />
        <TextField source="description" />
        <EditButton onSubmit={refresh} />
        <QuickDeleteButton resource="apps" onDelete={refresh} />
      </Datagrid>
    </List>
  );
};
