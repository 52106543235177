import React from 'react';
import {get, map} from "lodash"

function remoteUrl(attachment, name){
  let url = get(attachment.meta, `${name}.url`) || get(attachment.meta, "original.url")
  return url ? `${url}?${attachment.updatedAt}` : null
}

function AudioAttachments({attachments}) {
  return <>
  {map(attachments, audio =>
    <audio key={audio.uuid} controls>
      <source src={remoteUrl(audio, "mp3")} type="audio/mpeg" />
      <source src={remoteUrl(audio, "ogg")} type="audio/ogg" />
    </audio>
  )}
  </>
}

export default AudioAttachments
