import React, { createContext } from 'react';
import { Form as FinalForm, Field as FinalField } from 'react-final-form';

export const ReadOnlyFormCtx = createContext(false);

export const Form = ({ readOnly, children, ...props }) => {
  return (
    <ReadOnlyFormCtx.Provider value={readOnly}>
      <FinalForm {...props}>
        {children}
      </FinalForm>
    </ReadOnlyFormCtx.Provider>
  )
}

export const Field = ({ disabled, ...props }) => (
  <ReadOnlyFormCtx.Consumer>
    {readOnly => (
      <FinalField {...props} disabled={disabled || readOnly} />
    )}
  </ReadOnlyFormCtx.Consumer>
);
