import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    backgroundColor: theme.palette.background.card,
    padding: theme.spacing(3),
    boxSizing: 'border-box',
    borderRadius: theme.borderRadius,
  },
}));

export const Card = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.card}>{children}</div>;
};
