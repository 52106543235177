import React from "react"
import { SimpleForm, TextInput } from "ra-ui-materialui"
import { email, required, FormDataConsumer } from "ra-core"
import { QuickEditButton } from "components"

const requiredEmail = () => (value, values) => {
  const emailError = email()(values.email, values);
  if (emailError) {
    return emailError;
  }

  return required()(value, values);
}

export default props => (
  <QuickEditButton {...props} resource="monitoring-providers" >
    <SimpleForm toolbar={null} resource="monitoring-providers" record={props.record}>
      <TextInput source="name" validate={required()} />
      <TextInput source="email" validate={requiredEmail()} />
      <FormDataConsumer>
        {({ formData: { providerType }, ...rest }) => (<>
          {providerType === "webhook" && <TextInput source="callbackUrl" validate={required()} {...rest} />}
          {providerType === "email" && <TextInput source="notifyEmail" validate={required()} {...rest}/>}
          </>
        )}
      </FormDataConsumer>

    </SimpleForm>
  </QuickEditButton>
)
