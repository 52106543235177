import React from "react"
import { useState, useEffect } from "hooks"
import Portal from '@material-ui/core/Portal'
import {ListButton, EditButton} from "ra-ui-materialui"

export default ({ visible = true, containerRef, children, basePath, record, hasList, hasEdit }) => {
  const [loaded, setLoaded] = useState(!!containerRef.current)
  useEffect(() => setLoaded(!!containerRef.current), [containerRef, setLoaded])
  return <> {loaded && visible && <Portal container={containerRef.current}>
    {hasList && <ListButton basePath={basePath} />}
    {hasEdit && <EditButton basePath={basePath} record={record} />}
    {children}
  </Portal>}
  </>
}