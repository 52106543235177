import React, { useCallback, useEffect, useState } from 'react';

import { AddCircle } from '@material-ui/icons';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { ListFilter } from 'components/designSystem/ListFilter';
import { Dropdown } from 'components/designSystem/Dropdown';
import { useApps, useProjects } from 'hooks/use-resource-hooks';
import { appsColumns, loadApps, loadProjects } from './Apps.model';
import { useDataProvider, useTranslate } from 'ra-core';
import { useListFilters } from 'hooks';
import { Table } from 'components/designSystem/Table';
import { useHistory } from 'react-router';
import { CreateAppDialog } from 'resources/App/components/CreateAppDialog';
import { makeStyles } from '@material-ui/core';
// import { SearchField } from 'components/designSystem/inputs/TextField';

const useStyles = makeStyles(() => ({
  MuiOutlinedInput: {
    root: {
      maxHeight: undefined,
    },
    input: {
      paddingRight: 35,
      // marginTop: 14,
      // marginBottom: 14,
      paddingTop: 12,
      paddingBottom: 10,
    },
    notchedOutline: {
      border: 'none',
      borderRadius: 4,
    },
  },
  MuiSelect: {
    selectMenu: {
      height: undefined,
    },
  },
  MuiSvgIcon: {
    bottom: '20%',
  },
}));

const AppsList = ({ client, setHandleRefresh }) => {
  const t = useTranslate();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const { apps: stateApps, dispatch } = useApps(client.id);
  const { projects: stateProjects, dispatch: dispatchProjects } = useProjects(client.id);
  const classes = useStyles();

  const mapProject = useCallback(
    (project) => {
      let name = project.name;
      if (project.name === 'default' && project.isDefault) {
        name = t('resources.projects.default');
      }
      return { id: project.id, name, default: project.isDefault };
    },
    [t],
  );

  const filters = useListFilters({ orderBy: 'id', order: 'desc' });
  const [apps, setApps] = useState(stateApps);
  const [loading, setLoading] = useState(false);
  const [totalApps, setTotalApps] = useState(undefined);
  const [projectId, setProjectId] = useState(undefined);
  const [projects, setProjects] = useState(stateProjects.map(mapProject));

  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);

  const reload = () =>
    loadApps(dataProvider, setApps, setTotalApps, client.id, projectId, filters, dispatch, setLoading);
  useEffect(() => {
    setHandleRefresh(() => reload);
    if (!stateProjects?.length) {
      loadProjects(dataProvider, (projects) => setProjects(projects.map(mapProject)), client.id, dispatchProjects);
    }
  }, []); //eslint-disable-line
  useEffect(() => reload(), filters.propList); //eslint-disable-line

  return (
    <div>
      <CreateAppDialog
        clientId={client.id}
        isOpen={isCreateDialogOpen}
        onCreate={() => reload()}
        close={() => setCreateDialogOpen(false)}
      />
      <ListFilter
        setQSearch={filters.set.qSearch}
        actions={[
          <PrimaryButton
            onClick={() => setCreateDialogOpen(true)}
            key="client_create_app_button"
            label={t('resources.projects.buttons.createApp')}
            icon={<AddCircle />}
          />,
        ]}
      >
        <Dropdown
          className={classes.MuiSelect}
          allowEmpty
          items={projects || []}
          value={projectId}
          onChange={(id) => {
            setProjectId(id);
            loadApps(dataProvider, setApps, setTotalApps, client.id, id, filters, dispatch, setLoading);
          }}
          light
          condensed
          emptyLabel={t('resources.projects.allProjects')}
        />

        {/* <LabeledSwitch label={t('fields.showInactive')} reverse /> */}
      </ListFilter>
      <Table
        loading={loading && apps.length === 0}
        onRowClick={(row) => history.push(`/apps/${row.id}/show`)}
        data={apps}
        columns={appsColumns}
        onSort={filters.handleRequestSort}
        order={filters.order}
        orderBy={filters.orderBy}
        totalRecords={totalApps}
        rowsPerPage={filters.limit}
        page={filters.offset / filters.limit}
        onChangePage={(value) => filters.set.offset(value * filters.limit)}
        onChangeRowsPerPage={filters.set.limit}
        noDataMessage={t('resources.apps.noDataMessageApps')}
      />
    </div>
  );
};

export const Apps = ({ client, setHandleRefresh }) => {
  if (!client) {
    return <div></div>;
  }

  return <AppsList client={client} setHandleRefresh={setHandleRefresh} />;
};
