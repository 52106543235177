import React from 'react';
import { usePermissions } from 'ra-core';
import { DashboardMenuItem, MenuItemLink, Responsive } from 'ra-ui-materialui';
import { checkPermissions } from 'components';
import { compact } from 'lodash';
import { useTranslate, useAccount } from 'hooks';
import { getItems } from 'config/menu';
import { makeStyles } from '@material-ui/core';
import { useGlobalState } from 'state';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: 250,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    background: theme.palette.background.surface.primary,
    borderRight: '1px solid #E2E4E9',
    minHeight: '100%',
    boxSizing: 'border-box',
    '& li': {
      width: '90%',
      marginTop: theme.spacing(1),
      color: theme.palette.text.tertiary,
      fontSize: theme.typography.body2.fontSize,
      '& div': {
        minWidth: '34px',
      },
    },
  },
  links: {
    width: '90%',
    marginTop: theme.spacing(1),
    color: theme.palette.text.tertiary,
    fontSize: theme.typography.body2.fontSize,
    borderRadius: 4,
    '& svg': {
      color: theme.palette.text.tertiary,
    },
    '& div': {
      minWidth: '34px',
    },
  },
  activeLinks: {
    background: theme.palette.background.surface.secondary,
  },
}));

function renderItems(account, permissions, items, translate, classes) {
  return account
    ? compact(
        items.map((item) =>
          item.checkAccount(account) && checkPermissions(permissions, ...item.permissions) ? (
            <MenuItemLink
              key={item.name}
              to={item.path || `/${item.name}`}
              className={classes.links}
              activeClassName={classes.activeLinks}
              primaryText={translate(item.label || `resources.${item.name}.name`, { smart_count: 2 })}
              leftIcon={item.icon}
            />
          ) : null,
        ),
      )
    : [];
}

export const Sidebar = ({ logout }) => {
  const classes = useStyles();
  const translate = useTranslate();
  // const sidebarIsOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  // const [isOpen, setIsOpen] = useState();
  const { permissions } = usePermissions();
  const account = useAccount();
  const { growthBook } = useGlobalState();
  const useRegHandlerV2 = growthBook.isOn('reg-handler-v2') ?? false;

  return (
    <div className={classes.sidebar}>
      <div className={classes.container}>
        <DashboardMenuItem dense={false} activeClassName={classes.activeLinks} className={classes.links} />
        {renderItems(account, permissions, getItems(useRegHandlerV2), translate, classes)}
        <Responsive
          small={logout}
          medium={null} // Pass null to render nothing on larger devices
        />
      </div>
    </div>
  );
};
