import { stringify } from 'query-string';
import { fetchJson as httpClient } from './fetch';
import _ from 'lodash';
import authProvider from 'lib/authProvider';

async function responseMany(response) {
  const {
    json: { data, meta },
  } = await response;
  return {
    data: data.map((x) => ({
      ...x,
      id: x[(meta !== undefined && meta.id) || 'id'],
    })),
    total: meta.total || data.length,
  };
}

async function responseOne(response) {
  const {
    json: { data, meta },
  } = await response;
  return {
    data: { ...data, id: data[(meta !== undefined && meta.id) || 'id'] },
  };
}

async function responseRaw(response) {
  const { json } = await response;
  return { data: json };
}

function queryPagination(params) {
  const { page, perPage } = Object.assign({ page: 0, perPage: 100 }, params.pagination || {});
  return perPage
    ? {
        limit: perPage,
        offset: page > 0 ? (page - 1) * perPage : 0,
      }
    : {};
}

function querySort(params) {
  const { field, order } = params.sort || {};
  return field ? { order: `${field} ${order}` } : {};
}

function isFile(value) {
  return value && value.rawFile && value.rawFile instanceof File;
}

function flat(data) {
  const res = {};
  _.each(data, (value, key) => {
    if (typeof value === 'object' && !isFile(value)) {
      Object.assign(
        res,
        _.mapKeys(flat(value), (val, k) => `${key}.${k}`),
      );
    } else {
      res[key] = value;
    }
  });
  return res;
}

function multipartBody(params) {
  const data = flat(params);
  const formData = new FormData();
  Object.keys(data).map(async (key) => {
    const value = data[key];
    if (isFile(value)) {
      formData.append(key, value.rawFile);
    } else {
      formData.append(key, value);
    }
  });
  formData.append('_json', JSON.stringify(params));
  return formData;
}

function hasFiles(params) {
  const data = flat(params);
  return _.values(data).filter((value) => isFile(value)).length > 0;
}

const getV2Options = () => {
  const useRoutesV2 = localStorage.getItem('useRoutesV2');
  const useV2 = useRoutesV2 && JSON.parse(useRoutesV2);
  const { userType } = authProvider.getProfile() ?? {};

  return { useV2, userType };
};

export default (apiUrl) => ({
  fetch: (path, params = {}, options = {}) => {
    const { useV2, userType } = getV2Options();
    return responseRaw(httpClient(`${apiUrl}${useV2 ? `/v2/${userType}` : ''}/${path}?${stringify(params)}`, options));
  },

  execute: (path, params = {}, options) => {
    const multipart = hasFiles(params);
    const body = multipart ? multipartBody(params) : JSON.stringify(params);
    const { useV2, userType } = getV2Options();

    return responseRaw(
      httpClient(`${apiUrl}${useV2 ? (path.startsWith('authentication/') ? '/v2' : `/v2/${userType}`) : ''}/${path}`, {
        method: 'POST',
        multipart,
        body,
        ...(options || {}),
      }),
    );
  },

  patch: (path, params = {}) => {
    const multipart = hasFiles(params);
    const body = multipart ? multipartBody(params) : JSON.stringify(params);
    const { useV2, userType } = getV2Options();

    return responseRaw(
      httpClient(`${apiUrl}${useV2 ? `/v2/${userType}` : ''}/${path}`, {
        method: 'PATCH',
        multipart,
        body,
      }),
    );
  },

  getList: (resource, params) => {
    const query = {};

    Object.assign(query, queryPagination(params));
    Object.assign(query, querySort(params));
    Object.assign(query, params.filter);

    const { useV2, userType } = getV2Options();
    const url = `${apiUrl}${useV2 ? `/v2/${userType}` : ''}/${resource}?${stringify(query)}`;

    return responseMany(httpClient(url, { method: 'GET' }));
  },

  getOne: (resource, params) => {
    const { useV2, userType } = getV2Options();
    return responseOne(httpClient(`${apiUrl}${useV2 ? `/v2/${userType}` : ''}/${resource}/${params.id}`));
  },

  getMany: (resource, params) => {
    const { useV2, userType } = getV2Options();
    const query = { ids: params.ids.join(',') };
    const url = `${apiUrl}${useV2 ? `/v2/${userType}` : ''}/${resource}?${stringify(query)}`;

    return responseMany(httpClient(url, { method: 'GET' }));
  },

  getManyReference: (resource, params) => {
    const query = {};

    Object.assign(query, queryPagination(params));
    Object.assign(query, querySort(params));
    Object.assign(query, params.filter);

    query[params.target] = params.id;
    const { useV2, userType } = getV2Options();
    const url = `${apiUrl}${useV2 ? `/v2/${userType}` : ''}/${resource}?${stringify(query)}`;

    return responseMany(httpClient(url, { method: 'GET' }));
  },

  update: (resource, params) => {
    const multipart = hasFiles(params.data);
    const body = multipart ? multipartBody(params.data) : JSON.stringify(params.data);
    const { useV2, userType } = getV2Options();

    return responseOne(
      httpClient(`${apiUrl}${useV2 ? `/v2/${userType}` : ''}/${resource}/${params.id}`, {
        method: 'PATCH',
        multipart,
        body,
      }),
    );
  },

  updateMany: async (resource, params) => {
    const { useV2, userType } = getV2Options();
    const responses = await Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}${useV2 ? `/v2/${userType}` : ''}/${resource}/${id}`, {
          method: 'PATCH',
          body: JSON.stringify(params.data),
        }),
      ),
    );

    return { data: responses.map(({ json }) => json.id) };
  },

  rearrange: (resource, params) => {
    const { useV2, userType } = getV2Options();
    return responseMany(
      httpClient(`${apiUrl}${useV2 ? `/v2/${userType}` : ''}/${resource}/rearrange`, {
        method: 'PATCH',
        body: JSON.stringify(params),
      }),
    );
  },

  create: (resource, params) => {
    const multipart = hasFiles(params.data);
    const body = multipart ? multipartBody(params.data) : JSON.stringify(params.data);
    const { useV2, userType } = getV2Options();

    return responseOne(
      httpClient(`${apiUrl}${useV2 ? `/v2/${userType}` : ''}/${resource}`, {
        method: 'POST',
        multipart,
        body: body,
      }),
    );
  },

  delete: (resource, params) => {
    const { useV2, userType } = getV2Options();
    return responseOne(
      httpClient(`${apiUrl}${useV2 ? `/v2/${userType}` : ''}/${resource}/${params.id}`, { method: 'DELETE' }),
    );
  },

  deleteMany: async (resource, params) => {
    const { useV2, userType } = getV2Options();
    const responses = await Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}${useV2 ? `/v2/${userType}` : ''}/${resource}/${id}`, {
          method: 'DELETE',
        }),
      ),
    );

    return { data: responses.map(({ json }) => json.id) };
  },
});
