import React from 'react';

import { makeStyles } from '@material-ui/core';
import { ShowTitle } from 'components/designSystem/ShowTitle';

const useStyles = makeStyles((theme) => ({
  description: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.tertiary,
    margin: 0,
    wordBreak: 'break-word',
  },
}));

export const DefaultHeader = ({ title, description }) => {
  const classes = useStyles();

  return (
    <div>
      <ShowTitle name={title} />
      <p className={classes.description}>{description}</p>
    </div>
  );
}
