import React from 'react';

import { makeStyles } from '@material-ui/core';
import { DocumentFileIcon } from 'assets/DocumentFileIcon';
import { Field } from 'components/designSystem/Form';
import Icon from 'components/Icon';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  label: {
    boxSizing: 'border-box',
    width: '100%',
    border: `2px dashed ${theme.palette.background.surface.pressed}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: 150,
    cursor: 'pointer',
    padding: '5rem 1rem',
    aspectRatio: '5 / 2',
  },
  withValue: {
    border: `2px solid ${theme.palette.background.surface.secondary}`,
    background: theme.palette.background.surface.secondary,
  },
  noFileIcon: {
    borderRadius: '100%',
    aspectRatio: '1 / 1',

    '& svg': {
      transform: 'translate(2.5px, 2px)',
    },
  },
  noFileLabelContainer: {
    width: '100%',
    textAlign: 'center',
  },
  noFileLabel: {
    color: theme.palette.text.tertiary,
    fontSize: theme.typography.body2.fontSize,
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  removeButton: {
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.background.surface.secondary,
    width: 15,
    height: 15,
    borderRadius: '100%',
    cursor: 'pointer',
    background: theme.palette.text.tertiary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filenameContainer: {
    fontSize: theme.typography.caption.fontSize,
    background: theme.palette.background.surface.pressed,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderRadius: '4px',
    padding: '.5rem',
    boxSizing: 'border-box',
    margin: '3.5px 0',
  },
}));

export const DocumentFileDragDropField = ({ input, ...props }) => {
  const { label, value, onChange, accept } = { ...(input || {}), ...props };
  const classes = useStyles();

  const handleDocument = ({ evt, _file }) => {
    const file = evt ? evt.target.files[0] : _file;
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      onChange({ url: reader.result, rawFile: file });
    };
    reader.readAsDataURL(file);
  };

  const handleRemove = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    onChange(undefined);
  };

  return (
    <div
      className={classes.container}
      onDrop={(evt) => {
        evt.preventDefault();

        const _file = evt.dataTransfer.items ? evt.dataTransfer.items[0].getAsFile() : evt.dataTransfer.files[0];
        handleDocument({ _file });
      }}
      onDragOver={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();
      }}
    >
      <label className={`${classes.label} ${!!value ? classes.withValue : ''}`}>
        <div className={classes.noFileIcon}>
          <DocumentFileIcon />
        </div>
        {value ? (
          <div className={classes.filenameContainer}>
            <span>{value.rawFile.name}</span>
            <div className={classes.removeButton} onClick={handleRemove}>
              <Icon size={15}>close</Icon>
            </div>
          </div>
        ) : (
            <div className={classes.noFileLabelContainer}>
              <span className={classes.noFileLabel}>{label}</span>
            </div>
          )}
        <input
          type="file"
          style={{ opacity: 0 }}
          accept={accept || '.csv'}
          onChange={(evt) => handleDocument({ evt })}
        />
      </label>
    </div>
  );
};

export const DocumentFileDragDropFormField = (props) => <Field {...props} component={DocumentFileDragDropField} />;
