import React, { useState } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { DropdownFormField } from 'components/designSystem/Dropdown';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
// import { CheckCircle } from '@material-ui/icons';

import { Form } from 'react-final-form';
import { required } from 'lib/formValidators';
import { useDataProvider, useTranslate } from 'ra-core';
import { availableSiteExportFormats, exportSites } from './ExportSiteDialog.model';
import { LabeledSwitchField } from 'components/designSystem/LabeledSwitch';

const useStyles = makeStyles((theme) => ({
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    paddingTop: 14,

    '& button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 14,
  },
  setDefaultContainer: {
    paddingBottom: 2,
    justifyContent: 'flex-end',
    position: 'relative',
    left: theme.spacing(1) * -1,

    '& > div': {
      color: theme.palette.text.tertiary,
    },
  },
}));

export const ExportSiteDialog = ({ clientId, isOpen, close }) => {
  const t = useTranslate();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog isOpen={isOpen} close={close} title={t('resources.sites.exportSites')}>
      <Form
        initialValues={{ format: 'CSV', withInactive: false }}
        onSubmit={exportSites({ dataProvider, setLoading, clientId, closeDialog: close, t })}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DropdownFormField
              name="format"
              validate={required(t)}
              full
              items={availableSiteExportFormats || []}
              label={t('resources.sites.formatSites')}
              translate
            />
            <LabeledSwitchField
              reverse
              className={classes.setDefaultContainer}
              name="withInactive"
              label={t('resources.sites.inactiveSite')}
            />
            <div className={classes.bottomContainer}>
              <TertiaryButton disabled={loading} label={t('resources.projects.buttons.cancel')} onClick={close} />
              <PrimaryButton
                disabled={loading}
                type="submit"
                label={t('resources.projects.buttons.export')}
                onClick={handleSubmit}
                // icon={<CheckCircle />}
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};
