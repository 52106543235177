import React from 'react';

import { makeStyles, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { CheckCircle, Search } from '@material-ui/icons';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { Field } from 'components/designSystem/Form';
import { useInputStyles } from './index';

const useStyles = makeStyles((theme) => ({
  withIconBefore: {
    paddingLeft: 27,
  },
  withIconAfter: {
    paddingRight: 27,
  },
  iconContainer: {
    position: 'absolute',
    display: 'block',
    bottom: 8,
    left: 8,

    height: 16,

    '& svg': {
      color: theme.palette.text.tertiary,
      width: 16,
      height: 16,
    },
  },
  iconContainerAfter: {
    left: 'unset',
    right: 12,
  },

  iconError: {
    '& svg': {
      color: theme.palette.error.main,
      maxWidth: 16,
      maxHeight: 16,
    },
  },
  iconSuccess: {
    '& svg': {
      color: theme.palette.success.main,
      maxWidth: 16,
      maxHeight: 16,
    },
  },

  iconFocus: {
    '& svg': {
      color: theme.palette.text.primary,
      maxWidth: 16,
      maxHeight: 16,
    },
  },
}));

const FieldLabel = ({ label, required, helperText }) => {
  const classes = useInputStyles();

  return (
    <div className={classes.labelContainer}>
      <span className={classes.label}>
        {label}
        {required && <span className={classes.requiredFlag}>*</span>}
      </span>
      {helperText && (
        <Tooltip className={classes.helperInfo} title={helperText}>
          <InfoOutlinedIcon className={classes.helperIcon} />
        </Tooltip>
      )}
    </div>
  );
};

// const FieldFocus = ({ focus, icon: pIcon, className: propsClassName }) => {
//   const classes = useStyles();
//   let className = classes.iconContainer;

//   let icon = pIcon;
//   if (focus) {
//     icon = <CancelRoundedIcon />;
//   }

//   return <div className={`${className} ${propsClassName}`}>{icon}</div>;
// };

const FieldHint = ({ hint, errorMessage, successMessage }) => {
  const classes = useInputStyles();
  if (errorMessage) {
    return <span className={classes.errorMessage}>{errorMessage}</span>;
  }
  if (successMessage) {
    return <span className={classes.successMessage}>{successMessage}</span>;
  }
  if (hint) {
    return <span className={classes.hintMessage}>{hint}</span>;
  }
  return <span />;
};

function getInputClassName({
  initialClassName,
  classes,
  size,
  full,
  light,
  isSuccess,
  isError,
  iconPosition,
  componentClasses,
}) {
  let inputClassName = `${initialClassName} ${classes[size] || classes.small}`;

  if (full) {
    inputClassName = `${inputClassName} ${classes.full}`;
  }
  if (light) {
    inputClassName = `${inputClassName} ${classes.light}`;
  }

  if (!!iconPosition) {
    inputClassName = `${inputClassName} ${
      iconPosition === 'left' ? componentClasses.withIconBefore : componentClasses.withIconAfter
    }`;
  }

  if (isError) {
    inputClassName = `${inputClassName} ${classes.inputError}`;
  } else if (isSuccess) {
    inputClassName = `${inputClassName} ${classes.inputSuccess}`;
  }

  return inputClassName;
}

const FieldIcon = ({ className: propsClassName, icon: propsIcon, error, success }) => {
  const classes = useStyles();
  let className = classes.iconContainer;

  let icon = propsIcon;
  if (error) {
    if (!icon) {
      icon = <CloseRoundedIcon />;
    }
    className = `${className} ${classes.iconError}`;
  } else if (success) {
    if (!icon) {
      icon = <CheckCircle />;
    }
    className = `${className} ${classes.iconSuccess}`;
  }

  return <div className={`${className} ${propsClassName}`}>{icon}</div>;
};

export const TextField = React.forwardRef(({ input, ...props }, ref) => {
  const {
    value,
    onChange,
    onClick,
    onFocus,
    onBlur,
    label,
    placeholder,
    disabled,
    size,
    light,
    icon,
    iconPosition,
    className,
    password,
    required,
    helperText,
    inputClassName: _inputClassName,
    full,
    hint,
    meta = {},
  } = { ...(input || {}), ...props };

  const classes = useInputStyles();
  const componentClasses = useStyles();
  const errorMessage = meta.error && meta.touched ? meta.error : undefined;
  const successMessage = meta.success && meta.touched ? meta.success : undefined;

  const inputClassName = getInputClassName({
    initialClassName: classes.input,
    classes,
    full,
    light,
    size,
    isSuccess: !!successMessage,
    isError: !!errorMessage,
    componentClasses,
    iconPosition: icon && !iconPosition ? 'left' : iconPosition,
  });

  return (
    <label className={`${classes.container}${className || ''}`}>
      {label && <FieldLabel required={required} helperText={helperText} label={label} />}

      {iconPosition !== 'right' && <FieldIcon icon={icon} error={!!errorMessage} success={!!successMessage} />}
      <input
        ref={ref}
        className={`${inputClassName} ${_inputClassName || ''}`}
        type={password ? 'password' : 'text'}
        value={value || ''}
        onChange={onChange || (() => {})}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        placeholder={placeholder}
        disabled={disabled}
      />
      {iconPosition === 'right' && (
        <FieldIcon
          icon={icon}
          error={!!errorMessage}
          success={!!successMessage}
          className={componentClasses.iconContainerAfter}
        />
      )}
      <FieldHint errorMessage={errorMessage} successMessage={successMessage} hint={hint} />
      {/* <FieldFocus focus={focus} icon={icon} /> */}
    </label>
  );
});

export const TextFormField = (props) => <Field {...props} component={TextField} />;

export const SearchField = (props) => <TextField {...props} icon={<Search />} />;
export const SearchFormField = (props) => <Field {...props} component={SearchField} />;
