import React, { useEffect, useState } from 'react';
import { useTranslate } from 'ra-core';
import { DefaultHeader } from '../../../components/designSystem/DefaultHeader';
import { useGlobalState } from '../../../state';
import { GeneralCards } from './GeneralCards';
import { ClientCards } from './ClientCards';
import { AppSiteCards } from './AppSiteCards';

export default () => {
  const t = useTranslate();

  const { growthBook } = useGlobalState();
  const useClientSection = growthBook.isOn('sasi-user-dashboard-client-section') ?? false;

  const [clientId, setClientId] = useState();
  const [client, setClient] = useState();

  useEffect(() => {
    const localStorageClientId = parseInt(localStorage.getItem('clientId'), 10);
    if (!Number.isNaN(localStorageClientId)) {
      setClientId(localStorageClientId);
    }
  }, []);

  return (
    <div style={{ margin: '1rem 1rem' }}>
      <DefaultHeader title={t('resources.dashboard.dashboard')} />
      <GeneralCards />
      {useClientSection && (
        <>
          <ClientCards
            clientId={clientId}
            setClientId={setClientId}
            client={client}
            setClient={setClient}
          />
          {client && (
            <AppSiteCards client={client} clientId={clientId}/>
          )}
        </>
      )}
    </div>
  );
};
