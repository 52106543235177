import React from 'react';
import { Select, MenuItem, Icon, Button, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  rule: {
    display: 'flex',
    borderRadius: 3,
    color: '#9E9E9E',
    marginTop: '0.2rem',
    border: '1px solid #eee',
    padding: '0.5rem',
    width: '95%',
    '& >* ': {
      marginLeft: '0.5rem',
    },
  },
  selectField: {
    minWidth: 147,
  },
});

function Field({ formFields = [], dataFields = [], fieldMatch, onChange, handleDelete }) {
  const classes = useStyles();

  const onFieldSelected = (event, field) => {
    fieldMatch[field] = event.target.value;
    onChange();
  };

  return (
    <div className={classes.rule}>
      <FormControl>
        <Select
          className={classes.selectField}
          value={fieldMatch.formField}
          onChange={(ev) => onFieldSelected(ev, 'formField')}
        >
          {formFields.map((fieldName, index) => (
            <MenuItem value={fieldName} key={index}>
              {fieldName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <Select
          className={classes.selectField}
          value={fieldMatch.dataField}
          onChange={(ev) => onFieldSelected(ev, 'dataField')}
        >
          {dataFields.map((fieldName, index) => (
            <MenuItem value={fieldName} key={index}>
              {fieldName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button onClick={handleDelete}>
        <Icon>delete</Icon>
      </Button>
    </div>
  );
}
export default Field;
