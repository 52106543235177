import React, { useState, useEffect } from 'react';

import { Switch } from '@material-ui/core';

export const Toggler = (props) => {
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const onClick = (evt) => {
    setChecked(!checked);
    try {
      props.onClick(evt);
    } catch (err) {
      setChecked(!checked);
      throw err;
    }
  };

  return <Switch {...props} checked={checked} onClick={onClick} />;
};
