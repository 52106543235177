import React from 'react';

import { Icon } from 'components';
import { makeStyles } from '@material-ui/core';

import { buttonStyle, getAppTheme } from './ChannelPreviewButton.model';

const useStyles = makeStyles((theme) => ({
  channelButton: {
    width: '100%',
    border: 'none',
    outline: 'none',
    boxSizing: 'border-box',
    margin: theme.spacing(1.25),
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    alignItems: 'center',
  },
  minifiedChannelButton: {
    margin: theme.spacing(0.25),
  },
  icon: {
    marginRight: theme.spacing(2.5),
  },
  minifiedIcon: {
    marginRight: theme.spacing(.5),
  },
}));

export const ChannelPreviewButton = ({ channel, app, template, onClick, noPrimary = false, style: styles, minify }) => {
  const classes = useStyles();

  let sasiTheme = getAppTheme(app || template);
  if (minify) {
    sasiTheme = sasiTheme.minify;
  }

  const channelButtonStyle = buttonStyle(channel, app || template, noPrimary, minify);

  const channelConfig = channel.schema?.config || channel.config;
  const channelPrimary = channel.schema?.primary || channel.primary;
  const channelLabel = channel.schema?.label || channel.label;

  const iconName = channelConfig?.icon || '';
  const iconSize = channelPrimary ? sasiTheme.primaryIconSize : sasiTheme.secondaryIconSize;

  const iconClass = minify ? classes.minifiedIcon : classes.icon;

  return (
    <button
      type="button"
      className={`${classes.channelButton} ${minify ? classes.minifiedChannelButton : ''}`}
      onClick={onClick}
      style={{ ...channelButtonStyle, ...styles }}
    >
      {iconName && (
        <Icon size={iconSize} className={(!noPrimary && channelPrimary) ? '' : iconClass}>
          {iconName}
        </Icon>
      )}
      <span>{channelLabel || channel.name}</span>
    </button>
  );
};
