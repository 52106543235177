import React from 'react';
import { useSafeSetState, useNotify, useDataProvider, useTranslate } from 'ra-core';
import { makeStyles } from "@material-ui/styles";
import { Field, Form, useFormState } from "react-final-form";
import { Input } from "../components/Input";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router";

const useStyles = makeStyles(theme => ({
  loading: {
    position: 'absolute',
    bottom: '-1.3rem',
  },
  buttonLoginWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: 'relative',
  },
  buttonLogin: {
    width: '100%',
    marginBottom: 10,
    backgroundColor: '#16181D',
    borderRadius: '4px',
    "&:hover": {
      backgroundColor: "#26292d",
    },
    "&:disabled": {
      backgroundColor: "#858891",
    },
  },
  passwordRulesContainer: {
    color: '#8A94A8',
    transform: 'translateY(-1rem)',
    marginTop: '5px',
    fontSize: '0.813rem',
  },
  passwordRulesTitle: {
    margin: '0',
    padding: '0',
  },
  passwordRulesItems: {
    margin: '0',
    paddingLeft: '1rem',
  },
  passwordRulesItemErrored: {
    color: '#f44336',
  },
  title: {
    margin: '0',
    padding: '0',
    fontFamily: 'Nunito, sans-serif',
    marginBottom: '1.5rem',
    fontWeight: '400',
    fontSize: '1.5rem'
  },
}));

const handleError = (notify, history) => err => {
  switch (err.name) {
    case 'AuthenticationError':
      if (err.message === 'JWT_EXPIRED') {
        notify(`httpError.authentication.${err.message}_PASSWORD_RESET`, 'error', { requestId: err.requestId });
        history.push('/login');
      } else {
        notify(`httpError.authentication.${err.message}`, 'error', { requestId: err.requestId });
      }
      break;
    case 'HttpError':
    default:
      notify(`httpError.${err.message}`, 'error', { requestId: err.requestId });
  }
}

export const ForgotPasswordForm = ({ token, onSuccess }) => {
  const [loading, setLoading] = useSafeSetState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles();
  const history = useHistory();

  const submit = async ({ password, passwordConfirm }) => {
    setLoading(true);

    try {
      await dataProvider.execute('authentication/restore-password', { token, password, passwordConfirm });
      onSuccess();
    } catch (err) {
      handleError(notify, history)(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form onSubmit={submit} render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <div>
          <h2 className={classes.title}>Atualizar senha</h2>
          <Field
            id="password"
            name="password"
            component={Input}
            label={translate('ra.auth.password')}
            type="password"
            disabled={loading}
            customError={true}
            validate={value => {
              if (!value) {
                return ['INVALID_LENGTH'];
              }

              const errors = [];
              if (value.length < 8 || value.length > 64) {
                errors.push('INVALID_LENGTH');
              }
              const atLeastOneUppercase = /\w*[A-Z]\w*/;
              const atLeastOneNumber = /\w*[0-9]\w*/;
              const atLeastOneLowercase = /\w*[a-z]\w*/;
              if (!value.match(atLeastOneUppercase)) {
                errors.push('NO_UPPERCASE');
              }
              if (!value.match(atLeastOneLowercase)) {
                errors.push('NO_LOWERCASE');
              }
              if (!value.match(atLeastOneNumber)) {
                errors.push('NO_NUMBER');
              }
              return errors.length > 0 ? errors : undefined;
            }}
          />
          <PasswordValidation/>
          <Field
            id="passwordConfirm"
            name="passwordConfirm"
            component={Input}
            label='Confirmar senha nova'
            type="password"
            disabled={loading}
            validate={(value, allValues) => (value !== allValues.password) ? 'resources.users.errors.fields.passwordsDontMatch' : undefined}
          />
        </div>
        <div>
          <div className={classes.buttonLoginWrapper}>
            <Button type="submit" disabled={loading} className={classes.buttonLogin}>
              Alterar senha
            </Button>
            {loading && (
              <CircularProgress
                className={classes.loading}
                size={18}
                thickness={2}
              />
            )}
          </div>
        </div>
      </form>
    )}
    />
  )
}

const PasswordValidation = () => {
  const state = useFormState();
  const classes = useStyles();
  const errors = state.errors.password;
  const touched = state.touched.password;

  const invalidLength = errors && errors.find(e => e === 'INVALID_LENGTH');
  const noUppercase = errors && errors.find(e => e === 'NO_UPPERCASE');
  const noLowercase = errors && errors.find(e => e === 'NO_LOWERCASE');
  const noNumber = errors && errors.find(e => e === 'NO_NUMBER');

  return (
    <div className={classes.passwordRulesContainer}>
      <p className={classes.passwordRulesTitle}>
        Sua senha deve conter no mínimo:
      </p>
      <ul className={classes.passwordRulesItems}>
        <li className={(invalidLength && touched) ? classes.passwordRulesItemErrored : undefined}>
          8 caracteres (não mais que 64);
        </li>
        <li className={(noUppercase && touched) ? classes.passwordRulesItemErrored : undefined}>
          1 letra maiúscula;
        </li>
        <li className={(noLowercase && touched) ? classes.passwordRulesItemErrored : undefined}>
          1 letra minúscula;
        </li>
        <li className={(noNumber && touched) ? classes.passwordRulesItemErrored : undefined}>
          1 número;
        </li>
      </ul>
    </div>
  );
}
