import React from 'react';
import { Datagrid, List, TextField, FunctionField } from 'ra-ui-materialui';
import { DefaultActions } from 'components';
import { useCallback } from 'react';
import {
  useRefresh,
  useNotify,
  useTranslate,
  useListController,
} from 'ra-core';
import { useAccount, useState } from '../../hooks';
import ChannelExpandPanel from './components/ChannelExpandPanel';
import SampleButton from '../components/SampleButton';
import SampleEditPopover from '../components/SampleEditPopover';
import { PaginationWithNoQ } from 'resources/Client/components/PaginationWithNoQ';

const defaultState = {
  channel: {},
  anchorEl: null,
};

const ChannelList = (props) => {
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();
  const { ClientId } = useAccount();
  const [current, setCurrent] = useState(defaultState);

  const updated = useCallback(() => {
    notify('notifications.updated');
    refresh();
  }, [notify, refresh]);

  const controllerProps = useListController({
    ...props,
    resource: 'apps',
    filter: {
      ClientId,
      withApp: true,
    },
  });

  return (
    <>
      <SampleEditPopover
        resource="channels"
        channel={current.channel}
        config={
          current.channel && current.channel.App && current.channel.App.config
        }
        anchorEl={current.anchorEl}
        onSubmit={updated}
        onClose={() => setCurrent(defaultState)}
      />
      <List
        {...controllerProps}
        pagination={<PaginationWithNoQ />}
        bulkActionButtons={false}
        hasEdit={false}
        hasList={false}
        hasShow={false}
        actions={<DefaultActions />}
      >
        <Datagrid expand={<ChannelExpandPanel />}>
          <TextField label="titles.apps" source="App.name" />
          <FunctionField
            source="channelType"
            label="resources.channels.fields.channelType"
            render={(record, source) =>
              translate(`channelTypes.${record[source]}`)
            }
          />
          <FunctionField
            render={(channel) => (
              <SampleButton
                onClick={(event) =>
                  setCurrent({ channel, anchorEl: event.target })
                }
                channel={channel}
                config={{ ...(channel ? channel.config : []), primary: false }}
              />
            )}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default ChannelList;
