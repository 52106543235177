import React from "react"
import { makeStyles } from "@material-ui/styles"
import { email, required } from "ra-core"
import { TextInput, Labeled } from "ra-ui-materialui"

const useStyles = makeStyles(theme => ({
  input: {
    width: theme.spacing(32),
    clear: "both",
    marginRight: 5
  },
  divider: {
    clear: "both"
  }
}))

export default ({type, ...props}) => {
  const classes = useStyles()
  const validate = type === "standard2" ? [required()] : []
  return <Labeled label="titles.admin" className={classes.input}>
    <>
    <TextInput  {...props} source="user.name" validate={validate} className={classes.input} />
    <TextInput  {...props} source="user.username" validate={validate} className={classes.input} />
    <TextInput  {...props} source="user.email" validate={[...validate, email()]} className={classes.input} />
    </>
  </Labeled>
}
