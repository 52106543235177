import { useCallback, useEffect, useRef, useState } from "react";

export const useTimer = (timeAmountInSecs) => {
  const [time, setTime] = useState(timeAmountInSecs);
  const [timeStopped, setTimeStopped] = useState(false);
  const _interval = useRef(null);

  const _stopTimer = useCallback(() => {
    if (_interval.current) {
      clearInterval(_interval.current);
    }
  }, []); // eslint-disable-line

  const stopTimer = useCallback(() => {
    if (_interval.current) {
      clearInterval(_interval.current);
      setTimeStopped(true);
    }
  }, []); // eslint-disable-line

  const clearTimer = useCallback(() => {
    stopTimer();
    setTime(0);
  }, []); // eslint-disable-line

  const startTimer = useCallback(() => {
    setTimeStopped(false);
    _interval.current = setInterval(() => {
      setTime(t => t - 1)
    }, 1000);
  }, []); // eslint-disable-line

  useEffect(() => {
    startTimer();
    return () => {
      _stopTimer()
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (time === 0 && _interval.current) {
      clearInterval(_interval.current);
      setTimeStopped(true);
    }
  }, [time]); // eslint-disable-line

  const restartTimer = () => {
    stopTimer();
    setTime(timeAmountInSecs);
    startTimer();
  }

  return { time, restartTimer, stopTimer, clearTimer, isTimeStopped: timeStopped };
}
