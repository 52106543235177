import React, { useEffect, useCallback, useState } from 'react';
import {
  SimpleForm,
  TabbedForm,
  Filter,
  ReferenceManyField,
  ReferenceField,
  NumberInput,
  SelectInput,
  AutocompleteInput,
  SelectArrayInput,
  ResettableTextField,
  Toolbar,
  Tab,
  Pagination,
} from 'ra-ui-materialui';
import { List } from 'ra-ui-materialui';

import { useRouter, useAccount } from 'hooks';
import { useNotify } from 'ra-core';
import { includes } from 'lodash';
import PropTypes from 'prop-types';
import { MFAWelcomeMessage } from './MFAWelcomeMessage';
import Layout from './InnerLayout';

function extend(klass, props) {
  klass.defaultProps = Object.assign(klass.defaultProps || {}, props);
}

extend(SimpleForm, { variant: 'standard' });
extend(TabbedForm, { variant: 'standard' });
extend(AutocompleteInput, { variant: 'standard' });
extend(Filter, { variant: 'standard' });
extend(ResettableTextField, { variant: 'standard' });
extend(NumberInput, { variant: 'standard' });
extend(Toolbar, { margin: 'normal' });
extend(Tab, { contentClassName: 'tabContent' });
extend(Pagination, { rowsPerPageOptions: [], perPage: 50 });
extend(ReferenceManyField, { perPage: 50 });
extend(List, { perPage: 50 });
extend(SelectInput, { emptyText: '\u00A0' });
extend(SelectArrayInput, { variant: 'standard' });

ReferenceField.propTypes = {
  ...ReferenceField.propTypes,
  translateChoice: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default ({ setOpenOnce, openOnce, ...props }) => {
  const { history } = useRouter();
  const account = useAccount();
  const notify = useNotify();

  const [modalOpen, setModalOpen] = useState(
    (!account || !account.mfaMethod || account.mfaMethod.length === 0) && !openOnce,
  );
  const handleRedirect = account && account.id && !account.hasPassword;

  const validatePath = useCallback(
    ({ pathname }) => {
      const allowedPath = ['/profile', '/login'];
      if (handleRedirect && !includes(allowedPath, pathname)) {
        notify('Please setup your password', 'warning');
        history.push('/profile');
      }
    },
    [handleRedirect, history, notify],
  );

  useEffect(() => {
    if (handleRedirect) {
      validatePath(history.location);
      return history.listen((args) => {
        validatePath(args);
      });
    }
  }, [handleRedirect, history, validatePath]);

  return (
    <div>
      <Layout {...props} />
      <MFAWelcomeMessage
        isOpen={modalOpen}
        handleClose={() => {
          setModalOpen(false);
          setOpenOnce(true);
        }}
        account={account}
      />
    </div>
  );
};
