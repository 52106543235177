import React from 'react';
import { useInput, FieldTitle, useLocale, useTranslate } from 'ra-core';
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"
import enLocale from "date-fns/locale/en-US";
import ptBrLocale from "date-fns/locale/pt-BR";
import { makeStyles } from "@material-ui/styles";
const locales = {
  "pt-BR": ptBrLocale
}

const useStyles = makeStyles(() => ({
  formReady: {
    "&>div": {
      marginTop: '5px',
      marginBottom: '28px',
    }
  },
}))

const makePicker = (PickerComponent) => ({options, label, source, resource, className, formReady, format = "yyyy, MMMM do, HH:mm", ...props}) => {
  const locale = useLocale()
  const translate = useTranslate()
  const classes = useStyles()
  const { input, isRequired, meta } = useInput({ source, label, resource, ...props})

  const { touched, error } = meta
  const onChange = (date) => {
    input.onChange(date)
    input.onBlur()
  }

  return (
    <div className={`picker ${!!formReady ? classes.formReady : ''}`}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locales[locale] || enLocale}>
        <PickerComponent
          format={format}
          {...options}
          label={<FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />}
          margin="normal"
          error={!!(touched && error)}
          helperText={touched && error}
          className={className}
          value={input.value ? input.value : null}
          onChange={date => onChange(date)}
          okLabel={translate("titles.okLabel")}
          clearLabel={translate("titles.clearLabel")}
          cancelLabel={translate("titles.cancelLabel")}
          todayLabel={translate("titles.todayLabel")}
        />
      </MuiPickersUtilsProvider>
    </div>)
}

export const DateInput = makePicker(DatePicker);
export const TimeInput = makePicker(TimePicker);
export const DateTimeInput = makePicker(DateTimePicker);
