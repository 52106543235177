// import LockButton from './components/LockButton';
import React, { useState, useEffect } from 'react';
import { useTranslate, useDataProvider } from 'ra-core';
import { useHistory } from 'react-router';
import { useClients } from 'hooks/use-resource-hooks';
import { useListFilters } from 'hooks';
import { ListFilter } from 'components/designSystem/ListFilter';
import { clientsColumns, handleCreate, listClients } from './ClientList.model';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { AddCircle } from '@material-ui/icons';
import { LabeledSwitch } from 'components/designSystem/LabeledSwitch';
import { Table } from 'components/designSystem/Table';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { Refresh } from '@material-ui/icons';
// import { LockIcon } from '@material-ui/icons/Lock';

export const ClientList = ({ client }) => {
  const dataProvider = useDataProvider();
  const t = useTranslate();
  const history = useHistory();
  const { clients: stateClients, dispatch } = useClients(); //ver esse aqui

  const [loading, setLoading] = useState();
  const filters = useListFilters();
  const [clients, setClients] = useState(stateClients);
  const [totalClients, setTotalClients] = useState(undefined);
  // const [, setHandleRefresh] = useState(() => {});

  const reload = () => listClients(dataProvider, setClients, setTotalClients, client, filters, dispatch, setLoading);

  // useEffect(() =>
  //   setHandleRefresh(() => {
  //     reload();
  //   }),
  // ); //eslint-disable-line

  useEffect(() => {
    reload();
  }, filters.propList); //eslint-disable-line

  return (
    <div>
      <h2>Clientes</h2>
      <div>
        <ListFilter
          setQSearch={filters.set.qSearch}
          actions={[
            <TertiaryButton
              key="client_action_refresh"
              label="Atualizar"
              icon={<Refresh />}
              onClick={() => reload()}
            />,
            <PrimaryButton
              key="client_create_client_button"
              label="Novo cliente"
              icon={<AddCircle />}
              onClick={handleCreate(history)}
            />,
          ]}
        >
          <LabeledSwitch
            label={t('fields.showInactive')}
            onChange={filters.set.showInactive}
            value={filters.showInactive}
          />
        </ListFilter>
        <Table
          loading={loading && clients.length === 0}
          onRowClick={(row) => history.push(`/clients/${row.id}/show`)}
          data={clients}
          columns={clientsColumns(dataProvider, reload)}
          onSort={filters.handleRequestSort}
          order={filters.order}
          orderBy={filters.orderBy}
          totalRecords={totalClients}
          rowsPerPage={filters.limit}
          page={filters.offset / filters.limit}
          onChangePage={(value) => filters.set.offset(value * filters.limit)}
          onChangeRowsPerPage={filters.set.limit}
          noDataMessage="Ainda não existem clientes criados"
        />
      </div>
    </div>
  );
};

export const Clients = ({ client, setHandleRefresh }) => {
  if (!client) {
    return <div></div>;
  }
  return <ClientList client={client} setHandleRefresh={setHandleRefresh} />;
};

// const ClientListFilter = (props) => (
//   <Filter {...props}>
//     <SearchFormField name="q" alwaysOn light iconPosition="right" placeholder="Buscar" />
//     <FilterSwitch source="showInactive" label="fields.showInactive" alwaysOn />
//   </Filter>
// );

// const LockIcon = ({ record: { locked } }) => <Icon>{locked ? 'lock' : 'lock_open'}</Icon>;

// export default ({ permissions, ...props }) => {
//   const canLock = checkPermissions(permissions, 'client:update:locked');
//   const editable = checkPermissions(permissions, 'clients:update');
//   const t = useTranslate();
//   return (
//     <List {...props} filters={<ClientListFilter />}>
//       <Datagrid>
//         <LockIcon />
//         <ShowLink source="name" />
//         <TextField source="nickname" />
//         <ActiveSwitch
//           fieldName="active"
//           source={t('resources.projects.buttons.active')}
//           readonly={!editable}
//           readonlySource="locked"
//         />
//         {canLock && <LockButton label="" />}
//         {editable && <EditButton />}
//       </Datagrid>
//     </List>
//   );
// };
