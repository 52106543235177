import React from 'react';
import {
  ReferenceField,
  SelectField,
  Button,
  TextField,
  TabbedShowLayout,
  Pagination,
  ReferenceManyField,
  SimpleForm,
  TextInput,
  Datagrid,
  Tab,
} from 'ra-ui-materialui';
import { Show, DateField, ShowLink, checkPermissions, required } from 'components';
import { roles } from 'lib/consts';
import RoleButton from 'resources/MobileProfile/components/RoleButton';
import StatusUpdateButton from 'resources/MobileProfile/components/StatusUpdateButton';
import ActionsForTab from 'resources/Common/ActionsForTab';
import { DefaultActions, QuickCreateButton, QuickEditButton } from 'components';
import { useRef, useRecord, useAccount } from 'hooks';
import { useRefresh, email, useTranslate } from 'ra-core';
import ContactCard from 'resources/MobileProfile/components/ContactCard';
import { Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import RegistrationCode, { Create as CreateCodeButton } from 'resources/Common/RegistrationCode';
import DeleteWithConfirmButton from 'components/QuickDeleteButton';

const choices = roles.client;

const ShowEmail = ({ children, source, record, ...props }) => {
  const email = (record.profileProps || {}).email || record.email;
  record.email = email;
  return React.cloneElement(<TextField record={record} source="email" />, props);
};

const ShowPhone = ({ children, record, source, ...props }) => {
  const phone = (record.profileProps || {}).phone || record.phone;
  record.phone = phone;
  return React.cloneElement(<TextField record={record} source="phone" />, props);
};

const CreateUserButton = (props) => (
  <QuickCreateButton {...props} resource="users">
    <SimpleForm toolbar={null} resource="users">
      <TextInput source="name" validate={required()} />
      <TextInput source="username" validate={required()} />
      <TextInput source="email" validate={email()} />
      <TextInput source="password" autoComplete="off" type="password" validate={required()} />
      <TextInput source="passwordConfirm" autoComplete="off" type="password" validate={required()} />
    </SimpleForm>
  </QuickCreateButton>
);

const EditUserButton = (props) => (
  <QuickEditButton {...props} resource="users">
    <SimpleForm toolbar={null} resource="users" record={props.record}>
      <TextInput source="name" validate={required()} />
      <TextInput source="username" validate={required()} />
      <TextInput source="email" validate={email()} />
      <TextInput source="password" autoComplete="off" type="password" />
      <TextInput source="passwordConfirm" autoComplete="off" type="password" />
    </SimpleForm>
  </QuickEditButton>
);

export default ({ permissions, ...props }) => {
  const translate = useTranslate();
  const account = useAccount() || {};

  const hasClient = account.ClientId;

  const canSeeUsers = checkPermissions(permissions, 'users:view');
  const canSeeCodes = checkPermissions(permissions, 'registration-codes:view');
  const canSeeClients = checkPermissions(permissions, 'clients:view');

  const refresh = useRefresh();
  const containerRef = useRef();
  const record = useRecord('teams');
  const listPath = record && record.ClientId ? `/clients/${record.ClientId}/show/4` : '';
  const createProps = record
    ? {
        ClientId: record.ClientId,
        SiteId: record.id,
        userType: 'client',
        role: 'manager',
      }
    : {};
  return (
    <Show
      titleSource="name"
      actions={<DefaultActions hasList={false} hasEdit={false} portalRef={containerRef} />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="tabs.summary">
          <ActionsForTab containerRef={containerRef} hasList={false} hasEdit={true}>
            <Button component={Link} to={listPath} label="ra.action.list">
              <Icon>list</Icon>
            </Button>
          </ActionsForTab>
          <TextField source="name" />
          <TextField source="tag" />
          {hasClient && canSeeClients && (
            <ReferenceField basePath="/clients" source="ClientId" reference="clients" link="show">
              <TextField source="name" />
            </ReferenceField>
          )}
        </Tab>
        {canSeeUsers && (
          <Tab label="tabs.users">
            <ActionsForTab containerRef={containerRef}>
              {checkPermissions(permissions, 'users:update') && (
                <CreateUserButton
                  label={translate('titles.create', {
                    resource: translate('roles.client.manager'),
                  })}
                  defaultValues={createProps}
                  onSubmit={refresh}
                />
              )}
            </ActionsForTab>
            <ReferenceManyField addLabel={false} reference="users" target="SiteId" pagination={<Pagination />}>
              <Datagrid>
                <ShowLink source="name" />
                <TextField source="username" />
                <TextField source="email" />
                <SelectField source="role" choices={choices} />
                {checkPermissions(permissions, 'users:update') && <EditUserButton />}
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        )}
        <Tab label="tabs.maps">
          <ReferenceManyField addLabel={false} reference="maps" target="SiteId" pagination={<Pagination />}>
            <Datagrid expand={ContactCard}>
              <TextField source="name" />

              <ShowEmail source="profileProps.email" />
              <ShowPhone source="profileProps.phone" />

              {checkPermissions(permissions, 'users:approve:role') && <RoleButton source="siteManager" />}
              {checkPermissions(permissions, 'users:approve') && <StatusUpdateButton source="status" />}
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        {canSeeCodes && (
          <Tab label="titles.registrationCodes">
            <ActionsForTab containerRef={containerRef}>
              <CreateCodeButton
                title="titles.createCode"
                label="titles.createCode"
                mode="site"
                ClientId={createProps.ClientId}
                hasClient={hasClient}
                defaultValues={{ mode: 'site', SiteId: createProps.SiteId }}
                onSubmit={refresh}
              />
            </ActionsForTab>
            <ReferenceManyField
              addLabel={false}
              reference="registration-codes"
              filter={{ mode: 'site' }}
              target="SiteId"
              pagination={<Pagination />}
            >
              <Datagrid expand={RegistrationCode}>
                <TextField source="code" />
                <TextField label="fields.form" source="RegistrationForm.name" />
                <TextField label="fields.handler" source="RegistrationHandler.name" />
                <DateField source="createdAt" />
                {checkPermissions(permissions, 'registration-codes:delete') && (
                  <DeleteWithConfirmButton onDelete={refresh} />
                )}
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};
