import React from 'react';
import { Button, SaveButton, Toolbar } from 'ra-ui-materialui';
import { useTranslate, useUpdate, useNotify } from 'ra-core';

import IconContentCreate from '@material-ui/icons/Create';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const ExternalToolbar = ({ saving, handleSubmit, onClose }) => {
  return (
    <Toolbar>
      <>
        <SaveButton saving={saving} handleSubmitWithRedirect={handleSubmit} />
        <Button label="ra.action.cancel" onClick={onClose}>
          <IconCancel />
        </Button>
      </>
    </Toolbar>
  );
};

export default ({
  toolbar,
  noStyle,
  className,
  children,
  record,
  defaultValues,
  icon,
  resource,
  label,
  onSubmit,
  title,
}) => {
  if (record) {
    const translate = useTranslate();
    const notify = useNotify();
    const [update, { loading, error }] = useUpdate(resource, record.id, defaultValues);
    if (!loading && error) {
      notify(error.message, 'warning');
    }

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const open = () => setDialogOpen(true);
    const close = () => setDialogOpen(false);

    const handleSubmit = (values) => {
      close();
      update({ payload: { data: values } }, { onSuccess: onSubmit });
    };

    const dialogTitle = translate(title || 'ra.action.edit', {
      resource: translate(`resources.${resource}.name`, { smart_count: 1 }),
    });

    let tb = toolbar;
    if (!tb && toolbar !== false) {
      tb = <ExternalToolbar saving={loading} onClose={close} />;
    }

    return (
      <>
        <Button onClick={open} label={label || null} className={className}>
          {noStyle ? undefined : icon || <IconContentCreate />}
        </Button>
        <Dialog fullWidth open={dialogOpen} onClose={close}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            {React.cloneElement(children, {
              toolbar: tb,
              save: handleSubmit,
            })}
          </DialogContent>
        </Dialog>
      </>
    );
  }
  return <span />;
};
