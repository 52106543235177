import React, { useCallback, useState } from "react"
import { Datagrid, Button, TextField, Filter} from "ra-ui-materialui"
import { List, }      from "components"
import { Icon} from "@material-ui/core"
import { useRefresh, useTranslate, useDataProvider} from "ra-core"
import SendDemoMessage from "./components/SendDemoMessage"
import { makeStyles } from "@material-ui/styles"
import QuickDeleteButton from "components/QuickDeleteButton"
import { FunctionField } from "ra-ui-materialui/lib/field"
import ActiveSwitch from "resources/components/ActiveSwitch"
import FilterSwitch from "resources/components/FilterSwitch"

const useStyles = makeStyles({
  textField: {
    maxWidth: 300,
    maxHeight: "3em",
    display: "block",
    overflow: "hidden"
  }
})

const ApproveButton = ({ resource, record, onSuccess}) => {
  const dataProvider = useDataProvider()
  const onClick = useCallback(() => {
    dataProvider
    .update(resource, { id: record.id, data: { sentAt: (new Date()).toJSON() } })
    .then(onSuccess)
  }, [dataProvider, resource, record.id, onSuccess])

  return <Button onClick={onClick} label="actions.approve" >
    <Icon>send</Icon>
  </Button>
}
const SendButton = ({ record, onClick }) => {
  return <Button label="titles.testBroadcast" onClick={event => onClick(event, record)} >
    <Icon>send</Icon>
  </Button>
}
const MessagesFilter = props => <Filter {...props} >
  <FilterSwitch source="showInactive" label="fields.showInactive" alwaysOn />
</Filter>


const defaultState = { anchorEl: null, record: {} }
export default props => {
  const classes = useStyles()
  const translate = useTranslate()
  const refresh = useRefresh()
  const [current, setCurrent] = useState(defaultState)
  const onClick = useCallback((event, record) => setCurrent({ anchorEl: event.currentTarget, record }), [setCurrent])
  const onClose = useCallback(() => setCurrent(defaultState), [setCurrent])

  return <>
    <SendDemoMessage record={current.record} anchorEl={current.anchorEl} onClose={onClose} label={translate("titles.testBroadcast")} />
    <List {...props} filters={<MessagesFilter />}>
      <Datagrid expand={<TextField source="text"/>}>
        <TextField source="createdAt" />
        <TextField source="text" className={classes.textField} />
        <TextField source="Channel.name" />
        <TextField source="MonitoringProvider.name" />
        <ActiveSwitch source="active" />
        <SendButton onClick={onClick} />
        <ApproveButton onSuccess={refresh}/>
        <FunctionField render={record => 
          <QuickDeleteButton resource="broadcast-messages" 
            record={record} 
            title="ra.message.delete_content" content={record.text} 
            onDelete={refresh}/>
        }/>        
      </Datagrid>
    </List>
  </>
}