const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 50, 100];
const DEFAULT_ROWS_PER_PAGE = 50;
const CODE_LENGTH = 6;

const roles = {
  sasi: [
    { id: 'admin', name: 'roles.sasi.admin', key: 'admin' },
    {
      id: 'clientManager',
      name: 'roles.sasi.clientManager',
      key: 'clientManager',
    },
    { id: 'root', name: 'roles.sasi.root', key: 'root' },
    {
      id: 'broadcastOperator',
      name: 'roles.sasi.broadcastOperator',
      key: 'broadcastOperator',
    },
    { id: 'guest', name: 'roles.sasi.guest', key: 'guest' },
  ],
  client: [
    { id: 'admin', name: 'roles.client.admin' },
    { id: 'manager', name: 'roles.client.manager' },
    { id: 'approver', name: 'roles.client.approver' },
  ],
  map: [
    { id: 'manager', name: 'roles.map.manager' },
    { id: 'worker', name: 'roles.map.worker' },
  ],
};
const userTypes = [
  { id: 'sasi', name: 'userTypes.sasi', key: 'sasi' },
  { id: 'client', name: 'userTypes.client', key: 'client' },
];

const mapStatuses = [
  { id: 'deactivated', name: 'mapStatuses.deactivated', key: 'deactivated' },
  { id: 'active', name: 'mapStatuses.active', key: 'active' },
  { id: 'pending', name: 'mapStatuses.pending', key: 'pending' },
];
const providerTypes = [
  { id: 'standard3', name: 'providerTypes.standard3' },
  { id: 'bubble', name: 'providerTypes.bubble' },
  { id: 'email', name: 'providerTypes.email' },
  { id: 'webhook', name: 'providerTypes.webhook' },
  { id: 'api', name: 'providerTypes.api' },
  { id: 'telegram', name: 'providerTypes.telegram' },
];
const channelTypes = [
  { id: 'alert', name: 'channelTypes.alert' },
  { id: 'url', name: 'channelTypes.url' },
  { id: 'phone', name: 'channelTypes.phone' },
  { id: 'whatsapp', name: 'channelTypes.whatsapp' },
  { id: 'bubble', name: 'channelTypes.bubble' },
  { id: 'broadcast', name: 'channelTypes.broadcast' },
  { id: 'intercom', name: 'channelTypes.intercom' },
  { id: 'pdf', name: 'channelTypes.pdf' },
  { id: 'group', name: 'channelTypes.group' },
  { id: 'face-recognition', name: 'channelTypes.faceRecognition' },
];

const handlerModes = [
  { id: 'site', name: 'handlerModes.site', key: 'site' },
  { id: 'group', name: 'handlerModes.app', key: 'group' },
  { id: 'client', name: 'handlerModes.client', key: 'client' },
];
const timeOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
};
const dateOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};
export {
  providerTypes,
  channelTypes,
  roles,
  userTypes,
  mapStatuses,
  timeOptions,
  dateOptions,
  handlerModes,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  CODE_LENGTH,
};
