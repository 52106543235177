import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Popover from '@material-ui/core/Popover';
import { SaveButton} from "ra-ui-materialui"
import Card from '@material-ui/core/Card';
import { useNotify, useUpdate} from "ra-core"
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Icon, Typography, IconButton} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 350,
  },
  actions: {
    flexDirection: "row-reverse"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },   
}))
const ExternalToolbar = ({  readonly, saving, handleSubmit, onClose}) => {
  const classes = useStyles()
  return <CardActions className={classes.actions}>    
    {!readonly && <SaveButton saving={saving} handleSubmitWithRedirect={handleSubmit} />}    
  </CardActions>

}
export default ({ resource, title, subTitle, record, defaultValues = {}, anchorEl, onClose, onSubmit, readonly, children }) => {
  const classes = useStyles()
  const notify = useNotify()
  const [update, { loading, error }] = useUpdate(resource, record.id, defaultValues)
  if (error) {
    notify(error.message)
  }

  const save = (values) => {
    onClose()
    update({ payload: { data: values } }, { onSuccess: onSubmit })
  }
  
  return <Popover id="updateView" open={Boolean(anchorEl)} anchorEl={anchorEl}
    placement="bottom"
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  > 
    <Card className={classes.card}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <Icon >close</Icon>
      </IconButton>
      <CardContent>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="caption">{subTitle}</Typography>
        {React.cloneElement(children, { toolbar: <ExternalToolbar readonly={readonly} saving={loading} onClose={onClose} />, save })}
      </CardContent>      
    </Card>
  </Popover>
}
