import React, { useState } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
// import { CheckCircle } from '@material-ui/icons';

import { Form } from 'react-final-form';
import { required, requiredEmailField } from 'lib/formValidators';
import { useDataProvider, useTranslate, useNotify } from 'ra-core';
import { handleEditProvider } from './EditProviderDialog.model';

const useStyles = makeStyles((theme) => ({
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    paddingBottom: theme.spacing(2),
    paddingTop: 20,

    '& button': {
      padding: `${theme.spacing(1.1)}px ${theme.spacing(1.1)}px`,
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 20,
  },
}));

export const EditProviderDialog = ({ provider, isOpen, close, refresh }) => {
  const t = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog isOpen={isOpen} close={close} title={t('resources.monitoring-providers.editTitle')}>
      <Form
        initialValues={{ ...provider }}
        onSubmit={handleEditProvider(dataProvider, close, setLoading, refresh, notify)}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <BaseForm t={t} />

            <div className={classes.bottomContainer}>
              <TertiaryButton disabled={loading} label={t('actionButtons.cancel')} onClick={close} />
              <PrimaryButton disabled={loading} type="submit" label={t('actionButtons.save')} onClick={handleSubmit} />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};

const BaseForm = ({ t }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.content}>
        <TextFormField
          name="name"
          validate={required(t)}
          full
          dense
          size="full"
          label={t('resources.monitoring-providers.nameProvider')}
          placeholder={t('resources.monitoring-providers.placeholderName')}
        />
      </div>

      <div className={classes.content}>
        <TextFormField
          name="email"
          validate={requiredEmailField(t)}
          full
          dense
          size="full"
          label={t('resources.monitoring-providers.emailProvider')}
          placeholder={t('resources.monitoring-providers.placeholderEmail')}
        />
      </div>
    </>
  );
};
