import React from "react"
import { Button } from "ra-ui-materialui"
import { useNotify, useAuthProvider } from "ra-core"
import LoginAsIcon from '@material-ui/icons/Fingerprint';
import { useCallback, useRouter } from "hooks"


export default ({ record = {}, children }) => {
  const authProvider = useAuthProvider()
  const notify = useNotify()
  const { history } = useRouter()

  const handleLogin = useCallback(() => {
    authProvider.loginAs(record.id).then(() => {
      notify("notifications.loginAsSuccess");
      history.push("/", { id: record.id });
      history.go(0);
    }).catch(err => {
      switch (err.name) {
        case 'AuthenticationError':
          notify(`httpError.authentication.${err.message}`, 'error', { requestId: err.requestId });
          break;
        case 'HttpError':
        default:
          notify(`httpError.${err.message}`, 'error', { requestId: err.requestId });
      }
    })
  }, [authProvider, record.id, notify, history])

  return record && record.id ?
    children ?
      React.cloneElement(children, { onClick: handleLogin }) :
      <Button color="primary" onClick={handleLogin} label="titles.loginAs"><LoginAsIcon/></Button>
    : <span/>
}
