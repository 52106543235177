import React from "react"
import { useFetch} from "hooks"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  root: {
    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    padding: "1em"
  }
})

function content( data) {
  const value = Object.values(data)
  return value && value[0] ? value[0].content : ""
}
export default ({name, title}) => {
  document.title = title

  const classes   = useStyles()
  const { data, loaded} = useFetch("documents/info", { name})
  return <div className={classes.root} dangerouslySetInnerHTML={{ __html: loaded ? content(data.data) : "" }}></div>
    
}