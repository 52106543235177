import React, { useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@material-ui/core';

import { ChannelsList } from 'resources/Channel/components/ChannelsList';
import { loadRegistrationForms } from './Channels.model';
import { useDataProvider } from 'ra-core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  addChannelWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ChannelsView = ({ template }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [registrationForms, setRegistrationForms] = useState([]);
  const [page, setPage] = useState(0);

  const reload = () => loadRegistrationForms({ dataProvider, setRegistrationForms, clientId: template.clientId });

  useEffect(() => {
    reload();
  }, []); //eslint-disable-line

  const getChannels = useCallback(() => {
    template.channels.sort((a, b) => a.displayOrder - b.displayOrder);
    return template.channels.slice(page * 20, (page + 1) * 20);
  }, [page, template.channels]);

  return (
    <div className={classes.container}>
      <ChannelsList
        page={page}
        onChangePage={(page) => setPage(page)}
        channels={getChannels()}
        fullChannels={template.channels}
        template={template}
        readOnly
        registrationForms={registrationForms}
      />
    </div>
  );
};

export const Channels = ({ template }) => {
  if (!template) {
    return <div></div>;
  }
  return <ChannelsView template={template} />;
};
