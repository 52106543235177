import React, { useState, useEffect } from 'react';

import { useDataProvider, useTranslate } from 'ra-core';
import { AddCircle } from '@material-ui/icons';
import { LabeledSwitch } from 'components/designSystem/LabeledSwitch';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { ListFilter } from 'components/designSystem/ListFilter';
import { Table } from 'components/designSystem/Table';
import { loadProjects, projectsColumns } from './Projects.model';
import { useListFilters } from 'hooks';
// import { useHistory } from 'react-router';
import { useProjects } from 'hooks/use-resource-hooks';
import { CreateProjectDialog } from 'resources/Projects/components/CreateProjectDialog';
import { EditProjectDialog } from 'resources/Projects/components/EditProjectDialog';
import { DeleteProjectDialog } from 'resources/Projects/components/DeleteProjectDialog';

const ProjectList = ({ client, setHandleRefresh }) => {
  const t = useTranslate();
  const dataProvider = useDataProvider();
  // const history = useHistory();
  const { projects: stateProjects, dispatch } = useProjects(client.id);
  const [loading, setLoading] = useState(false);

  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(undefined);

  const filters = useListFilters({ orderBy: 'id', order: 'desc' });
  const [projects, setProjects] = useState(stateProjects);
  const [totalProjects, setTotalProjects] = useState(undefined);

  const reload = () =>
    loadProjects(dataProvider, setProjects, setTotalProjects, client.id, filters, dispatch, setLoading);
  useEffect(() => setHandleRefresh(() => reload), []); //eslint-disable-line

  useEffect(() => {
    reload();
  }, filters.propList); //eslint-disable-line

  return (
    <div>
      <CreateProjectDialog
        clientId={client.id}
        clientName={client.name}
        isOpen={isCreateDialogOpen}
        refresh={reload}
        close={() => setCreateDialogOpen(false)}
      />
      <EditProjectDialog
        project={selectedProject}
        isOpen={isEditDialogOpen}
        refresh={reload}
        close={() => {
          setEditDialogOpen(false);
          setSelectedProject(undefined);
        }}
      />
      <DeleteProjectDialog
        project={selectedProject}
        isOpen={isDeleteDialogOpen}
        refresh={reload}
        close={() => {
          setDeleteDialogOpen(false);
          setSelectedProject(undefined);
        }}
      />
      <ListFilter
        actions={[
          <PrimaryButton
            key="client_create_project_button"
            label={t('resources.projects.buttons.create')}
            onClick={() => setCreateDialogOpen(true)}
            icon={<AddCircle />}
          />,
        ]}
      >
        <LabeledSwitch
          label={t('resources.projects.buttons.showInactive')}
          onChange={filters.set.showInactive}
          value={filters.showInactive}
          reverse
        />
      </ListFilter>
      <Table
        loading={loading && projects.length === 0}
        onRowClick={(row) => console.log('ROW', row)}
        // onRowClick={(row) => history.push(`/projects/${row.id}/show`)}
        data={projects}
        columns={projectsColumns(
          (project) => {
            setSelectedProject(project);
            setEditDialogOpen(true);
          },
          (project) => {
            setSelectedProject(project);
            setDeleteDialogOpen(true);
          },
        )}
        onSort={filters.handleRequestSort}
        order={filters.order}
        orderBy={filters.orderBy}
        totalRecords={totalProjects}
        rowsPerPage={filters.limit}
        page={filters.offset / filters.limit}
        onChangePage={(value) => filters.set.offset(value * filters.limit)}
        onChangeRowsPerPage={filters.set.limit}
        noDataMessage={t('resources.projects.table.noDataMessage')}
      />
    </div>
  );
};

export const Projects = ({ client, setHandleRefresh }) => {
  if (!client) {
    return <div></div>;
  }
  return <ProjectList client={client} setHandleRefresh={setHandleRefresh} />;
};
