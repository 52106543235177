import React, { useEffect } from "react"
import { SelectInput, SimpleForm, TextField } from "ra-ui-materialui"
import { Edit as EditComp, DefaultToolbar, required, DateInput } from "components"
import { Col, Row } from "resources/components/Grid"
import { TextInput } from "ra-ui-materialui/lib/input";
import { useAutoRedirect, useDataProvider, useRecord, useState } from "hooks"
import Typography from "@material-ui/core/Typography";
import { useTranslate } from "ra-core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  dateInput: {
    width: '100%',
  },
  column: {
    width: '40%',
    display: 'flex',
    margin: '0 20px 0 0',
    flexDirection: 'column',
  },
  biggerColumn: {
    width: '82%',
  },
  row: {
    justifyContent: 'flex-start',
  },
}));

const validateBefore = (t) => (value, values) => {
  if (value && values.endDate && value > values.endDate) {
    return t('validation.startDateAfterEnd');
  }
}

const validateAfter = (t) => (value, values) => {
  if (value && values.startDate && value < values.startDate) {
    return t('validation.endDateBeforeStart');
  }
}

export const Edit = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const redirect = useAutoRedirect("show");
  const record = useRecord("license-comments/sasi") || {};
  const classes = useStyles();
  const [showPhaseComment, setShowPhaseComment] = useState(record.phase === "other");

  useEffect(() => {
    setShowPhaseComment(record.phase === "other");
  }, [record.phase]);

  const dataProvider = useDataProvider();
  const [phases, setPhases] = useState([]);

  useEffect(() => {
    dataProvider.getList("license-comments/sasi/phases", {})
      .then(response => {
        setPhases(response.data.map(({ id, name }) => ({
          id,
          name: translate('resources.license-comments/sasi.phases.' + name),
        })));
      })
      .catch(err => {
        // TODO: better error
        console.error(err);
      });
  }, []); // eslint-disable-line

  return (
    <EditComp {...props}>
      <SimpleForm toolbar={<DefaultToolbar/>} redirect={redirect} titleSource="title">
        <Typography variant="h6">{translate("resources.license-comments/sasi.editTitle")}</Typography>
        <TextField source="Client.name" label="resources.license-comments/sasi.client" addLabel/>
        <Row className={classes.row}>
          <Col className={classes.column}>
            <TextInput source="title" label="resources.license-comments/sasi.fields.title" validate={required()}/>
            <TextInput
              source="responsible"
              label="resources.license-comments/sasi.fields.responsible"
              validate={required()}/>
            <SelectInput source="phase" choices={phases} translate="yes" validate={required()} onChange={evt => {
              setShowPhaseComment(evt.target.value === "other");
            }}/>
          </Col>
          <Col className={classes.column}>
            <DateInput
              label="resources.license-comments/sasi.fields.startDate"
              source="startDate"
              formReady
              format="dd/MM/yyyy"
              className={classes.dateInput}
              validate={validateBefore(translate)}
            />
            <DateInput
              label="resources.license-comments/sasi.fields.endDate"
              source="endDate"
              formReady
              format="dd/MM/yyyy"
              className={classes.dateInput}
              validate={validateAfter(translate)}
            />
            {showPhaseComment && <TextInput
              source="phaseComment"
              label="resources.license-comments/sasi.fields.phaseComment"
              validate={showPhaseComment ? required() : undefined}
            />}
          </Col>
        </Row>
        <Row>
          <Col className={classes.biggerColumn}>
            <TextInput
              source="comment"
              label="resources.license-comments/sasi.fields.comment"
              validate={required()}
              multiline
              rows={20}
              variant={'outlined'}
            />
          </Col>
        </Row>
      </SimpleForm>
    </EditComp>
  )
}
