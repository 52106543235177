import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { History } from '@material-ui/icons';
import { useLocale, useTranslate } from 'ra-core';

const useDashboardStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'start',
    alignItems: 'flex-start',
    padding: 16,
    gap: 8,

    width: '100%',
    minWidth: 494,
    height: '100%',

    background: '#FCFCFD',
    border: '1 solid rgba(226, 228, 233, 0.4)',
    borderRadius: 8,
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  headerText: {
    width: 150,
    height: 16,

    fontWeight: 500,
    fontSize: 16,

    color: '#414958',
  },
  descriptionText: {
    width: 'full',
    height: 16,

    fontWeight: 450,
    fontSize: 12,
    lineHeight: '16px',
    marginBottom: 16,

    color: '#7F889C',
  },
  divisor: {
    minHeight: 1,
    width: '100%',
    background: '#F0F2F4',
    marginBottom: 9,
    marginTop: 9,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
    gap: '10px',

    width: '21px',
    height: '21px',

    background: '#F0F2F4',
    borderRadius: '16px',
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '17px',
    height: '17px',
    fontWeight: 700,
    fontSize: '14px',
    justifyContent: 'center',
    color: '#7F889C',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '12px',
    paddingTop: '2px',
  },
  infoName: {
    display: 'flex',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#7F889C',
  },
  infoNumber: {
    display: 'flex',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 700,
    color: '#414958',
    paddingTop: '4px',
  },
  historyWrapper: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  historyIcon: {
    width: '16px',
    height: '16px',
    paddingRight: '4px',
    color: '#7F889C',
  },
  historyText: {
    display: 'flex',
    fontWeight: 400,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '10px',
    color: '#7F889C',
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 96,
  },
  emptyIconContainer: {
    width: 70,
    height: 70,
  },
}));

//function to get the first letter of the name
const getFirstLetter = (name) => {
  return name.charAt(0).toUpperCase();
};

const prepareDate = (date, locale) => {
  return new Date(date).toLocaleDateString(locale, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const Top3Apps = ({ title, description, top3, date }) => {

  const classes = useDashboardStyles();

  const locale = useLocale();

  const t = useTranslate();

  return (
    <div className={classes.container}>
      <div className={classes.headerText}>{title}</div>
      <div className={classes.descriptionText}>{description}</div>
      {top3.length === 0 ? (
        <div className={classes.emptyContainer}>
          <div>
            <img className={classes.emptyIconContainer} src='/assets/emptyNoData.png' alt='empty' />
          </div>
          <div className={classes.infoWrapper}>
            <div className={classes.descriptionText}>{t('resources.dashboard.emptyMessage')}</div>
          </div>
        </div>
      ) : (
        <>
          {top3.map((item, index) => {
              return (
                <div key={index} style={{width: '100%'}}>
                  <div  className={classes.infoContainer}>
                    <div className={classes.iconWrapper}>
                      <div className={classes.icon}>
                        {getFirstLetter(item.app)}
                      </div>
                    </div>
                    <div className={classes.infoWrapper}>
                      <div className={classes.infoName}>{item.app}</div>
                      <div className={classes.infoNumber}>{item.users}</div>
                    </div>
                  </div>
                  <div className={classes.divisor} />
                </div>
              );
            },
          )}

          <div className={classes.historyWrapper}>
            <History className={classes.historyIcon} />
            <div className={classes.historyText}>{prepareDate(date, locale)}</div>
          </div>
        </>
      )}
    </div>
  );
};