import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    background: theme.palette.primary.main,
    border: 'none',
    color: theme.palette.background.surface.primary,
    display: 'inline-flex',
    position: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: theme.typography.body1.fontSize,
    transition: 'background .2s',
    borderRadius: '4px',
    padding: `${theme.spacing(0.8)}px ${theme.spacing(1)}px`,
    fontWeight: theme.typography.fontWeightMedium,

    gap: '10',
    width: '86',
    height: '48',
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.primary.hover,
    },
    '& svg': {
      color: theme.palette.background.surface.primary,
      maxHeight: 48,
      maxWidth: 86,
      marginRight: 2,
      marginLeft: 2,
    },
    '&:disabled': {
      cursor: 'inherit',
      background: theme.palette.primary.disabled,
    },
  },
  outlined: {
    background: 'transparent',
    color: theme.palette.primary.main,
    fontWeight: 500,
    '& svg': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.secondary.hover,
    },
  },
}));

export const PrimaryButton = ({ label, icon, onClick, type, disabled, title, outlined, className }) => {
  const classes = useStyles();

  return (
    <button
      className={`${classes.button} ${outlined ? classes.outlined : ''} ${className || ''}`}
      onClick={onClick}
      type={type || 'button'}
      disabled={disabled}
      title={title}
    >
      {icon}
      {label && <span>{label}</span>}
    </button>
  );
};
