import React from "react"
import { TextInput, AutocompleteInput, SimpleForm} from "ra-ui-materialui"
import {Create, DefaultToolbar, required}    from "components"
import zones from "lib/zone"
import { makeStyles } from "@material-ui/core"
import { useLocationProps, useAutoRedirect, useAccount } from "hooks"
import ClientGroup from "resources/Client/components/ClientGroup"

const zoneChoise = zones.map(id => ({ id: id, name: id }))

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',    
  },
  inputInput: {
    width: 250
  }
}))


export default ({ permissions, location, ...props}) => {
  const classes = useStyles()
  const account = useAccount() || {}
  const record = useLocationProps(location, [
    ['ClientId', 'number', account.ClientId],
    ['ProjectId', 'number'],
  ]);
  const redirect = useAutoRedirect("list")
  const clientLocked = account.userType === "client" || record.ClientId;
  const projectLocked = !!record.ProjectId;

  return (
    <Create {...props} titleSource="name" >
      <SimpleForm toolbar={<DefaultToolbar />} redirect={redirect} initialValues={record} >
          <TextInput source="name" validate={required()} />
          <TextInput source="tag" />
          <AutocompleteInput source="timezone" choices={zoneChoise} classes={classes} validate={required()} />
          <ClientGroup clientLocked={clientLocked} projectLocked={projectLocked} />          
      </SimpleForm>
    </Create>
  )
}
