import React from 'react';

import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslate } from 'ra-core';
import { Edit, Delete } from '@material-ui/icons';

import { TertiaryButton } from './TertiaryButton';

const useStyles = makeStyles(() => ({
  actionButton: {
    background: 'inherit',
  },
}));

export const TableRowActionButton = ({ action, record, onClick: propOnClick, goto }) => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslate();
  let icon;

  if (action === 'edit') {
    icon = <Edit />;
  } else if (action === 'delete') {
    icon = <Delete />;
  }

  const onClick = (evt) => {
    evt.stopPropagation();
    if (propOnClick) {
      propOnClick(record);
    } else if (goto) {
      const location = history.location;
      history.push(`${goto}?redirect=${location.pathname}${location.search || ''}`);
    }
  };

  return <TertiaryButton className={classes.actionButton} label={t(`actionButtons.${action}`)} icon={icon} onClick={onClick} />;
};
