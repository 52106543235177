// I actually don't know why this is here, it was already here when I got here,
// so I just took it out of LoginForm and left it as is.

// see http://redux-form.com/6.4.3/examples/material-ui/
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslate } from "ra-core";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '1.5rem',
    transform: 'translate(0, -3px) scale(0.75)',
    padding: '0',
    transition: '.2s color',
  },
  labelFocused: {
    fontSize: '1.5rem',
    transform: 'translate(0, -3px) scale(0.75)',
    color: '#16181D !important',
    padding: '0',
    transition: '.2s color',
  },
  smallLabel: {
    fontSize: '1.2rem',
    transform: 'translate(0, -3px) scale(0.75)',
    padding: '0',
    transition: '.2s color',
  },
  smallLabelFocused: {
    fontSize: '1.2rem',
    transform: 'translate(0, -3px) scale(0.75)',
    color: '#16181D !important',
    padding: '0',
    transition: '.2s color',
  },
  input: {
    margin: '0',
    marginBottom: '1rem',
  },
  innerInput: {
    '&>input': {
      border: '1px solid #C4C9D4',
      borderRadius: '4px',
      transition: '.2s box-shadow, .2s border-color',
      padding: '.5rem',
      background: 'white',
    },
    '&>input:focus': {
      boxShadow: '0 0 0px 1px #16181D',
      borderColor: '#16181D',
    },
  },
  innerInputErrored: {
    '&>input': {
      boxShadow: '0 0 0px 1px #f44336',
      borderColor: '#f44336',
    }
  }
}));

export const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  customError,
  smallLabel,
  ...props
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [isFocused, setFocused] = useState(false);

  const errored = !!(touched && error);

  let labelClass = smallLabel ? classes.smallLabel : classes.label;
  if (isFocused) {
    labelClass = smallLabel ? classes.smallLabelFocused : classes.labelFocused;
  }

  return (
    <TextField
      error={errored}
      {...inputProps}
      {...props}
      InputProps={{
        className: `${classes.innerInput} ${errored ? classes.innerInputErrored : ''}`,
        onFocus: () => {
          setFocused(true);
        },
        onBlur: () => {
          setFocused(false);
        },
        disableUnderline: true,
      }}
      fullWidth
      margin="normal"
      className={classes.input}
      helperText={(errored && !customError) ? translate(error) : undefined}
      InputLabelProps={{
        shrink: true,
        className: labelClass,
        disableAnimation: true,
      }}
    />
  );
}


