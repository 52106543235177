import React, { useState, useCallback } from 'react';
import { useTranslate, useDataProvider, ListContext } from 'ra-core';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/styles';
import { Icon, formatTime } from 'components';
import { get, isEmpty, filter, map, fromPairs } from 'lodash';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import { providerTypes } from 'lib/consts';
import AlertImageAttachments from './Drawer/ImageAttachments';
import AlertAudioAttachments from './Drawer/AudioAttachments';
import { Col, Row } from './Drawer/Grid';
import { useFetch, useDeepCompareEffect } from 'hooks';
import AlertLocation from './Drawer/AlertLocation';
import ContactInfo from './Drawer/ContactInfo';
import { ReferenceManyField, TextField, Datagrid, ReferenceField, SelectField, FunctionField } from 'ra-ui-materialui';
import DynamicValueField from 'components/DynamicValueField';

const useStyles = makeStyles((theme) => ({
  thumbData: {
    width: 100,
    cursor: 'pointer',
  },
  paperDrop: {
    width: 500,
    padding: 10,
  },
  mapIcon: {
    width: 80,
    height: 80,
    marginTop: 50,
  },
  mapLink: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  mapCol: {
    width: 100,
  },
  card: {
    width: '100%',
  },
  cardHeader: {
    paddingBottom: 0,
  },
  cardContent: {
    paddingTop: 0,
  },
  blacklist: {
    display: 'inline',
  },
  metaName: {
    maxWidth: 200,
    wordBreak: 'break-all',
  },
  extraHeader: {
    display: 'none',
  },
  message: {
    whiteSpace: 'pre-wrap',
  },
}));
const DataExpand = ({ record, setGalleryOpen, ...props }) => {
  const images = filter(record.attachments, { kind: 'image' });
  const audios = filter(record.attachments, { kind: 'audio' });
  const classes = useStyles();
  return (
    <>
      <TextField record={record} source="comments" addLabel label="comments" {...props} />
      {images.length > 0 && (
        <AlertImageAttachments
          attachments={images}
          onPreviewOpen={setGalleryOpen}
          classes={{ thumb: classes.thumbData }}
        />
      )}
      {audios.length > 0 && <AlertAudioAttachments attachments={audios} />}
    </>
  );
};

function getAlertFormData(alert, attachments = []) {
  const dataView = get(alert.meta, 'dataView');
  if (dataView) {
    const attachmentsCache = fromPairs(attachments.map((item) => [item.uuid, item]));
    return fromPairs(
      dataView.map((view) => [
        view.name,
        {
          ...view,
          hasAttachments: !isEmpty(view.attachments) || !isEmpty(view.comments),
          attachments: map(view.attachments, (uuid) => attachmentsCache[uuid]).filter((x) => x),
        },
      ]),
    );
  }
  return {};
}
function useAttachments(alert) {
  const [attachments, setAttachments] = useState([]);
  const dataProvider = useDataProvider();
  const AlertId = alert ? alert.id : null;

  useDeepCompareEffect(() => {
    if (AlertId) {
      dataProvider
        .fetch(`alerts/${AlertId}/attachments`)
        .then(({ data: { data } }) => setAttachments(data))
        .catch(() => {});
    }
  }, [AlertId, dataProvider, setAttachments]);

  return attachments;
}

function makeGoogleMapsUrl(item) {
  return item && item.lat && item.lng ? `https://maps.google.com/?q=${item.lat},${item.lng}` : null;
}
export default ({ provider, record: alert, onClose, permissions }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [version, setVersion] = useState(0);

  const refreshDrawer = useCallback(() => setVersion(version + 1), [version, setVersion]);
  if (!alert) {
    return <span />;
  }
  const { data: responsibles, loaded } = useFetch(`alerts/${alert.id}/responsibles`);
  const attachments = useAttachments(alert);

  const responsible = loaded && responsibles && responsibles.data ? responsibles.data[0] : null;
  const contact = alert.anonymous
    ? { name: translate('titles.anonymous') }
    : {
        id: get(alert, 'MobileProfile.id'),
        name: get(alert, 'MobileProfile.name'),
      };
  const images = filter(attachments, { kind: 'image' });
  const audios = filter(attachments, { kind: 'audio' });

  const googleMapsUrl = makeGoogleMapsUrl(alert.location) || makeGoogleMapsUrl(alert.Site);
  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const channelIcon = get(alert, 'Channel.meta.icon');
  const data = getAlertFormData(alert, attachments);

  const ids = Object.keys(data);
  const text = alert.text;

  const extraDataContext = {
    resource: 'alerts',
    selectedIds: [],
    hasBulkActions: false,
    currentSort: { field: 'name', order: 'ASC' },
    data,
    ids,
  };
  return (
    <Drawer
      key={`drawer_${alert.id}`}
      disableEscapeKeyDown={isGalleryOpen}
      anchor="right"
      open={alert.id ? true : false}
      onClose={onClose}
      classes={{ paper: classes.paperDrop }}
    >
      <div tabIndex={0} role="button">
        {alert.id && (
          <Card className={classes.card}>
            <CardHeader
              className={classes.cardHeader}
              avatar={<Avatar>{channelIcon ? <Icon>{channelIcon}</Icon> : ''}</Avatar>}
              title={get(alert, 'Channel.name')}
              subheader={formatTime(alert.generatedAt)}
            />
            <CardContent className={classes.cardContent}>
              <Row>
                <Col>
                  <Typography gutterBottom variant="h6">
                    {' '}
                    {translate('tabs.providers')}
                  </Typography>
                  <ReferenceManyField
                    basePath="/alert-assignments"
                    addLabel={false}
                    reference="alert-assignments"
                    target="AlertId"
                    record={alert}
                  >
                    <Datagrid>
                      <ReferenceField
                        basePath="/monitoring-providers"
                        source="MonitoringProviderId"
                        reference="monitoring-providers"
                        link={false}
                        allowEmpty
                        sortable={false}
                      >
                        <TextField source="name" />
                      </ReferenceField>
                      <ReferenceField
                        label="resources.monitoring-providers.fields.providerType"
                        translateChoice={true}
                        basePath="/monitoring-providers"
                        source="MonitoringProviderId"
                        reference="monitoring-providers"
                        link={false}
                        allowEmpty
                        sortable={false}
                      >
                        <SelectField source="providerType" choices={providerTypes} />
                      </ReferenceField>
                    </Datagrid>
                  </ReferenceManyField>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography gutterBottom variant="h6">
                    {' '}
                    {translate('resources.alerts.fields.text')}
                  </Typography>
                  <Typography variant="body1" className={classes.message}>
                    {text}
                  </Typography>
                  {ids.length > 0 && (
                    <ListContext.Provider value={extraDataContext}>
                      <Datagrid
                        expand={<DataExpand setGalleryOpen={setGalleryOpen} />}
                        classes={{ headerRow: classes.extraHeader }}
                      >
                        <TextField source="title" cellClassName={classes.metaName} />
                        <DynamicValueField source="value" />
                        <FunctionField
                          render={(record) => (record.hasAttachments ? <Icon>attachment</Icon> : <span />)}
                        />
                      </Datagrid>
                    </ListContext.Provider>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>{audios.length > 0 && <AlertAudioAttachments attachments={audios} />}</Col>
              </Row>
              <Row>
                <Col>
                  <Typography variant="h6">{translate('fields.siteTeam')}</Typography>
                  <AlertLocation alert={alert} />
                </Col>
                <Col className={classes.mapCol}>
                  {googleMapsUrl && (
                    <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer" className={classes.mapLink}>
                      <CardMedia classes={{ root: classes.mapIcon }} image="/assets/icons/gmaps.jpg" />
                    </a>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <ContactInfo
                    alert={alert}
                    onNotificationSent={refreshDrawer}
                    provider={provider}
                    header={translate('fields.contact')}
                    contact={contact}
                    loading={false}
                  />
                </Col>
                <Col>
                  <ContactInfo
                    alert={alert}
                    onNotificationSent={refreshDrawer}
                    provider={provider}
                    header={translate('fields.responsible')}
                    contact={responsible}
                    loading={!loaded}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {images.length > 0 && (
                    <Typography gutterBottom variant="h6">
                      {' '}
                      {translate('titles.attachments')}
                    </Typography>
                  )}
                  {images.length > 0 && <AlertImageAttachments attachments={images} onPreviewOpen={setGalleryOpen} />}
                </Col>
              </Row>
            </CardContent>
          </Card>
        )}
      </div>
    </Drawer>
  );
};
