import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { FormSpy, Form } from 'react-final-form';

import { makeStyles } from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import { useListContext, useListSortContext, useDataProvider, useTranslate } from 'ra-core';
import { Pagination as RAPagination } from 'ra-ui-materialui';

import { List, checkPermissions } from 'components';

import { AutocompleteDropdownFormField } from 'components/designSystem/AutocompleteDropdown';
import { DefaultHeader } from 'components/designSystem/DefaultHeader';
import { EmptyView } from 'components/designSystem/EmptyView';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { DropdownFormField } from 'components/designSystem/Dropdown';
import { TextFormField } from 'components/designSystem/inputs/TextField';

import { CreateAppDialog } from 'resources/App/components/CreateAppDialog';

import { TemplatePreview } from './components/TemplatePreview';
import { useAccount } from 'hooks';
import { useClients } from 'hooks/use-resource-hooks';
import { loadClients, loadTemplateVisibility } from './List.model';
import { TemplateUserInfo } from './views/TemplateUserInfo';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    '& > div > div > div': {
      boxShadow: 'none',
      backgroundColor: 'inherit',
    },
    '& > div': {
      padding: 0,
      paddingTop: theme.spacing(3),
    },
  },
}));

const useGridStyles = makeStyles((theme) => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(380px, auto))',
    gap: theme.spacing(2),
    justifyContent: 'flex-start',
  },
}));

const useGridItemStyles = makeStyles((theme) => ({
  container: {
    width: 380,
    marginBottom: theme.spacing(3),
    padding: '.5rem',
    boxSizing: 'border-box',
    borderRadius: '4px',
    transition: 'box-shadow .2s, transform .2s',

    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.025)',
      boxShadow: '0 0 7px #C2C4C9',
    },
  },
  previewContainer: {
    borderRadius: theme.borderRadius,
    flex: 1,
    background: theme.palette.background.surface.pressed,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  textContainer: {},
  templateName: {
    fontSize: theme.typography.body1.fontSize,
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(0.5)}px`,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  templateDescription: {
    fontSize: theme.typography.body1.fontSize,
    margin: 0,
    color: theme.palette.text.tertiary,
  },
  bottomContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > button': {
      borderRadius: '50%',
      padding: 1.8,
    },
    '& > button > svg': {
      margin: 0,
    },
  },
}));

const useFilterStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    '& > label': {
      marginRight: theme.spacing(2),
    },
    '& > div': {
      marginRight: theme.spacing(2),
    },
    '& > label > input': {
      width: 210,
    },
    '& > label > div > div': {
      width: 160,
      boxSizing: 'border-box',
    },
  },
}));

const TemplateGridItem = ({ template, selectTemplate }) => {
  const account = useAccount();
  const history = useHistory();
  const classes = useGridItemStyles();
  const canCreate = checkPermissions(account.permissions || [], 'apps:create');

  // For some reason, there's one specific scenario that I can't reproduce where an
  // undefined template is passed here
  if (!template) {
    return <div></div>;
  }

  const goToTemplatePage = (templateId) => {
    // Little timeout in order for react to properly clean anything related to state pending
    setTimeout(() => history.push(`/apps/templates/${templateId}/show`), 200);
  };

  return (
    <div className={classes.container} onClick={() => goToTemplatePage(template.id)}>
      <div className={classes.previewContainer}>
        <TemplatePreview template={template} />
      </div>
      <div className={classes.textContainer}>
        <h2 className={classes.templateName}>{template.name}</h2>
        <span className={classes.templateDescription}>{template.description}</span>
      </div>
      <div className={classes.bottomContainer}>
        <TemplateUserInfo template={template} />
        {canCreate && (
          <PrimaryButton
            icon={<Add />}
            onClick={(evt) => {
              evt.stopPropagation();
              selectTemplate(template);
            }}
          />
        )}
      </div>
    </div>
  );
};

const TemplateGrid = () => {
  const t = useTranslate();
  const history = useHistory();
  const classes = useGridStyles();
  const { ids, data } = useListContext();

  const [selectedTemplate, setSelectedTemplate] = useState(undefined);

  const goToAppPage = (app) => {
    // Little timeout in order for react to properly clean anything related to state pending
    setTimeout(() => history.push(`/apps/${app.id}/show`), 200);
  };

  return (
    <div>
      <CreateAppDialog
        isOpen={!!selectedTemplate}
        close={() => setSelectedTemplate(undefined)}
        onCreate={goToAppPage}
        template={selectedTemplate}
      />
      {ids.length > 0 && (
        <div className={classes.gridContainer}>
          {ids.map((templateId) => (
            <TemplateGridItem
              key={`template_grid_item_${JSON.stringify(data[templateId])}`}
              template={data[templateId]}
              selectTemplate={setSelectedTemplate}
            />
          ))}
        </div>
      )}
      {ids.length === 0 && <EmptyView halfMargin text={t('templates.noDataMessageTemplate')} />}
    </div>
  );
};

const templateOrder = [
  { id: 'DESC', name: 'templates.recent' },
  { id: 'ASC', name: 'templates.older' },
];

const TemplateListFilters = (props) => {
  const classes = useFilterStyles();
  const account = useAccount();
  const dataProvider = useDataProvider();
  const { clients: stateClients, dispatch } = useClients({ clientId: account?.ClientId });
  const { filterValues, setFilters } = useListContext(props);
  const { currentSort, setSort } = useListSortContext();
  const [clients, setClients] = useState(stateClients);
  const [clientsQ, setClientsQ] = useState('');
  const [templateVisibility, setTemplateVisibility] = useState([]);

  const reload = () => loadClients({ dataProvider, setClients, dispatch, q: clientsQ });
  useEffect(() => {
    reload();
  }, [clientsQ]); // eslint-disable-line

  useEffect(() => {
    loadTemplateVisibility({ dataProvider, setTemplateVisibility });
  }, []); // eslint-disable-line

  const handleSubmit = ({ visibility, q, clientId }) => {
    if (visibility === 'client' && !clientId && !account?.ClientId) {
      return;
    }
    setFilters({ visibility, q, clientId });
  };

  if (props.context === 'button') {
    return <></>;
  }

  const initialValues = {
    ...filterValues,
    order: currentSort.order,
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, form, values }) => (
        <form onSubmit={handleSubmit} className={classes.container}>
          <FormSpy
            subscription={{ modified: true, values: true }}
            onChange={({ modified, values }) => {
              const { order } = values;

              if (modified.order) {
                form.resetFieldState('order');
                setSort('createdAt', order);
              } else if (modified.visibility || modified.q || modified.clientId) {
                form.resetFieldState('q');
                if (values.visibility !== 'client' || account?.ClientId || values.clientId) {
                  form.resetFieldState('visibility');
                }
                if (form.getRegisteredFields().find((field) => field === 'clientId')) {
                  form.resetFieldState('clientId');
                }
                handleSubmit();
              }
            }}
          />
          <TextFormField
            name="q"
            placeholder="Search"
            icon={<Search />}
            iconPosition="right"
            condensed
            alwaysOn
            light
          />
          <DropdownFormField name="visibility" items={templateVisibility} alwaysOn light condensed translate />
          {account.userType === 'sasi' && values.visibility === 'client' && (
            <AutocompleteDropdownFormField
              name="clientId"
              required
              items={clients.map((client) => ({ name: client.name, id: client.id }))}
              loadItems={(values) => setClientsQ(values)}
              inputProps={{
                condensed: true,
                alwaysOn: true,
                light: true,
                placeholder: 'Client',
              }}
            />
          )}
          <DropdownFormField translate name="order" items={templateOrder} alwaysOn light condensed />
        </form>
      )}
    </Form>
  );
};

const Pagination = (props) => {
  if (props.total === 0) {
    return <div></div>;
  }

  return <RAPagination {...props} />;
};

export const ListView = (props) => {
  const classes = useStyles();
  const t = useTranslate();
  return (
    <div>
      <DefaultHeader title="Templates" description={t('templates.descriptionTemplates')} />
      <div className={classes.listContainer}>
        <List
          {...props}
          sort={{ field: 'createdAt', order: 'DESC' }}
          filterDefaultValues={{ visibility: 'public' }}
          filters={<TemplateListFilters />}
          actions={<></>}
          empty={<EmptyView halfMargin text={t('templates.noDataMessageTemplate')} />}
          pagination={<Pagination />}
        >
          <TemplateGrid />
        </List>
      </div>
    </div>
  );
};
