import React from 'react';
import {
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceManyField,
  Pagination,
  BooleanField,
  Filter,
  TextInput,
  SelectInput,
} from 'ra-ui-materialui';
import { List, DateField } from 'components';
import ContactCard from 'resources/MobileProfile/components/ContactCard';
import { Chip } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { SearchFormField } from 'components/designSystem/inputs/TextField';

const ProfileStatus = ({ record: { status } }) => {
  const translate = useTranslate();
  switch (status) {
    case 'deactivated':
    case 'active':
      return <Chip label={translate(`mapStatuses.${status}`)} />;
    default:
      return <span />;
  }
};

const ProfilesExpand = ({ record, ...props }) => {
  return (
    <ReferenceManyField
      basePath="/maps"
      addLabel={false}
      reference="maps"
      target="MobileUserUuid"
      record={record}
      pagination={<Pagination />}
    >
      <Datagrid expand={ContactCard}>
        <ReferenceField basePath="/clients" source="ClientId" reference="clients" link={false} allowEmpty>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="fields.siteTeam"
          basePath="/sites"
          source="SiteId"
          reference="sites"
          link={false}
          allowEmpty
          {...props}
        >
          <TextField source="name" />
        </ReferenceField>
        <ProfileStatus source="status" />
      </Datagrid>
    </ReferenceManyField>
  );
};

const MobileUserFilter = (props) => (
  <Filter {...props}>
    <SearchFormField name="q" alwaysOn light iconPosition="right" placeholder="Buscar" />
    <SelectInput
      source="active"
      choices={[
        { id: true, name: 'mapStatuses.active', key: 'active' },
        { id: false, name: 'mapStatuses.deactivated', key: 'inactive' },
      ]}
      label="Status"
    />
    <TextInput source="phone" />
    <TextInput source="email" />
  </Filter>
);

export default ({ hasShow, hasList, hasEdit, hasCreate, permissions, ...props }) => {
  return (
    <List
      resource="mobile-users"
      basePath="/mobile-users"
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={<MobileUserFilter />}
    >
      <Datagrid expand={ProfilesExpand}>
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="phone" />
        <BooleanField source="active" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};
