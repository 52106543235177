import React, { useState, useCallback } from 'react';
import { useNotify, useUpdate } from 'ra-core';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Icon } from '@material-ui/core';

export default ({
  record,
  data,
  onUpdate,
  source,
  fieldName,
  resource,
  readonly: readonlyValue,
  readonlySource,
  handleUpdate,
  withNegation,
}) => {
  if (record) {
    const [active, setActive] = useState(withNegation ? !record[fieldName || source] : record[fieldName || source]);
    const readonly = readonlySource ? record[readonlySource] : readonlyValue;
    const notify = useNotify();
    const change = (onClick, active) => () => {
      setActive(!active);
      onClick();
    };
    const onSuccess = useCallback(() => {
      notify('notifications.updated');
      onUpdate && onUpdate();
    }, [onUpdate, notify]);
    const [update, { error }] = useUpdate(resource, record.id, { active: !active, ...data }, data, { onSuccess });

    if (error) {
      notify(error.message);
    }

    return readonly ? (
      <Icon>{active ? 'done' : 'clear'}</Icon>
    ) : (
      <FormControlLabel control={<Switch checked={active} onChange={change(handleUpdate || update, active)} />} />
    );
  }
  return <span />;
};
