import React from 'react';
import { Datagrid, SelectInput, SelectField, SimpleForm, TextField, TextInput } from 'ra-ui-materialui';
import { List, DefaultActions, QuickCreateButton } from 'components';
import { useClient } from 'hooks/use-record';
import { required, useNotify, useRefresh, useUpdate } from 'ra-core';
import DeleteWithConfirmButton from 'components/QuickDeleteButton';
import { handlerModes } from 'lib/consts';

const ImportHandlerButton = ({ ClientId, ...props }) => (
  <QuickCreateButton title="actions.import" {...props} resource="registration-handlers" defaultValues={{ ClientId }}>
    <SimpleForm toolbar={null} resource="registration-handlers">
      <TextInput source="name" validate={required()} />
      <TextInput source="url" validate={required()} />
      <SelectInput source="mode" choices={handlerModes} validate={required()}></SelectInput>
    </SimpleForm>
  </QuickCreateButton>
);

const EditHandler = ({ record, ...props }) => {
  const [update] = useUpdate('registration-handlers', record.id, {}, {}, {});
  const notify = useNotify();
  const save = (data) =>
    update(
      { payload: { data } },
      {
        onSuccess: () => notify('Updated'),
        onFailure: (error) => notify(error.message, 'error'),
      },
    );

  return (
    <SimpleForm resource="registration-handlers" initialValues={record} save={save}>
      <TextInput source="name" validate={required()} />
      <TextInput source="url" validate={required()} />
      <SelectInput source="mode" choices={handlerModes} validate={required()}></SelectInput>
    </SimpleForm>
  );
};

export default function RegistrationHandlersList({ permissions, ...props }) {
  const [client] = useClient();
  const refresh = useRefresh();
  const filter = { ClientId: client.id };

  return (
    <List
      {...props}
      filter={filter}
      actions={
        <DefaultActions>
          <ImportHandlerButton ClientId={client.id} onSubmit={refresh} />
        </DefaultActions>
      }
    >
      <Datagrid expand={EditHandler}>
        <TextField source="name" />
        <SelectField choices={handlerModes} source="mode" />
        <TextField source="url" />
        <DeleteWithConfirmButton onDelete={refresh} />
      </Datagrid>
    </List>
  );
}
