import React from "react"
import { TextField } from "ra-ui-materialui"
import { get, isArray } from "lodash"

export default function DynamicValueField({ record, source, ...props }) {
  let value = get(record, source)
  if (isArray(value)) {
    value = value.join(", ")
  }
  return <TextField record={{ ...record, [source]: value }} source={source} {...props} />
}