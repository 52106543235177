import { useContext } from 'react';
import { GlobalDispatchContext, GlobalStateContext } from 'state';
import { setAppsAction } from 'state/actions/apps';
import { setAppSchemasAction } from 'state/actions/appSchemas';
import { setChannelsAction } from 'state/actions/channels';
import { setLicensesAction } from 'state/actions/licenses';
import { setProjectsAction } from 'state/actions/projects';
import { setProvidersAction } from 'state/actions/providers';
import { setSitesAction } from 'state/actions/sites';
import { setTeamsAction } from 'state/actions/teams';
import { setTermsAction } from 'state/actions/terms';
import { setUsersAction } from 'state/actions/users';
import { useAccount } from 'hooks';
import { setClientsAction } from 'state/actions/clients';

const clientFilteredState = (state, clientId, key = 'ClientId') => {
  return clientId ? state.filter((st) => st[key] === clientId) : state;
};

const appFilteredState = (state, appId, key = 'appId') => {
  return appId ? state.filter((st) => st[key] === appId) : state;
};

export const useApps = (clientId) => {
  const account = useAccount();
  const { apps: state } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const apps = clientFilteredState(state, account.ClientId || clientId, 'clientId');

  const handleDispatch = (apps) => {
    dispatch(setAppsAction(apps));
  };

  return { apps, dispatch: handleDispatch };
};

export const useClients = ({ clientId } = {}) => {
  const { clients: state } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const clients = clientFilteredState(state, clientId, 'id');

  const handleDispatch = (clients) => {
    dispatch(setClientsAction(clients));
  };

  return { clients, dispatch: handleDispatch };
};

export const useAppSchemas = ({ clientId, appId }) => {
  const account = useAccount();
  const { appSchemas: state } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  let schemas = state;

  if (clientId) {
    schemas = clientFilteredState(schemas, account.ClientId || clientId);
  }
  if (appId) {
    schemas = appFilteredState(schemas, appId);
  }

  const handleDispatch = (schemas) => {
    dispatch(setAppSchemasAction(schemas));
  };

  return { schemas, dispatch: handleDispatch };
};

export const useChannels = ({ appId, clientId } = {}) => {
  const account = useAccount();
  const { channels: state } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  let channels = state;

  if (clientId) {
    channels = clientFilteredState(channels, account.ClientId || clientId);
  }
  if (appId) {
    channels = appFilteredState(channels, appId);
  }

  const handleDispatch = (channels) => {
    dispatch(setChannelsAction(channels));
  };

  return { channels, dispatch: handleDispatch };
};

export const useTerms = ({ clientId } = {}) => {
  const { terms: state } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  let terms = state;

  if (terms) {
    terms = clientFilteredState(terms, clientId);
  }

  const handleDispatch = (terms) => {
    dispatch(setTermsAction(terms));
  };

  return { terms, dispatch: handleDispatch };
};

export const useLicenses = (clientId) => {
  const account = useAccount();
  const { licenses: state } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const licenses = clientFilteredState(state, account.ClientId || clientId);

  const handleDispatch = (licenses) => {
    dispatch(setLicensesAction(licenses));
  };

  return { licenses, dispatch: handleDispatch };
};

export const useProjects = (clientId) => {
  const account = useAccount();
  const { projects: state } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const projects = clientFilteredState(state, account.ClientId || clientId, 'clientId');

  const handleDispatch = (projects) => {
    dispatch(setProjectsAction(projects));
  };

  return { projects, dispatch: handleDispatch };
};

export const useProviders = (clientId) => {
  const account = useAccount();
  const { providers: state } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const providers = clientFilteredState(state, account.ClientId || clientId);

  const handleDispatch = (providers) => {
    dispatch(setProvidersAction(providers));
  };

  return { providers, dispatch: handleDispatch };
};

export const useSites = (clientId) => {
  const account = useAccount();
  const { sites: state } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const sites = clientFilteredState(state, account.ClientId || clientId);

  const handleDispatch = (sites) => {
    dispatch(setSitesAction(sites));
  };

  return { sites, dispatch: handleDispatch };
};

export const useTeams = (clientId) => {
  const account = useAccount();
  const { teams: state } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const teams = clientFilteredState(state, account.ClientId || clientId);

  const handleDispatch = (teams) => {
    dispatch(setTeamsAction(teams));
  };

  return { teams, dispatch: handleDispatch };
};

export const useUsers = (clientId) => {
  const account = useAccount();
  const { users: state } = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const users = clientFilteredState(state, account.ClientId || clientId);

  const handleDispatch = (users) => {
    dispatch(setUsersAction(users));
  };

  return { users, dispatch: handleDispatch };
};
