import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import {useTranslate} from "ra-core"
import {get} from "lodash"

const NearSiteText = ({alert}) => {
  const translate = useTranslate()
  return <>
    <strong>{translate("titles.nearSite")} </strong>
    <strong>{get(alert, "NearSite.name")}</strong>
  </>
}
const AlertSite = ({ alert}) => (
  <ListItem disableGutters>
    <ListItemIcon><Icon>place</Icon></ListItemIcon>
    <ListItemText primary={get(alert, "Site.location.address")} secondary={get(alert, "Site.referencePoint")} />
  </ListItem>
)

const AlertSiteNear = ({ alert }) => (
  <ListItem disableGutters>
    <ListItemIcon><Icon>place</Icon></ListItemIcon>
    {alert.NearSite && <ListItemText primary={<NearSiteText alert={alert} />} secondary={get(alert, "location.address")} />}
    {!alert.NearSite && <ListItemText primary={get(alert, "location.formatted")} secondary={""} />}    
  </ListItem>
)
export default ({alert}) => ( <List style={{width: "100%"}}  >
    <ListItem disableGutters >
      <ListItemIcon><Icon>domain</Icon></ListItemIcon>
      <ListItemText primary={get(alert, "Client.name")} secondary={get(alert, "Site.name", "")} />
    </ListItem>
      {alert.gpsAlert && <AlertSiteNear alert={alert} />} 
      {!alert.gpsAlert && <AlertSite alert={alert} />} 
   </List>)
