import { uniqBy, flatMap } from 'lodash';

export const loadRegistrationForms = async ({ dataProvider, clientId, setRegistrationForms }) => {
  const { data: registrationForms } = await dataProvider.getList('registration-forms', {
    filter: { ClientId: clientId },
  });
  const uniqSchemaFields = uniqBy(flatMap(registrationForms, 'schema.fields', 'name'));

  setRegistrationForms(uniqSchemaFields.filter((field) => !!field));
};
