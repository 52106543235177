import { makeStyles } from '@material-ui/styles';

export const messageTypeChoices = [
  {
    id: true,
    name: 'messageType.alert',
  },
  {
    id: false,
    name: 'messageType.message',
  },
];

export const intercomChoices = [
  {
    id: 'messenger',
    name: 'intercomModes.messenger',
    disabled: true,
  },
  {
    id: 'web',
    name: 'intercomModes.web',
  },
];

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  details: {
    alignItems: 'stretch',
  },
  structureDetails: {
    alignItems: 'stretch',
    display: 'block',
    overflowX: 'auto',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  input: {
    width: theme.spacing(32),
    clear: 'both',
    marginRight: 5,
  },
  dsHidden: {
    display: 'none',
  },
}));
