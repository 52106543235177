import React, {useCallback} from "react"
import {useTranslate, useNotify, useUpdate} from "ra-core"
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip';

const actions = {
  pending: {
    status: "active",
    notification: "notifications.mapApproved",
    icon: <DoneIcon />
  },
  deactivated: {
    status: "active",
    notification: "notifications.mapApproved",
    icon: <DoneIcon />
  },
  active: {
    status: "deactivated",
    notification: "notifications.mapDeactivated",
    icon: <ClearIcon />
  }
}

export default ({record: {id, status}}) => {
  const translate = useTranslate()
  const notify = useNotify()
  const props = actions[status] || {}
  const onSuccess = useCallback(() => notify(props.notification), [notify, props.notification])
  const [update, { error }] = useUpdate("maps", id, { status: props.status }, {}, { onSuccess })
  if (error) {
    notify(error.message)
  }
  switch(props.status) {
    case "deactivated":
    case "active":
      return <Chip label={translate(`mapStatuses.${status}`)} deleteIcon={props.icon} onDelete={update} />
    default:
      return <span />
  }
}
