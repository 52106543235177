// TODO: better email regex
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

export const requiredEmailField = (t) => (value) => {
  const filled = required(t)(value);
  if (!!filled) {
    return filled;
  }

  return EMAIL_REGEX.test(value) ? undefined : t('formErrors.email');
};

export const required = (t) => (value) => {
  if (value === undefined || value === null) {
    return t('formErrors.required');
  }

  if (typeof value === 'string' && value.trim().length === 0) {
    return t('formErrors.required');
  }
};

// Used when a user needs to update its profile but don't want to change it's password
export const nullablePasswordValidate = (t) => (val) => {
  if (!val) {
    return;
  }
  return passwordValidate(t)(val);
};

export const passwordValidate = (t) => (val) => {
  if (!val) {
    val = '';
  }

  if (val.length < 8) {
    return t('validation.password.tooShort');
  }
  if (val.length > 64) {
    return t('validation.password.tooLong');
  }
  const atLeastOneUppercase = /\w*[A-Z]\w*/;
  const atLeastOneNumber = /\w*[0-9]\w*/;
  const atLeastOneLowercase = /\w*[a-z]\w*/;
  if (!val.match(atLeastOneUppercase)) {
    return t('validation.password.atLeastOneUppercase');
  }
  if (!val.match(atLeastOneLowercase)) {
    return t('validation.password.atLeastOneLowercase');
  }
  if (!val.match(atLeastOneNumber)) {
    return t('validation.password.atLeastOneNumber');
  }
};

export const passwordConfirmValidate = (t) => (value, allValues) => {
  if (allValues.password !== undefined && value !== allValues.password) {
    return t('validation.passwordConfirm');
  }
  return undefined;
};
