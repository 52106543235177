import React, { useState } from 'react';

import { makeStyles, Menu, MenuItem, TablePagination } from '@material-ui/core';
import { ChevronRight, MoreHoriz, KeyboardArrowDown } from '@material-ui/icons';
import { useDataProvider, useTranslate, useNotify } from 'ra-core';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
// import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';

import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { ChannelPreviewButton } from './ChannelPreviewButton';
import { ChannelExpandPanels } from './channelPanels/ChannelExpandPanels.jsx';
import AssignChannels from './AssignChannels';
import VisibilityConfig from './VisibilityConfig';
import { deleteChannel } from './ChannelsList.model';
// import { DEFAULT_ROWS_PER_PAGE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from '../../../lib/consts';
// import { handlePasteChannel } from './PasteChannelDialog.model';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 680,
    paddingTop: theme.spacing(1),
  },
  groupContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  expandButton: {
    '& svg': {
      maxWidth: '.9em',
      maxHeight: '.9em',
      width: '.9em',
      height: '.9em',
      color: '#414958',
    },
  },
  expandCard: {
    padding: theme.spacing(1),
  },
  assignButton: {
    width: '100%',
    fontSize: 16,
    padding: '6px 16px',
    fontWeight: 'inherit',
    color: 'inherit',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.background.surface.pressed,
    },
    '& span': {
      paddingLeft: 0,
    },
    '& > span': {
      textAlign: 'left',
      display: 'inline',
    },
  },
}));

export const ChannelListItem = ({
  channel: channelProp,
  app,
  template,
  channels,
  readOnly,
  refresh,
  onCopyChannel,
  registrationForms,
}) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [channel, setChannel] = useState(channelProp);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandVisible, setExpandVisible] = useState(false);
  const t = useTranslate();
  const notify = useNotify();

  const handleOpenOptionsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptionsMenu = () => setAnchorEl(null);

  const copyChannel = async () => {
    const copyChannel = {
      label: channel.label,
      config: channel.config,
      primary: channel.primary,
      name: channel.name,
      channelType: channel.channelType,
    };
    localStorage.setItem('channel', JSON.stringify(copyChannel)); //valor do canal inteiro
    if (onCopyChannel) {
      onCopyChannel(copyChannel);
    }

    notify('templates.notifyChannel');
  };

  return (
    <>
      <div className={classes.row}>
        <div className={classes.groupContainer}>
          <TertiaryButton
            className={classes.expandButton}
            disabled={!app && !template}
            icon={expandVisible ? <KeyboardArrowDown /> : <ChevronRight />}
            onClick={() => setExpandVisible((visible) => !visible)}
          />
          <ChannelPreviewButton
            channel={channel}
            app={app}
            template={template}
            noPrimary
            style={{ padding: '24px 0 24px 16px', width: 279, maxWidth: 279, height: 72 }}
          />
        </div>

        <div className={classes.groupContainer}>
          {/* {!template && <TertiaryButton disabled={!app} icon={<FileCopy />} onClick={() => alert("!! WIP !!")} />} */}
          {/* <TertiaryButton disabled={!app && !channels} icon={<FileCopyRoundedIcon />} />
          <TertiaryButton icon={<HistoryRoundedIcon />} /> */}
          {!template && (
            <TertiaryButton disabled={!app && !template} icon={<FileCopyRoundedIcon />} onClick={copyChannel} />
          )}
          <TertiaryButton disabled={!app && !template} icon={<MoreHoriz />} onClick={handleOpenOptionsMenu} />
          <Menu id="channel-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleCloseOptionsMenu}>
            {/* <MenuItem onClick={() => alert("!! WIP !!")}>Adicionar provedor</MenuItem> */}
            {!template && (
              <MenuItem style={{ padding: 0 }}>
                <AssignChannels record={channel} onSubmit={() => refresh()} noStyle className={classes.assignButton} />
              </MenuItem>
            )}
            <MenuItem style={{ padding: 0 }}>
              <VisibilityConfig
                record={channel}
                fieldValues={registrationForms?.filter(
                  (e) => !['multilayer_dropdown', 'multilayer_dropdown_search'].includes(e.type),
                )}
                title="Visibilidade"
                label={t('titles.visibility')}
                noStyle
                source={template ? 'schema.config.visibilityQuery' : 'config.visibilityQuery'}
                onSubmit={() => refresh()}
                className={classes.assignButton}
                readonly={readOnly}
              />
            </MenuItem>
            {/* {!template && <MenuItem onClick={() => setSortOpen(true)}>{t('titles.order')}</MenuItem>} */}
            {!template && (
              <MenuItem
                onClick={deleteChannel({
                  dataProvider,
                  handleCloseOptionsMenu,
                  refresh,
                  notify,
                  channelId: channel.id,
                })}
              >
                {t('titles.delete')}
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>
      {expandVisible && (
        <ChannelExpandPanels
          channel={channel}
          channels={channels}
          setChannel={setChannel}
          app={app}
          template={template}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export const ChannelsList = ({
  app,
  template,
  channels,
  fullChannels,
  readOnly,
  refresh,
  setSortOpen,
  registrationForms,
  onCopyChannel,
  page,
  onChangePage,
}) => {
  const t = useTranslate();

  return (
    <div style={{ marginBottom: '5rem' }}>
      {channels.map((channel) => (
        <ChannelListItem
          key={`channel_list_item_${JSON.stringify(channel)}`}
          channel={channel}
          onCopyChannel={onCopyChannel}
          app={app}
          template={template}
          channels={fullChannels}
          readOnly={readOnly}
          refresh={refresh}
          setSortOpen={setSortOpen}
          registrationForms={registrationForms}
        />
      ))}
      <TablePagination
        component="div"
        count={fullChannels.length}
        rowsPerPage={20}
        page={page}
        labelDisplayedRows={({ from, to, count }) =>
          `${t('table.displayedRows', { from, to })} ${count !== -1 ? count : t('table.displayedRowsTooMany', { to })}`
        }
        nextIconButtonText={'table.nextPage'}
        backIconButtonText={'table.previousPage'}
        rowsPerPageOptions={[20]}
        onPageChange={(evt, value) => onChangePage(value)}
      />
    </div>
  );
};
