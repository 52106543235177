import React from 'react';

export const ImageFileIcon = () => (
  <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.53576 5.94641C8.13032 2.93844 11.0507 0.981986 14.0587 1.57655L46.7372 8.03582C49.7452 8.63038 51.7016 11.5508 51.1071 14.5588L44.6478 47.2372C44.0532 50.2452 41.1328 52.2017 38.1248 51.6071L5.44635 45.1478C2.43838 44.5533 0.481927 41.6329 1.07649 38.6249L7.53576 5.94641Z"
      fill="#E2E4E9"
    />
    <path
      d="M10.8106 9.82753C11.3203 7.24927 13.8235 5.57231 16.4018 6.08193L42.8558 11.3109C45.434 11.8205 47.111 14.3237 46.6014 16.902L42.9103 35.5754C42.4007 38.1536 39.8975 39.8306 37.3192 39.321L10.8652 34.0921C8.28697 33.5824 6.61001 31.0792 7.11963 28.5009L10.8106 9.82753Z"
      fill="#FCFCFD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.9632 35.6038C39.5621 35.7222 40.0647 35.1478 39.8677 34.5699L33.6639 16.366C33.4669 15.7881 32.7182 15.6401 32.3162 16.0996L23.0563 26.6843C22.6543 27.1438 21.9056 26.9958 21.7086 26.4179L19.8308 20.9078C19.6339 20.3299 18.8852 20.182 18.4832 20.6415L11.2471 28.9127C10.8451 29.3722 11.0913 30.0946 11.6902 30.213L18.7486 31.6081L23.819 32.6103L38.9632 35.6038Z"
      fill="#E2E4E9"
    />
    <circle cx="23.2693" cy="16.3322" r="2.37934" transform="rotate(11.181 23.2693 16.3322)" fill="#E2E4E9" />
  </svg>
);
