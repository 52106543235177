import React, { useState, useEffect } from 'react';

import { useDataProvider, useTranslate } from 'ra-core';
import { AddCircle } from '@material-ui/icons';
import { LabeledSwitch } from 'components/designSystem/LabeledSwitch';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { ListFilter } from 'components/designSystem/ListFilter';
import { Table } from 'components/designSystem/Table';
import { loadProviders, providersColumns } from './Providers.model';
import { useListFilters } from 'hooks';
import { useHistory } from 'react-router';
import { useProviders } from 'hooks/use-resource-hooks';
import { CreateProviderDialog } from 'resources/MonitoringProvider/components/CreateProviderDialog';
import { EditProviderDialog } from 'resources/MonitoringProvider/components/EditProviderDialog';

const ProviderList = ({ client, setHandleRefresh }) => {
  const t = useTranslate();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const { providers: stateProviders, dispatch } = useProviders(client.id);
  const [loading, setLoading] = useState(false);

  const filters = useListFilters({ orderBy: 'id', order: 'desc' });
  const [providers, setProviders] = useState(stateProviders);
  const [totalProviders, setTotalProviders] = useState(undefined);

  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(undefined);

  const reload = () =>
    loadProviders(dataProvider, setProviders, setTotalProviders, client.id, filters, dispatch, setLoading);
  useEffect(() => setHandleRefresh(() => reload), []); //eslint-disable-line

  useEffect(() => {
    reload();
  }, filters.propList); //eslint-disable-line

  return (
    <div>
      <CreateProviderDialog
        clientId={client.id}
        isOpen={isCreateDialogOpen}
        refresh={reload}
        close={() => setCreateDialogOpen(false)}
      />
      <EditProviderDialog
        isOpen={!!selectedProvider}
        clientId={client.id}
        provider={selectedProvider || {}}
        refresh={reload}
        close={() => setSelectedProvider(undefined)}
      />
      <ListFilter
        actions={[
          <PrimaryButton
            key="client_create_provider_button"
            label={t('resources.monitoring-providers.create')}
            icon={<AddCircle />}
            onClick={() => setCreateDialogOpen(true)}
          />,
        ]}
      >
        <LabeledSwitch
          label={t('fields.showInactive')}
          onChange={filters.set.showInactive}
          value={filters.showInactive}
          reverse
        />
      </ListFilter>
      <Table
        onRowClick={(row) => history.push(`/monitoring-providers/${row.id}/show`)}
        loading={loading && providers.length === 0}
        data={providers}
        columns={providersColumns(t, dataProvider, setSelectedProvider, reload)}
        onSort={filters.handleRequestSort}
        order={filters.order}
        orderBy={filters.orderBy}
        totalRecords={totalProviders}
        rowsPerPage={filters.limit}
        page={filters.offset / filters.limit}
        onChangePage={(value) => filters.set.offset(value * filters.limit)}
        onChangeRowsPerPage={filters.set.limit}
        noDataMessage={t('resources.monitoring-providers.noDataMessage')}
      />
    </div>
  );
};

export const Providers = ({ client, setHandleRefresh }) => {
  if (!client) {
    return <div></div>;
  }
  return <ProviderList client={client} setHandleRefresh={setHandleRefresh} />;
};
