import React, { useState } from "react"
import { TextInput, AutocompleteInput, TabbedForm, Pagination} from "ra-ui-materialui"
import { ReferenceManyField, Datagrid, TextField, SelectField } from "ra-ui-materialui"
import {Edit, DefaultToolbar, required, checkPermissions, ShowLink}     from "components"
import zones from "lib/zone"
import { roles } from "lib/consts"
import { makeStyles } from "@material-ui/core"
import { useAutoRedirect } from "hooks"
import { EditButton as EditUserButton } from "resources/User/components"
import ActiveTab from "resources/Common/ActiveTab"
import ClientGroup from "resources/Client/components/ClientGroup"
const zoneChoise = zones.map(id => ({ id: id, name: id }))

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',    
  },
  inputInput: {
    width: 250
  }
}))


export default ({permissions, ...props}) => {
  const classes = useStyles()
  const redirect = useAutoRedirect("list")
  const [toolbarVisible, setToolbar] = useState(true)
  
  return (
    <Edit {...props} titleSource="name" >
      <TabbedForm toolbar={<DefaultToolbar visible={toolbarVisible} />} redirect={redirect} >
        <ActiveTab label="tabs.summary" onActivated={() => setToolbar(true)}>
          <TextInput source="name" validate={required()}/>
          <TextInput source="tag" />
          <AutocompleteInput source="timezone" choices={zoneChoise} classes={classes} />                   
          <ClientGroup clientLocked />          
        </ActiveTab>
        <ActiveTab label="tabs.users" onActivated={() => setToolbar(false)}>
          <ReferenceManyField basePath="/users" addLabel={false} reference="users" target="SiteId" fullWidth pagination={<Pagination />}>
            <Datagrid>
              <ShowLink source="name" />
              <TextField source="username" />
              <TextField source="email" />
              <SelectField source="role" choices={roles.client} />
              {checkPermissions(permissions, "users:update") && <EditUserButton />}
            </Datagrid>
          </ReferenceManyField>
        </ActiveTab>
      </TabbedForm>
    </Edit>
  )
}
