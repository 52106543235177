import React from 'react';
import { SimpleForm, TextInput, BooleanInput, SelectInput } from 'ra-ui-materialui';
import { IconsAutocomplete, ColorInput } from 'components';
import sasiIcons from 'lib/sasi-icons';
import { Row, Col } from 'resources/components/Grid';
import SampleView from 'resources/components/SampleView';
import UpdatePopover from 'resources/components/UpdatePopover';
import { channelTypes } from 'lib/consts';
const icons = sasiIcons.map((name) => ({
  id: name,
  name,
}));
export default function SampleEditPopover({ resource, readonly, channel, config, anchorEl, onSubmit, onClose }) {
  const inputProps = { disabled: readonly };

  return (
    <UpdatePopover
      resource={resource}
      title={channel.name}
      subTitle={channel.id}
      record={channel}
      anchorEl={anchorEl}
      onSubmit={onSubmit}
      onClose={onClose}
      readonly={readonly}
    >
      <SimpleForm resource={resource} record={channel}>
        <Row>
          <Col>
            <TextInput source="label" {...inputProps} />
            <SelectInput
              source="channelType"
              choices={[...channelTypes, { id: 'survey', name: 'channelTypes.survey' }]}
              disabled
            />
            <BooleanInput type="checkbox" source="primary" {...inputProps} />
            <TextInput source="tag" {...inputProps} />
          </Col>
          <Col>
            <Row>
              <Col>
                <ColorInput source="config.textColor" margin="dense" {...inputProps} />
              </Col>
              <Col>
                <IconsAutocomplete
                  source="config.icon"
                  maxItems={50}
                  choices={icons}
                  allowEmpty
                  noPreview
                  {...inputProps}
                  readonly={readonly}
                />{' '}
              </Col>
            </Row>
            <Row>
              <Col>
                <ColorInput source="config.bgColor1" defaultValue="#4E4E4E" margin="dense" {...inputProps} />
              </Col>
              <Col>
                <ColorInput source="config.bgColor2" defaultValue="#424242" margin="dense" {...inputProps} />
              </Col>
            </Row>
            <SampleView config={config} />
          </Col>
        </Row>
      </SimpleForm>
    </UpdatePopover>
  );
}
