import React, { useState } from "react"
import { TextInput, NumberInput, Pagination, TabbedForm} from "ra-ui-materialui"
import { ReferenceManyField, Datagrid, TextField, SelectField } from "ra-ui-materialui"
import {  EditButton as EditUserButton } from "resources/User/components"

import { Edit, DefaultToolbar, GoogleLocationInput, ShowLink, required, checkPermissions}     from "components"
import { roles } from "lib/consts"
import MapWithMarker from "./components/MapWithMarker"
import { Col, Row } from "resources/components/Grid"
import { useAutoRedirect, useTranslate } from "hooks"
import ActiveTab from "resources/Common/ActiveTab"
import ClientGroup from "resources/Client/components/ClientGroup"

export default ({permissions, ...props}) => {
  const redirect = useAutoRedirect("list")
  const [toolbarVisible, setToolbar] = useState(true)
  const translate = useTranslate();

  const validate = (value) => {
    const errors = { formatted: undefined };
    if (typeof value === 'string') {
      if (value === '') {
        errors.formatted = translate('ra.validation.required');
      }
    } else if (!value?.formatted || value.formatted === '') {
      errors.formatted =  translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <Edit {...props} titleSource="name">
      <TabbedForm redirect={redirect} toolbar={<DefaultToolbar visible={toolbarVisible} />} >
        <ActiveTab label="tabs.summary" onActivated={() => setToolbar(true)}>
          <Row >
            <Col>
              <TextInput source="name" validate={required()} />
              <TextInput source="tag" />
              <ClientGroup clientLocked/>
            </Col>
            <Col>
              <GoogleLocationInput label="resources.sites.fields.location.address" source="location" validate={validate} />
              <TextInput source="referencePoint" />
              <NumberInput source="radius" helperText="km" />
              <MapWithMarker zoom={14} source="location" enableDrag />
            </Col>
            <Col>
              <NumberInput source="location.lat" />
              <NumberInput source="location.lng" />
            </Col>
          </Row>
        </ActiveTab>
        <ActiveTab label="tabs.users" onActivated={() => setToolbar(false)}>
          <ReferenceManyField basePath="/users" addLabel={false} reference="users" target="SiteId" fullWidth pagination={<Pagination />}>
            <Datagrid>
              <ShowLink source="name" />
              <TextField source="username" />
              <TextField source="email" />
              <SelectField source="role" choices={roles.client} />
              {checkPermissions(permissions, "users:update") && <EditUserButton />}
            </Datagrid>
          </ReferenceManyField>
        </ActiveTab>
      </TabbedForm>
    </Edit>
  )
}
