import React, { useEffect, useState } from 'react';

import { useDataProvider } from 'ra-core';
import { compose, withProps } from 'recompose';
import { withGoogleApi } from 'components';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { useSites } from 'hooks/use-resource-hooks';

const enhance = compose(
  withProps({
    containerElement: <div style={{ maxHeight: '400px' }} />,
    mapElement: <div style={{ height: '400px', borderRadius: '4px' }} />,
  }),
  withGoogleApi,
  withGoogleMap,
);

const SitesMap = enhance(({ zoom, sites }) => {
  const google = window.google;

  const bounds = sites.reduce((bounds, site) => {
    bounds.extend(new google.maps.LatLng(site.lat, site.lng));
    return bounds;
  }, new google.maps.LatLngBounds());

  const onMounted = (map) => map && map.fitBounds(bounds);

  return (
    <GoogleMap defaultZoom={zoom || 8} defaultCenter={sites[0]} ref={onMounted}>
      {sites.map((site) => (
        <Marker position={site} key={`SitesMap_site_${site.id}`} />
      ))}
    </GoogleMap>
  );
});

export const ClientSitesMap = ({ client, zoom }) => {
  const dataProvider = useDataProvider();
  const { dispatch, sites: stateSites } = useSites(client.id);
  const [sites, setSites] = useState(stateSites.filter((site) => site.lat && site.lng));

  useEffect(() => {
    dataProvider.getList('sites', { filter: { ClientId: client.id, siteType: 'site' } }).then(({ data }) => {
      dispatch(data);
      setSites((data || []).filter((site) => site.lat && site.lng));
    });
  }, []); //eslint-disable-line

  if (!sites || sites.length === 0) {
    return <div />;
  }

  return <SitesMap sites={sites} zoom={zoom} />;
};
