import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { Input } from "./Input";
import { makeStyles } from "@material-ui/styles";
import { Button } from "./Button";
import { useNotify } from "ra-core";

const useStyles = makeStyles(() => ({
  stepContainer: {
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    padding: '1.5rem 2rem',
    borderRadius: '4px',
    boxSizing: 'border-box',
    width: '472px',
    maxWidth: '100vw',
  },
  stepWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  stepText: {
    fontFamily: 'Nunito, sans-serif',
    fontSize: '1.125rem',
    margin: '0',
    marginBottom: '1rem',
  },
  stepTitle: {
    margin: '0',
    padding: '0',
    fontFamily: 'Nunito, sans-serif',
    marginBottom: '1.5rem',
    fontWeight: '400',
    fontSize: '1.5rem',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  center: {
    justifyContent: 'center',
  },
  end: {
    justifyContent: 'flex-end',
  },
  start: {
    justifyContent: 'flex-start',
  },
  multiple: {
    justifyContent: 'space-between',
  },
  inverseFooter: {
    flexDirection: 'row-reverse',
  },
}));

// Steps structure example at resources/User/Profile.model.js
export const MultiStepFormModal = ({ steps, isOpen, handleClose }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    if (loading) {
      return
    }
    setCurrentStepIndex(0);
    handleClose();
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={classes.stepContainer}>
        {(steps || []).map((step, index) => {
          if (index !== currentStepIndex) {
            return undefined;
          }

          const onSubmit = async values => {
            try {
              await step.onSubmit(setLoading)(values);
              if (index < steps.length - 1) {
                setCurrentStepIndex(index + 1);
              } else {
                onClose();
              }
            } catch (err) {
              notify(`resources.users.errors.external.${err.message}`, 'error');
              setLoading(false);
            }
          }

          let buttonLabel = step.buttonLabel;
          if (!buttonLabel) {
            buttonLabel = index < steps.length - 1 ? "Próximo" : "Confirmar";
          }

          let buttonPosition = step.buttonPosition;
          if (!buttonPosition) {
            buttonPosition = 'end';
          }
          if (step.back) {
            buttonPosition = 'multiple';
          }

          return (
            <Form key={step.key} onSubmit={onSubmit} render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className={classes.stepWrapper}>
                  {step.title && <h2 className={classes.stepTitle}>{step.title}</h2>}
                  {step.text && <p className={classes.stepText}>{step.text}</p>}
                  {step.fields.map(field => {
                    const Suffix = field.Suffix;
                    return (
                      <div key={field.key}>
                        <Field
                          name={field.name}
                          id={field.id}
                          label={field.label}
                          type={field.type}
                          placeholder={field.placeholder || ''}
                          validate={field.validate}
                          render={Input}
                          disabled={loading}
                          customError={field.customError}
                        />
                        {Suffix && <Suffix/>}
                      </div>
                    )
                  })}
                  <div
                    className={`${classes.footerContainer} ${classes[buttonPosition]} ${step.buttonInverse ? classes.inverseFooter : ''}`}
                  >
                    {step.back && (
                      <Button
                        label={index > 0 ? "Voltar" : "Fechar"}
                        onClick={() => {
                          if (index > 0) {
                            setCurrentStepIndex(index - 1);
                          } else {
                            onClose();
                          }
                        }}
                        disabled={loading}
                        variant={step.buttonInverse ? "primary" : "secondary"}
                        fullSize={buttonPosition === 'multiple'}
                        type={"button"}
                        style={{
                          ...((buttonPosition === 'multiple' && !step.buttonInverse) ? { marginRight: '.5rem' } : {}),
                          ...((buttonPosition === 'multiple' && step.buttonInverse) ? { marginLeft: '.5rem' } : {}),
                        }}
                      />
                    )}
                    <Button
                      label={buttonLabel}
                      type={"submit"}
                      disabled={loading}
                      fullSize={buttonPosition === 'multiple'}
                      variant={step.buttonInverse ? "secondary" : "primary"}
                      style={{
                        ...((buttonPosition === 'multiple' && !step.buttonInverse) ? { marginLeft: '.5rem' } : {}),
                        ...((buttonPosition === 'multiple' && step.buttonInverse) ? { marginRight: '.5rem' } : {}),
                      }}
                    />
                  </div>
                </div>
              </form>
            )}/>
          );
        })}
      </div>
    </Modal>
  )
}
