import { genericErrorHandler } from "lib/Errors";

export const ShowAppTabOrder = {
  settings: 0,
  channels: 1,
};

export const getShareToken = async ({ dataProvider, templateId, notify, setClipboard }) => {
  const errorHandler = genericErrorHandler(notify);
  
  try{
    const { data } = await dataProvider.execute(`apps/templates/${templateId}/share`);
    const token = data.data.token;
    setClipboard(token);
  } catch(err){
    console.error(err);
    errorHandler(err);
  }
};

