import React from 'react';
import AssignProviderButton from './AssignProviderButton';

const AssignChannels = ({ record, ...props }) => {
  if (record.id > 0 && record.channelType !== 'survey') {
    return <AssignProviderButton record={record} {...props} />;
  }
  return <span />;
};

export default AssignChannels;
