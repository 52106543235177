import { makeStyles } from '@material-ui/core';

export const mapList = (itemList) => {
  return { id: itemList.id, name: itemList.name };
};

export const addDots = (number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export const useDashboardStyles = makeStyles(() => ({
  generalCardsContainer: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'canter',
    justifyItems: 'space-between',
    width: '100%',
  },
  cardInfo: {
    display: 'flex',
    justifyContent: 'justify-between',
    paddingRight: '8px',
    width: '100%',
  },
  clientContainer: {
    display: 'flex',
    justifyContent: 'justify-between',
    flexFlow: 'column',
    paddingRight: '8px',
    marginTop: '1rem',
    width: '100%',
  },
  top3Container: {
    display: 'flex',
    marginTop: '8px',
    flexFlow: 'row',
    alignItems: 'start',
    justifyItems: 'space-between',
    width: '100%',
  },
  bigCardsContainer: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyItems: 'space-between',
    width: '100%',
  },
  bigCardsContainerSub: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyItems: 'space-between',
    width: '50%',
    marginTop: '8px',
  },
  top3Card: {
    display: 'flex',
    justifyContent: 'justify-between',
    paddingRight: '8px',
    width: '100%',
  },
  bigCard: {
    display: 'flex',
    justifyContent: 'justify-between',
    paddingRight: '8px',
    width: '100%',
  },
  dropdownContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
  },
  dropdownAppSiteContainer: {
    display: 'flex',
    flexFlow: 'column',
    marginTop: '1rem',
    marginRight: '1rem',
  },
  dropdownName: {
    display: 'flex',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#7F889C',
    marginBottom: '0.5rem',
  },
}));
