import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  props: {
    MuiInput: {
      disableUnderline: true,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: '#373435',
        fontFamily: 'avenir',
        borderRadius: '.5rem',
      },
      label: {
        color: '#FFF',
      },
    },
    MuiInputLabel: {
      formControl: {
        fontFamily: 'avenir',
        padding: '.180rem .75rem',
        color: '#999999',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#999999',
        '&$focused': {
          color: '#999999',
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#f2f2f2',
      },
      rounded: {
        borderRadius: 13,
      },
    },
    MuiInputBase: {
      root: {
        display: 'inline-flex',
      },
      input: {
        fontFamily: 'avenir',
        fontSize: '1rem',
        lineHeight: 1.5,
        color: '#495057',
        padding: '.375rem .75rem',
        transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
        border: '1px solid #999999',
        borderRadius: '.5rem',
      },
    },
  },
});
