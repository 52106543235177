import React from 'react';
import { checkPermissions } from 'components';
import { Resource } from 'ra-core';
import { Route } from 'react-router-dom';

import Users, { Profile } from 'resources/User';
import Clients from 'resources/Client';
import Sites from 'resources/Site';
import Teams from 'resources/Team';
import Terms from 'resources/Terms';
import RegistrationCode from 'resources/RegistrationCode';
import RegistrationHandler from 'resources/RegistrationHandler';
import RegistrationHandlerV2 from 'resources/RegistrationHandlerV2';
import RegistrationForm from 'resources/RegistrationForm';
import RegistrationData from 'resources/RegistrationData';
import Alerts from 'resources/Alert';
import Apps from 'resources/App';
import Survey from 'resources/Survey';
import Channels from 'resources/Channel';
import PublicCodes from 'resources/PublicCode';
import License from 'scopes/Admin/License';

import MonitoringProviders from 'resources/MonitoringProvider';
import Maps from 'resources/MobileProfile';
import MobileUsers from 'resources/MobileUser';
import Documents from 'resources/Document';
import BroadcastMessages from 'resources/BroadcastMessage';
import Projects from 'resources/Projects';
import Templates from 'resources/Templates';

import Dashboard from 'resources/Common/Dashboard';
import { Document, AppGo, SurveyRender } from 'public';
import { ForgotPassword } from '../layout/ForgotPassword';
import { ForgotPasswordWithCode } from '../layout/ForgotPasswordWithCode';

const ResourceFor = ({ name, rest, permissions, apiResource, access = {}, ...props }) => {
  const hasAccess = (type) => (access[type] && permissions ? checkPermissions(permissions, ...access[type]) : true);
  const resource = (type) => (rest[type] && hasAccess(type) ? rest[type] : null);
  return (
    <Resource
      key={name}
      name={apiResource || name}
      show={resource('Show')}
      list={resource('List')}
      edit={resource('Edit')}
      create={resource('Create')}
      {...props}
    />
  );
};

export default (permissions) => {
  return (
    permissions && [
      <ResourceFor
        name="clients"
        permissions={permissions}
        rest={Clients}
        access={{
          List: ['clients:view'],
          Show: ['clients:view'],
          Edit: ['clients:update'],
          Create: ['clients:create'],
        }}
      />,
      <ResourceFor
        name="users"
        rest={Users}
        access={{
          List: ['users:view'],
          Show: ['users:view'],
          Edit: ['users:update'],
          Create: ['users:update'],
        }}
      />,
      <ResourceFor
        name="public-codes"
        permissions={permissions}
        rest={PublicCodes}
        access={{
          List: ['public-codes:view'],
        }}
      />,
      <ResourceFor
        name="sites"
        permissions={permissions}
        rest={Sites}
        access={{
          List: ['sites:view'],
          Show: ['sites:view'],
          Edit: ['sites:update'],
          Create: ['sites:update'],
        }}
      />,
      <ResourceFor
        name="teams"
        permissions={permissions}
        rest={Teams}
        access={{
          List: ['sites:view'],
          Show: ['sites:view'],
          Edit: ['sites:update'],
          Create: ['sites:update'],
        }}
      />,
      <ResourceFor
        name="broadcast-messages"
        permissions={permissions}
        rest={BroadcastMessages}
        access={{
          List: ['broadcast-messages:view'],
          Show: ['broadcast-messages:view'],
        }}
      />,

      <Resource key="maps" name="maps" list={Maps.List} />,
      <Resource key="mobile-users" name="mobile-users" list={MobileUsers.List} />,
      <Resource key="alerts" name="alerts" list={checkPermissions(permissions, 'alerts:view') ? Alerts.List : null} />,

      <ResourceFor
        name="monitoring-providers"
        permissions={permissions}
        rest={MonitoringProviders}
        access={{
          List: ['monitoring-providers:view'],
          Show: ['monitoring-providers:view'],
          Edit: ['monitoring-providers:update'],
          Create: ['monitoring-providers:update'],
        }}
      />,
      <ResourceFor
        name="documents"
        rest={Documents}
        permissions={permissions}
        access={{
          List: ['documents:view'],
          Show: ['documents:view'],
          Edit: ['documents:update'],
          Create: ['documents:update'],
        }}
      />,
      <Resource key="alert-assignments" name="alert-assignments" />,
      <ResourceFor
        key="templates"
        apiResource="apps/templates"
        name="apps/templates"
        permissions={permissions}
        rest={Templates}
        access={{
          List: ['apps:view'],
          Show: ['apps:view'],
          Edit: ['apps:update'],
          Create: ['apps:create'],
        }}
      />,
      <ResourceFor
        name="apps"
        permissions={permissions}
        rest={Apps}
        access={{
          List: ['apps:view'],
          Show: ['apps:view'],
          Edit: ['apps:update'],
          Create: ['apps:create'],
        }}
      />,
      <Resource key="alert-histories" name="alert-histories" />,
      <Resource key="survey-results" name="survey-results" />,
      <Resource key="monitoring-providers/channels" name="monitoring-providers/channels" />,
      <Resource key="monitoring-providers/users" name="monitoring-providers/users" />,
      <Resource key="blacklists" name="blacklists" />,
      <ResourceFor
        name="surveys"
        permissions={permissions}
        rest={Survey}
        access={{
          List: ['surveys:view'],
          Show: ['surveys:view'],
          Edit: ['surveys:update'],
          Create: ['surveys:update'],
        }}
      />,
      <ResourceFor
        name="channels"
        permissions={permissions}
        rest={Channels}
        access={{
          List: ['channels:view'],
          Show: ['channels:view'],
          Edit: ['channels:update'],
          Create: ['channels:update'],
        }}
      />,
      <ResourceFor
        key="registration-codes"
        name="registration-codes"
        permissions={permissions}
        rest={RegistrationCode}
        access={{
          List: ['registration-codes:view'],
          Show: ['registration-codes:view'],
          Edit: ['registration-codes:update'],
          Create: ['registration-codes:update'],
        }}
      />,
      <ResourceFor
        key="registration-handlers"
        name="registration-handlers"
        permissions={permissions}
        rest={RegistrationHandler}
        access={{
          List: ['registration-handlers:view'],
          Show: ['registration-handlers:view'],
          Edit: ['registration-handlers:update'],
          Create: ['registration-handlers:update'],
        }}
      />,
      <ResourceFor
        key="v2/registration-handlers"
        name="v2/registration-handlers"
        permissions={permissions}
        rest={RegistrationHandlerV2}
        access={{
          List: ['registration-handlers:view'],
          Show: ['registration-handlers:view'],
          Edit: ['registration-handlers:update'],
          Create: ['registration-handlers:update'],
        }}
      />,
      <ResourceFor
        key="registration-forms"
        name="registration-forms"
        permissions={permissions}
        rest={RegistrationForm}
        access={{
          List: ['registration-forms:view'],
          Show: ['registration-forms:view'],
          Edit: ['registration-forms:update'],
          Create: ['registration-forms:update'],
        }}
      />,

      <ResourceFor
        key="s"
        name="registration-data/schemas"
        permissions={permissions}
        rest={RegistrationData}
        access={{
          List: ['registration-data:view'],
          Show: ['registration-data:view'],
          Edit: ['registration-data:update'],
          Create: ['registration-data:update'],
        }}
      />,
      <Resource key="registration-data" name="registration-data" />,
      <ResourceFor
        name="terms"
        permissions={permissions}
        rest={Terms}
        access={{
          List: ['terms:view'],
          Show: ['terms:view'],
          Edit: ['terms:update', 'client:terms:update'],
          Create: ['terms:create'],
        }}
      />,
      <ResourceFor
        key="client-terms"
        apiResource="terms"
        name="client-terms"
        permissions={permissions}
        rest={Terms}
        access={{
          List: ['client:terms:view'],
          Show: ['client:terms:view'],
          Edit: ['client:terms:update'],
          Create: ['client:terms:create'],
        }}
      />,
      <ResourceFor
        key="license"
        apiResource="license-comments/sasi"
        name="license-comments/sasi"
        permissions={permissions}
        rest={License}
        access={{
          List: ['license:comments:view'],
          Show: ['license:comments:view'],
          Edit: ['license:comments:update'],
          Create: ['license:comments:create'],
        }}
      />,
      <ResourceFor
        key="projects"
        apiResource="projects"
        name="projects"
        permissions={permissions}
        rest={Projects}
        access={{
          List: ['projects:view'],
          Show: ['projects:view'],
          Edit: ['projects:update'],
          Create: ['projects:create'],
        }}
      />,
    ]
  );
};

const customRoutes = [
  <Route key="profile" path="/profile" render={() => <Profile resource="users" basePath="/users" />} />,
  <Route
    key="privacy"
    path="/privacy\.html"
    noLayout
    render={(props) => <Document {...props} name="privacy" title="Política de Privacidade" />}
  />,
  <Route
    key="terms"
    path="/terms\.html"
    noLayout
    render={(props) => <Document {...props} name="terms" title="Termos de Uso" />}
  />,
  <Route
    key="appUpdate"
    path="/update\.html"
    noLayout
    render={(props) => <Document {...props} name="appUpdate" title="Requer atualização de aplicativo" />}
  />,
  <Route key="surveyRender" path="/static/surveys/:id" noLayout render={(props) => <SurveyRender {...props} />} />,
  <Route key="deepLink" path="/go/users/auth/:id" noLayout render={(props) => <AppGo {...props} />} />,
  <Route
    key="forgotPasswordCode"
    path="/password-reset/code"
    noLayout
    render={(props) => <ForgotPasswordWithCode {...props} />}
  />,
  <Route key="forgotPassword" path="/password-reset" noLayout render={(props) => <ForgotPassword {...props} />} />,
];
const dashboard = Dashboard;
export { customRoutes, dashboard };
