import React, { useState } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { DropdownFormField } from 'components/designSystem/Dropdown';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { Form } from 'react-final-form';
import { required } from 'lib/formValidators';
import { useDataProvider, useTranslate, useNotify } from 'ra-core';
import { LabeledSwitchField } from 'components/designSystem/LabeledSwitch';
import { channelTypes } from 'lib/consts';
import { handleCreateChannel } from './CreateChannelDialog.model';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  borderContainer: {
    border: '1px solid',
    borderColor: theme.palette.text.tertiary,
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
  bottomContainer: {
    display: 'flex',
    // alignItems: 'center',
    marginTop: theme.spacing(3.2),
    justifyContent: 'end',
    paddingBottom: theme.spacing(2.73),

    '& button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginLeft: 16,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  nameTextField: {
    marginBottom: theme.spacing(2.25),
  },
  setPrimaryContainer: {
    justifyContent: 'flex-end',
    position: 'relative',
    top: theme.spacing(1),
    left: theme.spacing(1) * -1,
    marginBottom: 20,
    paddingTop: 4,

    '& > div': {
      color: theme.palette.text.tertiary,
      paddingLeft: 8,
    },
  },
  content: {
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  contentDefault: {
    paddingTop: 16,
  },
}));

export const CreateChannelDialog = ({ appId, clientId, isOpen, close, refresh }) => {
  const t = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog isOpen={isOpen} close={close} title={t('resources.channels.fields.createChannel')}>
      <Form
        initialValues={{ channelType: 'alert' }}
        onSubmit={handleCreateChannel({ dataProvider, clientId, appId, close, setLoading, refresh, notify })}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.content}>
              <TextFormField
                name="name"
                validate={required(t)}
                full
                dense
                label={t('resources.channels.fields.name')}
                placeholder={t('resources.channels.fields.namePlaceholder')}
                required
                disabled={loading}
                iconPosition="right"
                iconError={<WarningRoundedIcon />}
              />
            </div>
            <DropdownFormField
              name="channelType"
              validate={required(t)}
              items={channelTypes}
              full
              label={t('resources.channels.fields.channelType')}
              placeholder={t('resources.channels.placeholders.channelType')}
              translate
            />

            <div className={classes.contentDefault}>
              <LabeledSwitchField
                reverse
                className={classes.setPrimaryContainer}
                name="primary"
                label={t('resources.channels.fields.config.primary')}
                caption={t('resources.channels.fields.placeholders.primary')}
              />
            </div>

            {/* <LabeledSwitchField reverse className={classes.setPrimaryContainer} name="primary" label="Primário" /> */}

            {/* <div className={classes.borderContainer}> */}
            <div className={classes.bottomContainer}>
              <TertiaryButton disabled={loading} label={t('actionButtons.cancel')} onClick={close} />
              <PrimaryButton
                disabled={loading}
                type="submit"
                label={t('actionButtons.create')}
                onClick={handleSubmit}
                notify={'resources.channels.fields.config.notifySucess'}

                // icon={<CheckCircle />}
              />
            </div>
            {/* </div> */}
          </form>
        )}
      />
    </Dialog>
  );
};
