import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';

import { defaults } from './AppPreview.model';
import { AppPreviewHeader } from './AppPreviewHeader';
import { useDataProvider } from 'ra-core';
import { useChannels } from 'hooks/use-resource-hooks';
import { ChannelPreviewButton } from 'resources/Channel/components/ChannelPreviewButton';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 325,
    width: '100%',
    maxWidth: 'calc((90vh - 1.5rem) / 2)',
    height: 900,
    maxHeight: 'calc(90vh - 1.5rem)', // TODO: better height. (3.5rem => navbar height)
    overflow: 'hidden',
    display: 'flex',
    background: 'green',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: theme.borderRadius * 2,
  },
  channelList: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'auto',

    '& > button': {
      width: '90%',
    },
  },
  mainChannelList: {
    marginBottom: '2.7rem',
  },
  secondaryChannelsTray: {
    position: 'absolute',
    top: '100%',
    transform: 'translateY(-3rem)',
    transition: '.5s transform',
    width: '100%',
    backgroundColor: '#16181D',
    borderRadius: `${theme.borderRadius * 4}px ${theme.borderRadius * 4}px 0 0`,
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  secondaryChannelsTrayOpen: {
    transform: 'translateY(-100%)',
    cursor: 'inherit',
  },
  secondaryChannelsTrayHeader: {
    display: 'flex',
    alignItems: 'center',
    color: '#C4C9D4',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '.5rem',
    paddingBottom: '1rem',
  },
  secondaryChannelsTrayHandler: {
    display: 'inline-block',
    width: '2rem',
    height: '4px',
    borderRadius: '100px',
    backgroundColor: 'white',
    marginBottom: '.5rem',
  },
  trayOverlay: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
    cursor: 'pointer',
  },
}));

export const AppPreview = ({ app }) => {
  const classes = useStyles();
  const { channels: channelsState, dispatch } = useChannels({ appId: app.id });
  const dataProvider = useDataProvider();

  channelsState.sort((a, b) => a.displayOrder - b.displayOrder);

  const [openTray, setOpenTray] = useState(false);
  const [primaryChannels, setPrimaryChannels] = useState(
    channelsState.filter((channel) => channel.primary && !channel.config?.parentId) || [],
  );
  const [secondaryChannels, setSecondaryChannels] = useState(
    channelsState.filter((channel) => !channel.primary && !channel.config?.parentId) || [],
  );

  useEffect(() => {
    dataProvider.getList('channels', { filter: { appId: app.id } }).then(({ data }) => {
      data.sort((a, b) => a.displayOrder - b.displayOrder);
      setPrimaryChannels(data.filter((channel) => channel.primary && !channel.config?.parentId));
      setSecondaryChannels(data.filter((channel) => !channel.primary && !channel.config?.parentId));
      dispatch(data);
    });
  }, [app?.id, app?.updatedAt]); //eslint-disable-line

  const { backgroundColor, textColor } = app.config ?? {};

  const showPrimaryChannels = primaryChannels.length > 0;

  return (
    <div
      className={classes.container}
      style={{
        backgroundColor: backgroundColor || defaults.backgroundColor,
        color: textColor || defaults.textColor,
      }}
    >
      <AppPreviewHeader app={app} />
      <div className={`${classes.channelList} ${classes.mainChannelList}`}>
        {showPrimaryChannels
          ? primaryChannels.map((channel) => (
            <ChannelPreviewButton channel={channel} app={app} key={`app_preview_channels_primary_${channel.id}`} />
          ))
          : secondaryChannels.map((channel) => (
            <ChannelPreviewButton
              channel={channel}
              app={app}
              key={`app_preview_channels_secondary_${channel.id}`}
              noPrimary
            />
          ))}
      </div>
      {showPrimaryChannels && secondaryChannels.length > 0 && (
        <>
          {openTray && <div className={classes.trayOverlay} onClick={() => setOpenTray(false)}></div>}
          <div className={`${classes.secondaryChannelsTray} ${openTray ? classes.secondaryChannelsTrayOpen : ''}`}>
            <div className={classes.secondaryChannelsTrayHeader} onClick={() => setOpenTray((open) => !open)}>
              <div className={classes.secondaryChannelsTrayHandler}></div>
              <span>Mais Opções</span>
            </div>
            <div className={classes.channelList}>
              {secondaryChannels.map((channel) => (
                <ChannelPreviewButton
                  channel={channel}
                  app={app}
                  key={`app_preview_channels_secondary_${channel.id}`}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
