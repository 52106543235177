import { SET_APPS } from '../actions/apps';

export const appsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_APPS:
      return {
        ...state,
        apps: payload,
      };
    default:
      return undefined;
  }
};
