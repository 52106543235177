import React from 'react';

import { makeStyles } from '@material-ui/styles';

import { Form } from 'components/designSystem/Form';
import { TemplatePreview } from '../components/TemplatePreview.jsx';

import { GeneralForm, BasicProperties } from './Settings.model';
import { defaults } from '../../App/components/AppPreview.model';
// import { useTerms, useProjects } from 'hooks/use-resource-hooks';
// import { useDataProvider, useNotify } from 'ra-core';
// import { PrimaryButton } from 'components/designSystem/PrimaryButton';
// import { ConfirmDialog } from 'components/designSystem/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formContainer: {
    flex: '3',
    maxWidth: '60rem',
  },
  phoneContainer: {
    flex: '1',
    backgroundColor: theme.palette.background.surface.pressed,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const SettingsView = ({ template }) => {
  const classes = useStyles();
  // const dataProvider = useDataProvider();
  // const notify = useNotify();

  // const [app, setApp] = useState(_app);
  // const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  // const [onDialogSaveSuccess, setOnDialogSaveSuccess] = useState(() => { });

  // const initialValues = useRef({ ...app, config: { ...defaults, ...(app?.config || {}) } });

  // const [currentApp, setCurrentApp] = useState(initialValues.current);

  // const { terms, dispatch: termsDispatch } = useTerms({ clientId: app.clientId });
  // const { projects, dispatch: projectsDispatch } = useProjects(app.clientId);

  // useEffect(() => {
  //   dataProvider.getList('terms', { filter: { ClientId: app.clientId } }).then(({ data }) => {
  //     termsDispatch(data);
  //   });
  //   dataProvider.getList('projects', { filter: { ClientId: app.clientId } }).then(({ data }) => {
  //     projectsDispatch(data);
  //   });
  // }, []); //eslint-disable-line

  // useEffect(() => {
  //   setApp(_app);
  //   initialValues.current = { ..._app, config: { ...defaults, ...(_app?.config || {}) } };
  // }, [_app]);

  // parentSave.current = async (onSuccess) => {
  //   setOnDialogSaveSuccess(() => onSuccess);
  //   setShowConfirmDialog(true);
  // };

  return (
    <Form
      initialValues={{ ...template, config: { ...defaults, ...(template?.config || {}) } }}
      onSubmit={(values) => console.log("SUBMIT", values)}
      readOnly
      // onSubmit={handleSave({ dataProvider, app, setLoading, refresh, setApp, notify, setCurrentApp })}
      render={({ handleSubmit, values }) => {
        return (
          <>
            {/* <ConfirmDialog */}
            {/*   isOpen={showConfirmDialog} */}
            {/*   close={() => setShowConfirmDialog(false)} */}
            {/*   title="Alterações pendentes" */}
            {/*   text="Você tem alterações pendentes. Gostaria de salvá-las antes de prosseguir?" */}
            {/*   cancelAction={() => setShowConfirmDialog(false)} */}
            {/*   cancelDisabled={loading} */}
            {/*   primaryLabel="Salvar" */}
            {/*   primaryAction={async () => { */}
            {/*     await handleSubmit(); */}
            {/*     setShowConfirmDialog(false); */}
            {/*     onDialogSaveSuccess(); */}
            {/*   }} */}
            {/*   primaryDisabled={loading} */}
            {/* /> */}
            <form onSubmit={handleSubmit} className={classes.container}>
              {/* <FormWrapper handleSubmit={handleSubmit} setEditing={setEditing}> */}
              <div className={classes.formContainer}>
                <GeneralForm />
                <BasicProperties />
                {/* <button ref={buttonRef} type="submit" style={{ display: 'none' }}> */}
                {/*   SAVE */}
                {/* </button> */}
              </div>
              <div className={classes.phoneContainer}>
                <TemplatePreview template={values} big />
              </div>
              {/* </FormWrapper> */}
            </form>
          </>
        );
      }}
    />
  );
};

export const Settings = ({ template, setMenuItems, refresh, setEditing, parentSave }) => {
  // const [loading, setLoading] = useState(false);
  // const buttonRef = useRef();
  // const appId = app?.id;
  // setEditing(false);

  // useEffect(() => {
  //   if (app) {
  //     setMenuItems([
  //       <PrimaryButton
  //         key="save_app_settings_primary_button"
  //         disabled={!!loading}
  //         onClick={() => {
  //           if (!!buttonRef.current?.click) {
  //             buttonRef.current.click();
  //           }
  //         }}
  //         label="Salvar alterações"
  //       />,
  //     ]);
  //   }
  // }, [app, setMenuItems, appId, loading]);

  if (!template) {
    return <div></div>;
  }
  return (
    <SettingsView
      template={template}
    // buttonRef={buttonRef}
    // setLoading={setLoading}
    // setEditing={setEditing}
    // parentSave={parentSave}
    // loading={loading}
    />
  );
};
