import React from 'react';

import { makeStyles } from '@material-ui/core';
import { TextField } from 'components/designSystem/inputs/TextField';
import { Field } from 'react-final-form';
import { channelTypes } from 'lib/consts';
import { useTranslate } from 'ra-core';
import { genericErrorHandler } from 'lib/Errors';

export const usePanelStyles = makeStyles((theme) => ({
  sectionTitle: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(1.7),
  },
  panel: {
    boxSizing: 'border-box',
    width: '100%',
    background: theme.palette.background.card,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    paddingTop: theme.spacing(2.25),
    borderRadius: '4px 4px 0 0',
    justifyContent: 'flex-end',
  },
  saveContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: theme.spacing(3),

    '& button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginLeft: 5.75,
      fontSize: 14,
      //colocar no medium os botões
    },
  },
}));

export const ChannelTypeTextField = ({ name, validate, ...props }) => {
  const t = useTranslate();
  return (
    <Field name={name} validate={validate}>
      {({ input, meta: { error, touched } }) => (
        <TextField
          {...props}
          {...input}
          errorMessage={error && touched ? error : undefined}
          value={t(channelTypes.find((t) => t.id === input.value).name || channelTypes[0].name)}
        />
      )}
    </Field>
  );
};

export const saveChannel =
  ({ dataProvider, channel, setLoading, setLocalChannel, setChannel, notify }) =>
  async (values) => {
    setLocalChannel(values);
    setLoading(true);
    try {
      if (values.config.isEmergency && values.config.phone) {
        values.config.phone = values.config.phone.replace('+', '').trim();
      }

      const newChannel = await dataProvider.update('channels', {
        id: channel.id,
        data: {
          ...values,  
          id: channel.id,
          channelType: channel.channelType,
          label: !values.label || values.label === '' ? channel.label : values.label,
          primary: values.primary === true,
        },
      });
      setChannel(newChannel.data);
      notify('resources.channels.fields.config.notifySucess');
    } catch (err) {
      setLocalChannel(channel);
      genericErrorHandler(notify)(err);
    } finally {
      setLoading(false);
    }
  };
