// in src/App.js
import React, { useState } from 'react';
import { CoreAdmin as Admin } from 'ra-core';
import { Loading, NotFound, Logout } from 'ra-ui-materialui';
import { ThemeProvider } from '@material-ui/styles';
import { createBrowserHistory } from 'history';
import LoginPage from 'layout/LoginPage';
import Layout from 'components/layout/Layout';
import ThemeContext from 'lib/ThemeContext';
import dataProvider from 'lib/dataProvider';
import config from 'config';
import i18nProvider from 'config/i18n';
import resources, { customRoutes, dashboard } from 'config/resources';
import { useThemeContext } from 'hooks';
import authProvider from './lib/authProvider';
import { GlobalState } from 'state';
import { Clipboard } from './Clipboard';

const history = createBrowserHistory();

export default () => {
  const themeContext = useThemeContext();
  const [openOnce, setOpenOnce] = useState(false);

  const theme = themeContext.getTheme();
  return (
    <ThemeContext.Provider value={themeContext}>
      <ThemeProvider theme={theme}>
        <Clipboard>
          <GlobalState>
            <Admin
              title=""
              theme={theme}
              i18nProvider={i18nProvider}
              authProvider={authProvider}
              dataProvider={dataProvider(config.apiUrl)}
              history={history}
              layout={(props) => <Layout {...props} setOpenOnce={setOpenOnce} openOnce={openOnce} />}
              loginPage={LoginPage}
              loading={Loading}
              catchAll={NotFound}
              logoutButton={Logout}
              dashboard={dashboard}
              customRoutes={customRoutes}
              customSagas={[]}
            >
              {resources}
            </Admin>
          </GlobalState>
        </Clipboard>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
