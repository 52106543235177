import React, { /*useCallback,*/ useState } from 'react';
import { get } from 'lodash';
import { /*required, useDataProvider,*/ useListContext /*useNotify*/ } from 'ra-core';
import { Icon } from '@material-ui/core';
// import { TextInput } from 'ra-ui-materialui/lib/input';
// import { SimpleForm } from 'ra-ui-materialui/lib/form';
import {
  Toolbar,
  SingleFieldList,
  Labeled,
  FunctionField,
  ChipField,
  TextField,
  TabbedShowLayout,
  ReferenceManyField,
  Datagrid,
  Tab,
  EditButton,
  Pagination,
  Button,
} from 'ra-ui-materialui';
import { Show, QuickDeleteButton, checkPermissions, ArrayField, DefaultActions } from 'components';
import GroupExpandChannels from 'resources/Channel/components/GroupExpandChannels';
import ActionsForTab from 'resources/Common/ActionsForTab';
import SitesMap from './components/SitesMap';
import { useTranslate, useRecord, useRef, useEffect } from 'hooks';
import { CreateButton as CreateGroupButton } from 'resources/App/components';
import { Col, Row } from 'resources/components/Grid';
import DrawerGroup from 'resources/components/DrawerGroup';
import useRefreshWithVersion from 'hooks/use-refresh';
// import { PasteButton, CleanBufferButton } from 'components/CopyPaste';
import { CopyButton } from 'components/CopyPaste';
import { Input } from '../../components/Input';
import { Debounce } from '../../lib/debounce';
import { ProjectDatagridDescription, ProjectDatagridName } from 'resources/Client/components/ProjectDatagridItems';

const searchDebounceValue = 500;
const debounceChannelsSearch = new Debounce();

const PreviewButton = ({ onClick, record }) => (
  <Button label="tabs.customize" onClick={() => onClick(record)}>
    <Icon>send</Icon>
  </Button>
);

const PaginationWithNoQ = () => {
  const { setPage, filterValues, total } = useListContext();
  const [searched, setSearched] = useState(false);

  useEffect(() => {
    if (filterValues.q && filterValues.q !== '' && !searched) {
      setSearched(true);
      setPage(1);
    } else if ((!filterValues.q || filterValues.q === '') && searched) {
      setSearched(false);
    }
  }, [filterValues.q]); // eslint-disable-line

  return !searched || total === 0 ? <Pagination /> : <></>;
};

export default ({ permissions, ...props }) => {
  const translate = useTranslate();
  // const dataProvider = useDataProvider();
  // const notify = useNotify();

  const record = useRecord('projects') || {};

  const projectEditable = checkPermissions(permissions, 'projects:update');
  const channelsVisible = checkPermissions(permissions, 'channels:view');
  const channelsEditable = checkPermissions(permissions, 'channels:update');

  const [channelFilter, setChannelFilter] = useState('');
  const [channelPreFilter, setChannelPreFilter] = useState('');
  const debouncedSetChannelFilter = (search) =>
    debounceChannelsSearch.debounced(() => {
      setChannelFilter(search);
    }, searchDebounceValue);

  useEffect(() => {
    debouncedSetChannelFilter(channelPreFilter);
  }, [channelPreFilter]);

  const [app, setApp] = useState({});
  const [refresh, version] = useRefreshWithVersion();
  const containerRef = useRef();

  // const onChannelPaste = useCallback(
  //   async (values, app) => {
  //     const { name, tag, channelType, config } = values;
  //     try {
  //       await dataProvider.create('channels', {
  //         data: { name, tag, channelType, config, appId: app.id },
  //       });
  //       notify('notifications.channelInserted');
  //       refresh();
  //     } catch (error) {
  //       notify(error.message, 'error');
  //     }
  //   },
  //   [dataProvider, notify], // eslint-disable-line
  // );

  // const onGroupPaste = useCallback(
  //   async (values) => {
  //     const { name, id, config } = values;
  //     try {
  //       const { data: channels } = await dataProvider.getList('channels', {
  //         filter: { appId: id },
  //       });
  //       const { data: newApp } = await dataProvider.create('apps', {
  //         data: {
  //           name,
  //           isDefault: false,
  //           clientId: record.id,
  //           config,
  //         },
  //       });
  //       await Promise.all(
  //         channels.map(({ name, tag, channelType, config }) =>
  //           dataProvider.create('channels', {
  //             data: { name, tag, channelType, config, appId: newApp.id },
  //           }),
  //         ),
  //       );
  //       notify('notifications.appInserted');
  //       refresh();
  //     } catch (error) {
  //       notify(error.message, 'error');
  //     }
  //   },
  //   [dataProvider, notify, refresh, record.id],
  // );

  return (
    <>
      <DrawerGroup
        title={record.nickname}
        resource="channels"
        source="appId"
        record={app}
        version={version}
        onClose={() => setApp({})}
        onUpdate={refresh}
      />
      <Show
        titleSource="name"
        actions={<DefaultActions hasList={false} hasEdit={false} portalRef={containerRef} />}
        {...props}
      >
        <TabbedShowLayout>
          <Tab label="tabs.summary">
            <ActionsForTab containerRef={containerRef} hasList={true} hasEdit={false}>
              {projectEditable && <EditButton basePath="/projects" record={record} label="actions.configure" />}
            </ActionsForTab>
            <Row>
              <Col>
                <Labeled source="name">
                  <ProjectDatagridName source="name" isNotLink />
                </Labeled>
                <Labeled source="description">
                  <ProjectDatagridDescription source="description" />
                </Labeled>
                <Labeled source="client.name">
                  <TextField source="client.name" />
                </Labeled>
              </Col>
              <Col>
                <ArrayField addLabel={false} source="sites">
                  <SitesMap />
                </ArrayField>
              </Col>
            </Row>
          </Tab>

          {channelsVisible && (
            <Tab label="tabs.apps">
              <ActionsForTab containerRef={containerRef}>
                {channelsEditable && (
                  <CreateGroupButton
                    defaultValues={{
                      ClientId: record.clientId,
                      ProjectId: props.id,
                    }}
                    onSubmit={refresh}
                  />
                )}
              </ActionsForTab>

              <Toolbar>
                <div style={{ marginTop: '1rem', width: '300px' }}>
                  <Input
                    label={translate('titles.search')}
                    smallLabel
                    value={channelPreFilter}
                    onChange={({ target }) => setChannelPreFilter(target.value)}
                    meta={{}}
                    input={{}}
                  />
                </div>
                {/* <PasteButton
                  onPaste={onGroupPaste}
                  title={translate('actions.paste')}
                  dialogTitle={translate('titles.insertApp')}
                  name="CopyApp"
                >
                  <SimpleForm toolbar={null} resource="apps">
                    <TextInput source="name" validate={required()} />
                  </SimpleForm>
                </PasteButton> */}
                {/* <CleanBufferButton title={translate('actions.discard')} name="CopyApp" /> */}
              </Toolbar>

              <ReferenceManyField
                addLabel={false}
                reference="apps"
                target="ProjectId"
                pagination={<PaginationWithNoQ />}
                filter={{ q: channelFilter }}
              >
                <Datagrid
                  expand={
                    <GroupExpandChannels readonly={!channelsEditable} customizationConfig={get(record, 'config', {})} />
                  }
                >
                  <TextField source="name" label="titles.apps" />
                  <ArrayField source="Channels">
                    <SingleFieldList linkType={false}>
                      <ChipField source="name" />
                    </SingleFieldList>
                  </ArrayField>
                  {/* <PasteButton
                    onPaste={onChannelPaste}
                    title={translate('actions.paste')}
                    dialogTitle={translate('titles.insertChannel')}
                    name="CopyChannel"
                  >
                    <SimpleForm toolbar={null} resource="channels">
                      <TextInput source="name" validate={required()} />
                    </SimpleForm>
                  </PasteButton>
                  <CleanBufferButton title={translate('actions.discard')} name="CopyChannel" /> */}
                  {channelsEditable && (
                    <FunctionField
                      render={(record) => (
                        <CopyButton
                          name="CopyApp"
                          value={record}
                          notification={translate('notifications.appCopied', record)}
                        />
                      )}
                    />
                  )}
                  {channelsEditable && <QuickDeleteButton resource="apps" record={record} onDelete={refresh} />}
                  <PreviewButton onClick={setApp} />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
          )}
        </TabbedShowLayout>
      </Show>
    </>
  );
};
