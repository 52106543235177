import React from 'react';
import { makeStyles } from '@material-ui/core';

const useShowTitleStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '12px 0',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
    margin: 0,
  },
}));

export const ShowTitle = ({ name, actions }) => {
  const classes = useShowTitleStyles();
  return (
    <div className={classes.container}>
      <h2 className={classes.title}>{name}</h2>
      <div>{actions || []}</div>
    </div>
  );
};
