export const loadClients = ({ dataProvider, setClients, dispatch, q }) =>
  dataProvider.getList('clients', { filter: { q } }).then(({ data }) => {
    setClients(data);
    dispatch(data);
  });

export const loadTemplateVisibility = ({ dataProvider, setTemplateVisibility }) =>
  dataProvider.getList('apps/templates/params/visibility', {}).then(({ data }) => {
    setTemplateVisibility(data);
  });
