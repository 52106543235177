import React from 'react';
import { ReferenceInput, SelectInput } from 'ra-ui-materialui';
import { useForm } from 'react-final-form';
import { required, FormDataConsumer } from 'ra-core';
import DownshiftInput from 'components/DownshiftInput';

export default ({ clientLocked, projectLocked, ...props }) => {
  const form = useForm();
  return (
    <FormDataConsumer>
      {({ formData: { ClientId, ProjectId }, ...rest }) => (
        <>
          <div className="ra-input">
            <ReferenceInput
              source="ClientId"
              reference="clients"
              validate={required()}
              disabled={clientLocked ? true : false}
              onChange={() => {
                form.change('appId', null);
                form.change('ProjectId', null);
              }}
              {...rest}
              {...props}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </div>
          <div className="ra-input">
            <DownshiftInput
              filter={{ ClientId, ProjectId, clientId: ClientId }}
              source="appId"
              reference="apps"
              type="text"
              optionValue="id"
              optionText="name"
              required
              initialValueProp="App"
              {...rest}
              {...props}
            />
          </div>
        </>
      )}
    </FormDataConsumer>
  );
};
