import React from 'react';
import { makeStyles } from '@material-ui/core';

import { TextFormField } from 'components/designSystem/inputs/TextField';
import { LabeledSwitchField } from 'components/designSystem/LabeledSwitch';
import { DropdownFormField } from 'components/designSystem/Dropdown';
import { usePanelStyles, ChannelTypeTextField } from '../ChannelPanels.model';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: theme.spacing(1.5),
  },
  wrapper: {
    display: 'flex',
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(1.5),
  },
  configContainer: {
    marginRight: theme.spacing(10),
  },
  finishButtonsContainer: {
    flex: '1',
  },
  finishOptionsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  textFields: {
    marginBottom: theme.spacing(1),
    marginTop: 0,
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
  },
  subtext: {
    fontSize: theme.spacing(1.4),
    lineHeight: theme.spacing(0.2),
    alignItems: 'center',

    marginTop: theme.spacing(1),
    color: theme.palette.text.tertiary,
  },
  setPrimaryContainer: {
    justifyContent: 'flex-end',
    position: 'relative',
    left: theme.spacing(1) * -1,
    marginBottom: theme.spacing(0.5),

    '& > div': {
      color: theme.palette.text.tertiary,
    },
  },
}));

export const BubbleGeneralPanel = ({ parentChannels }) => {
  const classes = useStyles();
  const panelClasses = usePanelStyles();
  const t = useTranslate();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.configContainer}>
          <h2 className={panelClasses.sectionTitle}>{t('resources.channels.fields.config.about')}</h2>
          <div className={classes.finishOptionsContainer}>
            <div className={classes.optionsContainer}>
              <TextFormField
                className={classes.textFields}
                name="id"
                disabled
                size="full"
                full
                label={t('resources.channels.fields.config.channelId')}
                helperText={t('resources.channels.fields.config.helpertext')}
              />
              <DropdownFormField
                name="config.parentId"
                full
                items={(parentChannels || []).map((c) => ({ id: c.id, name: c.name }))}
                label={t('resources.channels.fields.config.parentId')}
                helperText={t('resources.channels.fields.config.helpertext')}
                allowEmpty
              />
            </div>
            <div>
              <ChannelTypeTextField
                className={classes.textFields}
                name="channelType"
                disabled
                full
                size="full"
                label={t('resources.channels.fields.config.channelType')}
                helperText={t('resources.channels.fields.config.helpertext')}
              />
              <TextFormField
                className={classes.textFields}
                name="tag"
                size="full"
                full
                label="TAG"
                helperText={t('resources.channels.fields.config.helpertext')}
                placeholder={t('resources.channels.fields.placeholders.insertTag')}
              />
            </div>
          </div>
          <TextFormField
            className={classes.textFields}
            name="config.url"
            size="full"
            full
            label="URL"
            helperText={t('resources.channels.fields.config.helpertext')}
            placeholder={t('resources.channels.fields.placeholders.bubbleUrl')}
          />
        </div>
        <div className={classes.configContainer}>
          <h2 className={panelClasses.sectionTitle}>{t('resources.channels.fields.config.settings')}</h2>
          <div className={classes.finishOptionsContainer}>
            <div className={classes.optionsContainer}>
              <LabeledSwitchField
                className={classes.setPrimaryContainer}
                reverse
                name="primary"
                label={t('resources.channels.fields.config.primary')}
                caption={t('resources.channels.fields.placeholders.primary')}
              />

              <LabeledSwitchField
                reverse
                className={classes.setPrimaryContainer}
                name="config.notifyOpened"
                label={t('resources.channels.fields.config.notifyOpened')}
                caption={t('resources.channels.fields.placeholders.notifyOpened')}
              />
              {/* <LabeledSwitchField */}
              {/*   reverse */}
              {/*   className={classes.setPrimaryContainer} */}
              {/*   name="config.usePassport" */}
              {/*   label="Passport" */}
              {/* /> */}
              <LabeledSwitchField
                reverse
                className={classes.setPrimaryContainer}
                name="config.useWebclientAuth"
                label="SASI Token"
                caption={t('resources.channels.fields.placeholders.sasiToken')}
              />
              <LabeledSwitchField
                reverse
                className={classes.setPrimaryContainer}
                name="config.useWebclientAuthRefresh"
                label={t('resources.channels.fields.config.useWebclientAuthRefresh')}
                caption={t('resources.channels.fields.placeholders.useWebclientAuthRefresh')}
              />
              <LabeledSwitchField
                reverse
                className={classes.setPrimaryContainer}
                name="config.siteManagerOnly"
                label={t('resources.channels.fields.config.siteManagerOnly')}
                caption={t('resources.channels.fields.placeholders.siteManagerOnly')}
              />
              <LabeledSwitchField
                reverse
                className={classes.setPrimaryContainer}
                name="config.useNewWebclientApi"
                label={t('resources.channels.fields.config.newApi')}
                caption={t('resources.channels.fields.placeholders.newApi')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
