import React from 'react';

import {
  makeStyles,
  Table as MuiTable,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  CircularProgress,
} from '@material-ui/core';
import { useLocale, useTranslate } from 'ra-core';
import { TableHead } from './TableHeader';
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from 'lib/consts';
import { EmptyPane } from 'assets/EmptyPane';

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: theme.palette.background.surface.primary,
    borderRadius: theme.borderRadius,
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableRowClickable: {
    cursor: 'pointer',
  },
  noDataContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    paddingBottom: theme.spacing(6),
  },
  noDataLabel: {
    width: '40%',
    minWidth: '100px',
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightRegular,
  },
  breakCell: {
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
}));

export const Table = ({
  data,
  columns: cols,
  onSort,
  order,
  orderBy,
  onRowClick,
  onChangePage,
  onChangeRowsPerPage,
  page,
  totalRecords,
  rowsPerPage,
  rowsPerPageOptions,
  loading,
  noDataMessage,
}) => {
  const classes = useStyles();
  const locale = useLocale();
  const t = useTranslate();

  let columns = cols;
  if (typeof columns === 'function') {
    columns = columns(t, locale);
  }

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className={classes.noDataContainer}>
        <EmptyPane />
        {noDataMessage && <span className={classes.noDataLabel}>{t(noDataMessage)}</span>}
      </div>
    );
  }

  const columnsString = JSON.stringify(columns);
  const mappedData = data.map((d, index) => {
    const record = columns.reduce((record, c) => {
      const newRecord = {
        key: c.key,
        shouldBreak: !!c.shouldBreak,
      };

      if (typeof c.field === 'string') {
        newRecord.value = d[c.field] || c.default || '';
      } else if (typeof c.field === 'function') {
        newRecord.value = c.field(d) || c.default || '';
      } else {
        newRecord.value = c.default || '';
      }

      if (newRecord.value !== '') {
        newRecord.minWidth = c.minWidth;
      }

      return [...record, newRecord];
    }, []);

    return {
      record,
      data: d,
      key: `${columnsString}_row_${index}`,
    };
  });

  return (
    <TableContainer>
      <MuiTable className={classes.table}>
        <TableHead columns={columns} onSort={onSort} order={order} orderBy={orderBy} />
        <TableBody>
          {mappedData.map((row) => (
            <TableRow
              key={row.key}
              hover={!!onRowClick}
              className={`${!!onRowClick ? classes.tableRowClickable : ''}`}
              onClick={(evt) => (onRowClick || (() => {}))(row.data, evt)}
            >
              {row.record.map(({ value, key, shouldBreak, minWidth }) => (
                <TableCell
                  className={shouldBreak ? classes.breakCell : ''}
                  key={key}
                  style={minWidth ? { minWidth } : {}}
                >
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
      {onChangePage && totalRecords !== undefined && page !== undefined && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions || DEFAULT_ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage || DEFAULT_ROWS_PER_PAGE}
          page={page}
          labelRowsPerPage={t('table.rowsPerPage')}
          labelDisplayedRows={({ from, to, count }) =>
            `${t('table.displayedRows', { from, to })} ${
              count !== -1 ? count : t('table.displayedRowsTooMany', { to })
            }`
          }
          onPageChange={(evt, value) => onChangePage(value, evt)}
          onRowsPerPageChange={(evt) => (onChangeRowsPerPage || (() => {}))(evt.target.value, evt)}
        />
      )}
    </TableContainer>
  );
};
