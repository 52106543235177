import { SET_TEAMS } from '../actions/teams';

export const teamsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TEAMS:
      return {
        ...state,
        teams: payload,
      };
    default:
      return undefined;
  }
};
