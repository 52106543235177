import React from 'react';

import { makeStyles, Switch } from '@material-ui/core';
import { Field } from 'components/designSystem/Form';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  label: {
    position: 'relative',
    // marginTop: theme.spacing(0.5),
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    left: 6,
  },
  caption: {
    position: 'relative',
    fontSize: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.hint,
    marginLeft: theme.spacing(0.2),
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  reverse: {
    flexDirection: 'row-reverse',
    '& div': {
      left: 'unset',
      right: 6,
    },
  },
  withCaption: {
    transform: 'translateY(-5px)',
  },
}));

export const LabeledSwitch = ({ input, ...props }) => {
  const { label, onChange, checked, reverse, className, disabled, caption } = { ...(input || {}), ...props };

  const classes = useStyles();

  return (
    <label
      className={`${classes.container} ${reverse ? classes.reverse : ''} ${className || ''} ${
        caption ? classes.alignStart : ''
      }`}
    >
      <div>
        <div className={classes.label}>{label}</div>
        {caption && <div className={classes.caption}>{caption}</div>}
      </div>
      <Switch
        className={caption ? classes.withCaption : ''}
        disabled={disabled}
        onChange={(evt, value) => (onChange || (() => {}))(value)}
        checked={checked}
      />
    </label>
  );
};

export const LabeledSwitchField = (props) => <Field {...props} type="checkbox" component={LabeledSwitch} />;
