export default {
  resources: {
    'apps/templates': {
      name: 'Templates',
      lastUpdatedAt: 'Last update: %{date}',
    },
    dashboard: {
      activity: 'Activity',
      dashboard: 'Dashboard',
      messages: 'Messages',
      users: 'Users',
      mobileUsers: 'Mobile Users',
      clients: 'Clients',
      client: 'Client',
      apps: 'Apps',
      channels: 'Channels',
      alerts: 'Alerts',
      mobileProfiles: 'Mobile Profiles',
      top3Apps: 'Top 3 Apps',
      top3SitesTeams: 'Top 3 Sites/Teams',
      top3AppsDescription: 'Application with more registered mobile profiles inside ',
      top3SitesTeamsDescription: 'Sites/Teams with more registered mobile profiles inside ',
      emptyMessage: 'Not enough data to show',
      app: 'App',
      general: 'General',
      siteAndTeam: 'Team/Site',
      mobileProfileOfThisSite: 'Mobile Profiles of this Site/Team',
    },
    projects: {
      name: 'Name',
      description: 'Description',
      default: 'Default Project',
      defaultDescription: 'Default client project',
      allProjects: 'All projects',
      table: {
        noDataMessage: 'There is no projects for this client yet.',
      },
      createModal: {
        title: 'Create project for client "%{clientName}"',
      },
      editModal: {
        title: 'Edit project "%{name}"',
      },
      deleteModal: {
        title: 'Delete project "%{name}"?',
      },
      fields: {
        namePlaceholder: 'Name of the project',
        descriptionPlaceholder: 'Description of the project',
      },
      buttons: {
        showInactive: 'Show Inactive',
        active: 'Active',
        create: 'New Project',
        save: 'Save',
        cancel: 'Cancel',
        yes: 'Yes',
        no: 'No',
        createApp: 'New App',
        export: 'Export',
      },
    },
    summary: {
      nameClient: 'Name',
      nicknameClient: 'Nickname',
      versionClient: 'App Version',
      advice: 'Check the main customer information below',
    },
    'public-codes': {
      name: 'Public Code |||| Public Codes',
      fields: {
        name: 'Name',
        code: 'Code',
        description: 'Description',
      },
    },
    terms: {
      name: 'Terms |||| Terms',
      fields: {
        title: 'Title',
        content: 'Content',
        publishedAt: 'Publish Date',
        active: 'Active',
      },
    },
    'broadcast-messages': {
      name: 'Broadcast Message |||| Broadcast Messages',
      fields: {
        createdAt: 'Sent',
        text: 'Text',
        'Channel.name': 'Channel',
        'MonitoringProvider.name': 'Provider',
      },
    },
    documents: {
      name: 'Document |||| Documents',
      fields: {
        title: 'Title',
        content: 'Content',
        publishedAt: 'Publish Date',
        active: 'Active',
      },
    },
    'registration-forms': {
      name: 'Registration Form |||| Registration Forms',
      fields: {
        name: 'Name',
        'schema.title': 'Title',
        'schema.fields': 'Fields',
      },
    },
    'registration-data/schemas': {
      name: 'Registration Data |||| Registration Data',
    },
    'alert-assignments': {
      name: 'Status |||| Statuses',
      fields: {
        meta: {
          email: 'Email Sent',
          callback: 'Webhook',
        },
        createdAt: 'Recieved',
        status: 'Status',
      },
    },
    channels: {
      name: 'Channel |||| Channels',
      fields: {
        name: 'Channel name',
        createChannel: 'New Channel',
        namePlaceholder: 'Insert channel name',
        MonitoringProviderIds: 'Monitoring Providers',
        channelType: 'Channel Type',
        pastingChannel: 'Pasting',

        config: {
          about: 'About',
          updated: 'Last Update:',
          // titlePage: 'Title page',
          helpertext: 'Coming Soon',
          finallyAlert: 'Closing options',
          commentary: 'Finish with user comment:',
          noCommentary: 'Finish without user comment:',
          settings: 'Settings and permissions',
          allowGps: 'Allow GPS',
          allowGeocoding: 'Geocode access',
          anonymous: 'Set as anonymous channel',
          primary: ' Set as primary channel',
          siteManagerOnly: 'Visible to the manager only',
          notifyManager: 'Notify Manager',
          notifySucess: 'Saved successfully',
          textColor: 'Text Color',
          bgColor1: 'Background Color 1',
          bgColor2: 'Background Color 2 ',
          icon: 'Icon',
          channelTitle: 'Channel Name',
          sendNowTitle: 'Button:',
          sendNowText: 'System Message:',
          sendOtherTitle: 'Send Other Title',
          disclaimer: 'Disclaimer',
          notifySurveyResult: 'Create result notification',
          notifyOpened: 'Notify when the channel is accessed',
          useWebclientAuth: 'Authenticate with SASI',
          useWebclientAuthRefresh: 'Refresh Token',
          // usePassport: 'Use Passport token',
          url: 'URL',
          androidSdkKey: 'Android SDK Key',
          iosSdkKey: 'IOS SDK Key',
          applicationId: 'Application ID',
          'schema.fields': 'Custom fields',
          requireConfirm: 'Approval required',
          dataChannel: 'Data Collection',
          visibilityQuery: 'Visibility Query',
          parentId: 'Parent Channel',
          channelId: 'Channel ID',
          channelType: 'Channel Type',
          newApi: 'Use new API v2',
          messageType: 'Message Type',
          main: 'Set as main app',
          isEmergency: 'Emergency phone number',
          faceRecognitionType: 'Type',
          faceRecognitionForm: 'Form',
        },
        placeholders: {
          allowGps: 'Allows user location to be sent within the alert.',
          anonymous: 'This option enables anonymous alerts to be sent.',
          primary: 'This channel will be visible on the main page of the app.', //ok
          siteManagerOnly: "This channel will be visible only to the client's manager.",
          notifyManager: 'The manager will be notified of every alert sent.',
          notifyOpened: 'The manager will be notified when this channel is accessed.',
          sasiToken:
            'Allows external sites to access user info and execute a limited set of actions with their usernames through SASI. It expires every hour.',
          insertTag: 'Insert TAG',
          bubbleUrl: 'Insert URL',
          requireConfirm:
            'A broadcast will only be sent after content is reviewed by a super user or broadcast operator.',
          newApi: 'This option is temporary. Going forward, this option will be the default for all Bubble channels.',
          buttons: 'Insert title',
          example: '(E.g.: Closed)',
          useWebclientAuthRefresh: 'Allows tokens to be refreshed without opening the URL again.',
          main: 'Share its settings with the next apps created to the same client.',
          telephone: 'Insert telephone number',
          pasteChannel: 'Insert pasted channel name',
          isEmergency: 'Remove international formatting from the emergency phone number.',
        },
      },
      schema: {
        general: 'General',
        columns: 'Columns',
        comments: 'Comments',
        validation: 'Validation',
        schemaTitle: ' Page Title',
        min: 'Min',
        max: 'Max',
        step: 'Step',
        icon: 'Icon',
        pageBreak: 'Page Break',
        visibleIfQuery: 'Conditions',
        requiredErrorText: 'Required Error Text',
        invalidErrorText: 'Invalid value',
        maxLength: 'Too big. Max: %{max}',
        nonUniqueNameErrorText: 'Field\'s "name" should be unique',
        schemaTitlePlaceholder: 'Please fill additional fields:',
        schemaTitleHelperText: 'Page title',
        allowComments: 'Allow Comments',
        allowAttachments: 'Allow Attachments',
        allowAttachmentCamera: 'Allow Camera',
        allowAttachmentMic: 'Allow Audio Recording',
        allowAttachmentFile: 'Allow Files',
        allowQrCode: 'Allow QrCodes',
        allowSelectAll: 'Allow Select All',
        hasOther: 'Has Other',
        otherText: 'Other Text',
        limit: 'Limit',
        name: 'Name',
        placeholder: 'Placeholder',
        type: 'Type',
        defaultValue: 'Default Value',
        mask: 'Mask',
        lines: 'Lines',
        format: 'Format',
        required: 'Required',
        title: 'Title',
        tag: 'TAG',
        options: 'Options',
        validators: {
          title: 'Validators',
          fields: {
            type: 'Type',
            text: 'Text',
            regex: 'RegExp',
          },
        },
        choices: {
          fastentry: 'Fast Entry',
          title: 'Choices',
          import: 'Import CSV',
          preview: 'Preview',
          fields: {
            value: 'Value',
            text: 'Text',
          },
        },
      },
    },
    alerts: {
      name: 'Alert |||| Alerts',
      fields: {
        showClosed: 'Show Closed',
        test: 'Test',
        text: 'Message',
        MobileProfileId: 'MAP',
        date: 'Date',
        location: 'Location',
        status: 'Status',
        Channel: {
          name: 'Channel',
        },
        priority: 'Priority',
      },
    },
    apps: {
      name: 'App |||| Apps',
      noDataMessageApp: 'There are no apps for this client yet.',
      deleteAppWarning:
        'All the associated channels for this app will be lost, are you sure that you want to delete it?',
      deleteAppSuccess: 'App deleted with success!',
      settingsTabs: {
        theme: 'Theme',
        channels: 'Channels',
        versions: 'Versions',
      },
      fields: {
        name: 'Name',
        nameApp: 'App name',
        placeholderName: 'Insert app name',
        placeholderClient: 'Select',
        placeholderNickname: 'Insert nickname',
        placeholderDescription: 'Insert a short description about this application',
        titleVersion: "Publish new version's App",
        versionApp: "Version's name",
        placeholderVersion: '(Optional) Give a name for this version. Eg: v.0.1.0',
        commentVersion: "Version's description",
        placeholderCommentary: 'Comment a little about what has changed in this version',
        publishApp: 'Publish App',
        publishedVersion: 'Published Version',
        // publishVersion: 'Unpublish Version',
        // unpublishedVersion: 'Restore Version',
        // draftVersion: 'Publish Version',
        salveTemplate: 'Save Template',
        salveasTemplate: 'Save as Template',
        renameVersion: 'Rename Version',
        deleteApp: 'Delete App',
        Channels: 'Channels',
        project: 'Project',
        description: 'Description',
        updatedAt: 'Updated At',
        nickname: 'Nickname',
        title: 'Create new app',
        client: 'Clients',
        Project: {
          name: 'Project',
        },
        isDefault: 'Set as Default',
        status: {
          draft: 'draft',
          published: 'published',
          unpublished: 'unpublished',
          publishVersion: 'Unpublish Version',
          unpublishedVersion: 'Restore Version',
          draftVersion: 'Publish Version',
        },
        config: {
          properties: 'Basic Properties',
          banner: 'Banner',
          bannerBase: 'Drag your banner to upload or click to select image.',
          textColor: 'Main Text Color',
          backgroundColor: 'Main Background Color',
          headerColor: 'Standard Header Color',
          secondaryBackgroundColor: 'Standard backgrounds',
          bannerNickname: 'Turn the nickname visible on the banner',
          buttonsBorderRadius: 'Border Radius',
          secondaryButtonsRatio: 'Secondary Scale',
          primaryButtonsRatio: 'Primary Scale',
          buttonsPadding: 'Padding',
          primaryButtonsFontSize: 'Primary Font',
          secondaryButtonsFontSize: 'Secondary Font',
          primaryIconSize: 'Primary Icon',
          secondaryIconSize: 'Secondary Icon',
          centerPrimary: 'Primary Center',
          centerSecondary: 'Secondary Center',
          themeName: 'Theme',
          nickname: 'Nickname',
          name: 'Name',
          namePlaceholder: "Insert name's app",
          nicknamePlaceholder: "Insert nickname's app",
          descriptionPlaceholder: "Insert description's app",
          projeto: 'Project',
          description: 'Description',
          general: 'General',
          terms: 'Term',
          default: 'Set as Default',
        },
      },
      errors: {
        CANNOT_DELETE_WITH_RELATIONS: 'There are sites or teams that are using this app',
        CLIENT_LOCKED: `Not possible to perform the request because the client is locked!
        (error: %{requestId})`,
      },
    },
    surveys: {
      name: 'Survey |||| Surveys',
      fields: {
        name: 'Name',
        Channels: 'Channels',
        active: 'Active',
      },
    },
    'survey-results': {
      name: 'Survey Result |||| Survey Results',
      fields: {
        MobileProfileId: 'MAP',
      },
    },
    'monitoring-providers': {
      name: 'Monitoring Provider |||| Monitoring Providers',
      create: 'New Provider',
      editTitle: 'Edit Provider',
      noDataMessage: 'There are no providers for this client yet.',
      nameProvider: "Provider's name",
      placeholderName: "Enter Provider's name",
      placeholderEmail: 'Enter Email of a Responsible for this Provider',
      emailProvider: 'Email of a Responsible for this Provider',
      typeProvider: " Provider's type",
      fields: {
        name: 'Provider Name',
        callbackUrl: 'API Webhook URL',
        email: 'Email',
        notifyEmail: 'Notification Email',
        providerType: 'Type',
        active: 'Active',
        user: {
          username: 'Username',
          name: 'Full Name',
          email: 'Email',
        },
        config: {
          defaultStatus: {
            'assigned.text': 'Assigned Text',
            'assigned.icon': 'Assigned Icon',
            'assigned_primary.text': 'Primary Assigned Text',
            'assigned_primary.icon': 'Primary Assigned Icon',
            'closed.text': 'Closed Text',
            'closed.icon': 'Closed Icon',
            'acknowledged.text': 'Acknowledged Text',
            'acknowledged.icon': 'Acknowledged Icon',
          },
          status: {
            name: 'Status Name',
            icon: 'Status Icon',
          },
          notifications: {
            name: 'Notification',
          },
        },
      },
    },
    clients: {
      name: 'Client |||| Clients',
      fields: {
        name: 'Client Name',
        code: 'Client Code',
        nickname: 'Client Nickname',
        user: {
          username: 'Username',
          name: 'Manager Name',
          email: 'Manager email',
        },
        config: {
          minAppVersion: 'Min App Version',
        },
      },
      tabs: {
        summary: 'Summary',
        projects: 'Projects',
        sites: 'Sites',
        teams: 'Teams',
        providers: 'Monitoring Providers',
        apps: 'Apps',
        users: 'Users',
        licenses: 'Commercial',
      },
    },
    sites: {
      name: 'Site |||| Sites',
      createSite: 'New Site',
      exportSites: 'Export Sites',
      formatSites: 'Export file format',
      inactiveSite: 'Export from inactive sites',
      importSites: 'Import Sites',
      downloadExample: 'Download Example',
      importCSVLabel: 'Drag and drop your CSV here to import it, or click to select.',
      noSites: 'There are no sites for this client yet.',
      export: {
        siteName: 'Site Name',
        siteAddress: 'Site Address',
        siteZipcode: 'Site Zipcode',
        siteCity: 'Site City',
        siteRadius: 'Site Radius',
        siteTag: 'Site Tag',
        siteState: 'Site State',
        siteReferencePoint: 'Site Reference Point',
        siteApp: 'Site App',
        siteAppCode: 'Site App Code',
        siteCountry: 'Site Country',
        siteLatitude: 'Site Latitude',
        siteLongitude: 'Site Longitude',
        siteManagerName: 'Site Manager Name',
        smTelCountryCode: 'Site Manager Phone Country Code',
        smTelAreaCode: 'Site Manager Phone Area Code',
        smTelNum: 'Site Manager Phone',
        smJobTitle: 'Site Manager Job',
        smEmail: 'Site Manager Email',
      },
      fields: {
        Project: {
          name: 'Project',
        },
        name: 'Site Name',
        radius: 'Search radius',
        registrationCodes: 'Registration Codes',
        app: 'App',
        appId: 'App',
        location: {
          formatted: 'Address',
          address: 'Street',
          lat: 'Latitude',
          lng: 'Longtitude',
        },
        referencePoint: 'Reference Point',
        user: {
          email: 'Site Manager Email',
          username: 'Site Manager Username',
        },
        panel: {
          profileProps: {
            job: 'Job Title',
            phone: 'Phone number',
            email: 'Email',
          },
          name: 'Name',
          status: 'Status',
        },
      },
    },
    teams: {
      name: 'Team |||| Teams',
      create: 'New Team',
      noDataMessage: 'There are no teams for this client yet.', //traduzir aqui
      fields: {
        name: 'Team Name',
        timezone: 'Timezone',
        appId: 'App',

        panel: {
          profileProps: {
            email: 'Team Manager Email',
            job: 'Job Title',
            phone: 'Phone number',
          },
          name: 'Name',
          status: 'Status',
        },
      },
    },
    maps: {
      name: 'Mobile Profile |||| Mobile Profiles',
      fields: {
        name: 'Name',
        profileProps: {
          job: 'Job Title',
          phone: 'Phone number',
          email: 'Email',
        },
        active: 'Active',
        siteManager: 'Site Manager',
        appVersion: 'Version',
        SiteId: 'Site/Team',
      },
    },
    'mobile-users': {
      name: 'Mobile User |||| Mobile Users',
      fields: {
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        active: 'Active',
      },
    },
    users: {
      titleUser: 'Create new User',
      emailUser: 'E-mail',
      titleEdit: 'Edit User',
      create: 'New User',
      nameUser: 'Name',
      usernameUser: 'Username',
      password: 'Password',
      user: 'User',
      office: 'Office',
      passwordConfirm: 'Confirm Password',
      localteam: 'Local/Team',
      passwordUpdated: 'Password updated successfully! Now just login!',
      name: 'User |||| Users',
      phone: 'phone',
      email: 'email',
      placeholderName: 'Full Name of user',
      placeholderUsername: 'Insert the username of user',
      placeholderEmail: 'E-mail of user',
      placeholderPassword: 'Password of user',
      placeholderConfirmPassword: 'Confirm your Password',

      errors: {
        fields: {
          required: "Name can't be blank",
          passwordsDontMatch: 'Passwords do not match',
        },
        external: {
          INVALID_OLD_PASSWORD: 'Invalid current password.',
          INTERNAL_ERROR: 'Something went wrong, try again later',
          INVALID_PASSWORD: 'Invalid password.',
          MISSING_TOKEN: 'User not authorized.',
          VERIFICATION_INVALID_CODE: 'Invalid code.',
        },
      },
      fields: {
        name: 'Name',
        userType: 'Type',
        username: 'Username',
        email: 'Email',
        active: 'Active',
        phone: 'Phone number with country code (Ex.: +5592999999999)',
        mfaMethod: 'MFA Method',
        Client: {
          name: 'Client',
        },
        ClientId: 'Client',
        SiteId: 'Sites',
        MonitoringProviderId: 'Monitoring Providers',
        id: 'Id',
        role: 'Role',
        password: 'Password',
        passwordConfirm: 'Password confirmation',
        token: 'API token',
        verify: 'Verify',
      },
    },
    'license-comments/sasi': {
      commentTitle: 'Create new commercial comment for client of',
      noCommercialMessage: 'There are no commercial comments created for this customer yet.',
      create: 'New Commercial',
      name: 'Commercial |||| Commercial',
      createTitle: 'New Commercial Term',
      editTitle: 'Edit Commercial Term',
      client: 'Client',
      phases: {
        pilot: 'Pilot',
        contract: 'Contract',
        price_proposal: 'Price Proposal',
        implementation: 'Implementation',
        other: 'Other',
      },
      fields: {
        Project: {
          name: 'Project',
        },
        title: 'Title',
        titlePlaceholder: 'Title of this comment.',
        responsible: 'Responsible',
        responsiblePlaceholder: "Responsible's name for this comment.",
        startDate: 'Agreement start date',
        endDate: 'Agreement end date',
        lastUpdatedBy: 'Last Update by',
        comment: 'Comment',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        active: 'Active',
        phase: 'Phase',
        phaseComment: 'Phase Comment',
      },
    },
    profiles: {
      title: 'Personal information',
      add: 'Add',
      update: 'Update',
      config: 'Configure',
      multiFactorAuth: 'Multifactor Authentication',
      fields: {
        name: 'Name',
        user: 'User',
        password: 'Password',
        email: 'E-mail',
        emailNotFound: 'You have not added an e-mail yet.',
        phone: 'Phone number',
        phoneNotFound: 'You have not added an phone number yet.',
      },
    },
  },
  actionButtons: {
    edit: 'Edit',
    delete: 'Delete',
    saveChanges: 'Save Changes',
    save: 'Save',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    create: 'Create',
    define: 'Set as Default',
  },
  appTitle: 'SASI Admin',
  consts: {
    days: '%{smart_count} day |||| %{smart_count} days',
  },
  locales: {
    'en-US': 'English',
    'pt-BR': 'Portuguese',
  },
  fields: {
    title: 'Title',
    icon: 'Icon',
    nameIcon: 'Name',
    actionsIcon: 'Actions',
    publishedAt: 'Publish Date',
    contentId: 'Content ID',
    autoApprove: 'Automatic approve',
    form: 'Form',
    handler: 'Handler',
    recieveTestNotifications: 'Receive Test Notifications',
    messageType: 'Message Type',
    intercomMode: 'Intercom Mode',
    date: 'Date',
    rpi: 'RPI',
    status: 'Status',
    client: 'Client',
    site: 'Site',
    team: 'Team',
    siteTeam: 'Site/Team',
    app: 'App',
    name: 'Name',
    active: 'Active',
    test: 'Test',
    channel: 'Channel',
    alertDate: 'Alert Date',
    notification: 'Notification',
    contact: 'Contact',
    map: 'Map',
    referencePoint: 'Reference Point',
    customStatuses: 'Custom Statuses',
    notifications: 'Notifications',
    images: 'Images',
    showInactive: 'Show Inactive',
    responsible: 'Responsible',
    comment: 'Comment',
    location: 'Location',
    copyFrom: 'Copy Config From',
    copySurveys: 'Copy Surveys',
    copyStyling: 'Copy Client Customization',
    createdAt: 'Created',
    updatedAt: 'Updated',
  },
  userTypes: {
    sasi: 'SASI',
    client: 'Client',
    clientManager: 'Client Manager',
    'monitoring-provider': 'Monitoring Provider',
  },
  mapStatuses: {
    deactivated: 'Deactivated',
    active: 'Active',
    pending: 'Pending',
  },
  actions: {
    addRule: 'Add rule',
    addMatchingRule: 'Add Form-Data Matching',
    addApp: 'Add App',
    addGroup: 'Add Group',
    clear: 'Clear',
    import: 'Import',
    insert: 'Insert',
    paste: 'Paste',
    notifyUser: 'Notify User',
    deactivate: 'Deactivate',
    approve: 'Approve',
    discard: 'Discard',
    delete_title: 'Delete %{name}',
    block: 'Block',
    send: 'Send',
    assignProviders: 'Providers',
    assignProviderTo: 'Assign Provider to "%{channel}"',
    assignSurveys: 'Surveys',
    assignSurveyTo: 'Assign Survey to "%{channel}"',
    customize: 'Customize',
    importCsv: 'Import CSV',
    csvSample: 'Download sample XLS',
    lock: 'Lock',
    unlock: 'Unlock',
    unlockTemporary: 'Temporary Unlock UI',
    configure: 'Configure',
    dragOrClickPDF: 'Click or drag a PDF file here',
    showInactive: 'Show Inactive',
    settings: 'Settings',
  },
  placeholders: {
    search: 'Search',
  },
  roles: {
    sasi: {
      admin: 'Admin',
      root: 'Super User',
      clientManager: 'Client Manager',
      guest: 'Guest',
      broadcastOperator: 'Broadcast Operator',
    },
    'monitoring-provider': {
      admin: 'Admin',
      operator: 'Operator',
    },
    client: {
      admin: 'Client Manager',
      manager: 'Manager',
      approver: 'Mobile Profile Approver',
    },
    map: {
      worker: 'Site Worker',
      manager: 'Manager',
    },
  },
  filters: {
    userType: 'User Type',
    from: 'Date From',
    until: 'Date Until',
    scale: 'Scale',
  },
  table: {
    rowsPerPage: 'Rows per page:',
    displayedRows: '%{from}-%{to} of',
    displayedRowsTooMany: 'more than %{to}',
  },
  tabs: {
    registration: 'Registration',
    projects: 'Projects',
    summary: 'Summary',
    preview: 'Preview',
    results: 'Results',
    surveys: 'Surveys',
    users: 'Users',
    password: 'Password',
    properties: 'Properties',
    details: 'Details',
    sites: 'Sites',
    teams: 'Teams',
    maps: 'MAPs',
    client_manager: 'Client Manager',
    site_manager: 'Site Manager',
    team_manager: 'Team Manager',
    manager: 'Manager',
    apps: 'Apps',
    channels: 'Channels',
    sitesGroups: 'Sites / Apps',
    channelsGroups: 'Channels / Apps',
    providers: 'Monitoring Providers',
    providersAssignments: 'Agency Assignments',
    subChannels: 'Sub Channels',
    example: 'Example',
    defaultStatuses: 'Default Statuses',
    visual: 'Visual',
    customize: 'Customize',
    apiTokens: 'Api Tokens',
    credentials: 'Credentials',
    licenses: 'Commercial',
  },
  titles: {
    id: 'Id',
    channels: 'Channels',
    lgpd: 'LGPD',
    general: 'General',
    terms: 'Terms',
    clientTerms: 'Client Terms',
    visibility: 'Visibility',
    order: 'Order',
    delete: 'Delete',
    empty: 'Empty',
    registrationData: 'Registration Data',
    registrationCodes: 'Registration Codes',
    registrationHandlers: 'Handlers',
    registrationForms: 'Forms',
    createCode: 'Create Code',
    removeFieldTitle: 'Remove field?',
    removeFieldContent: 'Do you want to remove field "%{name}"?',
    insertChannel: 'Insert Channel',
    insertApp: 'Insert App',
    cleanBuffer: 'Clean',
    reorderChannels: 'Reorder',
    reorderFields: 'Reorder Fields',
    unprocesedAlerts: 'Unassigned Alerts',
    unlockMode: 'Unlock Mode',
    search: 'Search',
    unlockDescription: 'You can temporary unlock UI for editing or globaly unlock client, \n please choose option',
    banner: 'Banner',
    baseProps: 'Base Properties',
    buttonProps: 'Button Properties',
    preview: 'Preview',
    testBroadcast: 'Test Broadcast',
    structure: 'Form',
    style: 'Style',
    // general: 'General',
    surveyMessageDefaultText: 'You have a new search result!',
    exportCSV: 'Export CSV',
    exportTSV: 'Export TSV',
    generateToken: 'Generate Token',
    generateTokenV2: 'Generate Token v2',
    system: 'System',
    blacklist: 'Black List',
    log: 'Log',
    anonymous: 'Anônimo',
    reports: 'Reports',
    config: 'Config',
    token: 'Token %{data}',
    create: 'Create %{resource}',
    edit: 'Edit %{resource}',
    runCommand: 'Run Command',
    valueError: '<Error>',
    close: 'Close',
    sync: 'Sync',
    profile: 'Profile',
    alerts: 'Alerts',
    justUpdated: 'Just Updated',
    loginAs: 'Login As',
    login: 'Login',
    logout: 'Logout',
    remove: 'Remove',
    forgotPassword: 'Forgot Password',
    uploadCsvDropzone: 'Drop your CSV file to upload or click to select',
    uploadBanner: 'Drop your Banner to upload or click to select',
    dateFrom: 'From',
    dateUntil: 'Until',
    yes: 'Yes',
    logoText: 'customizable communication',
    attachments: 'Attachments',
    addComment: 'Add Comment',
    statusChanged: 'status changed to %{status}',
    commentAdded: 'commented: %{comments}',
    notificationSent: 'notification sent: %{comments}',
    customMessage: 'Custom Message',
    apps: 'Apps',
    nearSite: 'Near - ',
    okLabel: 'OK',
    clearLabel: 'Clear',
    cancelLabel: 'Cancel',
    todayLabel: 'Today',
    pdfFileLabel: 'Upload PDF',
    admin: 'Admin',
    emailVerification: 'Verifique seu email',
    phoneVerification: 'Verifique seu número de telefone',
  },
  themes: {
    grey: 'Grey',
    blue: 'Blue',
  },
  templates: {
    create: 'New Template',
    client: 'Client',
    public: 'Public',
    recent: 'Recent',
    older: 'Older',
    name: 'Template name',
    namePlaceholder: 'Insert template name',
    description: 'Description',
    descriptionPlaceholder: 'Insert a short description.',
    tags: 'Tags',
    tagsPlaceholder: 'Add tags to facilitate the search for the template',
    visibility: 'Visible to',
    visibility_client: 'Client',
    visibility_public: 'Public',
    createFor: 'Created by',
    alert: 'Monitoring Provider Notification',
    mapRegister: 'MAP Registered',
    passwordForgot: 'Forgot Password',
    userRegister: 'User Created',
    noDataMessageTemplate: 'There are no templates yet.',
    descriptionTemplates: 'Find ready-to-use application templates that best fit your project.',
    useButton: 'Apply',
    optionsButton: "App's Options",
    channels: 'Channels',
    copyLink: 'Copy Link',
    delete: 'Delete',
    edit: 'Edit',
    duplicate: 'Duplicate',
    copyChannel: 'Copy',
    pasteChannel: 'Paste',
    paste: 'Paste Channel',
    notify: 'Link copied successfully!',
    notifyChannel: 'Channel copied successfully!',
  },
  notifications: {
    mapApproved: 'Mobile Panel has been approved',
    mapDeactivated: 'Mobile Panel has been deactivated',
    mapDiscarded: 'Mobile Panel has been discarded',
    syncCompleted: 'Sync completed',
    mapRoleUpdated: 'Map role updated',
    loginAsSuccess: 'Login Successfully',
    forgotSent: 'Emails has been sent. Please check your inbox',
    iosNotReady: 'Not yet published',
    uploadCsvComplete: 'CSV Upload completed. Please wait while file is processed',
    uploadCsvFailed: 'CSV Upload failed. Invalid csv format',
    uploadCsvValid: 'CSV valid. Preview available in "Choices" tab',
    uploadCsvInvalid: 'CSV invalid. Only allowed 2 to 3 columns',
    statusUpdated: 'Status has been updated',
    updated: 'Updated',
    surveySuccess: 'Survey has been submitted',
    channelInserted: 'Channel inserted',
    appInserted: 'App inserted',
    appPublished: 'App published',
    channelCopied: 'Channel %{name} copied',
    appCopied: 'App %{name} copied',
    profileUpdated: 'Your profile has been updated successfully!',
  },
  validation: {
    passwordConfirm: 'Password do not match',
    password: {
      tooShort: 'Password too short: minimum 8 chars',
      tooLong: 'Password too long: maximum 64 chars',
      atLeastOneUppercase: 'Invalid password: at least one uppercase letter is needed',
      atLeastOneLowercase: 'Invalid password: at least one lowercase letter is needed',
      atLeastOneNumber: 'Invalid password: at least one number is needed',
    },
    moreThan: 'Value should be more than %{source}',
    lessThan: 'Value should be less than %{source}',
    phoneNumber: 'Not valid phone number ',
    noValidEmail: 'You also need to have a valid email in order to use this method',
    noValidPhoneNumber: 'You also need to have a valid phone number in order to use this method',
    startDateAfterEnd: "Start date can't be after ending date!",
    endDateBeforeStart: "End date can't be before start date!",
  },
  messageType: {
    alert: 'Alert',
    message: 'Message',
  },
  faceRecognitionType: {
    register: 'Register',
    search: 'Search',
  },
  faceRecognitionForm: {
    before: 'Before',
    after: 'After',
  },
  adminAlertStatus: {
    assigned: 'Assigned',
    pending: 'Pending',
    notAssigned: 'Not Asssigned',
  },
  channelTypes: {
    alert: 'Alert',
    survey: 'Survey',
    bubble: 'Bubble',
    url: 'URL',
    broadcast: 'Broadcast',
    intercom: 'Intercom',
    pdf: 'PDF',
    group: 'Group Channel',
    phone: 'Telephone',
    whatsapp: 'WhatsApp',
    faceRecognition: 'Face Recognition',
  },
  intercomModes: {
    messenger: 'Messenger',
    web: 'Web',
  },
  providerTypes: {
    standard2: 'Standard2 Provider',
    standard3: 'sistema.sasi.com.br',
    bubble: 'Bubble Provider',
    email: 'Email',
    telegram: 'Telegram',
    webhook: 'Web Hook',
    api: 'Api',
  },
  handlerModes: {
    client: 'Client',
    app: 'App',
    site: 'Site',
  },
  chart_headers: {
    date: 'Date',
    month: 'Month',
    week: 'Week',
    map_register: 'Users Registered',
    map_register_total: 'Total Users Registered',
    alert: 'Messages',
    alert_total: 'Total Messages',
    map_auth: 'Activity',
  },
  dates: {
    year: 'Year',
    month: 'Month',
    date: 'Date',
    week: 'Week',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    thisYear: 'This Year',
    lastYear: 'Last Year',
  },
  help: {
    whatIsMfa:
      "After selecting any value for this field, on the next time you atempt to log in SASI Admin you'll receive a code according to the selected option, this code will be used to make your login as secure as possible.",
  },
  httpError: {
    authentication: {
      MISSING_USERNAME_OR_PASSWORD: `Missing username/password! 
      (error: %{requestId})`,
      INVALID_USERNAME_OR_PASSWORD: `Invalid username/password! 
      (error: %{requestId})`,
      INVALID_AUTHORIZATION_HEADER: `User not authenticated! 
      (error: %{requestId})`,
      INVALID_USER: `Invalid User! 
      (error: %{requestId})`,
      NO_MFA_METHOD_FOR_USER: `User has not set MFA method yet! 
      (error: %{requestId})`,
      MFA_INVALID_CODE: `Invalid Code!
      (error: %{requestId})`,
      JWT_EXPIRED_PASSWORD_RESET: `Request expired! Please, request password update again!
      (error: %{requestId})`,
      JWT_EXPIRED_PASSWORD_RESET_NO_REQUEST: `Request expired! Please, request password update again!`,
      CLIENT_LOCKED: `Not possible to perform the request because the client is locked!
      (error: %{requestId})`,
    },
    INTERNAL_ERROR: `Something went wrong! Please, try again later!
     (error: %{requestId})`,
  },
  welcomeMessages: {
    mfa: {
      title: 'Welcome to SASI!',
      text: 'For better security and access to admin features, a multi-factor authentication is required. Go to your Profile to set it up.',
      close: 'Dismiss',
    },
  },
  authentication: {
    logoutConfirm: 'Are you sure you want to logout?',
    loginRedirectConfirm: 'Are you sure you want to go back to login page?',
    goToLogin: 'Head back to login page',
    goToLoginDescription:
      "By going back to login page, you won't be logged out, but instead you may also login to a different account!",
    logout: 'Logout',
    forgotPassword:
      'Input your username in order to receive a email or phone message with information about recoverying your password.',
  },
  formErrors: {
    required: 'Required field',
    email: 'Invalid email',
  },
  ra: {
    page: {
      empty: 'There are no resources yet!',
      invite: 'To create new resources, press the button below:',
    },
  },
  cancel: 'Cancel',
  disclaimer:
    'Aviso! Os serviços do SASI não substituem a necessidade de fazer uma chamada a 190 ou 193 no caso de uma emergência. Serviços de resposta de emergência só podem ser acessados por de uma chamada direta ao 190 ou 193',
  appWelcome:
    'Welcome to SASI, our new alert system. To start using the system, download the SASI application at the link below. ',
  appPasswordRecoverySuccess: 'Your password was redefined!',
  passwordRecoveryWelcome: 'Input your new password',
  passwordRecoveryError: 'There was a problem redefining your password',
  passwordRecoverySuccess: 'Password redefined!',
  passwordLengthRequirement: 'Your password must have between 8 and 64 characters and not be empty',
  passwordSpaceRequirement: 'Your password cannot include consecutive blank spaces',
  missingSiteDataOnCSVUploadError: 'Could not save site due to missing data: %{fields}',
  couldNotLoadData: 'Could not load data properly! Try again later.',
  unauthorized: 'Invalid username/password',
  confirm: 'Confirm',
  propertyVerificationDescription:
    'A confirmation code was sent to your %{platform}! Enter the code below in order to continue.',
  mfaCodeDescription: 'A confirmation code was sent to %{mfaValue}! Enter the code below in order to continue.',
  mfaCodeWillExpire: 'Your code will expiry in:',
  mfaCodeSending: 'Your code is being sent, wait just a moment...',
  mfaCodeMistake: 'Invalid Code',
  mfaSetupDescriptionNoAvailable:
    "For better security, it's necessary that you setup Multi-factor Authentication (MFA). In order to do this, you need to choose one of the options below:",
  mfaInOrderToUse:
    'In order to use the selected option, you need to first add your %{method} and confirm it! Please, fill the value below:',
  errorSendingMfaCode: 'Something went wrong while trying to send your code, try again later!',
  errorCode: 'Error code',
  notSignedIn: "You're not signed in",
  expiredSession: 'Your session has expired: sign in and try again!',
  didntReceiveCode: "Didn't receive any code? Send it again by pressing the button below:",
  requestNewCode: 'Send code',
  clickHereToVerify: 'click here to verify!',
  emailNotVerified: 'Your email has not been verified yet,',
  phoneNotVerified: 'Your phone has not been verified yet,',
};
