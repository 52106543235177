import React from 'react';
import { FileField, FileInput } from 'ra-ui-materialui';
import { useTranslate, useDataProvider, useNotify } from 'ra-core';
import { useForm, useFormState } from 'react-final-form';

export default () => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const form = useForm();
  const { values } = useFormState();

  const handleSubmit = (rawFile) => {
    if (rawFile) {
      dataProvider
        .execute('channels/params/options', { ...values, csvMultilayer: { rawFile } })
        .then(({ data: { data } }) => {
          notify('notifications.uploadCsvValid', 'success');
          Object.keys(data).forEach((prop) => form.change(prop, data[prop]));
        })
        .catch(() => notify('notifications.uploadCsvInvalid', 'warning'));
    }
  };

  return (
    <FileInput
      source="csvMultilayer"
      label=""
      name="csvMultilayer"
      accept='text/csv'
      onChange={handleSubmit}
      placeholder={<p>{translate('titles.uploadCsvDropzone')}</p>}
    >
      <FileField source="csvMultilayer" title="title" />
    </FileInput>
  );
};
