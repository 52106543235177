import React from "react"
import { Datagrid, ArrayField, SingleFieldList, EditButton, ChipField, Filter} from "ra-ui-materialui"
import { List, QuickDeleteButton, DefaultActions, ShowLink}      from "components"
import FilterSwitch from "resources/components/FilterSwitch"
import { useRefresh } from "ra-core"
import { CreateButton} from "./components"

const SurveyListFilter = props => <Filter {...props} >
  <FilterSwitch source="showInactive" label="fields.showInactive" alwaysOn />
</Filter>

export default props => {
  const refresh = useRefresh()
  return <List {...props} 
    actions={<DefaultActions hasCreate={false} >
              <CreateButton onSubmit={refresh} />
              </DefaultActions>} 
    filters={<SurveyListFilter />}>
    <Datagrid>
      <ShowLink source="name" />
      <ArrayField source="Channels"  >
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <EditButton onSubmit={refresh} />
      <QuickDeleteButton resource="surveys" onDelete={refresh} />
    </Datagrid>
  </List>
}