import { required, number, email } from "ra-core"
import { memoize, get } from "lodash"
// import PhoneNumber from 'awesome-phonenumber'

const json = value => ((typeof value === "object") ? null : "invalid json")
const passwordConfirmValidate = (value, allValues) => {
  if (allValues.password !== undefined && value !== allValues.password) {
    return "validation.passwordConfirm"
  }
  return undefined
}

// Used when a user needs to update its profile but don't want to change it's password
const nullablePasswordValidate = val => {
  if (!val) {
    return
  }
  return passwordValidate(val);
}

const passwordValidate = (val) => {
  if (!val) {
    val = "";
  }

  if (val.length < 8) {
    return "validation.password.tooShort";
  }
  if (val.length > 64) {
    return "validation.password.tooLong";
  }
  const atLeastOneUppercase = /\w*[A-Z]\w*/;
  const atLeastOneNumber = /\w*[0-9]\w*/;
  const atLeastOneLowercase = /\w*[a-z]\w*/;
  if (!val.match(atLeastOneUppercase)) {
    return "validation.password.atLeastOneUppercase";
  }
  if (!val.match(atLeastOneLowercase)) {
    return "validation.password.atLeastOneLowercase";
  }
  if (!val.match(atLeastOneNumber)) {
    return "validation.password.atLeastOneNumber";
  }
}
const moreThan = (source, sourceName = null) =>
  (value, allValues) => {
    if (allValues[source] && value && value <= allValues[source]) {
      return ({
        message: "validation.moreThan",
        args: { source: sourceName || source },
      })
    }
    return undefined
  }
const lessThan = (source, sourceName = null) =>
  (value, allValues) => {
    if (allValues[source] && value && value >= allValues[source]) {
      return ({
        message: "validation.lessThan",
        args: { source: sourceName || source },
      })
    }
    return undefined
  }
const phone = () => (value) => {
  if (value && !value.match(/^\+?[\d]{8,20}$/)) {
    return "validation.phoneNumber"
  }
  return undefined
}

const mfaMethodValidate = () => (value, values) => {
  if (value === 'phone' && (!values.phone || phone()(values.phone, values))) {
    return "validation.noValidPhoneNumber";
  }
  if (value === 'email' && (!values.email || email()(values.email, values))) {
    return "validation.noValidEmail";
  }
  return required()(value, values);
}

const requiredIf = memoize(function (source) {
  return function (value, allValues) {
    if (get(allValues, source) && !value) {
      return "ra.validation.required"
    }
    return undefined
  }
})

export {
  required,
  phone,
  number,
  json,
  passwordConfirmValidate,
  moreThan,
  lessThan,
  requiredIf,
  passwordValidate,
  nullablePasswordValidate,
  mfaMethodValidate,
}
