import React from "react"
import { SimpleForm} from "ra-ui-materialui"
import { Edit, DefaultToolbar, required }    from "components"
import RichTextInput from "ra-input-rich-text"


export default props => {  
  return <Edit {...props} titleSource="name" >
    <SimpleForm toolbar={<DefaultToolbar />} redirect="list" >
      <RichTextInput rows={4} multiline source="content" validate={required()} />
    </SimpleForm>
  </Edit>
}