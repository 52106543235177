import React from 'react';

import { makeStyles } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { Card } from 'components/designSystem/Card';
import { Tip } from 'components/designSystem/Tip';
import { ArrowDownwardSharp } from '@material-ui/icons';
import { TextField } from 'components/designSystem/inputs/TextField';
import { ClientSitesMap } from '../components/ClientSitesMap';
import { LabeledSwitch } from 'components/designSystem/LabeledSwitch';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
}));

const useContentStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: theme.spacing(3),
    '& > div': {
      flex: 1,
    },
  },
  clientInfoContainer: {
    boxSizing: 'borderBox',
    paddingRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.body1.fontSize,
    margin: 0,
    marginBottom: theme.spacing(2),
  },
  activeSwitch: {
    justifyContent: 'flex-end',
    transform: 'translateX(-9px)',
  },
  content: {
    paddingBottom: '14px',
  },
}));

const Header = () => {
  const t = useTranslate();
  return (
    <div>
      <Tip icon={<ArrowDownwardSharp />} label={t('resources.summary.advice')} />
    </div>
  );
};

const Content = ({ client }) => {
  const t = useTranslate();

  const classes = useContentStyles();
  return (
    <div className={classes.contentContainer}>
      <div className={classes.clientInfoContainer}>
        <h1 className={classes.title}>{t('resources.apps.fields.config.general')}</h1>
        <div className={classes.content}>
          <TextField disabled size="medium" value={client?.name} label={t('resources.summary.nameClient')} />
        </div>
        <div className={classes.content}>
          <TextField disabled size="medium" value={client?.nickname} label={t('resources.summary.nicknameClient')} />
        </div>

        <LabeledSwitch
          className={classes.activeSwitch}
          disabled
          reverse
          label={
            client?.active === undefined ? '' : !!client?.active ? t('resources.projects.buttons.active') : 'Desativo'
          }
          value={!!client?.active}
          onChange={() => {
            console.log('CHANGE');
          }}
        />
        {client?.config?.minAppVersion && (
          <div className={classes.content}>
            <TextField
              disabled
              size="medium"
              value={client?.config?.minAppVersion}
              label={t('resources.summary.versionClient')}
            />
          </div>
        )}
      </div>
      {client && <ClientSitesMap client={client} />}
    </div>
  );
};

export const ClientSummary = ({ client }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Card>
        <Header />
        <Content client={client} />
      </Card>
    </div>
  );
};
