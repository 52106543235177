import React from 'react';
import { makeStyles } from '@material-ui/core';
import ProfileImage from 'layout/components/ProfileImage';
import { useLocale, useTranslate } from 'ra-core';

const useStyles = makeStyles((theme) => ({
  userInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.tertiary,
    marginBottom: theme.spacing(1),
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: theme.spacing(1),
  },
  lastUpdate: {
    fontSize: theme.typography.caption.fontSize,
  }
}))

export const TemplateUserInfo = ({ template }) => {
  const classes = useStyles();
  const locale = useLocale();
  const t = useTranslate();

  if (!template?.user) {
    return <div></div>;
  }

  const updatedAt = new Date(template.updatedAt).toLocaleDateString(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  })
  return (
    <div className={classes.userInfoContainer}>
      <ProfileImage profile={template.user} variant="menu" />
      <div className={classes.info}>
        <span>{template.user.name || template.user.username}</span>
        <span className={classes.lastUpdate}>
          {t('resources.apps/templates.lastUpdatedAt', { date: updatedAt })}
        </span>
      </div>
    </div>
  );
}
