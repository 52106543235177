import { makeStyles } from "@material-ui/styles";
import { WarningRounded } from "@material-ui/icons";
import React from "react";

const useStyle = makeStyles(() => ({
  container: {
    padding: '6px 16px',
    display: 'flex',
    background: '#d44660',
    borderRadius: '4px',
    fontSize: '0.875rem',
    color: '#fff',
    fontWeight: '500',
    margin: '1rem 0',
  },
  textWrapper: {
    padding: '0.5rem 0.3rem',
    margin: '0',
    cursor: 'inherit',
    userSelect: 'none',
  },
}));

export const ErrorCard = ({ message }) => {
  const classes = useStyle();

  return (
    <div className={classes.container}>
      <div className={classes.textWrapper}>
        <WarningRounded/>
      </div>
      <div className={classes.textWrapper}>
        {message}
      </div>
    </div>
  );
}
