import { useTranslate } from 'ra-core';
import { QuickEditButton, ReferenceMultiGrid } from '../../../components';
import { SimpleForm, TextField } from 'ra-ui-materialui';
import React from 'react';

const AssignProviderButton = ({ record, ...props }) => {
  const translate = useTranslate();
  const ClientId = props.ClientId || record.ClientId;
  const title = translate('actions.assignProviderTo', { channel: record.name });

  return (
    <QuickEditButton {...props} resource="channels" record={record} title={title} label="actions.assignProviders">
      <SimpleForm toolbar={null} resource="channels" record={record}>
        <ReferenceMultiGrid
          reference="monitoring-providers"
          source="MonitoringProviderIds"
          withFilter
          filter={{ ClientId, includeGlobal: !props.clientOnly }}
        >
          <TextField source="id" />
          <TextField source="name" />
        </ReferenceMultiGrid>
      </SimpleForm>
    </QuickEditButton>
  );
};

export default AssignProviderButton;
