import React from "react"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ProfileImage from "layout/components/ProfileImage"
import { makeStyles } from "@material-ui/styles";
import {get, fromPairs} from "lodash"
import DynamicValueField from "components/DynamicValueField";

const useStyles = makeStyles({
  card: {
    display: 'inline-flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250
  },
  content: {
    flex: '1 0 auto',
  },
})


export default function ContactCard ({ record: { name, profileProps, meta = {} } }){
  const classes = useStyles();
  const keys = Object.keys(profileProps)
  const schema = fromPairs(get(meta.schema, "fields", []).map(field => [field.name, field]))
  return <Card className={classes.card}>
    <div className={classes.details}>
      <CardContent className={classes.content}>
        <Typography component="h6" variant="h6">{name}</Typography>
        {keys.map(key =>
          <Typography key={key} variant="subtitle1" color="textSecondary">
            <span>{get(schema[key], "title", key)}:</span>
             &nbsp;     
             <DynamicValueField record={profileProps} source={key}/>
          </Typography>
        )}
      </CardContent>
    </div>
    <ProfileImage profile={{ email: profileProps.email || "noemail@example.com" }} variant="card" />
  </Card>
}