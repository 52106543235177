import React, { useRef } from 'react';

import { BackButtonWithWrapper } from 'components/designSystem/BackButton';
import { ShowTitle } from 'components/designSystem/ShowTitle';
import { useRefresh, useShowController, useTranslate } from 'ra-core';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { Menu as MenuIcon } from '@material-ui/icons';
import { ShowAppTabOrder } from './Show.model';
import { useTabs } from 'hooks/use-tabs';
import { useRouter } from 'hooks';
import { Channels } from './views/Channels';
import { Settings } from './views/Settings';
import { Versions } from './views/Versions';
import { useState } from 'react';
import { PublishAppDialog } from './components/PublishAppDialog';
import { DeleteAppDialog } from './components/DeleteAppDialog';
import { CreateTemplateDialog } from 'resources/Templates/components/CreateTemplateDialog';

const useSubHeaderStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  description: {
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.tertiary,
    margin: 0,
    marginRight: theme.spacing(1),
    wordBreak: 'break-word',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const SubHeader = ({ app, items, handlePublish, handleDelete, handleCreateTemplate }) => {
  const classes = useSubHeaderStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const t = useTranslate();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.container}>
      <p className={classes.description}>{app?.description}</p>
      <div className={classes.headerContainer}>
        {items}
        <TertiaryButton disabled={!app} icon={<MenuIcon />} onClick={handleOpen} />
        <Menu id="app-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              handleClose();
              handlePublish();
            }}
          >
            {t('resources.apps.fields.publishApp')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              handleCreateTemplate();
            }}
          >
            {t('resources.apps.fields.salveTemplate')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              handleDelete();
            }}
          >
            {t('resources.apps.fields.deleteApp')}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export const Show = (props) => {
  const t = useTranslate();
  const showController = useShowController(props);
  const app = showController.record;
  const refreshApp = useRefresh();
  const { history } = useRouter();

  const isEditing = useRef(false);
  const triggerSave = useRef(() => {});

  const [tab, setTab] = useTabs(ShowAppTabOrder, { defaultTab: 'settings' });
  const [menuItems, setMenuItems] = useState([]);
  const [customRefresh, setCustomRefresh] = useState(() => {});
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showSaveTemplateDialog, setShowSaveTemplateDialog] = useState(false);
  const [selectedAppSchema, setSelectedAppSchema] = useState();

  const handleTabChange = (tab) => {
    setTab(tab);
    setSelectedAppSchema();
    triggerSave.current = () => {};
    isEditing.current = false;
  };

  return (
    <div>
      {app && (
        <PublishAppDialog
          appId={app.id}
          isOpen={showPublishDialog}
          close={() => setShowPublishDialog(false)}
          refresh={() => {
            refreshApp();
            if (customRefresh && typeof customRefresh === 'function') {
              customRefresh();
            }
          }}
        />
      )}
      {app && (
        <DeleteAppDialog
          app={app}
          isOpen={showDeleteDialog}
          close={() => setShowDeleteDialog(false)}
          refresh={() => history.push(`/clients/${app.clientId}/show?tab=apps`)}
        />
      )}
      {app && (
        <CreateTemplateDialog
          isOpen={showSaveTemplateDialog && !isEditing.current}
          close={() => setShowSaveTemplateDialog(false)}
          app={app}
          schema={selectedAppSchema}
        />
      )}
      <BackButtonWithWrapper />
      <ShowTitle name={app?.name} />
      <SubHeader
        app={app}
        items={menuItems}
        handlePublish={() => setShowPublishDialog(true)}
        handleDelete={() => setShowDeleteDialog(true)}
        handleCreateTemplate={() => {
          if (isEditing.current) {
            triggerSave.current(() => {
              setShowSaveTemplateDialog(true);
            });
          } else {
            setShowSaveTemplateDialog(true);
          }
        }}
      />
      <Tabs value={tab} onChange={(event, value) => handleTabChange(value)}>
        <Tab value={ShowAppTabOrder['settings']} label={t('resources.apps.settingsTabs.theme')} />
        <Tab value={ShowAppTabOrder['channels']} label={t('resources.apps.settingsTabs.channels')} />
        <Tab value={ShowAppTabOrder['versions']} label={t('resources.apps.settingsTabs.versions')} />
      </Tabs>
      {tab === ShowAppTabOrder['channels'] && (
        <Channels setMenuItems={setMenuItems} app={app} setCustomRefresh={setCustomRefresh} />
      )}
      {tab === ShowAppTabOrder['versions'] && (
        <Versions
          refresh={refreshApp}
          setCustomRefresh={setCustomRefresh}
          setMenuItems={setMenuItems}
          app={app}
          handleNewTemplate={(schema) => {
            setSelectedAppSchema(schema);
            setShowSaveTemplateDialog(true);
          }}
        />
      )}
      {tab === ShowAppTabOrder['settings'] && (
        <Settings
          refresh={refreshApp}
          setMenuItems={setMenuItems}
          app={app}
          setEditing={(editing) => {
            isEditing.current = editing;
          }}
          parentSave={triggerSave}
        />
      )}
    </div>
  );
};
