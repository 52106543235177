import { SET_APP_SCHEMAS } from '../actions/appSchemas';

export const appSchemasReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_APP_SCHEMAS:
      return {
        ...state,
        appSchemas: payload,
      };
    default:
      return undefined;
  }
};
