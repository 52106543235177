import React, { useState, useCallback, useEffect } from 'react';
import {
  TextInput,
  SelectInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  Button,
  Confirm,
} from 'ra-ui-materialui';
import { required } from 'components';
import { makeStyles } from '@material-ui/styles';
import OptionsInput from './OptionsInput';
import { ReorderDialog } from 'resources/components/Reoder';
import { useFormState, useForm } from 'react-final-form';
import { get, find, each, unset, compact } from 'lodash';
import ButtonBase from '@material-ui/core/ButtonBase';
import Icon from '@material-ui/core/Icon';
import { useTranslate, useDataProvider } from 'ra-core';

const useButtonStyles = makeStyles({
  fieldRender: {
    padding: 16,
    width: 300,
    maxWidth: 300,
    minWidth: 300,
    margin: 10,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
    fontWeight: 500,
  },
});
const useStyles = makeStyles((theme) => ({
  schema: {
    display: 'flex',
    '& .ra-input': {
      marginRight: 10,
      display: 'flex',
    },
  },
  schemaAction: {
    display: 'flex',
    padding: 0,
  },
  typeInput: {
    width: theme.spacing(16),
    marginRight: 5,
    minWidth: 150,
  },
  schemaInput: {
    width: theme.spacing(16),
    marginRight: 5,
    minWidth: 200,
  },
  shortInput: {
    width: theme.spacing(16),
    marginRight: 5,
    maxWidth: 50,
  },
  schemaBoolean: {
    width: theme.spacing(16),
    marginRight: 5,
    marginTop: 20,
  },
  schemaBoolean2: {
    width: theme.spacing(20),
    marginRight: 5,
    marginTop: 20,
  },
  schemaInputText: {
    width: theme.spacing(16),
    marginRight: 5,
  },
}));

const checkNonAlphaNumberUnderscoreSpace = (str) => !/^[\w\d\s_]+$/.test(str);
const checkNonAlphaNumberUnderscore = (str) => !/^[\w\d_]+$/.test(str);

const FieldRender = ({ field }) => {
  const classes = useButtonStyles();
  return <ButtonBase className={classes.fieldRender}>{field.title || field.name}</ButtonBase>;
};

const RemoveButton = ({ source }) => {
  const { values } = useFormState();
  const form = useForm();
  const translate = useTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const onDelete = () => {
    unset(values, source);
    const baseSource = source.replace(/\[\d+\]$/, '');
    form.change(baseSource, compact(get(values, baseSource)));
    close();
  };
  return (
    <>
      <Button size="small" onClick={open}>
        <Icon>delete</Icon>
      </Button>
      <Confirm
        isOpen={isOpen}
        title={translate('titles.removeFieldTitle')}
        content={translate('titles.removeFieldContent')}
        translateOptions={{
          name: get(values, `${source}[name]`, ''),
        }}
        onConfirm={onDelete}
        onClose={close}
      />
    </>
  );
};

export default function SchemaInput({ readonly, allowComments, source, ...props }) {
  const form = useForm();
  const classes = useStyles();
  const translate = useTranslate();
  const { values } = useFormState();
  const dataProvider = useDataProvider();
  const [options, setOptions] = useState({ types: [] });

  useEffect(() => {
    dataProvider.fetch('channels/params/options').then(({ data: { data } }) => setOptions(data));
  }, []); //eslint-disable-line

  const fieldsValues = get(values, source, []);
  const inputProps = {
    disabled: readonly,
    options: { disabled: readonly },
    readOnly: readonly,
  };
  const onReorder = useCallback((fields) => form.change(source, fields), [form, source]);
  const onTypeChanged = (event) => {
    const { defaults = {} } = find(options.types, ['id', event.target.value]) || {};
    const base = event.target.name.replace(/\.type/, '');
    each(defaults, (defaultValue, field) => {
      const value = get(values, `${base}.${field}`);
      if (!value) form.change(`${base}.${field}`, defaultValue);
    });
  };

  const tagValidation = (value) => {
    if (value && checkNonAlphaNumberUnderscoreSpace(value)) {
      return translate('resources.channels.schema.invalidErrorText');
    }

    const maxLength = 32;
    if (value && value.length > maxLength) {
      return translate('resources.channels.schema.maxLength', { max: maxLength.toString() });
    }
  };

  const nameValidation = (value, values) => {
    const isNotFilledError = required()(value, values);
    const schemaFields = values?.config?.schema?.fields ?? values?.schema?.fields ?? [];

    if (isNotFilledError) {
      return isNotFilledError;
    }

    const maxLength = 55;
    if (value.length > maxLength) {
      return translate('resources.channels.schema.maxLength', { max: maxLength.toString() });
    }

    if (checkNonAlphaNumberUnderscore(value)) {
      return translate('resources.channels.schema.invalidErrorText');
    }

    // Check whether there's another field with the same name.
    if (schemaFields.filter((f) => f && f.name === value).length > 1) {
      return translate('resources.channels.schema.nonUniqueNameErrorText');
    }
  };

  return (
    <ArrayInput source={source} {...props} label={''} {...inputProps}>
      <SimpleFormIterator
        classes={{ form: classes.schema, action: classes.schemaAction }}
        {...inputProps}
        disableRemove={true}
        disableAdd={readonly}
        TransitionProps={{ timeout: 0, classNames: 'back' }}
      >
        <TextInput
          source="name"
          {...inputProps}
          className={classes.schemaInput}
          required
          label="resources.channels.schema.name"
          validate={nameValidation}
          validateFields={[]}
        />
        <TextInput
          source="tag"
          {...inputProps}
          className={classes.shortInput}
          label="resources.channels.schema.tag"
          validate={tagValidation}
          validateFields={[]}
        />
        <SelectInput
          source="type"
          {...inputProps}
          className={classes.typeInput}
          allowEmpty
          choices={options.types}
          defaultValue=""
          label="resources.channels.schema.type"
          onChange={onTypeChanged}
          validate={required()}
        />
        <TextInput
          source="title"
          {...inputProps}
          className={classes.schemaInput}
          label="resources.channels.schema.title"
        />
        <OptionsInput source="options" readonly={readonly} allowComments={allowComments} options={options} />
        <BooleanInput source="required" className={classes.schemaBoolean} label="resources.channels.schema.required" />
        <BooleanInput
          source="pageBreak"
          className={classes.schemaBoolean2}
          label="resources.channels.schema.pageBreak"
        />
        {!readonly && (
          <ReorderDialog
            title={translate('titles.reorderFields')}
            items={fieldsValues}
            render={(field) => <FieldRender field={field} />}
            onReorder={onReorder}
          />
        )}
        {!readonly && <RemoveButton />}
      </SimpleFormIterator>
    </ArrayInput>
  );
}
