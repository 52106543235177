import { useSelector } from 'react-redux';
import useRouter from './use-react-router';
import { get } from 'lodash';
import { useAccount } from 'hooks';
import { useDataProvider } from 'ra-core';
import { useEffect, useState } from 'react';
import Storage from 'lib/storage';

export default function useRecord(resource, options) {
  const { paramOnly, resourceId } = options || {};
  const {
    match: {
      params: { id },
    },
  } = useRouter();

  const record = useSelector((state) => {
    if (!resourceId && paramOnly) {
      return undefined;
    }

    return get(state, `admin.resources.${resource}.data.${resourceId || id}`);
  });

  return record;
}

export function useClient() {
  const account = useAccount() || {};
  const dataProvider = useDataProvider();
  const [client, setClient] = useState(Storage.getObject('client') || {});

  useEffect(() => {
    if (account.ClientId && account.ClientId !== client.id) {
      dataProvider.getOne('clients', { id: account.ClientId }).then(({ data }) => {
        Storage.setObject('client', data);
        setClient(data);
      });
    } else if (!account.ClientId) {
      setClient({});
    }
  }, [dataProvider, account.ClientId, client.id, setClient]);

  return [client, setClient];
}
