import React from 'react';
import { useRefresh } from 'ra-core';
import {
  TextField,
  Datagrid,
  FunctionField,
  ReferenceManyField,
  Pagination,
  TextInput,
  SimpleForm,
} from 'ra-ui-materialui';
import { QuickCreateButton, QuickEditButton } from 'components';
import { omitBy, isEmpty } from 'lodash';
import { Card, CardContent, Icon, Typography } from '@material-ui/core';
import DeleteWithConfirmButton from 'components/QuickDeleteButton';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  card: {
    display: 'inline-flex',
  },
  title: {
    fontSize: 14,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250,
  },
  content: {
    flex: '1 0 auto',
  },
});
const ProfileProps = ({ record: { profileProps } }) => {
  const classes = useStyles();
  const keys = Object.keys(profileProps);
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        {keys.map((key) => (
          <div key={key}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {key}
            </Typography>
            <Typography variant="subtitle1">{profileProps[key]}</Typography>
          </div>
        ))}
      </CardContent>
    </Card>
  );
};
const Edit = ({ record, ...props }) => {
  if (record) {
    const keys = Object.keys(omitBy(record.profileProps, (value, key) => isEmpty(key)));
    return (
      <QuickEditButton {...props} resource="registration-data" record={record} icon={<Icon>edit</Icon>}>
        <SimpleForm toolbar={null} resource="registration-data" record={record}>
          <TextInput source="key" />
          <TextInput source="tags" />
          {keys.map((key) => (
            <TextInput key={key} label={key} source={`profileProps.${key}`} />
          ))}
        </SimpleForm>
      </QuickEditButton>
    );
  }
  return <span />;
};

export function Create({ record, ...props }) {
  if (record) {
    const keys = record.schema.fields.filter((x) => !isEmpty(x));
    return (
      <QuickCreateButton
        defaultValues={{ RegistrationDataSchemaId: record.id }}
        {...props}
        resource="registration-data"
        label={'ra.action.create'}
        icon={<Icon>edit</Icon>}
      >
        <SimpleForm toolbar={null} resource="registration-data">
          <TextInput source="key" />
          <TextInput source="tags" />
          {keys.map((key) => (
            <TextInput key={key} label={key} source={`profileProps.${key}`} />
          ))}
        </SimpleForm>
      </QuickCreateButton>
    );
  }
  return <span />;
}
export default function RegistrationData({ record, readonly, ...props }) {
  const refresh = useRefresh();
  return (
    <ReferenceManyField
      {...props}
      record={record}
      addLabel={false}
      basePath="/registration-data"
      reference="registration-data"
      target="RegistrationDataSchemaId"
      pagination={<Pagination />}
    >
      <Datagrid expand={ProfileProps}>
        <TextField source="id" />
        <TextField source="key" />
        <FunctionField
          source="tags"
          render={(record) => (record.tags && record.tags.join ? record.tags.join(', ') : '')}
        />
        {!readonly && <Edit />}
        {!readonly && (
          <FunctionField
            render={(regData) => (
              <DeleteWithConfirmButton
                resource="registration-data"
                record={regData}
                onDelete={refresh}
                title="actions.delete_title"
                translateOptions={{ name: regData.key }}
              />
            )}
          />
        )}
      </Datagrid>
    </ReferenceManyField>
  );
}
