import React from "react";
import { withGoogleApi } from "components"
import { compose, withProps } from "recompose"
import {  withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const enchance = compose(
  withProps({
    containerElement: <div style={{ height: "400px", width: "400px" }} />,
    mapElement: <div style={{ height: "100%" }} />,
  }),
  withGoogleApi,
  withGoogleMap
)

const SitesMap = enchance(({zoom, sites}) => {
  const google = window.google
  const bounds = new google.maps.LatLngBounds()
  sites.forEach(site => {
    bounds.extend(new google.maps.LatLng(site.lat, site.lng))
  })
  const onMounted = map => {
    if(map) map.fitBounds(bounds)
  }

  return  <GoogleMap defaultZoom={zoom || 8} defaultCenter={sites[0]} ref={onMounted} >
    {sites.map(site => <Marker position={site} key={`site${site.id}`} />  )}
  </GoogleMap>
})

const SitesMapRender = ({zoom, data}) => {
  if(!data) {
    return <span />
  }
  const sites = Object.values(data)
  const sitesWithLocations = sites.filter(site => site.lat && site.lng)
  return sitesWithLocations.length > 0 ?
    <SitesMap zoom={zoom} sites={sitesWithLocations} /> :
    <span />
}

export default SitesMapRender
