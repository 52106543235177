import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { ArrowBack } from '@material-ui/icons';

const useBackButtonStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 10,
  },
  backButton: {
    border: 'none',
    background: theme.palette.background.default,
    outline: 'none',
    transition: 'background .2s',
    borderRadius: '100px',
    position: 'relative',
    transform: 'translateX(-8px)',
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.background.surface.pressed,
    },
  },
  backButtonLogo: {
    width: 20,
    color: theme.palette.text.tertiary,
  },
}));

export const BackButton = () => {
  const history = useHistory();
  const classes = useBackButtonStyles();

  return (
    <button className={classes.backButton} onClick={() => history.goBack()}>
      <ArrowBack className={classes.backButtonLogo} />
    </button>
  );
};

export const BackButtonWithWrapper = () => {
  const classes = useBackButtonStyles();
  return (
    <div className={classes.wrapper}>
      <BackButton />
    </div>
  );
};
