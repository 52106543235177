import { genericErrorHandler } from 'lib/Errors';

export const publishAppSchema = (dataProvider, appId, setLoading, handleClose, refresh, notify) => async (data) => {
  setLoading(true);
  try {
    await dataProvider.execute(`apps/${appId}/publish`, data);
  } catch (err) {
    genericErrorHandler(notify)(err);
  }
  if (refresh) {
    refresh();
  }
  setLoading(false);
  handleClose();
};
