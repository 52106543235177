import React, { useCallback } from 'react';
import Field from './Field';
import { Button, makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import { useForm, useFormState } from 'react-final-form';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles({
  child: {
    marginLeft: '1rem',
  },
  condition: {
    border: '1px solid #CCC',
    padding: 5,
  },
});

const cleanTree = ({ fieldMatches, ...rest }) => {
  if (fieldMatches) {
    return { ...rest, fieldMatches: fieldMatches.map(cleanTree) };
  }
  return { ...rest };
};

export default function MatchConfigBuilder({ formFields = [], dataFields = [], source }) {
  const translate = useTranslate();
  const classes = useStyles();
  const form = useForm();
  const { values } = useFormState();
  const fieldMatchesPath = `${source}.fieldMatches`;
  const handleChange = useCallback((data) => form.change(source, cleanTree({ ...data })), [form, source]);

  const addMatch = () => {
    const fieldMatches = [
      ...get(values, fieldMatchesPath, []),
      {
        formField: '',
        dataField: '',
      },
    ];
    form.change(fieldMatchesPath, fieldMatches);
  };

  const deleteMatch = (index) => {
    const fieldMatches = [...get(values, fieldMatchesPath, [])];
    fieldMatches.splice(index, 1);
    form.change(fieldMatchesPath, fieldMatches);
  };

  const handleChildUpdate = () => handleChange(get(values, source));

  return (
    <div className={classes.condition}>
      <Button onClick={addMatch} disabled={!(formFields.length && dataFields.length)}>
        {translate('actions.addMatchingRule')}
      </Button>
      {get(values, fieldMatchesPath, []).map((fieldMatch, index) => (
        <div className={classes.child} key={index}>
          <Field
            formFields={formFields}
            dataFields={dataFields}
            fieldMatch={fieldMatch}
            handleDelete={() => deleteMatch(index)}
            onChange={handleChildUpdate}
          />
        </div>
      ))}
    </div>
  );
}
