import React from 'react';

import { TableRowActionButton } from 'components/designSystem/TableRowActionButton';

export const projectsColumns = (onEdit, onDelete) => (t) =>
  [
    {
      key: 'client_projects_table_column_name',
      id: 'name',
      field: (record) => {
        let name = record.name;
        if (record.name === 'default' && record.isDefault) {
          name = t('resources.projects.default');
        }
        return name;
      },
      header: t('resources.projects.name'),
    },
    {
      key: 'client_projects_table_column_description',
      id: 'description',
      field: (record) => {
        let description = record.description;
        if (record.name === 'default' && record.isDefault) {
          description = t('resources.projects.defaultDescription');
        }
        return description;
      },
      header: t('resources.projects.description'),
    },
    {
      key: 'client_projects_table_column_actions',
      id: 'actions',
      field: (record) =>
        !record.isDefault &&
        !record.deletedAt && (
          <div>
            <TableRowActionButton action="edit" record={record} onClick={onEdit} />
            {record.deletable && <TableRowActionButton action="delete" record={record} onClick={onDelete} />}
          </div>
        ),
    },
  ];

export const loadProjects = (dataProvider, setProjects, setTotalProjects, clientId, filters, dispatch, setLoading) => {
  setLoading(true);
  dataProvider
    .getList('projects', {
      filter: {
        ClientId: clientId,
        showDeleted: filters.showInactive,
        limit: filters.limit,
        offset: filters.offset,
        ...(filters.order && filters.orderBy
          ? {
              order: `${filters.orderBy} ${filters.order.toUpperCase()}`,
            }
          : {}),
      },
    })
    .then(({ data, total }) => {
      setLoading(false);
      dispatch(data);
      setProjects(data);
      setTotalProjects(total);
    })
    .catch((err) => {
      setLoading(false);
      throw err;
    });
};
