import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  profilePropContainer: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center',
    marginBottom: '1.2rem',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
  },
  profilePropWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  profilePropLabel: {
    textTransform: 'uppercase',
    fontSize: '.75rem',
    fontFamily: 'Roboto, Nunito, sans-serif',
    fontWeight: '700',
    color: '#1C1B1F',
    marginBottom: '.3rem',
  },
  profilePropValue: {
    fontSize: '1rem',
    fontFamily: 'Roboto, Nunito, sans-serif',
    color: '#1C1B1F',
  },
  profilePropDisabled: {
    fontSize: '1rem',
    fontFamily: 'Roboto, Nunito, sans-serif',
    color: 'rgba(28, 27, 31, 0.3)',
  },
  profilePropPrefixContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const ProfilePropField = ({ value, label, disabled, suffixComponent, prefixComponent }) => {
  const classes = useStyles();

  return (
    <div className={classes.profilePropContainer}>
      <div className={classes.profilePropPrefixContainer}>
        {prefixComponent}
        <div className={classes.profilePropWrapper}>
          <span className={classes.profilePropLabel}>{label}</span>
          <span className={disabled ? classes.profilePropDisabled : classes.profilePropValue}>{value}</span>
        </div>
      </div>
      {suffixComponent}
    </div>
  )
}
