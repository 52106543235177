import React, { useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core';
import { ImageFileIcon } from 'assets/ImageFileIcon';
import { Field } from 'components/designSystem/Form';
import { useState } from 'react';
import Icon from 'components/Icon';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  label: {
    boxSizing: 'border-box',
    width: '100%',
    border: `2px dashed ${theme.palette.background.surface.pressed}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: 150,
    cursor: 'pointer',
    padding: '2rem 1rem',
    aspectRatio: '5 / 2',
  },
  noFileIcon: {
    background: theme.palette.text.disabled,
    borderRadius: '100%',
    padding: '.4rem',
    aspectRatio: '1 / 1',

    '& svg': {
      transform: 'translate(2.5px, 2px)',
    },
  },
  noFileLabelContainer: {
    width: '50%',
    textAlign: 'center',
  },
  noFileLabel: {
    color: theme.palette.text.tertiary,
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  removeButton: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.background.surface.primary,
    width: 30,
    height: 30,
    borderRadius: '100%',
    cursor: 'pointer',
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const ImageFileDragDropField = ({ input, ...props }) => {
  const { label, value, onChange } = { ...(input || {}), ...props };
  // errorMessage={error && touched ? error : undefined} />

  const classes = useStyles();
  const inputRef = useRef();
  const [image, setImage] = useState(value?.url);

  const handleImage = (file) => {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
      onChange({ url: reader.result, rawFile: file });
    };
    reader.readAsDataURL(file);
  };

  const url = value?.url;
  useEffect(() => {
    setImage((img) => url || img);
  }, [url, setImage]);

  const handleRemove = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    setImage(undefined);
    inputRef.current.value = "";

    onChange({ url: undefined, rawFile: undefined });
  };

  return (
    <div
      className={`${classes.container}`}
      onDrop={(evt) => {
        evt.preventDefault();

        const items = evt.dataTransfer.items;
        const files = evt.dataTransfer.files;

        const file = items ? items[0].getAsFile() : files[0];
        handleImage(file);
      }}
      onDragOver={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();
      }}
    >
      <label className={classes.label} style={{ ...(image ? { backgroundImage: `url(${image})` } : {}) }}>
        {image ? (
          <div></div>
        ) : (
            <>
              <div className={classes.noFileIcon}>
                <ImageFileIcon />
              </div>
              <div className={classes.noFileLabelContainer}>
                <span className={classes.noFileLabel}>{label}</span>
              </div>
            </>
          )}
        <input ref={inputRef} type="file" style={{ opacity: 0 }} accept="image/*" onChange={(evt) => handleImage(evt.target?.files?.[0])} />
      </label>
      {image && (
        <div className={classes.removeButton} onClick={handleRemove}>
          <Icon>close</Icon>
        </div>
      )}
    </div>
  );
};

export const ImageFileDragDropFormField = (props) => <Field {...props} component={ImageFileDragDropField} />;
