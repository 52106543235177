import React from 'react';
import { Datagrid, TextField, FunctionField, Toolbar } from 'ra-ui-materialui';
import { List, DefaultActions } from 'components';
import { useClient } from 'hooks/use-record';
import { useRefresh } from 'ra-core';
import DeleteWithConfirmButton from 'components/QuickDeleteButton';
import RegistrationData, { Create as CreateRegistrationData } from 'resources/Common/RegistrationDataGrid';
import { UploadRegistrationData } from 'resources/Client/components';

export default function RegistrationDataList({ permissions, ...props }) {
  const [client] = useClient();
  const refresh = useRefresh();
  const filter = { ClientId: client.id };
  const readonly = !!client.locked;

  return (
    <>
      <List
        {...props}
        filter={filter}
        basePath="/registration-data/schemas"
        resource="registration-data/schemas"
        actions={<DefaultActions />}
      >
        <Datagrid expand={RegistrationData}>
          <TextField source="contentId" label="fields.contentId" />
          <CreateRegistrationData />
          <FunctionField
            render={(cid) => (
              <DeleteWithConfirmButton
                resource="registration-data/schemas"
                record={cid}
                onDelete={refresh}
                title="actions.delete_title"
                translateOptions={{ name: cid.contentId }}
              />
            )}
          />
        </Datagrid>
      </List>
      {!readonly && (
        <Toolbar>
          <UploadRegistrationData client={client} source="csvRegistrationData" />
        </Toolbar>
      )}
    </>
  );
}
