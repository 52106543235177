import React, { useState } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

// import { CheckCircle } from '@material-ui/icons';

import { Form } from 'react-final-form';
import { required } from 'lib/formValidators';
import { useDataProvider, useTranslate, useNotify } from 'ra-core';
import { handleCreateLicense } from './CreateLicenseDialog.model';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'end',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    alignItems: 'center',

    '& button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginLeft: 16,
      fontSize: 14,
      //colocar no medium os botões
    },
  },

  content: {
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const CreateLicenseDialog = ({ clientId, clientName, isOpen, close, refresh }) => {
  const t = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      isOpen={isOpen}
      close={close}
      title={`${t('resources.license-comments/sasi.commentTitle')} ${clientName ? `"${clientName}"` : ''}`}
    >
      <Form
        onSubmit={handleCreateLicense({ dataProvider, clientId, close, setLoading, refresh, notify })}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextFormField
              name="title"
              validate={required(t)}
              full
              dense
              required
              size="full"
              label={t('resources.license-comments/sasi.fields.title')}
              iconError={<WarningRoundedIcon />}
              iconPosition="right"
              placeholder={t('resources.license-comments/sasi.fields.titlePlaceholder')}
            />
            <div className={classes.content}>
              <TextFormField
                name="responsible"
                validate={required(t)}
                full
                dense
                required
                size="full"
                iconError={<WarningRoundedIcon />}
                iconPosition="right"
                label={t('resources.license-comments/sasi.fields.responsible')}
                placeholder={t('resources.license-comments/sasi.fields.responsiblePlaceholder')}
              />
            </div>

            <div className={classes.bottomContainer}>
              <TertiaryButton disabled={loading} label={t('actionButtons.cancel')} onClick={close} />
              <PrimaryButton
                disabled={loading}
                type="submit"
                label={t('actionButtons.create')}
                onClick={handleSubmit}
                // icon={<CheckCircle />}
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};
