import { validateColor } from 'lib/color';

const defaults = {
  baseHeight: 300.0,
  minify: {
    baseHeight: 60.0,
  },
  colors: {
    textColor: '#FCDD3E',
    bgColor1: '#2B2B2B',
    bgColor2: '#424242',
  },
};

const buttonsThemes = {
  sasi: {
    buttonsBorderRadius: 10,

    primaryButtonsRatio: 2.5,
    primaryIconSize: 32,
    primaryButtonsFontSize: 16,
    primaryButtonsPadding: 24,

    secondaryButtonsRatio: 5.2,
    secondaryIconSize: 24,
    secondaryButtonsFontSize: 16,
    secondaryButtonsPadding: 16,

    showExpand: true,
    primaryFlow: 'column',
    secondaryFlow: 'row',
    primaryJustifyContent: 'space-evenly',
    secondaryJustifyContent: 'left',

    minify: {
      buttonsBorderRadius: 5,

      primaryButtonsRatio: 2.5,
      primaryIconSize: 10,
      primaryButtonsFontSize: 8,
      primaryButtonsPadding: 12,

      secondaryButtonsRatio: 5.2,
      secondaryIconSize: 10,
      secondaryButtonsFontSize: 8,
      secondaryButtonsPadding: 8,
    },
  },
  business: {
    buttonsBorderRadius: 10,

    primaryButtonsRatio: 4.04,
    primaryIconSize: 32,
    primaryButtonsFontSize: 16,
    primaryButtonsPadding: 20,

    secondaryButtonsRatio: 6.06,
    secondaryIconSize: 20,
    secondaryButtonsFontSize: 16,
    secondaryButtonsPadding: 16,

    primaryFlow: 'row',
    secondaryFlow: 'row',
    primaryJustifyContent: 'left',
    secondaryJustifyContent: 'center',
    showExpand: false,

    minify: {
      buttonsBorderRadius: 5,

      primaryButtonsRatio: 4.04,
      primaryIconSize: 10,
      primaryButtonsFontSize: 8,
      primaryButtonsPadding: 10,

      secondaryButtonsRatio: 6.06,
      secondaryIconSize: 10,
      secondaryButtonsFontSize: 8,
      secondaryButtonsPadding: 8,
    },
  },
};

export const getAppTheme = (app) => {
  const themeName = app.config?.themeName || 'sasi';
  return buttonsThemes[themeName] || buttonsThemes.sasi;
};

export const buttonStyle = (channel, app, noPrimary, minify) => {
  let theme = getAppTheme(app);
  if (minify) {
    theme = theme.minify;
  }

  const isPrimary = !!(channel.schema?.primary || channel.primary);
  const channelConfig = channel.schema?.config || channel.config;

  const color = validateColor(channelConfig?.textColor, defaults.colors.textColor);
  const bg1 = validateColor(channelConfig?.bgColor1, defaults.colors.bgColor1);
  const bg2 = validateColor(channelConfig?.bgColor2, defaults.colors.bgColor2);
  const borderRadius = theme.buttonsBorderRadius;
  let heightRatio = theme.secondaryButtonsRatio;

  // TODO: refactor this theme thing later
  const themeProps = {
    padding: theme.secondaryButtonsPadding,
    fontSize: theme.secondaryButtonsFontSize,
    flexFlow: theme.secondaryFlow,
    justifyContent: theme.secondaryJustifyContent,
  };

  if (isPrimary && !noPrimary) {
    heightRatio = theme.primaryButtonsRatio;
    themeProps.padding = theme.primaryButtonsPadding;
    themeProps.fontSize = theme.primaryButtonsFontSize;
    themeProps.flexFlow = theme.primaryFlow;
    themeProps.justifyContent = theme.primaryJustifyContent;
  }

  let height = (minify ? defaults.minify.baseHeight : defaults.baseHeight) / heightRatio;

  return {
    color,
    background: `linear-gradient(103.25deg, ${bg1} 0%, ${bg2} 100%)`,
    borderRadius,
    height,
    ...themeProps,
  };
};
