import React, { useCallback, useState } from 'react';
import { FormTab, TabbedForm, ReferenceInput, TextInput, TextField, SelectInput } from 'ra-ui-materialui';
import { email, useRedirect } from 'ra-core';
import {
  Edit,
  DefaultToolbar,
  checkPermissions,
  required,
  passwordConfirmValidate,
  ReferenceMultiGrid,
  nullablePasswordValidate,
  phone,
} from 'components';
import { roles } from 'lib/consts';
import { FormDependency } from 'components/FormDependency';
import { useRecord, useAutoRedirect, useAccount } from 'hooks';
import useCanEditUser from './useCanEditUser';
import { Col, Row } from 'resources/components/Grid';

export default ({ permissions, ...props }) => {
  const redirectTo = useRedirect();
  const redirect = useAutoRedirect('list');
  const account = useAccount() || {};

  const { id, userType } = useRecord('users') || {};

  const rolesEnabled = checkPermissions(permissions, 'users:update:role') && id !== account.id;

  const [role, setRole] = useState('');
  const onFormInit = useCallback(({ values }) => setRole(values.role), [setRole]);
  const onChangeRole = useCallback((event) => setRole(event.target.value), [setRole]);

  const hasSite = userType === 'client' && !account.SiteId;
  const hasClients = userType === 'sasi' && role === 'clientManager';
  const siteRequired = userType === 'client' && role === 'manager' ? required() : () => {};

  const canEdit = useCanEditUser(userType, permissions);
  if (permissions && !canEdit) {
    redirectTo('list', '/users');
    return <span />;
  }

  return (
    <Edit {...props} titleSource="email">
      <TabbedForm toolbar={<DefaultToolbar />} redirect={redirect}>
        <FormTab label="tabs.summary">
          <Row>
            <Col>
              <TextInput source="username" validate={required()} />
              <TextInput source="email" validate={email()} />
              {hasClients && (
                <>
                  <TextInput source="name" validate={required()} />
                  <TextInput source="phone" allowNull validate={phone()} />
                </>
              )}
              <FormDependency onInit={onFormInit}>
                <SelectInput
                  key={`role${userType}`}
                  source="role"
                  validate={required()}
                  choices={roles[userType]}
                  allowEmpty
                  disabled={!rolesEnabled}
                  onChange={onChangeRole}
                />
              </FormDependency>
              {hasSite && (
                <ReferenceInput
                  label="fields.siteTeam"
                  source="SiteId"
                  reference="sites"
                  validate={siteRequired}
                  allowEmpty
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              )}
            </Col>

            {!hasClients && (
              <Col>
                <TextInput source="name" validate={required()} />
                <TextInput source="phone" allowNull validate={phone()} />
              </Col>
            )}

            {hasClients && (
              <Col>
                <ReferenceMultiGrid reference="clients" source="ClientIds" withFilter>
                  <TextField source="id" />
                  <TextField source="name" />
                </ReferenceMultiGrid>
              </Col>
            )}
          </Row>

          <TextInput source="password" autoComplete="off" type="password" validate={nullablePasswordValidate} />
          <TextInput source="passwordConfirm" autoComplete="off" type="password" validate={passwordConfirmValidate} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
