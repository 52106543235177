import { SET_USERS } from '../actions/users';

export const usersReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_USERS:
      return {
        ...state,
        users: payload,
      };
    default:
      return undefined;
  }
};
