import React, { useCallback } from 'react';
import { get, set } from 'lodash';
import { TextField } from 'ra-ui-materialui';
import { useDataProvider, useTranslate } from 'hooks';
import { ShowLink } from 'components';
import ActiveSwitch from 'resources/components/ActiveSwitch';

export function ProjectDatagridName({ record, ...props }) {
  const translate = useTranslate();

  const projectRecord = { ...record };

  const isDefaultKey = props.isDefaultKey || 'isDefault';
  const nameKey = props.nameKey || 'name';

  if (get(projectRecord, isDefaultKey)) {
    set(projectRecord, nameKey, translate('resources.projects.default'));
  }

  const render = props.isNotLink ? (
    <TextField {...props} record={projectRecord} />
  ) : (
    <ShowLink {...props} record={projectRecord} />
  );

  return render;
}

export function ProjectDatagridDescription({ record, ...props }) {
  const translate = useTranslate();

  const projectRecord = { ...record };

  if (projectRecord.isDefault) {
    projectRecord.description = translate(
      'resources.projects.defaultDescription',
    );
  }

  return <TextField {...props} record={projectRecord} />;
}

export function ProjectToggler(props) {
  const dataProvider = useDataProvider();

  const deleteProject = useCallback(async () => {
    await dataProvider.delete('projects', {
      id: props.record.id,
    });
    props.refresh();
  }, [props.record, dataProvider]); //eslint-disable-line

  return (
    <ActiveSwitch
      {...props}
      handleUpdate={deleteProject}
      withNegation
      readonly={props.record && !props.record.deletable}
    />
  );
}
