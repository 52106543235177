import React, { useState, useCallback } from 'react';
import { Button, Toolbar, SaveButton } from 'ra-ui-materialui';
import { Icon } from '@material-ui/core';
import { useNotify } from 'ra-core';
import { useCopyPaste } from 'hooks';
import ActionDelete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconCancel from '@material-ui/icons/Cancel';

export function CopyButton({ onCopy, name, value, notification, ...props }) {
  const notify = useNotify();
  const [, copy] = useCopyPaste(name);

  const onClick = (evt) => {
    evt.stopPropagation();
    copy(typeof value == 'function' ? value(props) : value);
    notification && notify(notification);
    onCopy && onCopy(name);
  };
  return (
    <Button label="Copy" {...props} onClick={onClick}>
      <Icon>content_copy</Icon>
    </Button>
  );
}
const ExternalToolbar = ({ saving, handleSubmit, onClose }) => {
  return (
    <Toolbar>
      <>
        <SaveButton saving={saving} handleSubmitWithRedirect={handleSubmit} />
        <Button label="ra.action.cancel" onClick={onClose}>
          <IconCancel />
        </Button>
      </>
    </Toolbar>
  );
};

const noop = () => {};
export function PasteButton({ onPaste = noop, dialogTitle, title = 'Paste', name, children, record, ...props }) {
  const [buffer, setBuffer] = useCopyPaste(name);
  const [dialogOpen, setDialogOpen] = useState(false);

  const open = useCallback(() => setDialogOpen(true), [setDialogOpen]);
  const close = useCallback(() => setDialogOpen(false), [setDialogOpen]);
  const create = (values) => {
    onPaste(values, record);
    setBuffer(null);
  };

  const onClick = children ? open : () => create(buffer, record);
  if (!buffer) return <span />;
  return (
    <>
      <Button label={title} onClick={onClick}>
        <Icon>content_paste</Icon>
      </Button>
      {children && (
        <Dialog fullWidth open={dialogOpen} onClose={close}>
          <DialogTitle>{dialogTitle || title}</DialogTitle>
          <DialogContent>
            {children &&
              React.cloneElement(children, {
                record: buffer,
                toolbar: <ExternalToolbar saving={false} onClose={close} />,
                save: create,
              })}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export function CleanBufferButton({ name, title = 'Remove' }) {
  const [buffer, setCopyChannel] = useCopyPaste(name);
  return buffer ? (
    <Button onClick={() => setCopyChannel(null)} label={title} className="ra-delete-button">
      <ActionDelete />
    </Button>
  ) : (
    <span />
  );
}
