import { uniqBy, flatMap } from 'lodash';
import { genericErrorHandler } from 'lib/Errors';

export const loadChannels = async ({
  dataProvider,
  setChannels,
  appId,
  dispatch,
  setRegistrationForms,
  clientId,
  onFinish,
}) => {
  const { data: channels } = await dataProvider.getList(`channels`, { filter: { appId } });

  const { data: registrationForms } = await dataProvider.getList('registration-forms', {
    filter: { ClientId: clientId },
  });
  const uniqSchemaFields = uniqBy(flatMap(registrationForms, 'schema.fields', 'name'));

  setChannels(channels);
  setRegistrationForms(uniqSchemaFields.filter((field) => !!field));
  dispatch(channels);
  if (onFinish) {
    onFinish();
  }
};

const setChannelDisplayOrder = async ({ dataProvider, clientId, channelsOrder }) =>
  dataProvider.rearrange('channels', {
    channelsOrder,
    clientId,
  });

export const onReorder =
  ({ dataProvider, clientId, refresh, notify }) =>
  async (channels) => {
    try {
      const channelsOrder = channels.map((channel, index) => ({
        id: channel.id,
        schemaId: channel.schemaId,
        displayOrder: index,
      }));

      const { data } = await setChannelDisplayOrder({ dataProvider, channelsOrder, clientId });
      refresh();

      return data;
    } catch (err) {
      genericErrorHandler(notify)(err);
    }
  };
