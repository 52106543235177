import { genericErrorHandler } from 'lib/Errors';

export const handlePasteChannel =
  ({ setLoading, close, dataProvider, refresh, appId, clientId, channel, notify }) =>
  async (formData) => {
    if (!channel) {
      notify('canal invalido');
      return;
    }
    setLoading(true);

    try {
      const { name, primary } = formData;
      const newChannel = { ...channel, config: { ...(channel.config || {}) } };
      delete newChannel.config.parentId;

      await dataProvider.create('channels', {
        data: { ...channel, name, primary, clientId, appId },
      });
      localStorage.removeItem('channel');
      if (refresh) {
        refresh();
      }
    } catch (err) {
      genericErrorHandler(notify)(err);
    } finally {
      setLoading(false);
      close();
    }
  };
