import React from 'react';

import { TableRowActionButton } from 'components/designSystem/TableRowActionButton';
import { Toggler } from 'components/designSystem/Toggler';

export const usersColumns = (dataProvider, onEdit, refresh) => (translate) =>
  [
    {
      key: 'client_users_table_column_name',
      sortable: true,
      id: 'name',
      field: 'name',
      header: translate('resources.users.nameUser'),
    },
    {
      key: 'client_users_table_column_username',
      sortable: true,
      id: 'username',
      field: 'username',
      header: translate('resources.users.user'),
    },
    {
      key: 'client_users_table_column_email',
      sortable: true,
      id: 'email',
      field: 'email',
      header: 'Email',
    },
    {
      key: 'client_users_table_column_roles',
      sortable: true,
      id: 'role',
      field: (record) => translate(`roles.client.${record.role}`),
      header: translate('resources.users.office'),
    },
    {
      key: 'client_users_table_column_active',
      id: 'active',
      field: (record) => (
        <Toggler
          key={JSON.stringify(record)}
          checked={record.active}
          onClick={(evt) => {
            evt.stopPropagation();
            toggleUserActive(dataProvider, record, refresh);
          }}
        />
      ),
      header: translate('resources.projects.buttons.active'),
    },
    {
      key: 'client_users_table_column_site',
      id: 'siteId',
      field: (record) => record.site?.name || '-',
      header: translate('resources.users.localteam'),
    },
    {
      key: 'client_users_table_column_edit_action',
      id: 'action',
      field: (record) => <TableRowActionButton action="edit" record={record} onClick={onEdit} />,
    },
  ];

const toggleUserActive = async (dataProvider, user, refresh) => {
  const { id, ...data } = user;
  await dataProvider.update('users', { id, data: { ...data, active: !user.active } });

  if (refresh) {
    refresh();
  }
};

export const loadUsers = (dataProvider, setUsers, setTotalUsers, clientId, filters, dispatch, setLoading) => {
  setLoading(true);
  dataProvider
    .getList('users', {
      filter: {
        ClientId: clientId,
        showInactive: filters.showInactive,
        withSites: true,
        limit: filters.limit,
        offset: filters.offset,
        ...(filters.order && filters.orderBy
          ? {
              order: `${filters.orderBy} ${filters.order.toUpperCase()}`,
            }
          : {}),
      },
    })
    .then(({ data, total }) => {
      setLoading(false);
      dispatch(data);
      setUsers(data);
      setTotalUsers(total || 0);
    })
    .catch((err) => {
      setLoading(false);
      throw err;
    });
};
