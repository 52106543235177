import React from "react"
import { TextInput,  SimpleForm } from "ra-ui-materialui"
import { QuickCreateButton, required } from "components"
import { maxLength, minLength} from "ra-core"
import { CODE_LENGTH } from "lib/consts"

export default (props) => {
  return <QuickCreateButton {...props} resource="public-codes"  >
    <SimpleForm toolbar={null} resource="public-codes" >
      <TextInput source="code" inputProps={{ maxLength: CODE_LENGTH }} validate={[required(), minLength(CODE_LENGTH), maxLength(CODE_LENGTH)]} />
      <TextInput source="state" />
      <TextInput source="description" multiline={true} />
    </SimpleForm>
  </QuickCreateButton>
}