import React from 'react';
import { Datagrid, TextInput, SimpleForm, EditButton, FunctionField } from 'ra-ui-materialui';
import { List, DefaultActions, DateField, QuickCreateButton, ShowLink } from 'components';
import ActiveSwitch from 'resources/components/ActiveSwitch';
import { useClient } from 'hooks/use-record';
import { useRefresh } from 'ra-core';
import DeleteWithConfirmButton from 'components/QuickDeleteButton';
import { EmptyView } from 'components/designSystem/EmptyView';

export function CreateTermsButton({ record, ClientId, ...props }) {
  return (
    <QuickCreateButton defaultValues={{ ClientId }} record={record} {...props} resource="terms">
      <SimpleForm toolbar={null} resource="terms" record={record}>
        <TextInput source="title" />
      </SimpleForm>
    </QuickCreateButton>
  );
}

export default function ListTerms({ permissions, ...props }) {
  const [client] = useClient();
  const refresh = useRefresh();
  return (
    <List
      {...props}
      empty={
        <EmptyView
          halfMargin
          text="ra.page.empty"
          translate
          customComponent={<CreateTermsButton title="titles.terms" ClientId={client.id} onSubmit={refresh} />}
        />
      }
      actions={
        <DefaultActions>
          {client.id && <CreateTermsButton title="titles.terms" ClientId={client.id} onSubmit={refresh} />}
        </DefaultActions>
      }
    >
      <Datagrid>
        <ShowLink source="title" />
        <DateField source="publishedAt" />
        <ActiveSwitch source="active" readonly={!props.hasEdit} />
        {props.hasEdit && <EditButton />}
        <FunctionField
          render={(record) =>
            !record.key ? (
              <DeleteWithConfirmButton resource="terms" basePath="/terms" record={record} onDelete={refresh} />
            ) : (
              <span />
            )
          }
        />
      </Datagrid>
    </List>
  );
}
