import { genericErrorHandler } from '../../../lib/Errors';

export const initDashboardData = async ({ dataProvider, notify, clientId, useClientSection }) => {
  const requests = [fetchSasiDashboardData({ dataProvider, notify })];
  if (clientId && useClientSection) {
    requests.push(fetchClientDashboardData({ dataProvider, notify, clientId }));
  }

  const [sasiDashboard, clientDashboard] = await Promise.allSettled(requests);
  return {
    sasi: sasiDashboard.status === 'fulfilled' ? sasiDashboard.value : null,
    client: clientDashboard?.status === 'fulfilled' ? clientDashboard.value : null,
  };
}

export const fetchSasiDashboardData = async ({ dataProvider, notify }) => {
  try {
    const { data } = await dataProvider.fetch('dashboard', {});
    return data;
  }
  catch (error) {
    genericErrorHandler(notify)(error);
    console.error(error);
    throw error;
  }
}

export const fetchClientDashboardData = async ({ dataProvider, notify, clientId }) => {
  try {
    const { data } = await dataProvider.fetch(`dashboard/client/${clientId}`, {});
    return data;
  }
  catch (error) {
    genericErrorHandler(notify)(error);
    console.error(error);
    throw error;
  }
}

export const listClients = async ({ dataProvider, notify }) => {
  try {
    const { data } = await dataProvider.fetch('clients', {order: 'name ASC'});
    return data;
  }
  catch (error) {
    genericErrorHandler(notify)(error);
    console.error(error);
    throw error;
  }
}

export const listApps = async ({ dataProvider, notify, clientId }) => {
  try {
    const { data } = await dataProvider.fetch(`apps`, {clientId, order: 'name ASC'});
    return data;
  }
  catch (error) {
    genericErrorHandler(notify)(error);
    console.error(error);
    throw error;
  }
}

export const listSite = async ({ dataProvider, notify, clientId }) => {
  try {
    const { data } = await dataProvider.fetch(`sites`, {clientId, order: 'name ASC'});
    return data;
  }
  catch (error) {
    genericErrorHandler(notify)(error);
    console.error(error);
    throw error;
  }
}
export const fetchAppDashboardData = async ({ dataProvider, notify, appId }) => {
  try {
    const { data } = await dataProvider.fetch(`dashboard/apps`, {appId});
    return data;
  }
  catch (error) {
    genericErrorHandler(notify)(error);
    console.error(error);
    throw error;
  }
}

export const fetchSiteDashboardData = async ({ dataProvider, notify, siteId }) => {
  try {
    const { data } = await dataProvider.fetch(`dashboard/sites`, {siteId});
    return data;
  }
  catch (error) {
    genericErrorHandler(notify)(error);
    console.error(error);
    throw error;
  }
}