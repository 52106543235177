import React from 'react';
import {
  Datagrid,
  TextField,
  ReferenceField,
  Filter,
  EditButton,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'ra-ui-materialui';
import { useRefresh } from 'ra-core';
import { List, ShowLink, checkPermissions } from 'components';
import ActiveSwitch from 'resources/components/ActiveSwitch';
import FilterSwitch from 'resources/components/FilterSwitch';
import DownshiftInput from 'components/DownshiftInput';
import { useAccount } from 'hooks';

const TeamsFilter = ({ hasClient, ...props }) => (
  <Filter {...props}>
    {hasClient || (
      <DownshiftInput type="text" source="ClientId" reference="clients" optionValue="id" optionText="name" alwaysOn />
    )}
    <FilterSwitch type="checkbox" source="showInactive" label="fields.showInactive" alwaysOn />
  </Filter>
);

export default ({ permissions, ...props }) => {
  const account = useAccount();
  const editable = checkPermissions(permissions, 'sites:update');
  const canSeeApps = checkPermissions(permissions, 'apps:view');
  const canSeeCodes = checkPermissions(permissions, 'registration-codes:view');
  const refresh = useRefresh();
  const hasClient = !!account.ClientId;

  return (
    <List {...props} filters={<TeamsFilter hasClient={hasClient} />}>
      <Datagrid>
        <ShowLink source="name" />
        <TextField source="tag" />
        {!hasClient && (
          <ReferenceField
            basePath="/clients"
            source="ClientId"
            reference="clients"
            link="show"
            allowEmpty
            sortBy="Client.name"
          >
            <TextField source="name" />
          </ReferenceField>
        )}
        {canSeeCodes && (
          <ArrayField source="registrationCodes" fieldKey="id" linkType={false}>
            <SingleFieldList>
              <ChipField source="code" />
            </SingleFieldList>
          </ArrayField>
        )}
        <ActiveSwitch source="active" sortable={false} onUpdate={refresh} readonly={!editable} />
        {canSeeApps && (
          <ReferenceField source="appId" basePath="/apps" reference="apps" link={false} allowEmpty sortable={false}>
            <TextField source="name" />
          </ReferenceField>
        )}
        {editable && <EditButton />}
      </Datagrid>
    </List>
  );
};
