import { genericErrorHandler } from 'lib/Errors';

export const handleCreateUser =
  ({ dataProvider, clientId, close, setLoading, refresh, notify }) =>
  async (formData) => {
    setLoading(true);

    try {
      const data = { ...formData };
      if (clientId) {
        data.ClientId = clientId; // TODO: rename to 'data.clientId'
      }

      await dataProvider.create('users', { data });
      if (refresh) {
        refresh();
      }
    } catch (err) {
      genericErrorHandler(notify)(err);
    } finally {
      setLoading(false);
      close();
    }
  };
