import React from "react"
import { TextInput, BooleanInput, SimpleForm, } from "ra-ui-materialui"
import { Edit, DefaultToolbar, required }    from "components"
import SurveyCreator from "./components/SurveyCreator"
import { useRecord } from "hooks"

export default props => {  
  const record = useRecord(props.resource) || { locked: true}

  return <Edit {...props} titleSource="name" >
    <SimpleForm toolbar={<DefaultToolbar />} redirect="show" >
      <TextInput source="name" validate={required()} />
      <BooleanInput source="active" validate={required()} />
      {!record.locked && <SurveyCreator source="config" />}       
    </SimpleForm>
  </Edit>
} 