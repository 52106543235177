import { genericErrorHandler } from 'lib/Errors';

export const handleCreateProvider =
  (dataProvider, clientId, close, setLoading, refresh, notify) => async (formData) => {
    setLoading(true);

    try {
      await dataProvider.create('monitoring-providers', { data: { ...formData, ClientId: clientId } });
      if (refresh) {
        refresh();
      }
    } catch (err) {
      genericErrorHandler(notify)(err);
    } finally {
      setLoading(false);
      close();
    }
  };
