import React, { useCallback, useState } from "react"
import { TextInput, SelectInput, SimpleForm } from "ra-ui-materialui"
import { email } from "ra-core"
import { Create, DefaultToolbar, required } from "components"
import { providerTypes } from "lib/consts"
import { useAutoRedirect, useAccount } from "hooks"
import AdminField from "./components/AdminField"

export default props => {
  const [providerType, setProviderType] = useState()
  const providerChanged = useCallback(event => setProviderType(event.target.value), [setProviderType])
  const redirect = useAutoRedirect("list")
  const { ClientId } = useAccount() || {}

  return <Create {...props} >
    <SimpleForm toolbar={<DefaultToolbar/>} redirect={redirect} initialValues={{ ClientId }}>
      <TextInput source="name" validate={required()}/>
      <TextInput source="email" validate={[required(), email()]}/>
      <SelectInput source="providerType" choices={providerTypes} onChange={providerChanged} validate={required()}/>
      {providerType === "webhook" && <TextInput source="callbackUrl"/>}
      {providerType === "email" && <TextInput source="notifyEmail" validate={required()}/>}
      {providerType === "standard2" && <AdminField type={providerType}/>}
      {providerType === "api" && <AdminField type={providerType}/>}
    </SimpleForm>
  </Create>
}
