import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CardMedia from '@material-ui/core/CardMedia';
import md5 from 'js-md5';
const useStyles = makeStyles({
  profile: {
    margin: '10px 10px',
    width: 64,
    height: 64,
  },
  menu: {
    width: 32,
    height: 32,
    borderRadius: 32,
    backgroundColor: '#FFF',
  },
  card: {
    margin: '10px 10px',
    width: 128,
    height: 128,
  },
});
const variants = {
  profile: {
    className: 'profile',
    size: 64,
  },
  menu: {
    className: 'menu',
    size: 32,
  },
  card: {
    className: 'card',
    size: 128,
  },
};
function profileUrl(profile, size) {
  const hash = profile && profile.email ? md5(profile.email) : 'guest';
  return `https://www.gravatar.com/avatar/${hash}?size=${size}&d=mp`;
}
export default ({ profile, variant = 'profile', className }) => {
  const classes = useStyles();
  return (
    <CardMedia
      className={`${classes[variants[variant].className]} ${className}`}
      image={profileUrl(profile, variants[variant].size)}
    />
  );
};
