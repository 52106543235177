import { SET_CLIENTS } from '../actions/clients';

export const clientsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CLIENTS:
      return {
        ...state,
        clients: payload,
      };
    default:
      return undefined;
  }
};
