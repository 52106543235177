import React from 'react';
import lightTheme from 'themes/light';

const themes = {
  light: lightTheme,
};

const context = {
  getThemes: () => themes,
  getTheme: () => themes[context.getThemeName()] || themes['light'],
  getThemeName: () => 'light',
  changeTheme: () => {},
};

export default React.createContext(context);
