import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import inflection from 'inflection';
import { Confirm, Button } from 'ra-ui-materialui';
import { useState, useCallback } from 'hooks';
import { useDelete, useNotify } from 'ra-core';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const DeleteWithConfirmButton = ({
  className,
  icon = <ActionDelete />,
  label = 'ra.action.delete',
  record,
  resource,
  customResource,
  onDelete,
  doDelete,
  translateOptions,
  ...props
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const [destroy, { loading, error }] = useDelete(customResource || resource);
  const id = record ? record.id : undefined;

  if (error) {
    notify(error.message);
  }
  const [open, setOpen] = useState(false);
  const doOpen = useCallback(() => setOpen(true), [setOpen]);
  const doClose = useCallback(() => setOpen(false), [setOpen]);
  const onConfirm = useCallback(() => {
    doClose();
    if (doDelete) {
      doDelete(id);
    } else {
      destroy({ payload: { id } }, { onSuccess: onDelete });
    }
  }, [doClose, doDelete, onDelete, id, destroy]);

  const deletable = record && record.deletable !== false;
  return (
    <>
      {deletable && (
        <Button
          onClick={doOpen}
          disabled={loading}
          label={label}
          className={classnames('ra-delete-button', classes.deleteButton, className)}
          key="button"
        >
          {icon}
        </Button>
      )}
      <Confirm
        isOpen={open}
        title="ra.message.delete_title"
        content="ra.message.delete_content"
        translateOptions={{
          name: inflection.humanize(inflection.singularize(customResource || resource), true),
          id,
          ...translateOptions,
        }}
        onConfirm={onConfirm}
        onClose={doClose}
        {...props}
      />
    </>
  );
};

export default DeleteWithConfirmButton;
