import React, { useState } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';

import { Form } from 'react-final-form';
import { required } from 'lib/formValidators';
import { useDataProvider, useNotify, useTranslate } from 'ra-core';
import { importSites } from './ImportSiteDialog.model';
import { DocumentFileDragDropFormField } from 'components/designSystem/DocumentFileDragDropField';

const useStyles = makeStyles((theme) => ({
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  setDefaultContainer: {
    justifyContent: 'flex-end',
    position: 'relative',
    top: theme.spacing(3) * -1,
    left: theme.spacing(1) * -1,

    '& > div': {
      color: theme.palette.text.tertiary,
    },
  },
}));

export const ImportSiteDialog = ({ clientId, isOpen, close, reload }) => {
  const t = useTranslate();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog isOpen={isOpen} close={loading ? () => {} : close} title={t('resources.sites.importSites')}>
      <Form
        initialValues={{ format: 'CSV', withInactive: false }}
        onSubmit={importSites({ dataProvider, setLoading, clientId, closeDialog: close, t, notify, reload })}
        render={({ handleSubmit, errors }) => (
          <form onSubmit={handleSubmit}>
            <DocumentFileDragDropFormField
              name="csvSites"
              label={t('resources.sites.importCSVLabel')}
              validate={required(t)}
            />
            <div className={classes.bottomContainer}>
              <TertiaryButton
                icon={<CloudDownload />}
                disabled={loading}
                label={t('resources.sites.downloadExample')}
                noBackground
                onClick={() => {
                  window.open(`${process.env.REACT_APP_UI_URL}/sasiImportSiteExample.csv`, '_blank');
                }}
              />
              <PrimaryButton
                disabled={loading || Object.keys(errors || {}).length > 0}
                type="submit"
                label={t('resources.sites.importSites')}
                onClick={handleSubmit}
                // icon={<CheckCircle />}
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};
