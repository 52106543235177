import React from 'react';
import { TableRowActionButton } from 'components/designSystem/TableRowActionButton';
import { genericErrorHandler } from 'lib/Errors';
import { Toggler } from 'components/designSystem/Toggler';

export const clientsColumns = (dataProvider, refresh) => [
  //tabela com lock/unlock
  {
    key: 'client_table_column_locked',
    id: 'icon_locked',
  },

  //tabela nome
  {
    key: 'client_table_column_name',
    sortable: true,
    id: 'name',
    field: 'name',
    header: 'Nome do Cliente',
  },

  //tabela nickname
  {
    key: 'client_table_column_username',
    sortable: true,
    id: 'nickname',
    field: 'nickname',
    header: 'Apelido do Cliente',
  },

  // ativo
  {
    key: 'client_table_column_actions',
    id: 'active',
    field: (record) => (
      <Toggler
        key={JSON.stringify(record)}
        checked={record.active}
        onClick={(evt) => {
          evt.stopPropagation();
          toggleClientActive(dataProvider, record, refresh);
        }}
      />
    ),
    header: 'ativo',
  },

  //editar
  {
    key: 'client_table_column_edit_action',
    id: 'action',
    header: 'Ações',

    field: (record) => <TableRowActionButton action="edit" goto={`/clients/${record.id}`} />,
  },
];

export const handleCreate = (history) => (evt) => {
  evt.stopPropagation();
  history.push(`/clients/create`);
};

const toggleClientActive = async (dataProvider, client, refresh) => {
  const { id, active } = client;
  await dataProvider?.update('clients', { id, data: { active: !active } });

  if (refresh) {
    refresh();
  }
};

export const listClients = (
  dataProvider,
  setClients,
  setTotalClients,
  client,
  filters,
  dispatch,
  notify,
  setLoading,
) => {
  dataProvider
    .getList('clients', {
      filter: {
        client: client,
        q: filters.qSearch,
        showInactive: filters.showInactive,
        limit: filters.limit,
        offset: filters.offset,
        ...(filters.order && filters.orderBy
          ? {
              order: `${filters.orderBy} ${filters.order.toUpperCase()}`,
            }
          : {}),
      },
    })

    .then(({ data, total }) => {
      setLoading = false;
      dispatch(data);
      setClients(data);
      setTotalClients(total || 0);
    })
    .catch((err) => {
      genericErrorHandler(notify)(err);
    });
};

// export async function listClients({
//   dataProvider,
//   setClients,
//   setTotalClients,
//   client,
//   dispatch,
//   filters,
//   notify,
//   setLoading,
// }) {
//   setLoading(true);
//   dataProvider
//     .getList('clients', {
//       filter: {
//         client: client,
//         q: filters.qSearch,
//         showInactive: filters.showInactive,
//         limit: filters.limit,
//         offset: filters.offset,
//         ...(filters.order && filters.orderBy
//           ? {
//               order: `${filters.orderBy} ${filters.order.toUpperCase()}`,
//             }
//           : {}),
//       },
//     })
//     .then(({ data, total }) => {
//       setLoading(false);
//       dispatch(data);
//       setClients(data);
//       setTotalClients(total);
//     })
//     .catch((err) => {
//       genericErrorHandler(notify)(err);
//     });
// }
