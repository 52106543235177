import React, {useState, useCallback} from "react"
import {useNotify, useUpdate} from "ra-core"
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default ({record, resource}) => {
  const [role, setRole] = useState(record.role)
  const notify = useNotify()
  const change =  (onClick, role) => () =>  {
    setRole(role === "manager" ? "worker" : "manager")
    onClick()
  }
  const onSuccess = useCallback(() => notify("notifications.mapRoleUpdated"), [notify])
  const [update, { error }] = useUpdate(resource, record.id, { role: role === "manager" ? "worker" : "manager" }, record, { onSuccess })
  if (error) {
    notify(error.message)
  }
  return <FormControlLabel control={<Switch checked={role === "manager"} onChange={change(update, role)} />} />
}
