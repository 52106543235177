import React, { useState } from 'react';
import { useSafeSetState, useNotify } from 'ra-core';
import { useHistory } from 'react-router';
import authProvider from '../../lib/authProvider';
import { LoginForm as Form, MFAForm } from './Forms';

const handleError = notify => err => {
  switch (err.name) {
    case 'AuthenticationError':
      notify(`httpError.authentication.${err.message}`, 'error', { requestId: err.requestId });
      break;
    case 'HttpError':
    default:
      notify(`httpError.${err.message}`, 'error', { requestId: err.requestId });
  }
}

export const LoginForm = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const notify = useNotify()
  const history = useHistory()

  const [mfaValue, setMfaValue] = useState(undefined);
  const [mfaValues, setMfaValues] = useState(undefined);

  const submitLogin = async ({ username, password }) => {
    setLoading(true);

    let loginResponse;
    try {
      loginResponse = await authProvider.login({ username, password });
    } catch (err) {
      handleError(notify)(err);
      setLoading(false);
      return;
    }

    if (loginResponse.authentication) {
      const { token, refreshToken, profile } = loginResponse.authentication;
      setLoading(false);
      authProvider.setActiveAccount(token, { refreshToken, profile });
      history.push('/');
      return;
    }

    const { mfaValues } = loginResponse.mfaFlow;
    setMfaValues(mfaValues);
    setMfaValue(mfaValues[0]);
    setLoading(false);
  }

  const sendMFACodeWithToken = async (methodValue) => {
    setLoading(true);
    try {
      await authProvider.resendMFACodeWithToken(methodValue);
    } catch (err) {
      handleError(notify)(err);
    }
    setLoading(false);
  }

  const verifyMFACode = async values => {
    setLoading(true);
    const { code } = values;

    let verifyResponse;
    try {
      verifyResponse = await authProvider.verifyMFACode(code.toUpperCase())
    } catch (err) {
      handleError(notify)(err);
      setLoading(false);
      return;
    }

    const { token, refreshToken, profile } = verifyResponse;
    setLoading(false);
    authProvider.setActiveAccount(token, { refreshToken, profile });
    history.push('/');
  }

  const handleMfaSwitch = async method => {
    await sendMFACodeWithToken(method);
    setMfaValue(method);
  }

  return (mfaValue && mfaValues)
    ? (
      <MFAForm
        submit={verifyMFACode}
        loading={loading}
        mfaValue={mfaValue}
        handleNewCodeRequest={sendMFACodeWithToken}
        otherMethods={mfaValues.filter(v => v !== mfaValue)}
        handleSwitchMethod={handleMfaSwitch}
      />
    ) : <Form submit={submitLogin} loading={loading}/>;
}
