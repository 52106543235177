import React, { useState, useCallback } from "react"
import {Datagrid, TextField, FunctionField} from "ra-ui-materialui"
import { useTranslate} from "ra-core"
import {List}  from "components"
import { format } from 'date-fns'
import DrawerAlert from "./components/DrawerAlert"
import AlertsFilter from "./components/Filter"
import { makeStyles } from "@material-ui/styles"
const formatDate = date => format(new Date(date), "yyyy-MM-dd HH:mm:ss")

function alertStatus(record) {
  if (record.processedAt) {
    return "assigned"
  } else {
    return "pending"
  }   
}

const useStyles = makeStyles({
  dateField: {
    minWidth: 250
  }
})

export default props => {
  const classes = useStyles()
  const translate = useTranslate()
  const [alert, setAlert] = useState()  
  const onRowClick = useCallback((id, resource, alert) => setAlert(alert), [setAlert])

  return (
    <>
      <DrawerAlert key={alert && alert.id} record={alert} onClose={() => setAlert(null)}  />
      <List {...props}  filters={<AlertsFilter />} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick={onRowClick} >
          <FunctionField source="status" render={record => translate(`adminAlertStatus.${alertStatus(record)}`)} />
          <FunctionField source="date" width={250} headerClassName={classes.dateField} render={record => (
            <span>
              {formatDate(record.generatedAt)}
              <br />
              {formatDate(record.createdAt)}
            </span>
          )} />
          <TextField label="fields.channel" source="Channel.name" sortable={false} />
          <FunctionField label="fields.site" render={record => (
            <span>
              <TextField source="Client.name" record={record} />
              <br />
              <TextField source="Site.name" record={record} />
            </span>
          )} />
          <FunctionField render={({ anonymous, MobileProfile: profile }) => (
            <span>
              {anonymous ? translate("titles.anonymous") : profile && profile.name}<br />
              {!anonymous && <a href={`tel:${profile.phone}`}>{profile.phone}</a>}
            </span>
          )} />
          <FunctionField sortable={false} source="priority" render={record => <span>{record.priority ? translate("titles.yes") : ""}</span>} />
          <FunctionField sortable={false}  source="test" render={record => <span>{record.test ? translate("titles.yes") : ""}</span>} />
        </Datagrid>
      </List>
    </>
   )
}
