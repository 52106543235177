import React from "react"
import { TextInput, SimpleForm,  } from "ra-ui-materialui"
import { QuickEditButton, required } from "components"
import { CODE_LENGTH } from "lib/consts"
import { maxLength, minLength } from "ra-core"


export default props => (
  props.record ? <QuickEditButton {...props} resource="public-codes" label="ra.action.edit">
    <SimpleForm toolbar={null} resource="public-codes" record={props.record} >
      <TextInput source="code" validate={[required(), required(), minLength(CODE_LENGTH), maxLength(CODE_LENGTH)]} inputProps={{ maxLength: CODE_LENGTH }} />
      <TextInput source="state" />
      <TextInput source="description" multiline={true} />
    </SimpleForm>
  </QuickEditButton>: <span />)
