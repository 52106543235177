import React from "react"
import { Datagrid, TextField } from "ra-ui-materialui"
import { List, DefaultActions, DateField, checkPermissions } from "components"
import { useClient } from "hooks/use-record"
import { useRefresh } from "ra-core"
import RegistrationCode, { Create as CreateCodeButton } from "resources/Common/RegistrationCode"
import DeleteWithConfirmButton from "components/QuickDeleteButton"

export default function RegistrationCodesList({ permissions, ...props }) {
  const [client] = useClient()
  const refresh = useRefresh()
  const filter = { mode: "client", ClientId: client.id }

  return <List {...props} filter={filter} actions={<DefaultActions>
    <CreateCodeButton
      title="titles.createCode"
      label="titles.createCode"
      mode="client"
      ClientId={client.id}
      defaultValues={filter}
      onSubmit={refresh}
    />
  </DefaultActions>}>
    <Datagrid expand={RegistrationCode}>
      <TextField source="code"/>
      <TextField label="fields.form" source="RegistrationForm.name"/>
      <TextField label="fields.handler" source="RegistrationHandler.name"/>
      <DateField source="createdAt"/>
      {checkPermissions(permissions, "registration-codes:delete") && <DeleteWithConfirmButton onDelete={refresh}/>}
    </Datagrid>
  </List>
}
