import React, { useEffect, useState } from 'react';
import TeamsIcon from '@material-ui/icons/SupervisorAccountOutlined';
import SitesIcon from '@material-ui/icons/Place';
import MonitoringProviderIcon from '@material-ui/icons/Security';
import AlertPanelIcon from '@material-ui/icons/Smartphone';
import NotificationIcon from '@material-ui/icons/NotificationsActive';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import { Link } from 'ra-ui-materialui';
import { useTranslate, useDataProvider, useAccount } from 'hooks';
import { checkPermissions } from 'components';
import UIBox from 'components/UIBox';
import { useNotify } from 'ra-core';

const ResourceBox = ({ permissions, title, resource, timeSource = 'updatedAt', data, ...props }) => {
  const translate = useTranslate();
  const source = props.source || resource;
  const visible = checkPermissions(permissions, `${resource}:view`) && data && data[source];
  return visible ? (
    <GridItem xs={12} sm={6} md={3}>
      <UIBox
        {...props}
        title={translate(title || `resources.${resource}.name`, { smart_count: 2 })}
        subTitle={<Link to={`/${resource}`}>{data[source]['total']}</Link>}
        time={data[source]['updatedAt']}
      />
    </GridItem>
  ) : (
    <span />
  );
};

export default ({ permissions }) => {
  const account = useAccount() || {};
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const useV2Routes = localStorage.getItem('useRoutesV2');
  const [chartData, setChartData] = useState({});
  const clientManager = account.SiteId == null;
  useEffect(() => {
    dataProvider
      .fetch(`dashboard${useV2Routes ? '' : '/client'}`)
      .then(({ data }) => {
        setChartData(data.data);
      })
      .catch((e) => notify(e.message, 'warning'));
  }, [dataProvider, notify, setChartData, useV2Routes]);

  return (
    <div>
      <GridContainer>
        {clientManager && (
          <>
            <ResourceBox permissions={permissions} data={chartData} resource="sites" icon={<SitesIcon />} />
            <ResourceBox permissions={permissions} data={chartData} resource="teams" icon={<TeamsIcon />} />
            <ResourceBox
              permissions={permissions}
              data={chartData}
              resource="monitoring-providers"
              icon={<MonitoringProviderIcon />}
            />
          </>
        )}
        <ResourceBox permissions={permissions} data={chartData} resource="maps" icon={<AlertPanelIcon />} />
      </GridContainer>
      <hr />
      <GridContainer>
        <ResourceBox
          permissions={permissions}
          data={chartData}
          color="warning"
          resource="alerts"
          icon={<NotificationIcon />}
        />
      </GridContainer>
    </div>
  );
};
