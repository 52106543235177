export class Debounce {
  constructor() {
    this.timeout = null;
  }

  debounced = (callback, wait) => {
    this.clear();
    this.timeout = setTimeout(callback, wait);
  };

  clear = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  };
}

