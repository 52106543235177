import { genericErrorHandler } from 'lib/Errors';

export const loadClientAndProjects = async ({
  dataProvider,
  setProjects,
  clientId,
  filters,
  dispatch,
  setClients,
  shouldLoadClients,
}) => {
  if (shouldLoadClients) {
    const { data: clients } = await dataProvider.getList('clients', { filter: { limit: 1000 } });
    setClients(clients);
  }

  await loadProjects({ dataProvider, setProjects, clientId, filters, dispatch });
};

export const loadProjects = async ({ dataProvider, setProjects, clientId, filters, dispatch }) => {
  const { data: projects } = await dataProvider.getList('projects', {
    filter: {
      ClientId: clientId,
      limit: filters.limit,
      offset: filters.offset,
      ...(filters.order && filters.orderBy
        ? {
            order: `${filters.orderBy} ${filters.order.toUpperCase()}`,
          }
        : {}),
    },
  });

  dispatch(projects);
  setProjects(projects);
};

export const handleCreateApp =
  ({ dataProvider, close, onCreate, template, notify }) =>
  async (formData) => {
    const { name, projectId, isDefault, config, description, clientId } = formData;

    try {
      const createAppBody = {
        name,
        projectId,
        isDefault,
        clientId,
        description,
      };

      if (template) {
        createAppBody.appTemplateId = template.id;
        createAppBody.nickname = config?.nickname;
      }

      const { data: app } = await dataProvider.create('apps', {
        data: createAppBody,
      });

      if (!template) {
        await dataProvider.patch(`apps/${app?.id}/schema`, { config: config || {}, label: name });
      }

      close();
      if (onCreate) {
        onCreate(app);
      }
    } catch (err) {
      genericErrorHandler(notify)(err);
    }
  };
