import React, { useState, useEffect } from 'react';

import { useDataProvider, useTranslate } from 'ra-core';
import { AddCircle } from '@material-ui/icons';
import { LabeledSwitch } from 'components/designSystem/LabeledSwitch';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { ListFilter } from 'components/designSystem/ListFilter';
import { Table } from 'components/designSystem/Table';
import { teamsColumns, loadTeams, handleCreate } from './Teams.model';
import { useListFilters } from 'hooks';
import { useHistory } from 'react-router';
import { useTeams } from 'hooks/use-resource-hooks';

const TeamsList = ({ client, setHandleRefresh }) => {
  const t = useTranslate(); //verificar
  const dataProvider = useDataProvider();
  const history = useHistory();
  const { teams: stateTeams, dispatch } = useTeams(client.id);
  const [loading, setLoading] = useState(false);

  const filters = useListFilters();
  const [teams, setTeams] = useState(stateTeams);
  const [totalTeams, setTotalTeams] = useState(undefined);

  const reload = () => loadTeams(dataProvider, setTeams, setTotalTeams, client.id, filters, dispatch, setLoading);
  useEffect(() => setHandleRefresh(() => reload), []); //eslint-disable-line

  useEffect(() => {
    reload();
  }, filters.propList); //eslint-disable-line

  return (
    <div>
      <ListFilter
        setQSearch={filters.set.qSearch}
        actions={[
          <PrimaryButton
            key="client_create_team_button"
            label={t('resources.teams.create')} //alterar
            icon={<AddCircle />}
            onClick={handleCreate(client.id, history)}
          />,
        ]}
      >
        <LabeledSwitch
          label={t('fields.showInactive')}
          onChange={filters.set.showInactive}
          value={filters.showInactive}
          reverse
        />
      </ListFilter>
      <Table
        loading={loading && teams.length === 0}
        onRowClick={(row) => history.push(`/teams/${row.id}/show`)}
        data={teams}
        columns={teamsColumns(dataProvider, reload)}
        onSort={filters.handleRequestSort}
        order={filters.order}
        orderBy={filters.orderBy}
        totalRecords={totalTeams}
        rowsPerPage={filters.limit}
        page={filters.offset / filters.limit}
        onChangePage={(value) => filters.set.offset(value * filters.limit)}
        onChangeRowsPerPage={filters.set.limit}
        noDataMessage={t('resources.teams.noDataMessage')}
      />
    </div>
  );
};

export const Teams = ({ client, setHandleRefresh }) => {
  if (!client) {
    return <div></div>;
  }
  return <TeamsList client={client} setHandleRefresh={setHandleRefresh} />;
};
