import React from 'react';

import { AlertGeneralPanel } from './alert/AlertGeneralPanel';
import { UrlGeneralPanel } from './url/UrlGeneralPanel';
import { PhoneGeneralPanel } from './phone/PhoneGeneralPanel';
import { PdfGeneralPanel } from './pdf/PdfGeneralPanel';
import { BroadcastGeneralPanel } from './broadcast/BroadcastGeneralPanel';
import { IntercomGeneralPanel } from './intercom/IntercomGeneralPanel';
import { GroupGeneralPanel } from './group/GroupGeneralPanel';
import { BubbleGeneralPanel } from './bubble/BubbleGeneralPanel';
import { WhatsAppGeneralPanel } from './whatsapp/WhatsAppGeneralPanel';
import { FaceRecognitionGeneralPanel } from './faceRecognition/FaceRecognitionGeneralPanel';

export const GeneralPanel = ({ channel, parentChannels, readOnly }) => {
  switch (channel.channelType) {
    case 'alert':
      return <AlertGeneralPanel channel={channel} parentChannels={parentChannels} />;
    case 'bubble':
      return <BubbleGeneralPanel channel={channel} parentChannels={parentChannels} />;
    case 'url':
      return <UrlGeneralPanel channel={channel} parentChannels={parentChannels} />;
    case 'phone':
      return <PhoneGeneralPanel channel={channel} parentChannels={parentChannels} />;
    case 'whatsapp':
      return <WhatsAppGeneralPanel channel={channel} parentChannels={parentChannels} />;
    case 'pdf':
      return <PdfGeneralPanel channel={channel} parentChannels={parentChannels} readOnly={readOnly} />;
    case 'broadcast':
      return <BroadcastGeneralPanel channel={channel} parentChannels={parentChannels} />;
    case 'intercom':
      return <IntercomGeneralPanel channel={channel} parentChannels={parentChannels} />;
    case 'group':
      return <GroupGeneralPanel channel={channel} parentChannels={parentChannels} />;
    case 'face-recognition':
      return <FaceRecognitionGeneralPanel channel={channel} parentChannels={parentChannels} />;
    default:
      return <div></div>;
  }
};
