import React from 'react';
import TreeHelper from './helpers/TreeHelper';
import Rule from './Rule';
import { Select, MenuItem, Button, Icon, makeStyles, FormControl, Typography } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { find, isEmpty } from 'lodash';

const useStyles = makeStyles({
  child: {
    marginLeft: '1rem',
  },
  condition: {
    border: '1px solid #CCC',
    padding: 5,
  },
});

function Condition({ nodeName, fieldValues, onChange, config, data, readonly }) {
  const translate = useTranslate();
  const treeHelper = new TreeHelper(data);
  const node = treeHelper.getNodeByName(nodeName);
  const classes = useStyles();

  const combinatorChange = ({ target: { value } }) => {
    node.combinator = value;
    onChange(data);
  };
  const addCondition = () => {
    const nodeName = treeHelper.generateNodeName(node);
    node.rules.push({
      combinator: config.combinators[0].combinator,
      nodeName,
      rules: [],
    });
    onChange(data);
  };
  const addRule = () => {
    const nodeName = treeHelper.generateNodeName(node);
    node.rules.push({
      field: '',
      operator: config.operators[0].operator,
      value: '',
      nodeName,
    });
    onChange(data);
  };

  const handleDelete = () => {
    treeHelper.removeNodeByName(nodeName);
    onChange(data);
  };
  const handleChildUpdate = () => onChange(data);

  return (
    <div className={classes.condition}>
      {!readonly && (
        <FormControl>
          <Select value={node.combinator} onChange={combinatorChange}>
            {config.combinators.map((combinator, index) => (
              <MenuItem value={combinator.combinator} key={index}>
                {combinator.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {readonly && (
        <Typography>
          {!isEmpty(node.rules)
            ? find(config.combinators, { combinator: node.combinator }).label
            : translate('titles.empty')}
        </Typography>
      )}
      {!readonly && <Button onClick={addCondition}>{translate('actions.addGroup')}</Button>}
      {!readonly && <Button onClick={addRule}>{translate('actions.addRule')}</Button>}
      {nodeName !== '1' && !readonly ? (
        <Button onClick={handleDelete}>
          <Icon>delete</Icon>
        </Button>
      ) : null}
      {node.rules.map((rule, index) => (
        <div className={classes.child} key={index}>
          {rule.field != null && (
            <Rule
              fieldValues={fieldValues}
              operators={config.operators}
              nodeName={rule.nodeName}
              data={data}
              onChange={handleChildUpdate}
              readonly={readonly}
            />
          )}
          {rule.combinator != null && (
            <Condition
              fieldValues={fieldValues}
              config={config}
              nodeName={rule.nodeName}
              data={data}
              onChange={handleChildUpdate}
              readonly={readonly}
            />
          )}
        </div>
      ))}
    </div>
  );
}
export default Condition;
