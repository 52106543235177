import React, { useEffect, useState } from 'react';
import { UserMenu, Button } from 'ra-ui-materialui';
import { useLogout } from 'ra-core';
import MenuItem from '@material-ui/core/MenuItem';
import UserCard from '../../layout/components/UserCard';
import { makeStyles } from '@material-ui/styles';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import LoginIcon from '@material-ui/icons/Fingerprint';
import _ from 'lodash';
import ProfileImage from '../../layout/components/ProfileImage';
import { useProfiles, useTranslate } from 'hooks';
import { useHistory } from 'react-router';
import { Modal } from '@material-ui/core';
import { Button as CustomButton } from '../Button';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    padding: '1.5rem 2rem',
    borderRadius: '4px',
    boxSizing: 'border-box',
    width: '472px',
    maxWidth: '100vw',
  },
  modalWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  modalTitle: {
    margin: '0',
    padding: '0',
    fontFamily: 'Nunito, sans-serif',
    fontWeight: '400',
    fontSize: '1.5rem',
  },
  modalDescription: {
    fontFamily: 'Nunito, sans-serif',
    fontSize: '1.125rem',
  },
  modalFooterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  userMenuButton: {
    width: '24px',
    height: '24px',
  },
});

const LogoutModal = ({ isOpen, handleClose, modalMode }) => {
  const classes = useStyles();
  const history = useHistory();
  const logout = useLogout();
  const translate = useTranslate();

  let title;
  let confirmButton = undefined;
  let description = undefined;
  let handleConfirm;

  switch (modalMode) {
    case 'login':
      title = 'authentication.loginRedirectConfirm';
      confirmButton = 'authentication.goToLogin';
      description = 'authentication.goToLoginDescription';
      handleConfirm = () => history.push('/login');
      break;
    case 'logout':
      title = 'authentication.logoutConfirm';
      confirmButton = 'authentication.logout';
      handleConfirm = () => logout();
      break;
    default:
      title = '';
      handleConfirm = () => {};
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={classes.modalContainer}>
        <div className={classes.modalWrapper}>
          <h2 className={classes.modalTitle}>{translate(title)}</h2>
          {description && (
            <p className={classes.modalDescription}>{translate(description)}</p>
          )}
          <div className={classes.modalFooterContainer}>
            <CustomButton
              label={translate(confirmButton)}
              type="button"
              onClick={handleConfirm}
              variant="secondary"
            />
            <CustomButton
              label={translate('cancel')}
              type="button"
              onClick={handleClose}
              variant="primary"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Logout = ({ handleLogout, handleLogin }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.footer}>
        <Button label="titles.login" onClick={handleLogin}>
          <LoginIcon />
        </Button>
        <Button label="titles.logout" onClick={handleLogout}>
          <LogoutIcon />
        </Button>
      </div>
    </>
  );
};

export default (props) => {
  const profiles = useProfiles();
  const account = profiles[0];
  const history = useHistory();
  const [modalMode, setModalMode] = useState(undefined);
  const classes = useStyles();

  useEffect(() => {
    if (profiles.length === 0) {
      history.push('/login');
    }
  }, [profiles]); //eslint-disable-line

  const handleLogin = () => setModalMode('login');
  const handleLogout = () => setModalMode('logout');
  const handleCloseModal = () => setModalMode(undefined);

  return (
    <UserMenu
      {...props}
      logout={
        <Logout
          account={account}
          handleLogout={handleLogout}
          handleLogin={handleLogin}
        />
      }
      icon={
        <ProfileImage
          profile={account}
          variant="menu"
          className={classes.userMenuButton}
        />
      }
    >
      {_.map(profiles, (profile) => (
        <MenuItem key={profile.id}>
          <UserCard
            profile={profile}
            account={account}
            handleLogout={handleLogout}
            {...props}
          />
        </MenuItem>
      ))}
      <hr />
      <LogoutModal
        isOpen={!!modalMode}
        handleClose={handleCloseModal}
        modalMode={modalMode}
      />
    </UserMenu>
  );
};
