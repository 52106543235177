import React, { useEffect, useState, useCallback, useLayoutEffect } from "react"
import "whatwg-fetch"	
import { useRouter } from "hooks";
import { parse } from "query-string"
import { useTranslate, useNotify } from "ra-core";
import dataProvider from "lib/dataProvider"
import config from "config"
import { find, flatMap} from "lodash"
import { Loading, Notification} from "ra-ui-materialui";
import { makeStyles } from "@material-ui/styles";


const apiProviderV1 = dataProvider(config.apiUrl.replace("/management", "/mobile"))
const apiProviderV2 = dataProvider(config.apiUrl.replace("/management", "/mobile/v2"))

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        height: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        height: '100vh',
        marginTop: '-3em',
      },
    },    
    message: {
      textAlign: 'center',
      fontFamily: 'Roboto, sans-serif',
      opacity: 0.5,
      margin: '0 1em',
    },
  })
);
const Error = ({title, message}) => {
  const classes = useStyles( );
  const translate = useTranslate();

  return <div className={classes.container}>
    <div className={classes.message}>
      <h1>{translate(title)}</h1>
      <div>{translate(message)}</div>
    </div>
  </div>
}

export default () => {
  const translate = useTranslate()
  const notify    = useNotify()
  const { location, history, match: { params: {id} }} = useRouter()  
  const { token, latitude, longitude, ChannelId, profileId} = parse(location.search)
  const [config, setConfig] = useState()
  const apiProvider = profileId ? apiProviderV2 : apiProviderV1
  
  const onComplete = useCallback(({ data }) => {
    apiProvider.execute(`surveys/${id}/result`, { data, latitude, longitude, ChannelId, profileId }, { token })
      .then(() => {
        notify("notifications.surveySuccess")
        history.push("/static/surveys/finish")
        document.location = document.location.href
      })
      .catch(error => notify(error.message))
  }, [id, token, notify, history, latitude, longitude, ChannelId, profileId, apiProvider])

  useEffect(() => {
    const { Survey } = window
    Survey.StylesManager.applyTheme("darkblue")    
    document.title = translate("resources.surveys.name", { smart_count: 1})    
  }, [translate])

  useLayoutEffect(() => {
    if(id !== "finish") {
      apiProvider.fetch("config", { profileId }, { token }).then(({ data: { channels, profiles } }) => {
        if(profiles) {
          channels = find(profiles, ["id", parseInt(profileId)]).channels
        }
        const surveyChannel = find(flatMap(channels, "surveys"), ["id", parseInt(id)])
        if (surveyChannel) {
          setConfig(surveyChannel.config)
          const survey = new window.Survey.Model(surveyChannel.config)
          survey.onComplete.add(result => {
            onComplete(result)
          })
          survey.render("surveyElement")
        } else {
          setConfig({})
          notify("ra.page.not_found", "error")
        }
      }).catch(error => notify(error.message))
    }
  
  }, [notify, token, id, apiProvider, setConfig, profileId, onComplete])
  
  const model = config && config.pages ? new window.Survey.Model(config) : null
  return id === "finish" ? <div></div> : <div style={{paddingLeft: 20, paddingRight: 20}}>
    <Notification />
    {!config && <Loading />}
    {config && !model && <Error title="ra.page.not_found" message="ra.page.not_found" />}
    {model && <div id="surveyElement"></div>}
  </div>  
} 