
import React from "react"
import { SimpleForm, TextInput,  } from "ra-ui-materialui"
import { useUpdate,  useNotify } from "ra-core"
import { QuickCreateButton } from "components"
import SchemaInput from "resources/Common/SchemaInput"


export function Create({ record, ClientId, ...props }) {
  return <QuickCreateButton defaultValues={{ClientId}} record={record} {...props} resource="registration-forms" >
    <SimpleForm toolbar={null} resource="registration-forms" record={record}>
      <TextInput source="name" />
      <TextInput source="schema.title" />      
    </SimpleForm>
  </QuickCreateButton>
}
export default function RegistrationForm({ record, readonly }) {
  const [update] = useUpdate("registration-forms", record.id, {}, {}, {})
  const notify = useNotify()
  const save = data => update({ payload: { data } }, { 
    onSuccess: () => notify("Updated"), 
    onFailure: error => notify(error.message, "error") 
  })
  return <SimpleForm resource="registration-forms" initialValues={record} save={save}>
    <TextInput source="name" disabled={readonly}/>
    <TextInput source="schema.title" disabled={readonly}/>
    <SchemaInput source="schema.fields" label={""} allowComments={false} readonly={readonly} />
  </SimpleForm>
}