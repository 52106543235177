import React, { useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle } from 'ra-core';
import TextField from '@material-ui/core/TextField';
import * as ReactColor from 'react-color';
import {get} from "lodash";
import pure from 'recompose/pure';

require('./assets/ColorInput.css');

const ColorFieldComponent = ({ source, record = {}, className }) =>
  (
    <div style={{ display: 'flex' }}>
      <div style={{
        width: '20px',
        height: '20px',
        background: get(record, source),
        marginRight: '5px',
      }}
      />
      <span className={className}>{get(record, source)}</span>
    </div>
  );

ColorFieldComponent.propTypes = {
  addLabel: PropTypes.bool,
  className: PropTypes.string,
  elStyle: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const PureTextField = pure(ColorFieldComponent);

PureTextField.defaultProps = {
  addLabel: true,
};


const ColorInputComponent = props => {
  const { label, source, disabled, className, options, picker, resource, helperText, margin = "normal"}  = props;
  const {input, meta, isRequired} = useInput(props)
  const { touched, error, } = meta;
  const Picker = ReactColor[`${picker}Picker`];
  const [open, setOpen] = useState(false) 
  const handleOpen = useCallback(() => {
    setOpen(true)
    setTimeout(() => document.querySelector(".ColorInput-popup .chrome-picker input").focus(), 100)
  }, [setOpen])
  const handleClose = useCallback(() => setOpen(false), [setOpen])
  const handleChange = ({ hex }) => {
    input.onChange(hex)    
  }
  
  return (
    <>
      <TextField
        {...input}
        margin={margin}
        autoComplete="off"
        onFocus={handleOpen}
        disabled={disabled}
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
        error={!!(touched && error)}
        helperText={(touched && error) ? error : helperText}
        className={className}
      />
      {
        open ?
          <div className="ColorInput-popup" >
            <div
              className="ColorInput-cover"
              onClick={handleClose}
            />
            <Picker
              {...options}
              color={input.value}
              onChange={handleChange}
            />
          </div>
          : null
      }
    </>
  )
}
ColorInputComponent.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  source: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  className: PropTypes.string,
  picker: (props, propName, componentName) =>
    !ReactColor[`${props[propName]}Picker`] &&
    new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`.`)
};

ColorInputComponent.defaultProps = {
  picker: 'Chrome',
  options: {
    disableAlpha: true
  },
};

export const ColorField = PureTextField;
export const ColorInput = ColorInputComponent;
