import React from 'react';
import { TextInput, BooleanInput, SimpleForm } from 'ra-ui-materialui';
import { QuickCreateButton, required } from 'components';

export default (props) => (
  <QuickCreateButton {...props} resource="apps">
    <SimpleForm toolbar={null} resource="apps">
      <TextInput source="name" validate={required()} />
      <TextInput source="config.nickname" />
      <BooleanInput source="isDefault" defaultValue={true} />
    </SimpleForm>
  </QuickCreateButton>
);
