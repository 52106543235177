import React from 'react';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import SchemaInput from 'resources/Common/SchemaInput';
import { useTranslate } from 'ra-core';

export const StructurePanel = ({ loading, readOnly }) => {
  const translate = useTranslate();
  const t = useTranslate();

  const inputProps = {
    resource: 'channels',
    translate,
    disabled: false,
    options: { disabled: false },
  };

  return (
    <div>
      <div>
        <TextFormField
          name="config.schema.title"
          size="medium"
          full
          disabled={loading}
          label={t('resources.channels.schema.schemaTitle')}
        />
      </div>
      <div>
        <SchemaInput source="config.schema.fields" label={''} readonly={loading || readOnly} {...inputProps} />
      </div>
    </div>
  );
};
