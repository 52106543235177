import React, { useContext, useEffect, useReducer } from 'react';
import { GrowthBook } from '@growthbook/growthbook';
import config from 'config';
import 'whatwg-fetch';

import { reducer } from './reducers';
import { setGbFeatures } from './actions/growthbook';

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const getInitialState = () => {
  return {
    apps: [],
    appSchemas: [],
    clients: [],
    channels: [],
    licenses: [],
    projects: [],
    providers: [],
    sites: [],
    teams: [],
    terms: [],
    users: [],
    growthBook: new GrowthBook(),
  };
};

export const GlobalState = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const reloadGrowthbook = () => {
    fetch(config.growthbookUrl)
      .then((data) => data.json())
      .then(({ features }) => {
        dispatch(setGbFeatures(features));
        // temporary set v2 routes to localstorage, remove this later
        localStorage.setItem('useRoutesV2', features['new-client-sasi-mgmt-routes']?.defaultValue ?? false);
      })
      .catch(() => console.error('Could not reload GBs features'));
  };

  const dispatchWithGB = (args) => {
    dispatch(args);
    reloadGrowthbook();
  };

  useEffect(() => {
    reloadGrowthbook();
  }, []); // eslint-disable-line

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatchWithGB}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  return useContext(GlobalStateContext);
};
