import { useState } from 'react';
import { useHistory } from 'react-router';

import { useURLQueryParams } from './use-react-router';

export const useTabs = (tabs, { defaultTab, urlTabParam, noURL }) => {
  const history = useHistory();
  const queryParams = useURLQueryParams();
  const _urlTabParam = urlTabParam || 'tab';

  const [tab, setTab] = useState(!noURL ? tabs[queryParams.get(_urlTabParam)] || tabs[defaultTab] : tabs[defaultTab]);

  const handleSetTab = (tab) => {
    if (!noURL) {
      const key = Object.keys(tabs).find((key) => tabs[key] === tab);
      queryParams.set('tab', key);
      history.push({ search: queryParams.toString() });
    }
    setTab(tab);
  };

  return [tab, handleSetTab];
};
