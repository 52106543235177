import React, { useState /*, { useCallback }*/ } from 'react';

import { Datagrid, TextField /*FunctionField, SimpleForm, TextInput*/ } from 'ra-ui-materialui';
import { List, QuickDeleteButton, DefaultActions, checkPermissions } from 'components';

// import { useTranslate, useNotify, useDataProvider, required } from 'ra-core';

import useRefreshWithVersion from 'hooks/use-refresh';
import { useHistory } from 'react-router';
import { CreateAppDialog } from './components/CreateAppDialog';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { AddCircle } from '@material-ui/icons';
import { useClient } from 'hooks/use-record';
import { useAccount } from 'hooks';

export default ({ permissions, ...props }) => {
  const account = useAccount();
  const [refresh] = useRefreshWithVersion();
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [client] = useClient();
  const history = useHistory();

  const channelsEditable = !client?.locked && checkPermissions(permissions, 'channels:update');

  return (
    <>
      <CreateAppDialog
        isOpen={isCreateDialogOpen && account?.ClientId}
        refresh={refresh}
        close={() => setCreateDialogOpen(false)}
      />
      <List
        {...props}
        filter={{
          withSchema: true,
        }}
        actions={
          <DefaultActions hasCreate={false}>
            {channelsEditable && account?.ClientId && (
              <PrimaryButton
                onClick={() => setCreateDialogOpen(true)}
                key="client_create_app_button"
                label="Criar App"
                icon={<AddCircle />}
              />
            )}
          </DefaultActions>
        }
      >
        <Datagrid rowClick={(appId) => history.push(`/apps/${appId}/show`)}>
          <TextField source="name" />
          <TextField
            source="description"
            fullWidth
            style={{ wordWrap: 'break-word', maxWidth: '60vw', display: 'inline-block' }}
          />
          {/* <FunctionField
            render={(record) => (
              <CopyButton name="CopyApp" value={record} notification={translate('notifications.appCopied', record)} />
            )}
          /> */}
          <QuickDeleteButton resource="apps" onDelete={refresh} />
        </Datagrid>
      </List>
    </>
  );
};
