import React, { useState } from 'react'
import { useTimer } from "../hooks";
import { Button } from "./Button";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  label: {
    fontFamily: 'Nunito, sans-serif',
    color: '#444',
    fontSize: '0.875rem',
    margin: 0,
    padding: 0,
    marginBottom: '.3rem',
  },
  timeLabel: {
    fontFamily: 'Nunito, sans-serif',
    color: '#444',
    fontSize: '1.125rem',
    margin: 0,
    padding: 0,
    marginBottom: '.3rem',
  },
}));

export const TimedRequestButton = ({ sendingLabel, sentLabel, resend, buttonLabel, timeLimit }) => {
  const classes = useStyles();
  const { time, restartTimer } = useTimer(timeLimit || 150);
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);
    await resend();
    restartTimer();
    setLoading(false);
  }

  return (
    <div className={classes.container}>
      <p className={classes.label}>{time > 0 ? sendingLabel : sentLabel}</p>
      {time > 0
        ? (
          <p className={classes.timeLabel}>{Math.floor(time / 60)}:
            {(time % 60).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </p>
        )
        : (
          <Button
            type="button"
            label={buttonLabel}
            variant="secondary"
            onClick={onClick}
            disabled={loading}
          />
        )
      }
    </div>
  )
}
