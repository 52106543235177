import React from "react"
import { Datagrid, EditButton} from "ra-ui-materialui"
import { List, DateField, ShowLink}   from "components"


export default (props) => {
  return (
    <List {...props} >
      <Datagrid>
        <ShowLink source="name" />
        <DateField source="publishedAt" showTime={false}/>
        {props.hasEdit && <EditButton />}
      </Datagrid>
    </List>
  )
}
