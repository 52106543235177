import React from 'react';
import { BooleanInput } from 'ra-ui-materialui';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  hideHelperText: {
    '& p': {
      display: 'none',
    },
  },
});
export default ({ allowEmpty, alwaysOn, label, helperText, ...props }) => {
  const classes = useStyles();
  return (
    <FormControl {...props} margin={'full'} className={classes.hideHelperText}>
      <BooleanInput label={label} resource={props.resource} source={props.source} type="checkbox" />
    </FormControl>
  );
};
