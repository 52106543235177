import React, { useState, useEffect } from 'react';
import { map } from 'lodash';
import { useGlobalState } from 'state';
import { SimpleForm, TextInput, BooleanInput, ReferenceInput, SelectInput } from 'ra-ui-materialui';
import { useUpdate, required, minLength, maxLength, useNotify, useDataProvider } from 'ra-core';
import { QuickCreateButton } from 'components';
import MatchConfigBuilder from 'components/MatchConfigBuilder';
import { useForm, useFormState } from 'react-final-form';
import { CODE_LENGTH } from 'lib/consts';

const Field = ({ record, options, ...props }) => {
  const { title, name, view, type, choices, defaultValue } = options;
  const label = title || `fields.${name}`;
  switch (view || type) {
    case 'checkbox':
      return <BooleanInput record={record} label={label} {...props} defaultValue={defaultValue} />;
    case 'dropdown':
      return <SelectInput record={record} choices={choices} label={label} {...props} defaultValue={defaultValue} />;
    case 'text':
    default:
      return <TextInput record={record} label={label} {...props} defaultValue={defaultValue} />;
  }
};

const RegHandlerV2Fields = ({ record }) => {
  const form = useForm();
  const { values } = useFormState();
  const dataProvider = useDataProvider();
  const [formFields, setFormFields] = useState(record.RegistrationForm.schema?.fields?.map((f) => f.name) ?? []);
  const [dataFields, setDataFields] = useState([]);
  const [regDataSchemaList, setRegDataSchemaList] = useState([]);
  const [regDataSchemaLoading, setRegDataSchemaLoading] = useState(true);
  const [regFormList, setRegFormList] = useState([]);
  const [regFormLoading, setRegFormLoading] = useState(true);
  const [regHandlerList, setRegHandlerList] = useState([]);
  const [regHandlerLoading, setRegHandlerLoading] = useState(true);
  const [disableAutoApprove, setDisableAutoApprove] = useState(!!record.RegistrationHandlerV2.id);
  const [showMatchConfig, setShowMatchConfig] = useState(record.RegistrationHandlerV2.schema?.matchConfig);

  const loadRegDataSchemas = () =>
    dataProvider
      .getList('registration-data/schemas', { filter: { ClientId: record.ClientId } })
      .then(({ data }) => {
        setRegDataSchemaList(data);
        const fields = data.find((r) => r.id === record?.config?.matchConfig?.regDataSchemaId)?.schema?.fields ?? [];
        setDataFields(fields);
      })
      .finally(() => setRegDataSchemaLoading(false));

  useEffect(() => {
    dataProvider
      .getList('registration-forms', { filter: { ClientId: record.ClientId } })
      .then(({ data }) => setRegFormList(data))
      .finally(() => setRegFormLoading(false));

    dataProvider
      .getList('v2/registration-handlers', { filter: { clientId: record.ClientId, showInternal: true } })
      .then(({ data }) => setRegHandlerList(data))
      .finally(() => setRegHandlerLoading(false));

    if (showMatchConfig) {
      loadRegDataSchemas();
    }
  }, []); // eslint-disable-line

  const onRegDataChange = ({ target: { value } }) => {
    form.change('config.matchConfig.fieldMatches', []);
    if (!value) {
      return setDataFields([]);
    }
    const fields = regDataSchemaList.find((r) => r.id === value)?.schema?.fields ?? [];
    setDataFields(fields);
  };

  const onRegFormChange = ({ target: { value } }) => {
    if (!value) {
      return setFormFields([]);
    }
    const fields = regFormList.find((r) => r.id === value)?.schema?.fields?.map((f) => f.name) ?? [];
    setFormFields(fields);
  };

  const onRegHandlerChange = ({ target: { value } }) => {
    form.change('config', { autoApprove: false });
    if (!value) {
      setDisableAutoApprove(false);
      setShowMatchConfig(false);
      return;
    }
    const matchConfig = regHandlerList.find((r) => r.id === value)?.schema?.matchConfig ?? false;
    setShowMatchConfig(matchConfig);
    setDisableAutoApprove(true);
    if (!regDataSchemaList.length) {
      loadRegDataSchemas();
    }
  };

  return (
    <div>
      <SelectInput
        source="RegistrationForm.id"
        label="Registration Form"
        choices={regFormList}
        allowEmpty
        loading={regFormLoading}
        optionText="name"
        onChange={onRegFormChange}
      />
      <SelectInput
        source="RegistrationHandlerV2.id"
        label="Registration Handler"
        choices={regHandlerList}
        allowEmpty
        loading={regHandlerLoading}
        disabled={values.config.autoApprove}
        optionText="name"
        onChange={onRegHandlerChange}
      />
      <BooleanInput
        record={record}
        label="fields.autoApprove"
        source="config.autoApprove"
        disabled={disableAutoApprove}
      />
      {showMatchConfig && (
        <SelectInput
          source="config.matchConfig.regDataSchemaId"
          label="Registration Data"
          choices={regDataSchemaList}
          loading={regDataSchemaLoading}
          optionText="contentId"
          onChange={onRegDataChange}
        />
      )}
      {showMatchConfig && !regDataSchemaLoading && (
        <MatchConfigBuilder source="config.matchConfig" formFields={formFields} dataFields={dataFields} />
      )}
    </div>
  );
};

export function Create({ record, ClientId, mode, useRegHandlerV2, ...props }) {
  const defaultValue = {
    ...record,
    ...(mode === 'site' && !useRegHandlerV2 ? { RegistrationHandlerId: 1 } : {}),
  };
  return (
    <QuickCreateButton record={record} {...props} resource="registration-codes">
      <SimpleForm toolbar={null} resource="registration-codes" record={defaultValue}>
        <TextInput
          source="code"
          inputProps={{ maxLength: 6 }}
          validate={[minLength(6), maxLength(6)]}
          disabled={props.hasClient}
        />
        <ReferenceInput source="RegistrationFormId" reference="registration-forms" allowEmpty filter={{ ClientId }}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        {useRegHandlerV2 ? (
          <ReferenceInput
            source="regHandlerId"
            reference="v2/registration-handlers"
            label="Registration Handler"
            allowEmpty
            filter={{ clientId: ClientId, showInternal: true }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        ) : (
          <ReferenceInput
            validate={required()}
            source="RegistrationHandlerId"
            reference="registration-handlers"
            allowEmpty
            filter={{ ClientId, showGlobal: true, mode }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
      </SimpleForm>
    </QuickCreateButton>
  );
}

export default function RegistrationCode({ record = {} }) {
  const [update] = useUpdate('registration-codes', record.id, {}, {}, {});
  const notify = useNotify();
  const { growthBook } = useGlobalState();
  const useRegHandlerV2 = growthBook.isOn('reg-handler-v2') ?? false;

  if (record) {
    const save = (data) =>
      update(
        { payload: { data } },
        {
          onSuccess: () => notify('Updated', 'success'),
          onFailure: (error) => notify(error.message, 'error'),
        },
      );

    return (
      <SimpleForm resource="registration-codes" initialValues={record} save={save}>
        <TextInput source="code" inputProps={{ maxLength: CODE_LENGTH }} validate={[minLength(CODE_LENGTH), maxLength(CODE_LENGTH)]} />
        <ReferenceInput source="TermId" reference="terms" filter={{ ClientId: record.ClientId }} allowEmpty>
          <SelectInput optionText="title" />
        </ReferenceInput>
        {useRegHandlerV2 && <RegHandlerV2Fields record={record} />}
        {map(record[`RegistrationHandler${useRegHandlerV2 ? 'V2' : ''}`].schema?.fields, (field) => (
          <Field key={field.name} source={`config.${field.name}`} options={field} record={record} />
        ))}
      </SimpleForm>
    );
  }
  return <span />;
}
