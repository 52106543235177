import React from "react"
import {SaveButton, Toolbar} from "ra-ui-materialui"
import {refreshView, useRefresh, useUpdate, useCreate} from "ra-core"

const PanelToolbar = ({ record, resource, onChange, readonly, allowDelete, ...props}) => <Toolbar {...props}>
  {!readonly && <SaveButton label={record && record.id ? "ra.action.save" : "ra.action.create"} redirect={false} submitOnEnter={true} />}    
</Toolbar>


export default ({record, resource, basePath, onComplete, parentProps, children, ...props}) => {
  const allowDelete = record.deletable === undefined || record.deletable === true
  const refresh = useRefresh()
  const options = {
    onSuccess: onComplete || refresh,
    onFailure: onComplete || refresh
  }

  const [update] = useUpdate(resource, record.id, {}, {}, options)
  const [create] = useCreate(resource, {}, options)

  function save(data) {
    if(data.id)
      update({payload: { data } })
    else
      create({ payload: { data } })
  }
  const editProps = {
    basePath: basePath || `/${resource}`,
    resource: resource,
    id: `${record.id}`,
    record: record,
    initialValues: record,
    redirect: false,
    save: (data) => save({...data, ...parentProps})
  }
  return React.cloneElement(children, { ...editProps, toolbar: <PanelToolbar resource={resource} onChange={refreshView} allowDelete={allowDelete} {...props}/>})
}
