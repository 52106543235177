export const handleCreateTemplate =
  ({ dataProvider, schema, app, notify, setLoading, close }) =>
  async ({ tags, visibility, name, description }) => {
    setLoading(true);
    const tagArray = (tags || '').split(',').filter((tag) => tag !== '' && !!tag);

    try {
      const saveBody = {
        tags: tagArray,
        visibility,
        name,
        description,
        appId: app.id,
        clientId: app.clientId,
      };

      if (schema) {
        saveBody.appSchemaId = schema.id;
      }

      await dataProvider.execute('apps/templates', saveBody);
      notify('Template criado com sucesso!', 'success');
      close();
    } catch (e) {
      notify('Algo deu errado, tente novamente mais tarde!', 'error');
    } finally {
      setLoading(false);
    }
  };

export const loadTemplateVisibility = ({ dataProvider, setTemplateVisibility }) =>
  dataProvider.getList('apps/templates/params/visibility', {}).then(({ data }) => {
    setTemplateVisibility(data);
  });
