import { useState, useEffect, useCallback } from "react"
import Storage from "lib/storage"

export default function useCopyPaste(name) {
  const [value, setValue] = useState(Storage.getObject(name))
  useEffect(() => {
    Storage.addListener(name, setValue)
    return () => Storage.removeListener(name, setValue)
  }, [name, setValue])
  
  const copy = useCallback(val => Storage.setObject(name, val), [name])
  return [value, copy]
}