import React from 'react';

import { EmptyPane } from 'assets/EmptyPane';
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: theme.spacing(12),
  },
  halfContainer: {
    marginTop: theme.spacing(6),
  },
  label: {
    width: '40%',
    minWidth: '100px',
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightRegular,
  },
  customComponent: {
    marginTop: theme.spacing(2),
  },
}));

export const EmptyView = ({ text, translate, halfMargin, customComponent }) => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <div className={`${classes.container} ${halfMargin ? classes.halfContainer : ''}`}>
      <EmptyPane />
      <span className={classes.label}>{translate ? t(text) : text}</span>
      {customComponent ? <span className={classes.customComponent}>{customComponent}</span> : null}
    </div>
  );
};
