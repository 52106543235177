import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core';
import { useDataProvider, useNotify } from 'ra-core';

import { useAppSchemas } from 'hooks/use-resource-hooks';

import { PublishAppDialog } from '../components/PublishAppDialog';
import { Version } from '../components/Version';
import { handlePublishing, loadSchemas } from './Versions.model';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    marginTop: theme.spacing(2),
  },
}));

const VersionsView = ({ app, setCustomRefresh, refresh, handleNewTemplate }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { schemas: stateSchemas, dispatch } = useAppSchemas({ appId: app.id });
  const [schemas, setSchemas] = useState(stateSchemas);
  const [showPublishDialog, setShowPublishDialog] = useState(false);

  const reload = () => loadSchemas(dataProvider, setSchemas, app.id, dispatch);
  useEffect(() => {
    setCustomRefresh(() => reload);
    reload();
  }, []); //eslint-disable-line

  return (
    <div>
      <PublishAppDialog
        appId={app.id}
        isOpen={showPublishDialog}
        close={() => setShowPublishDialog(false)}
        refresh={() => {
          reload();
          refresh();
        }}
      />
      <div className={classes.listContainer}>
        {schemas.map((schema) => (
          <Version
            key={`app_${app.id}_version_history_item_${schema.id}`}
            schema={schema}
            // onDelete={(schema) => handleDelete(dataProvider, schema, app, reload, notify)}
            onVersionSelect={(schema) => {
              if (schema.status === 'draft') {
                setShowPublishDialog(true);
                return;
              }

              handlePublishing(dataProvider, schema, app, reload, notify);
            }}
            onRename={() => alert('!! WIP !!')}
            handleNewTemplate={handleNewTemplate}
          />
        ))}
      </div>
    </div>
  );
};

export const Versions = ({ app, setMenuItems, setCustomRefresh, refresh, handleNewTemplate }) => {
  useEffect(() => {
    setMenuItems([]);
  }, [setMenuItems]);

  if (!app) {
    return <div></div>;
  }
  return (
    <VersionsView
      app={app}
      setCustomRefresh={setCustomRefresh}
      refresh={refresh}
      handleNewTemplate={handleNewTemplate}
    />
  );
};
