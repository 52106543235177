import { SET_LICENSES } from '../actions/licenses';

export const licensesReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LICENSES:
      return {
        ...state,
        licenses: payload,
      };
    default:
      return undefined;
  }
};
