
import React from "react"
import { Datagrid, TextField } from "ra-ui-materialui"
import { List, DefaultActions, } from "components"
import { useClient } from "hooks/use-record"
import {  useRefresh } from "ra-core"
import DeleteWithConfirmButton from "components/QuickDeleteButton"
import RegistrationForm, { Create as CreateFormButton } from "resources/Common/RegistrationForm"

export default function RegistrationHandlersList({ permissions, ...props }) {
  const [client] = useClient()
  const refresh = useRefresh()
  return <List {...props}  actions={<DefaultActions >
    <CreateFormButton title="titles.registrationForms" ClientId={client.id} onSubmit={refresh} />
  </DefaultActions>}>
    <Datagrid expand={RegistrationForm} >
      <TextField source="name" />
      <TextField source="schema.title" />
      <DeleteWithConfirmButton onDelete={refresh} />
    </Datagrid>
  </List>
}