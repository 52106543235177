import { genericErrorHandler } from 'lib/Errors';

export const loadSchemas = (dataProvider, setSchemas, appId, dispatch) =>
  dataProvider.getList(`apps/${appId}/schemas`, {}).then(({ data }) => {
    setSchemas(data);
    dispatch(data);
  });

export const handlePublishing = async (dataProvider, schema, app, reload, notify) => {
  try {
    switch (schema.status) {
      case 'published':
        await dataProvider.execute(`apps/${app.id}/unpublish`, {});
        break;
      case 'unpublished':
        await dataProvider.execute(`apps/${app.id}/schema/${schema.versionId}`, {});
        break;
      case 'draft':
      default:
        await dataProvider.execute(`apps/${app.id}/publish`, {});
    }

    if (reload) {
      reload();
    }
  } catch (err) {
    genericErrorHandler(notify)(err);
  }
};

export const handleDelete = async (dataProvider, schema, app, reload, notify) => {
  try {
    await dataProvider.delete(`apps/${app.id}/schema`, { id: schema.id });

    if (reload) {
      reload();
    }
  } catch (err) {
    genericErrorHandler(notify)(err);
  }
};
