import React, { useState } from 'react';
import TreeHelper from './helpers/TreeHelper';
import { Select, MenuItem, Icon, TextField, Button, FormControl, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { includes, find, isArray, omitBy, isNil} from "lodash"
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles({
  rule: {
    display: "flex",
    borderRadius: 3,
    color: "#9E9E9E",
    marginTop: "0.2rem",
    border: "1px solid #eee",
    padding: "0.5rem",
    width: "95%",
    '& >* ': {
      marginLeft: "0.5rem",
    }
  },
  selectField: {
    minWidth: 147
  }
})
const ValueInput = ({ value, onChange, field, operator }) => {
  const classes = useStyles()
  const {type = "text", fieldType = "text", min, max} = field || {}
  
  if (type === "checkbox") return <Switch checked={value || false} onChange={(ev, value) => onChange({ target: { value}})} color="primary"/>
  if (type === "text") {
    const props = fieldType === "number" ? omitBy({min, max}, isNil) : {}
    return <TextField value={value} onChange={onChange} type={fieldType} {...props}/>
  }
  if (type === "rating") {
    const props = fieldType === "number" ? omitBy({ min, max }, isNil) : {}
    return <TextField value={value} onChange={onChange} type="number" {...props}/>
  }

  if (includes(["dropdown", "dropdown_search", "radios", "checkboxes"], type)) {
    const other = field.hasOther ? [{ id: "", name: field.otherText }] : []
    const values = field.choices || []
    const choices = [...values.map(({ text, value }) => ({ id: value, name: text })), ...other]
    const multiple = operator.multiple && includes(operator.multiple, type)
    const val = value ?
      (multiple ? (isArray(value) ? value : [value]) : (isArray(value) ? value[0] : value)) :
      (multiple ? [] : "")
    return <Select value={val} onChange={onChange} className={classes.selectField} multiple={multiple}>
      {choices.map((choice, index) => <MenuItem value={choice.id} key={index}>{choice.name}</MenuItem>)}
    </Select>
  }
  return <span />
}
function Rule({ fieldValues = [], nodeName, operators, onChange, data, readonly}) {
  const treeHelper = new TreeHelper(data)
  const node = treeHelper.getNodeByName(nodeName)
  const classes = useStyles()

  const getField = () => find(fieldValues, { name: node.field })
  const getOperator = () => find(operators, { operator: node.operator})

  const [field, setField] = useState(getField())
  const [operator, setOperator] = useState(getOperator())

  const onFieldChanged = event => {
    node.field = event.target.value
    onChange()
  }
  const onFieldSelected = event => {
    node.field = event.target.value
    node.value = ""
    setField(getField())
    onChange()
  }

  const onOperatorChanged = event => {
    node.operator = event.target.value;
    setOperator(getOperator())
    onChange()
  }
  const onInputChanged = event => {
    node.value = event.target.value;
    onChange()
  }

  const handleDelete = ()  =>{
    treeHelper.removeNodeByName(nodeName)
    onChange()
  }
  const operatorDisabled = (operator, field) => field && operator.types && !includes(operator.types, field.type)
  const isBinary = operator && operator.mode === "binary"

  if(readonly) {
    return <div className={classes.rule}>
      <Typography >{node.field}</Typography>
      <Typography >{node.operator}</Typography>
      <Typography >{node.value}</Typography>
    </div>
  }
  return (
    <div className={classes.rule}>
      <FormControl>
        {fieldValues.length > 0 && <Select className={classes.selectField} value={node.field} onChange={onFieldSelected}>
          {fieldValues.map((field, index) => <MenuItem value={field.name} key={index}>{field.title}</MenuItem>) }
        </Select>}
        {fieldValues.length === 0 && <TextField value={node.field} onChange={onFieldChanged} />}
      </FormControl>
      <FormControl>
      <Select className={classes.select} value={node.operator} onChange={onOperatorChanged}>
        {operators.map((operator, index) =>
          <MenuItem disabled={operatorDisabled(operator, field)} value={operator.operator} key={index}>{operator.label}</MenuItem>
        )}
      </Select>
      </FormControl>
      {isBinary && <FormControl>
        <ValueInput value={node.value} onChange={onInputChanged} field={field} operator={operator} />
      </FormControl>}      
      <Button onClick={handleDelete}>
        <Icon>delete</Icon>
      </Button>
    </div>
  )
}
export default Rule;