import React, { useState } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
// import { CheckCircle } from '@material-ui/icons';

import { Form } from 'react-final-form';
import { passwordConfirmValidate, required, requiredEmailField, nullablePasswordValidate } from 'lib/formValidators';
import { useDataProvider, useTranslate, useNotify } from 'ra-core';
import { handleEditUser } from './EditUserDialog.model';

const useStyles = makeStyles((theme) => ({
  bottomContainer: {
    display: 'flex',
    // alignItems: 'center',
    marginTop: theme.spacing(3),
    justifyContent: 'end',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    alignItems: 'center',

    '& button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginLeft: 16,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20px',
  },
}));

export const EditUserDialog = ({ user, isOpen, close, refresh }) => {
  const t = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog isOpen={isOpen} close={close} title={t('resources.users.titleEdit')}>
      <Form
        className={classes.form}
        initialValues={{ ...user }}
        onSubmit={handleEditUser({ dataProvider, close, setLoading, refresh, notify })}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.content}>
              <TextFormField
                name="name"
                validate={required(t)}
                full
                dense
                size="full"
                label={t('resources.users.nameUser')}
                placeholder={t('resources.users.placeholderName')}
              />
            </div>
            <div className={classes.content}>
              <TextFormField
                name="username"
                validate={required(t)}
                full
                dense
                size="full"
                label="Username"
                placeholder={t('resources.users.placeholderUsername')}
              />
            </div>
            <div className={classes.content}>
              <TextFormField
                name="email"
                validate={requiredEmailField(t)}
                full
                dense
                size="full"
                label="Email"
                placeholder={t('resources.users.placeholderEmail')}
              />
            </div>

            <div className={classes.content}>
              <TextFormField
                name="password"
                validate={nullablePasswordValidate(t)}
                full
                dense
                password
                size="full"
                label={t('resources.users.password')}
                placeholder={t('resources.users.placeholderPassword')}
              />
            </div>

            <div className={classes.content}>
              <TextFormField
                name="passwordConfirm"
                validate={passwordConfirmValidate(t)}
                full
                dense
                password
                size="full"
                label={t('resources.users.passwordConfirm')}
                placeholder={t('resources.users.placeholderConfirmPassword')}
              />
            </div>

            <div className={classes.bottomContainer}>
              <TertiaryButton disabled={loading} label={t('actionButtons.cancel')} onClick={close} />
              <PrimaryButton
                disabled={loading}
                type="submit"
                label={t('actionButtons.save')}
                onClick={handleSubmit}
                // icon={<CheckCircle />}
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};
