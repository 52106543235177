import React from 'react';

import { DialogContent, Dialog as MuiDialog, makeStyles, DialogTitle } from '@material-ui/core';
import { CloseButton } from './CloseButton';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: -10,
  },
}));

export const Dialog = ({ isOpen, children, close, title }) => {
  const classes = useStyles();

  return (
    <MuiDialog fullWidth open={isOpen}>
      <div className={classes.titleContainer}>
        <DialogTitle>{title}</DialogTitle>
        <CloseButton onClick={close} />
      </div>
      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  );
};
