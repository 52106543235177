import React from 'react';
import { Pagination, Datagrid } from 'ra-ui-materialui';
import { xor, get, map } from 'lodash';
import { useFormState, useForm } from 'react-final-form';
import { useCallback, useRef, useState } from 'hooks';
import { useNotify, useGetList, ListContext, ListPaginationContext, useListContext } from 'ra-core';
import { Box, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { TextInput } from 'ra-ui-materialui/lib/input';
import { Form, FormSpy } from 'react-final-form';

export default ({ reference, source, filter = {}, page = 1, perPage = 10, sort = {}, children, withFilter }) => {
  const deps = Object.keys(filter);
  const { values } = useFormState();
  const form = useForm();
  const notify = useNotify();

  const basePath = `/${reference}`;
  const [_page, setPage] = useState(page);
  const [selectedIds, setSelectedIds] = useState(get(values, source) || []);
  const [filters, setFilters] = useState(filter);
  if (deps.find((dep) => filter[dep] !== filters[dep])) {
    setFilters({ ...filters, ...filter });
  }
  const { data, total, loading, error } = useGetList(reference, { page: _page, perPage }, sort, filters);
  if (error) notify(error.message);

  const selectItems = useCallback(
    (ids) => {
      setSelectedIds(ids);
      form.change(source, ids);
    },
    [setSelectedIds, form, source],
  );

  const onToggleItem = useCallback((id) => selectItems(xor([id], selectedIds)), [selectItems, selectedIds]);
  const onSelect = useCallback((ids) => selectItems(ids), [selectItems]);

  const context = {
    resource: reference,
    basePath,

    currentSort: {},
    hasBulkActions: true,
    selectedIds,
    data,
    ids: map(data, 'id'),
    total,
    loading,
    onToggleItem,
    onSelect,
    setFilters,
  };
  const paginationContext = {
    perPage,
    page: _page,
    setPage,
    total: total || 0,
    loading,
  };
  return (
    <>
      <ListContext.Provider value={context}>
        {withFilter && <CustomFilter />}
        <Datagrid margin="dense" hasBulkActions={true}>
          {children}
        </Datagrid>
      </ListContext.Provider>
      <ListPaginationContext.Provider value={paginationContext}>
        <Pagination rowsPerPageOptions={[]} />
      </ListPaginationContext.Provider>
    </>
  );
};

const CustomFilter = () => {
  const isLoading = useRef(0);
  const { setFilters, filterValues, displayedFilters } = useListContext();

  const onSubmit = (values) => {
    clearTimeout(isLoading.current);
    isLoading.current = setTimeout(() => {
      const filters = { q: values.q || '' };
      if (!!values.id) {
        filters.id = values.id;
      }
      setFilters(filters, displayedFilters);
    }, 400);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit, form, submitting }) => (
          <>
            <FormSpy
              subscription={{ values: true, pristine: true, invalid: true, initialValues: true }}
              onChange={({ pristine, values, invalid }) => {
                form.submit();
              }}
            />
            <FormSpy
              subscription={{ initialValues: true }}
              onChange={() => {
                form.change('q', '');
                form.change('id', '');
              }}
            />
            <form onSubmit={handleSubmit}>
              <Box display="flex" alignItems="flex-end" mb={1}>
                <Box component="span" mr={2}>
                  <TextInput
                    resettable
                    helperText={false}
                    source="q"
                    label="titles.search"
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <SearchIcon color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box component="span" mr={2}>
                  <TextInput
                    resettable
                    helperText={false}
                    source="id"
                    label="Id"
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <SearchIcon color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </form>
          </>
        )}
      </Form>
    </div>
  );
};
