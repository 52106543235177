module.exports = {
  ra: {
    action: {
      add_filter: 'Adicionar Filtro',
      add: 'Adicionar',
      back: 'Voltar',
      bulk_actions: '%{smart_count} selecionados',
      cancel: 'Cancelar',
      clear_input_value: 'Limpar campo',
      clone: 'Duplicar',
      confirm: 'Confirmar',
      create: 'Novo',
      delete: 'Apagar',
      edit: 'Editar',
      export: 'Exportar',
      list: 'Listar',
      refresh: 'Atualizar',
      remove_filter: 'Cancelar filtro',
      remove: 'Apagar',
      save: 'Salvar',
      search: 'Buscar',
      show: 'Exibir',
      expand: 'Expandir',
      close: 'Fechar',
      sort: 'Ordenar',
      undo: 'Desfazer',
    },
    boolean: {
      true: 'Sim',
      false: 'Não',
    },
    page: {
      delete: 'Deletar %{name} #%{id}',
      create: 'Novo %{name}',
      dashboard: 'Painel de Controle',
      edit: '%{name} #%{id}',
      list: 'Listar %{name}',
      loading: 'Carregando',
      not_found: 'Não encontrado',
      show: '%{name} #%{id}',
    },
    input: {
      file: {
        upload_several: 'Arraste alguns arquivos para fazer o upload, ou clique para selecioná-los.',
        upload_single: 'Arraste um arquivo para upload ou clique em selecionar arquivo.',
      },
      image: {
        upload_several: 'Arraste alguns arquivos para fazer o upload, ou clique para selecioná-los.',
        upload_single: 'Arraste um arquivo para upload ou clique em selecionar arquivo.',
      },
      references: {
        all_missing: 'Unable to find references data.',
        many_missing: 'At least one of the associated references no longer appears to be available.',
        single_missing: 'Associated reference no longer appears to be available.',
      },
    },
    message: {
      about: 'Sobre',
      are_you_sure: 'Tem certeza?',
      bulk_delete_content:
        'Você tem certeza que deseja excluir %{name}? |||| Você tem certeza que deseja excluir estes %{smart_count} itens?',
      bulk_delete_title: 'Excluir %{name} |||| Excluir %{smart_count} %{name} itens',
      delete_content: 'Você tem certeza que deseja excluir?',
      delete_title: 'Excluir %{name} #%{id}',
      invalid_form: 'Este formulário não está valido. Certifique-se de corrigir os erros',
      loading: 'A página está carregando. Um momento, por favor',
      no: 'Não',
      not_found: 'Foi digitada uma URL inválida, ou o link pode estar quebrado.',
      yes: 'Sim',
    },
    navigation: {
      no_results: 'Nenhum resultado encontrado',
      no_more_results: 'A página numero %{page} está fora dos limites. Tente a página anterior.',
      page_out_of_boundaries: 'Página %{page} fora o limite',
      page_out_from_end: 'Não é possível ir após a última página',
      page_out_from_begin: 'Não é possível ir antes da primeira página',
      page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
      page_rows_per_page: 'Resultados por página:',
      next: 'Próximo',
      prev: 'Anterior',
    },
    auth: {
      user_menu: 'Perfil',
      username: 'Usuário',
      password: 'Senha',
      sign_in: 'Login',
      sign_in_error: 'Erro na autenticação, tente novamente.',
      logout: 'Sair',
    },
    notification: {
      updated: 'Item atualizado com sucesso',
      created: 'Item criado com sucesso',
      deleted: 'Item removido com sucesso!',
      bad_item: 'Item incorreto',
      item_doesnt_exist: 'Esse item não existe mais',
      http_error: 'Erro na comunicação com servidor',
      data_provider_error: 'Erro interno do servidor. Entre em contato',
      canceled: 'Ação cancelada',
      logged_out: 'Sua sessão foi encerrada. Por favor, reconecte',
    },
    validation: {
      required: 'Obrigatório',
      minLength: 'Deve ser ter no mínimo %{min} caracteres',
      maxLength: 'Deve ter no máximo %{max} caracteres',
      minValue: 'Deve ser %{min} ou maior',
      maxValue: 'Deve ser %{max} ou menor',
      number: 'Deve ser um número',
      email: 'Deve ser um email válido',
      oneOf: 'Deve ser uma das seguintes opções: %{options}',
      regex: 'Deve ter o formato específico (regexp): %{pattern}',
    },
  },
};
