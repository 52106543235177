import { SET_TERMS } from '../actions/terms';

export const termsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TERMS:
      return {
        ...state,
        terms: payload,
      };
    default:
      return undefined;
  }
};
