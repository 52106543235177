import React from "react"
import { Show, DateField } from "components"
import { useRecord } from "hooks"
import { TextField, SimpleShowLayout } from "ra-ui-materialui";


export default ({permissions, ...props}) => {
  const record = useRecord("documents") || {};

  return (
    <Show {...props} titleSource="name">
      <SimpleShowLayout>
        <TextField source="name" />
        <DateField addLabel={true} source="publishedAt" />
        <div dangerouslySetInnerHTML={{ __html: record.content }} />
      </SimpleShowLayout>
    </Show>
  )
}
