import React from 'react';
import { SelectField, TextField, Datagrid, EditButton, Filter, SelectInput } from 'ra-ui-materialui';
import { List, ShowLink } from 'components';
import { roles } from 'lib/consts';
import useCanEditUser from './useCanEditUser';
import ActiveSwitch from 'resources/components/ActiveSwitch';
import FilterSwitch from 'resources/components/FilterSwitch';
import { useAccount } from 'hooks';
import { TextInput } from 'ra-ui-materialui/lib/input';
import { SearchFormField } from 'components/designSystem/inputs/TextField';

const UserFilters = ({ userType, ...props }) => (
  <Filter {...props}>
    <SearchFormField name="q" alwaysOn light iconPosition="right" placeholder="Buscar por nome ou e-mail" size="full" />
    <FilterSwitch source="showInactive" label="fields.showInactive" alwaysOn />
    <SelectInput source="role" choices={roles[userType]} />
    <TextInput source="username" />
  </Filter>
);

export default ({ permissions, ...props }) => {
  const account = useAccount() || {};
  console.log(account);
  const userType = account.userType;
  const editable = props.hasEdit && useCanEditUser(userType, permissions);
  return (
    <List {...props} filter={{ userType }} hasCreate={props.hasCreate} filters={<UserFilters userType={userType} />}>
      <Datagrid>
        <ShowLink source="name" />
        <ShowLink source="username" />
        <TextField source="email" />
        <SelectField source="role" choices={roles[userType] || []} />
        <ActiveSwitch source="active" sortable={false} readonly={!editable} />
        {editable && <EditButton />}
      </Datagrid>
    </List>
  );
};
