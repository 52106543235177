import React from "react"
import { TextInput, SimpleForm} from "ra-ui-materialui"
import { email, useTranslate} from "ra-core"
import { Create, DefaultToolbar, required, }    from "components"
import { useAutoRedirect } from "hooks"
import { Row, Col } from "resources/components/Grid"
import { Typography } from "@material-ui/core"


export default props => {
  const translate = useTranslate()
  const redirect = useAutoRedirect("list")
  
  return <Create {...props} >
    <SimpleForm toolbar={<DefaultToolbar />} redirect={redirect} >
        <Row >
          <Col>
            <><Typography variant="h6">{translate("tabs.summary")}</Typography></>
            <TextInput source="name" validate={required()} />
            <TextInput source="nickname" validate={required()} />            
          </Col>
          <Col>
            <><Typography variant="h6">{translate("tabs.manager")}</Typography></>
            <TextInput source="user.username" validate={required()} />
            <TextInput source="user.email" validate={[required(), email()]} />
            <TextInput source="user.name" validate={required()} />
          </Col>       
        </Row>        
    </SimpleForm>     
  </Create>
}