import React, { useRef, useLayoutEffect } from "react";
import { Field } from "react-final-form"

const Editor = ({ input }) => {
  const { value, onChange } = input || { value: {} }
  const text = JSON.stringify(value)
  
  const ref = useRef()
  
  useLayoutEffect(() => {
    if (!ref.current) {
      const { SurveyCreator} = window      
      SurveyCreator.StylesManager.applyTheme("darkblue")
      ref.current = new SurveyCreator.SurveyCreator("surveyCreatorContainer", {
        showEmbededSurveyTab: false,        
      })
      ref.current.haveCommercialLicense = true
      ref.current.onModified.add(surveyCreator => onChange(surveyCreator.JSON))
      ref.current.text = text
    }
  }, [text, onChange])
  return <div id="surveyCreatorContainer" />  
}
export default ({ source }) => {
  return <Field name={source} component={Editor} />
}
