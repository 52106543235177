import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  borderRadius: 4,
  sidebar: {
    width: 250,
  },
  palette: {
    primary: {
      main: '#005BFF',
      hover: '#003799',
      disabled: '#E2E4E9',
    },
    secondary: {
      main: '#CCDEFF',
      hover: '#E5EFFF',
      disabled: '#CCDEFF',
    },
    primaryDanger: {
      main: '#BA2612',
      hover: '#8C1D0D',
    },
    tertiaryDanger: {
      main: '#E93016',
      hover: '#FDEAE8',
      disabled: '#FBD6D0',
    },
    background: {
      default: '#F0F2F4',
      card: '#FCFCFD',
      surface: {
        primary: '#FCFCFD', //aqui é da tab
        secondary: '#F0F2F4',
        pressed: '#E2E4E9',
      },
    },
    text: {
      primary: '#090B0D',
      secondary: '#414958',
      tertiary: '#7F889C',
      disabled: '#C4C9D4',
    },
    common: {
      yellow: {
        main: '#FFB800',
        dark: '#414958',
        darker: '#996E00',
        light: '#FFE299',
      },
      green: {
        main: '#0CC095',
        light: '#E7FDF8',
      },
      red: {
        main: '#E93016',
        light: '#FDEAE8',
      },
    },
    success: {
      main: '#0CC095',
      dark: '#E7FDF8',
      contrastText: '#FFF',
    },
    warning: {
      main: '#FFB800',
      dark: '#414958',
    },
    error: {
      main: '#E93016',
      dark: '#E93016',
      light: '#D49800', // TODO: set the right color after removing RA,
      lighter: '#FDEAE8',
      contrastText: '#FFF', // This controls both warning and error snackbar warnings
    },
  },
  spacing: 8,
  typography: {
    h1: {
      fontSize: '32px',
    },
    h2: {
      fontSize: '24px',
    },
    h3: {
      fontSize: '20px',
    },
    body1: {
      fontSize: '16px',
    },
    body2: {
      fontSize: '14px',
    },
    caption: {
      fontSize: '12px',
    },
    fontWeightMedium: '500',
    fontWeightRegular: '400',
    fontFamily: 'Inter, sans-serif',
  },
  overrides: {
    MuiDialog: {
      paper: {
        // TODO: handle responsivity
        maxWidth: '450px !important',
        boxSizing: 'border-box',
        borderBox: '4px',
      },
    },
    MuiListItem: {
      root: {
        //barra do ladinho que começa com o dashboard
        backgroundColor: '#FCFCFD',
        '&.Mui-selected': {
          backgroundColor: '#E2E4E9',
          '&:hover': {
            backgroundColor: '#E2E4E9',
          },
        },
      },
      button: {
        color: '#7F889C',
        '&:hover': {
          backgroundColor: '#E2E4E9',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        flex: 'inherit',
        color: '#414958',
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#2196f3',
      },
    },
    MuiSnackbar: {
      root: {
        overflowWrap: 'anywhere',
      },
    },
    MuiTableCell: {
      root: {
        lineHeight: '2.43',
      },
      head: {
        textTransform: 'uppercase',
        color: '#7F889C',
        background: '#E2E4E966',
      },
      body: {
        color: '#7F889C',
      },
    },
    MuiTabs: {
      indicator: {
        height: '1px',
        backgroundColor: '#E2E4E9',
        width: '100% !important',
      },
    },
    MuiChip: {
      root: {
        margin: 4,
      },
    },
    MuiSelect: {
      selectMenu: {
        minHeight: 'inherit',
      },
      outlined: {
        '&.MuiSelect-outlined': {
          // width: 150,
          borderRadius: 4,
          padding: '7px  45px 7px 15px',
        },
      },
      select: {
        transition: '.2s backgroundColor, .1s outline',
        '&:focus': {
          borderRadius: 4,
          backgroundColor: '#FCFCFD',
          boxShadow: `0 0 0 2px  ${'#005BFF'}`,
        },
        '&.Mui-disabled': {
          background: '#E2E4E9',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        marginBottom: 1,
      },
      input: {
        padding: '8px 14px',
        fontSize: 14,
        color: '#414958',
        background: '#F0F2F4',
        borderRadius: 4,
      },
      notchedOutline: {
        border: 'none',
        borderRadius: 4,
      },
    },
    MuiTab: {
      root: {
        minWidth: 'unset !important',
        color: '#414958 !important',
        fontWeight: 400,
        textTransform: 'none',
        borderBottom: '1px solid #E2E4E9',
        zIndex: 1,
        '&$selected': {
          fontWeight: 500,
          color: '#005bff !important',
          borderBottom: '1px solid #005bff',
        },
      },
    },
    MuiSwitch: {
      colorSecondary: {
        transform: 'translateX(3px)',
        '&$checked': {
          transform: 'translateX(15px)',
          color: '#005bff',
          '&$disabled': {
            color: '#005bff',
          },
        },
      },
      colorPrimary: {
        transform: 'translateX(3px)',
        '&$checked': {
          transform: 'translateX(15px)',
          color: '#005bff',
        },
      },
      track: {
        width: 32,
        height: 14,
        opacity: 0.32,
        backgroundColor: '#414958',
        '$checked$checked + &': {
          width: 32,
          height: 14,
          opacity: 0.54,
          backgroundColor: '#005bff',
        },
      },
    },
  },
});
