import React, { useCallback, useState } from 'react';
import { get } from "lodash"
import Carousel, { Modal, ModalGateway } from 'react-images';
import { makeStyles } from '@material-ui/styles';
import { Button, Icon } from "@material-ui/core"
const useStyles = makeStyles({
  thumb: {
    width: 350,
    cursor: "pointer"
  },
  download: {
    background: "white",
    '&:hover': {
      background: "white",
    }
  }
})

function remoteUrl(attachment, name) {
  let url = get(attachment.meta, `${name}.url`) || get(attachment.meta, "original.url")
  return url ? `${url}?${attachment.updatedAt}` : null
}

const DownloadButton = ({ source }) => {
  const classes = useStyles()
  const onClick = useCallback(() => {
    var tag = document.createElement("a")
    tag.href = source;
    tag.download = source
    document.body.appendChild(tag)
    tag.click()
    document.body.removeChild(tag)
  }, [source])
  return <Button className={classes.download} onClick={onClick}>
    <Icon>cloud_download</Icon>
  </Button>
}
export default ({ attachments, onPreviewOpen, classes: classesOverride }) => {
  const classes = useStyles({ classes: classesOverride })
  const thumbs = attachments.map((attachment, index) => ({ index, key: attachment.uuid, src: remoteUrl(attachment, "thumb") }))
  const images = attachments.map(attachment => ({
    width: 1024,
    caption: <DownloadButton source={remoteUrl(attachment, "original")} />,
    source: {
      fullscreen: remoteUrl(attachment, "preview"),
      download: remoteUrl(attachment, "preview"),
      regular: remoteUrl(attachment, "thumb"),
      thumbnail: remoteUrl(attachment, "thumb"),
    }
  }))
  const getStyles = useCallback(css => ({
    ...css,
    zIndex: (css.zIndex || 1200) + 300
  }), [])
  const [modalIsOpen, setOpen] = useState(false)
  const [currentIndex, setIndex] = useState(0)
  const openModal = useCallback(index => () => {
    setIndex(index)
    setOpen(true)
    onPreviewOpen(true)
  }, [setIndex, setOpen, onPreviewOpen])
  const closeModal = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()
    setOpen(false)
    onPreviewOpen(false)
    return false
  }, [setOpen, onPreviewOpen])

  return <div>
    {thumbs.map(thumbnailProps => <img className={classes.thumb} alt="" {...thumbnailProps} onClick={openModal(thumbnailProps.index)} />)}
    <ModalGateway >
      {modalIsOpen ? (
        <Modal onClose={closeModal} allowFullscreen={true} styles={{ blanket: getStyles, positioner: getStyles }} >
          <Carousel currentIndex={currentIndex} views={images} />
        </Modal>
      ) : null}
    </ModalGateway>
  </div>
}
