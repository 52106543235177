import React, { useState, useRef, useEffect } from 'react';

import { makeStyles } from '@material-ui/core';
// import { Search } from '@material-ui/icons';
import { Debounce } from 'lib/debounce';
import { SearchField } from './inputs/TextField';
import { useTranslate } from 'ra-core';

const useListFilterStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 56,
    boxSizing: 'border-box',

    paddingTop: theme.spacing(0.625),
    paddingBottom: theme.spacing(0.625),
    paddingLeft: theme.spacing(0.3),
    paddingRight: theme.spacing(0.3),
    borderBottom: `1px solid ${theme.palette.background.surface.pressed}`,
    marginBottom: theme.spacing(3),
  },
  iconContainer: {
    bottom: '20%',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  filtersWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 16,
    },
  },
}));

const debounceValue = 500;

export const ListFilter = ({ children, actions, setQSearch }) => {
  const t = useTranslate();
  const classes = useListFilterStyles();
  const debounce = useRef(new Debounce());
  const [qSearch, setLocalQSearch] = useState('');

  useEffect(() => {
    debounce.current.debounced(() => {
      if (setQSearch) {
        setQSearch(qSearch);
      }
    }, debounceValue);
  }, [qSearch]); //eslint-disable-line

  return (
    <div className={classes.container}>
      <div className={classes.filtersWrapper}>
        {setQSearch && (
          <SearchField
            // size="small"
            value={qSearch}
            light
            onChange={(evt) => setLocalQSearch(evt.target.value)}
            placeholder={t('placeholders.search')}
            iconPosition="right"
          />
        )}
        {children}
      </div>
      <div className={classes.actionsContainer}>{actions}</div>
    </div>
  );
};
