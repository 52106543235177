import { useDataProvider, useNotify, useTranslate } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { fetchSasiDashboardData } from './Dashboard.model';
import { InfoCard } from '../../../components/designSystem/InfoCard';
import { Apps, ListSharp, Smartphone, Work } from '@material-ui/icons';
import { addDots, useDashboardStyles } from './index';

export const GeneralCards = () => {
  const notify = useNotify();
  const t = useTranslate();
  const dataProvider = useDataProvider();
  const classes = useDashboardStyles();
  const [sasiData, setSasiData] = useState({});

  useEffect(() => {
    fetchSasiDashboardData({ dataProvider, notify })
      .then(setSasiData)
      .catch((error) => console.log(error));
  }, [dataProvider, notify]);

  return (
    <div style={{ margin: '1rem' }}>
      <h2 style={{ fontSize: 'inherit', color: '#414958' }}>{t('resources.dashboard.general')}</h2>
      <div style={{ display: 'flex', flexFlow: 'row' }}>
        <div className={classes.generalCardsContainer}>
          <div className={classes.cardInfo}>
            <InfoCard icon={<Smartphone />} infoName={t('resources.dashboard.mobileUsers')}
                      infoNumber={addDots(sasiData?.mobileUsers?.total)} date={sasiData?.timestamp} />
          </div>
          <div className={classes.cardInfo}>
            <InfoCard icon={<Work />} infoName={t('resources.dashboard.clients')}
                      infoNumber={addDots(sasiData?.clients?.total)} date={sasiData?.timestamp} />
          </div>
          <div className={classes.cardInfo}>
            <InfoCard icon={<Apps />} infoName={t('resources.dashboard.apps')} infoNumber={addDots(sasiData?.apps?.total)}
                      date={sasiData?.timestamp} />
          </div>
          <div className={classes.cardInfo}>
            <InfoCard icon={<ListSharp />} infoName={t('resources.dashboard.channels')}
                      infoNumber={addDots(sasiData?.channels?.total)} date={sasiData?.timestamp} />
          </div>
        </div>
      </div>
    </div>
  );
};
