import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  TextInput,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SelectInput,
  SelectArrayInput,
  SimpleFormIterator,
} from 'ra-ui-materialui';
import { FormDataConsumer, useTranslate } from 'ra-core';
import { IconsAutocomplete, required } from 'components';
import { makeStyles } from '@material-ui/styles';
import { useForm, useFormState } from 'react-final-form';
import { get, omitBy, isNil, includes, isArray, isEmpty, size } from 'lodash';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Button,
  Icon,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { Col, Row } from 'resources/components/Grid';
import { SimpleForm } from 'ra-ui-materialui/lib/form';
import ReactMultiQueryBuilder from 'components/ReactMultiQueryBuilder';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { DateFormats } from './OptionsInput.model';
import { useGlobalState } from 'state';
import UploadMultilayerDropdown from './UploadMultilayerDropdown';

const cleanArgs = (values) => omitBy(values, isNil);

const ratingIcons = [
  'grade',
  'star_rate',
  'starts',
  'favorite',
  'favorite_border',
  'thumb_up',
  'check_circle_outline',
  'check_circle',
  'verified',
  'sentiment_satisfied_alt',
  'mood',
];

const icons = ratingIcons.map((name) => ({
  id: name,
  name,
}));

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  schema: {
    display: 'flex',
    '& .ra-input': {
      marginRight: 10,
      display: 'flex',
    },
  },
  typeInput: {},
  schemaAction: {
    display: 'flex',
    padding: 0,
  },
  schemaInput: {
    width: theme.spacing(16),
    marginRight: 5,
  },
  validatorType: {
    width: 50,
  },
  validatorText: {
    width: 120,
  },
  validatorOptions: {
    width: 80,
  },
  schemaBoolean: {
    marginRight: 5,
    marginTop: 20,
  },
  schemaInputText: {
    width: theme.spacing(16),
    marginRight: 5,
  },
  root: {
    width: '100%',
  },
  details: {
    display: 'block',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  choices: {
    maxHeight: 200,
    overflow: 'scroll',
  },
}));

const RemoveButton = (props) => {
  return (
    <Button size="small" {...props}>
      <Icon>delete</Icon>
    </Button>
  );
};

const DefaultValueInput = ({ ...props }) => {
  const classes = useStyles();
  const { values } = useFormState();
  const { type, fieldType, defaultValue, min, max } = values;

  if (type === 'checkbox') return <BooleanInput {...props} className={classes.schemaBoolean} />;
  if (type === 'text') {
    if (fieldType === 'number') return <NumberInput {...props} min={min} max={max} />;
    return <TextInput {...props} />;
  }
  if (type === 'rating') {
    return <NumberInput {...props} min={min} max={max} />;
  }

  if (includes(['dropdown_search', 'dropdown', 'multilayer_dropdown', 'multilayer_dropdown_search', 'radios'], type)) {
    const choices = (values.choices || []).map(({ text, value } = {}) => ({ id: value, name: text }));
    const value = !isEmpty(defaultValue) && isArray(defaultValue) ? defaultValue[0] : defaultValue;
    return <SelectInput choices={choices} {...props} value={value || ''} allowEmpty />;
  }
  if (type === 'checkboxes') {
    const choices = (values.choices || []).map(({ text, value } = {}) => ({ id: value, name: text }));
    const value = !isEmpty(defaultValue) && !isArray(defaultValue) ? [defaultValue] : defaultValue || [];
    return <SelectArrayInput choices={choices} {...props} options={{ ...props.options, value }} />;
  }
  return <span />;
};

function choicesToFastEntry(choices = []) {
  if (choices.___text___) {
    return choices.___text___;
  } else {
    return choices.map((ch) => [ch.value, ch.text].join('|')).join('\n');
  }
}

function fastEntryToChoices(text) {
  const choices = text
    .split('\n')
    .filter((line) => !isEmpty(line))
    .map((line) => {
      const [value, text] = line.split('|');
      return { value, text };
    });
  choices.___text___ = text;
  return choices;
}

const OptionsForm = ({ readonly, allowComments, record, innerStateRef, fieldValues, options }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const form = useForm();
  const formState = useFormState();

  const { growthBook } = useGlobalState();
  const { fieldTypes, fileTypeChoices, mandatoryChoices } = options;
  const attachmentsTypeFeature = growthBook.isOn('attachments-type-selection') ?? false;
  const attachmentsFieldFeature = growthBook.isOn('attachments-field') ?? false;
  const multilayerDropdownFeature = growthBook.isOn('multilayer-dropdown') ?? false;

  innerStateRef.current = formState;

  const {
    type,
    required: isRequired,
    fieldType,
    types = [],
    qty,
    mandatory,
    hasOther,
    choices,
    allowAttachments,
    firstLevelValue,
    secondLevelValue,
  } = formState.values;
  const validationEnabled = isRequired || type === 'text';

  const inputProps = {
    disabled: readonly,
    options: { disabled: readonly },
  };

  const [qtyMin, setQtyMin] = useState(mandatory === 'all' ? types.length : 1);
  const handleQtyChange = (types, mandatory) => {
    if (mandatory === 'all') {
      setQtyMin(types.length);
      if (qty < types.length) {
        form.change('qty', types.length);
      }
    } else {
      setQtyMin(1);
    }
  };

  const [choicesTab, setChoices] = useState('choices');
  const handleChoicesTabChange = (event, newValue) => setChoices(newValue);

  const [mlChoicesTab, setMlChoicesTab] = useState(record.choices ? 'preview' : 'import');
  const handleMlChoicesTabChange = (event, newValue) => setMlChoicesTab(newValue);

  const [firstLevelChoices, setFirstLevelChoices] = useState([]);
  const [secondLevelTitle, setSecondLevelTitle] = useState('');
  const [thirdLevelTitle, setThirdLevelTitle] = useState('');

  const mapMlChoices = (choices = []) => {
    const map = {};
    const flChoices = [];
    choices.forEach((choice) => {
      map[choice.value] = [];
      flChoices.push({ id: choice.value, name: choice.text });
      setSecondLevelTitle(choice.children.title);
      choice.children.choices.forEach((choice2) => {
        map[choice.value].push({ id: choice2.value, name: choice2.text });
        if (choice2.children) {
          setThirdLevelTitle(choice2.children.title);
          if (!map[choice2.value]) {
            map[choice2.value] = [];
          }
          choice2.children.choices.forEach((choice3) => {
            map[choice2.value].push({ id: choice3.value, name: choice3.text });
          });
        }
      });
    });
    return { flChoices, map };
  };

  const [mlChoicesMap, setMlChoicesMap] = useState({});

  useEffect(() => {
    if (multilayerDropdownFeature && ['multilayer_dropdown', 'multilayer_dropdown_search'].includes(record.type)) {
      const { flChoices, map } = mapMlChoices(record.choices);
      setMlChoicesMap(map);
      setFirstLevelChoices(flChoices);
    }
  }, []); //eslint-disable-line

  const [expanded, setExpanded] = useState(false);
  const handleExpand = useCallback(
    (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false),
    [setExpanded],
  );

  return (
    <>
      <Accordion expanded={expanded === 'general'} onChange={handleExpand('general')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="generalbh-content" id="generalbh-header">
          <Typography className={classes.heading}>{translate('resources.channels.schema.general')}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {includes(
            [
              'dropdown',
              'dropdown_search',
              'multilayer_dropdown',
              'multilayer_dropdown_search',
              'radios',
              'text',
              'checkbox',
              'checkboxes',
            ],
            type,
          ) && (
            <Row>
              <Col>
                <TextInput {...inputProps} source="placeholder" label="resources.channels.schema.placeholder" />
              </Col>
              <Col>
                <DefaultValueInput
                  {...inputProps}
                  source="defaultValue"
                  label="resources.channels.schema.defaultValue"
                />
              </Col>
            </Row>
          )}
          {type === 'text' && (
            <>
              <Row>
                <Col>
                  <SelectInput
                    {...inputProps}
                    source="fieldType"
                    validate={required()}
                    choices={fieldTypes}
                    label="resources.channels.schema.validators.fields.type"
                  />
                </Col>
                <Col>
                  {includes(['maskedText'], fieldType) && (
                    <div className="ra-input">
                      <TextInput
                        {...inputProps}
                        source="mask"
                        label="resources.channels.schema.mask"
                        placeholder="+# (###) ###-##-##"
                        helperText="# - Number, A - Text"
                      />
                    </div>
                  )}
                  {includes(['date'], fieldType) && (
                    <div className="ra-input">
                      <SelectInput
                        {...inputProps}
                        source="format"
                        label="resources.channels.schema.format"
                        defaultValue="dd-MM-yyyy"
                        choices={DateFormats}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {fieldType === 'number' && (
                    <NumberInput {...inputProps} source="min" label="resources.channels.schema.min" />
                  )}
                  {fieldType !== 'number' && (
                    <NumberInput {...inputProps} source="limit" label="resources.channels.schema.limit" />
                  )}
                </Col>
                <Col>
                  {fieldType === 'number' && (
                    <NumberInput {...inputProps} source="max" label="resources.channels.schema.max" />
                  )}
                </Col>
              </Row>
            </>
          )}
          {attachmentsFieldFeature && type === 'attachment' && (
            <>
              <Row>
                <Col>
                  <SelectArrayInput
                    {...inputProps}
                    source="types"
                    validate={required()}
                    choices={fileTypeChoices}
                    label="Tipos de anexo"
                    onChange={(e) => handleQtyChange(e.target.value, mandatory)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <NumberInput {...inputProps} validate={required()} min={qtyMin} source="qty" label="Quantidade" />
                </Col>
                <Col>
                  <SelectInput
                    {...inputProps}
                    source="mandatory"
                    validate={required()}
                    choices={mandatoryChoices}
                    label="Obrigatoriedade"
                    onChange={(e) => handleQtyChange(types, e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {types.map((e) => (
                    <NumberInput
                      {...inputProps}
                      validate={required()}
                      min={1}
                      source={`sizes.${e}`}
                      label={`Tamanho máximo (${e}) em MBs`}
                    />
                  ))}
                </Col>
              </Row>
            </>
          )}
          {includes(['radios', 'checkboxes'], type) && (
            <Row>
              <Col>
                <NumberInput
                  {...inputProps}
                  source="columns"
                  label="resources.channels.schema.columns"
                  defaultValue={0}
                />
              </Col>
            </Row>
          )}
          {includes(['textarea'], type) && (
            <div className="ra-input">
              <NumberInput {...inputProps} source="lines" label="resources.channels.schema.lines" />
            </div>
          )}
          {includes(['rating'], type) && (
            <Row>
              <Col>
                <IconsAutocomplete
                  {...inputProps}
                  maxItems={50}
                  choices={icons}
                  source="icon"
                  label="resources.channels.schema.icon"
                />
              </Col>
              <Col>
                <DefaultValueInput
                  {...inputProps}
                  source="defaultValue"
                  label="resources.channels.schema.defaultValue"
                />
              </Col>
            </Row>
          )}
          {includes(['rating'], type) && (
            <Row>
              <Col>
                <NumberInput {...inputProps} source="min" label="resources.channels.schema.min" />
              </Col>
              <Col>
                <NumberInput {...inputProps} source="max" label="resources.channels.schema.max" />
              </Col>
              <Col>
                <NumberInput {...inputProps} source="step" label="resources.channels.schema.step" />
              </Col>
            </Row>
          )}
        </AccordionDetails>
      </Accordion>
      {includes(
        ['dropdown', 'radios', 'checkboxes', 'dropdown_search', 'multilayer_dropdown', 'multilayer_dropdown_search'],
        type,
      ) && (
        <Accordion expanded={expanded === 'choices'} onChange={handleExpand('choices')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="choicesbh-content" id="choicesbh-header">
            <Typography className={classes.heading}>{translate('resources.channels.schema.choices.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.choices}>
            {multilayerDropdownFeature && ['multilayer_dropdown', 'multilayer_dropdown_search'].includes(type) ? (
              <div className="ra-input">
                <Paper square>
                  <Tabs
                    value={mlChoicesTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleMlChoicesTabChange}
                  >
                    <Tab label={translate('resources.channels.schema.choices.import')} value="import" />
                    <Tab
                      label={translate('resources.channels.schema.choices.preview')}
                      value="preview"
                      disabled={!record.choices}
                    />
                  </Tabs>
                </Paper>
                {mlChoicesTab === 'import' && <UploadMultilayerDropdown />}
                {mlChoicesTab === 'preview' && (
                  <>
                    <SelectInput
                      name="firstLevelValue"
                      choices={firstLevelChoices}
                      onChange={() => form.change('secondLevelValue', null)}
                      label={record.title}
                      allowEmpty
                      fullWidth
                    />
                    <SelectInput
                      name="secondLevelValue"
                      choices={mlChoicesMap[firstLevelValue] || []}
                      label={secondLevelTitle}
                      allowEmpty
                      fullWidth
                    />
                    {thirdLevelTitle && (
                      <SelectInput
                        name="thirdLevelValue"
                        choices={mlChoicesMap[secondLevelValue] || []}
                        label={thirdLevelTitle}
                        allowEmpty
                        fullWidth
                      />
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="ra-input">
                <Paper square>
                  <Tabs
                    value={choicesTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChoicesTabChange}
                  >
                    <Tab label={translate('resources.channels.schema.choices.title')} value="choices" />
                    <Tab label={translate('resources.channels.schema.choices.fastentry')} value="fastentry" />
                  </Tabs>
                </Paper>
                {choicesTab === 'choices' && (
                  <ArrayInput label={''} {...inputProps} source="choices">
                    <SimpleFormIterator
                      classes={{ form: classes.schema, action: classes.schemaAction }}
                      disableRemove={readonly}
                      disableAdd={readonly}
                      removeButton={<RemoveButton />}
                      TransitionProps={{ classNames: 'back' }}
                    >
                      <TextInput
                        source="text"
                        validate={required()}
                        label="resources.channels.schema.choices.fields.text"
                        className={classes.schemaInput}
                      />
                      <TextInput
                        source="value"
                        validate={required()}
                        label="resources.channels.schema.choices.fields.value"
                        className={classes.schemaInput}
                        defaultValue={'item' + size(choices)}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                )}
                {choicesTab === 'fastentry' && (
                  <TextInput
                    source="choices"
                    format={choicesToFastEntry}
                    parse={fastEntryToChoices}
                    multiline
                    lines={10}
                    label=""
                  />
                )}
                {includes(['dropdown_search', 'dropdown', 'radios'], type) && (
                  <>
                    <Row>
                      <Col>
                        <BooleanInput
                          className={classes.schemaBoolean}
                          {...inputProps}
                          source="hasOther"
                          label="resources.channels.schema.hasOther"
                        />
                      </Col>
                      <Col>
                        {hasOther && (
                          <TextInput {...inputProps} source="otherText" label="resources.channels.schema.otherText" />
                        )}
                      </Col>
                    </Row>
                  </>
                )}
                {includes(['checkboxes'], type) && (
                  <>
                    <BooleanInput
                      {...inputProps}
                      source="allowSelectAll"
                      label="resources.channels.schema.allowSelectAll"
                    />
                  </>
                )}
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {validationEnabled && (
        <Accordion expanded={expanded === 'validation'} onChange={handleExpand('validation')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="validation-content" id="validation-header">
            <Typography className={classes.heading}>{translate('resources.channels.schema.validation')}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div className="ra-input">
              {isRequired && (
                <TextInput
                  {...inputProps}
                  source="requiredErrorText"
                  label="resources.channels.schema.requiredErrorText"
                />
              )}
              <ArrayInput label={'resources.channels.schema.validators.title'} {...inputProps} source="validators">
                <SimpleFormIterator
                  classes={{ form: classes.schema, action: classes.schemaAction }}
                  disableRemove={readonly}
                  disableAdd={readonly}
                  removeButton={<RemoveButton />}
                  TransitionProps={{ timeout: 0, classNames: 'back' }}
                >
                  <SelectInput
                    source="type"
                    validate={required()}
                    choices={[
                      { id: 'regex', name: 'regex' },
                      { id: 'br_cpf', name: 'cpf' },
                      {
                        id: 'br_cnpj',
                        name: 'cnpj',
                      },
                    ]}
                    label="resources.channels.schema.validators.fields.type"
                    className={classes.validatorType}
                  />
                  <FormDataConsumer>
                    {({ scopedFormData, formData, getSource, ...rest }) => (
                      <>
                        {scopedFormData && scopedFormData.type === 'regex' && (
                          <TextInput
                            source={getSource('text')}
                            validate={required()}
                            label="resources.channels.schema.validators.fields.text"
                            className={classes.validatorText}
                          />
                        )}
                      </>
                    )}
                  </FormDataConsumer>
                  <FormDataConsumer>
                    {({ scopedFormData, formData, getSource, ...rest }) => (
                      <>
                        {scopedFormData && scopedFormData.type === 'regex' && (
                          <TextInput
                            {...rest}
                            source={getSource('regex')}
                            record={scopedFormData}
                            {...inputProps}
                            validate={required()}
                            label="resources.channels.schema.validators.fields.regex"
                            className={classes.validatorOptions}
                          />
                        )}
                      </>
                    )}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </div>
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion expanded={expanded === 'visibleIfQuery'} onChange={handleExpand('visibleIfQuery')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="visibility-content" id="visibility-header">
          <Typography className={classes.heading}>{translate('resources.channels.schema.visibleIfQuery')}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className="ra-input">
            <ReactMultiQueryBuilder
              readonly={readonly}
              source="visibleIfQuery"
              fieldValues={fieldValues?.filter(
                (e) => !['multilayer_dropdown', 'multilayer_dropdown_search'].includes(e.type),
              )}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      {allowComments && (
        <Accordion expanded={expanded === 'comments'} onChange={handleExpand('comments')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="comments-content" id="comments-header">
            <Typography className={classes.heading}>{translate('resources.channels.schema.comments')}</Typography>
            <Typography className={classes.secondaryHeading}>
              {record.allowComments && <Icon>comments</Icon>}
              &nbsp;
              {record.allowAttachments && <Icon>attachment</Icon>}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="ra-input">
              <BooleanInput {...inputProps} source="allowComments" label="resources.channels.schema.allowComments" />
              {type !== 'attachment' && (
                <>
                  <BooleanInput {...inputProps} source="allowQrCode" label="resources.channels.schema.allowQrCode" />
                  <BooleanInput
                    {...inputProps}
                    source="allowAttachments"
                    label="resources.channels.schema.allowAttachments"
                  />
                  {attachmentsTypeFeature && allowAttachments && (
                    <>
                      <BooleanInput
                        {...inputProps}
                        defaultValue={true}
                        source="allowAttachmentCamera"
                        label="resources.channels.schema.allowAttachmentCamera"
                      />
                      <BooleanInput
                        {...inputProps}
                        defaultValue={true}
                        source="allowAttachmentMic"
                        label="resources.channels.schema.allowAttachmentMic"
                      />
                      <BooleanInput
                        {...inputProps}
                        defaultValue={true}
                        source="allowAttachmentFile"
                        label="resources.channels.schema.allowAttachmentFile"
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

const OptionsInput = ({ readonly, allowComments = true, allowQrCode = false, source, options }) => {
  const translate = useTranslate();
  const classes = useStyles();

  const formState = useFormState();
  const form = useForm();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [, path, index] = source.match(/(.+?)\[(\d+)\]\.(\w+)$/);
  const fieldSource = `${path}[${index}]`;

  const [state, setState] = useState({});
  const innerStateRef = useRef({});

  const close = useCallback(() => {
    form.change(fieldSource, cleanArgs(innerStateRef.current.values));
    setDialogOpen(false);
  }, [form, fieldSource, innerStateRef, setDialogOpen]);

  const open = useCallback(() => {
    setState(cleanArgs({ ...get(formState.values, fieldSource) }));
    setDialogOpen(true);
  }, [setState, setDialogOpen, formState, fieldSource]);

  const cancel = useCallback(() => setDialogOpen(false), [setDialogOpen]);
  const fieldValues = get(formState.values, path);
  const { type } = get(formState.values, fieldSource, {});
  return (
    <>
      <Dialog fullWidth open={dialogOpen} onClose={cancel}>
        <DialogTitle>
          {translate('resources.channels.schema.options')}
          <IconButton aria-label="close" className={classes.closeButton} onClick={cancel}>
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <SimpleForm toolbar={null} resource="channels" basePath="/channels" record={state}>
            <OptionsForm
              readonly={readonly}
              allowComments={allowComments}
              innerStateRef={innerStateRef}
              fieldValues={fieldValues}
              options={options}
            />
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          {!readonly && (
            <Button color="primary" variant="contained" onClick={close}>
              <Icon>check</Icon>
              {translate('ra.action.confirm')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Button label="Options" onClick={open} disabled={!type}>
        <Icon>settings</Icon>
      </Button>
    </>
  );
};

export default OptionsInput;
