import React from 'react';
import { makeStyles } from '@material-ui/core';

import { usePanelStyles } from '../ChannelPanels.model';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { LabeledSwitchField } from 'components/designSystem/LabeledSwitch';
import { DropdownFormField } from 'components/designSystem/Dropdown';
import { ChannelTypeTextField } from '../ChannelPanels.model';
import { useTranslate } from 'ra-core';
import { PhoneFormField } from 'components/designSystem/PhoneInput';

const useStyles = makeStyles((theme) => ({
  app: {
    textAlign: 'center',
  },
  colunn: {
    marginTop: 2,
    marginBottom: 10,
  },
  wrapper: {
    display: 'flex',
    marginBottom: theme.spacing(2.0),
    marginTop: theme.spacing(1.5),
  },
  configContainer: {
    marginRight: theme.spacing(10),
    marginLeft: theme.spacing(0),
    marginBottom: theme.spacing(2.5),
  },
  finishButtonsContainer: {
    flex: '1',
  },
  finishOptionsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  textFields: {
    marginBottom: theme.spacing(1),
    marginTop: 0,
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
  },
  subtext: {
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(0.2),
    alignItems: 'center',
    fontWeight: 500,

    marginTop: theme.spacing(1),
    color: theme.palette.text.tertiary,
  },
  setPrimaryContainer: {
    justifyContent: 'flex-end',
    position: 'relative',
    left: theme.spacing(1) * -1,
    marginBottom: theme.spacing(0.5),

    '& > div': {
      color: theme.palette.text.tertiary,
    },
  },

  hiddenField: {
    display: 'none',
  },
}));

export const WhatsAppGeneralPanel = ({ parentChannels }) => {
  const classes = useStyles();
  const panelClasses = usePanelStyles();
  const t = useTranslate();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.configContainer}>
          <h2 className={panelClasses.sectionTitle}>{t('resources.channels.fields.config.about')}</h2>
          <div className={classes.finishOptionsContainer}>
            <div className={classes.optionsContainer}>
              <TextFormField
                className={classes.textFields}
                name="id"
                disabled
                size="full"
                full
                label={t('resources.channels.fields.config.channelId')}
                helperText={t('resources.channels.fields.config.helpertext')}
              />
              <DropdownFormField
                name="config.parentId"
                full
                items={(parentChannels || []).map((c) => ({ id: c.id, name: c.name }))}
                label={t('resources.channels.fields.config.parentId')}
                placeholder="Selecionar"
                helperText={t('resources.channels.fields.config.helpertext')}
                allowEmpty
              />
            </div>

            <div className={classes.colunn}>
              <ChannelTypeTextField
                className={classes.textFields}
                name="channelType"
                disabled
                full
                size="full"
                label={t('resources.channels.fields.config.channelType')}
                helperText={t('resources.channels.fields.config.helpertext')}
              />
              <TextFormField
                className={classes.textFields}
                name="tag"
                size="full"
                full
                label="TAG"
                helperText={t('resources.channels.fields.config.helpertext')}
                placeholder={t('resources.channels.fields.placeholders.insertTag')}
              />
            </div>
          </div>

          <div className={classes.textFields}>
            <PhoneFormField
              name="config.phone"
              label={t('channelTypes.phone')}
              helperText={t('resources.channels.fields.config.helpertext')}
              placeholder={t('resources.channels.fields.placeholders.telephone')}
            />
            <TextFormField
              className={classes.textFields}
              name="config.text"
              size="full"
              full
              label="Texto"
              placeholder={t('actions.insert')}
            />
          </div>
        </div>

        <div className={classes.configContainer}>
          <h2 className={panelClasses.sectionTitle}>{t('resources.channels.fields.config.settings')}</h2>
          <LabeledSwitchField
            reverse
            className={classes.setPrimaryContainer}
            name="primary"
            label={t('resources.channels.fields.config.primary')}
            caption={t('resources.channels.fields.placeholders.primary')}
          />

          <LabeledSwitchField
            reverse
            className={classes.setPrimaryContainer}
            name="config.allowGps"
            label={t('resources.channels.fields.config.allowGps')}
            caption={t('resources.channels.fields.placeholders.allowGps')}
          />

          <LabeledSwitchField
            reverse
            className={classes.setPrimaryContainer}
            name="config.notifyOpened"
            label={t('resources.channels.fields.config.notifyOpened')}
            caption={t('resources.channels.fields.placeholders.notifyOpened')}
          />

          <LabeledSwitchField
            reverse
            className={classes.setPrimaryContainer}
            name="config.siteManagerOnly"
            caption={t('resources.channels.fields.placeholders.siteManagerOnly')}
            label={t('resources.channels.fields.config.siteManagerOnly')}
          />
        </div>
      </div>
    </div>
  );
};
