import { UPDATE_GB_FEATURES } from '../actions/growthbook';

export const growthbookReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_GB_FEATURES:
      state.growthBook.setFeatures(payload);
      return state;
    default:
      return undefined;
  }
};
