import React, { useState } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { Form } from 'react-final-form';
import { required } from 'lib/formValidators';
import { useDataProvider, useNotify, useTranslate } from 'ra-core';
import { LabeledSwitchField } from 'components/designSystem/LabeledSwitch';
import { handlePasteChannel } from './PasteChannelDialog.model';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  setPrimaryContainer: {
    justifyContent: 'flex-end',
    position: 'relative',
    top: theme.spacing(0.5),
    left: theme.spacing(1) * -1,
    marginBottom: 20,
    paddingTop: 12,

    '& > div': {
      color: theme.palette.text.tertiary,
      paddingLeft: 8,
    },
  },

  bottomContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'end',
    paddingBottom: theme.spacing(2.73),

    '& button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginLeft: 10,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,

      //colocar no medium os botões
    },
  },
}));

export const PasteChannelDialog = ({ appId, isOpen, close, refresh }) => {
  const t = useTranslate();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const channel = JSON.parse(localStorage.getItem('channel'));
  return (
    <Dialog
      isOpen={isOpen}
      close={close}
      title={`${t(`resources.channels.fields.pastingChannel`)} ${channel?.name ? `"${channel.name}"` : ''}`}
    >
      <Form
        onSubmit={handlePasteChannel({
          dataProvider,
          channelId: channel?.id,
          appId,
          close,
          setLoading,
          refresh,
          channel,
          notify,
        })}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextFormField
              name="name"
              validate={required(t)}
              label={t('resources.channels.fields.name')}
              placeholder={t('resources.channels.fields.placeholders.pasteChannel')}
              required
              full
              disabled={loading}
              className={classes.nameTextField}
              iconPosition="right"
              iconError={<WarningRoundedIcon />}
            />

            <LabeledSwitchField
              reverse
              className={classes.setPrimaryContainer}
              name="primary"
              label={t('resources.channels.fields.config.primary')}
              caption={t('resources.channels.fields.placeholders.primary')}
            />

            <div className={classes.bottomContainer}>
              <TertiaryButton disabled={loading} label={t('actionButtons.cancel')} onClick={close} />
              <PrimaryButton
                disabled={loading}
                type="submit"
                label={t('templates.pasteChannel')}
                onClick={handleSubmit}
                notify={'resources.channels.fields.config.notifySucess'}
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};
