import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useNotify, useSafeSetState, useTranslate } from 'ra-core';
import { Notification, TextInput } from "ra-ui-materialui";
import { passwordConfirmValidate } from "../components";
import { SimpleForm } from "ra-ui-materialui/lib/form";
import config from "../config";
import { useParams } from "react-router";
import { useGlobalState } from 'state';


const useStyles = makeStyles({
  root: {
    maxWidth: 445,
    margin: "auto"
  },
  media: {
    height: 140,
    backgroundSize: "contain"
  },
  download: {
    minwidth: 100,
    maxWidth: "100%"
  },
  button: {
    width: "75%",
    margin: "auto"
  }
});
export default () => {
  const notify = useNotify()
  const classes = useStyles();
  const translate = useTranslate()
  const [done, setDone] = useSafeSetState(false)
  const { id } = useParams()
  const { growthBook } = useGlobalState();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="/assets/logo.png"
          title={translate("titles.logoText")}
        />
        <CardContent>
          <Typography align="center" variant="h5" component="h2">{translate("titles.logoText")}</Typography>

          <Typography variant="body2" color="textSecondary"
            component="p">{translate("passwordRecoveryWelcome")}</Typography>
        </CardContent>
      </CardActionArea>
      {done &&
        <Typography align="center" variant="h5" component="h2">{translate("appPasswordRecoverySuccess")}</Typography>}
      {!done && <SimpleForm save={(data => {
        const requestV3 = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': "application/json",
            authorization: `Bearer ${id}`
          },
          mode: 'cors',
          cache: 'default'

        }

        const configRequest = {
          method: 'PATCH',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': "application/json",
            authorization: id
          },
          mode: 'cors',
          cache: 'default'
        };

        const tokenV3On = growthBook.isOn('token-mobile-v3') ?? false;
        const url = tokenV3On ? config.mobileApiUrl + "/reset-password" : config.apiUrl.replace("management", "mobile/v2/config");
        fetch(url, tokenV3On ? requestV3 : configRequest)
          .then(response => {
            if (!response.ok) {
              notify(translate("passwordRecoveryError"), 'warning')
            }
            setDone(true)
            notify(translate("passwordRecoverySuccess"))
            return response.json()
          })
          .then(json => console.log(json))
          .catch(err => {
            notify(translate("passwordRecoveryError"), 'warning')
          })

      })}>
        <TextInput label={translate("resources.users.fields.password")} source="password" autoComplete="off" type="password" validate={value => {
          if (!value || value.length < 8 || value.length > 64) {
            return translate("passwordLengthRequirement")
          }
          if (/\s{2,}/.test(value)) {
            return translate("passwordSpaceRequirement")
          }
          return undefined
        }
        } />
        <TextInput label={translate("resources.users.fields.passwordConfirm")} source="passwordConfirm" autoComplete="off" type="password"
          validate={passwordConfirmValidate} />
      </SimpleForm>}
      <div style={{ padding: "1rem" }}>
        <Typography variant="body2" color="textSecondary"
          component="p">{translate("appWelcome")}</Typography>
      </div>
      <CardActions>

        <Button size="small" color="primary" href="https://apps.apple.com/us/app/sasi/id1467748272?ls=1"
          className={classes.button}>
          <img src="/assets/appstore-en-US.png" alt="" className={classes.download} />
        </Button>
        <Button size="small" color="primary"
          href="https://play.google.com/store/apps/details?id=br.com.sasi.app" className={classes.button}>
          <img src="/assets/googleplay-en-US.png" alt="" className={classes.download} />
        </Button>
      </CardActions>
      <Notification />
    </Card>
  );
}
