import React, { useState, useEffect } from 'react';
import { useListContext } from 'ra-core';
import { Pagination } from 'ra-ui-materialui';

export const PaginationWithNoQ = () => {
  const { setPage, filterValues } = useListContext();
  const [searched, setSearched] = useState(false);

  useEffect(() => {
    if (
      Object.values(filterValues).some(
        (value) => value !== undefined && value !== '',
      ) &&
      !searched
    ) {
      setSearched(true);
    } else if (searched) {
      setSearched(false);
    }
    setPage(1);
  }, [...Object.values(filterValues)]); // eslint-disable-line

  return <Pagination />;
  // return !searched || total === 0 ? <Pagination /> : <></>;
};
