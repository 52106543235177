import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import { FormSpy } from 'react-final-form';
import { Form } from 'components/designSystem/Form';

import { AppPreview } from '../components/AppPreview';

import { BasicProperties, GeneralForm, handleSave, LGPDProperties } from './Settings.model';
import { defaults } from '../components/AppPreview.model';
import { useTerms, useProjects } from 'hooks/use-resource-hooks';
import { useDataProvider, useNotify, useTranslate } from 'ra-core';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { ConfirmDialog } from 'components/designSystem/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formContainer: {
    flex: '3',
    maxWidth: '60rem',
  },
  phoneContainer: {
    flex: '1',
    backgroundColor: theme.palette.background.surface.pressed,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const FormWrapper = ({ children, setEditing }) => {
  return (
    <>
      <FormSpy
        subscription={{ dirty: true }}
        onChange={({ dirty }) => {
          setEditing(dirty);
        }}
      />
      {children}
    </>
  );
};

const SettingsView = ({ app, buttonRef, setLoading, refresh, setEditing, parentSave, loading }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [onDialogSaveSuccess, setOnDialogSaveSuccess] = useState(() => {});

  const { terms, dispatch: termsDispatch } = useTerms({ clientId: app.clientId });
  const { projects, dispatch: projectsDispatch } = useProjects(app.clientId);

  useEffect(() => {
    dataProvider.getList('terms', { filter: { ClientId: app.clientId } }).then(({ data }) => {
      termsDispatch(data);
    });
    dataProvider.getList('projects', { filter: { ClientId: app.clientId } }).then(({ data }) => {
      console.log(data);
      projectsDispatch(data);
    });
  }, []); //eslint-disable-line

  parentSave.current = async (onSuccess) => {
    setOnDialogSaveSuccess(() => onSuccess);
    setShowConfirmDialog(true);
  };

  return (
    <Form
      initialValues={{ ...app, config: { ...defaults, ...(app?.config || {}) } }}
      readOnly={loading}
      onSubmit={handleSave({ dataProvider, app, setLoading, refresh, notify })}
      render={({ handleSubmit, values: appState }) => {
        return (
          <>
            <ConfirmDialog
              isOpen={showConfirmDialog}
              close={() => setShowConfirmDialog(false)}
              title="Alterações pendentes"
              text="Você tem alterações pendentes. Gostaria de salvá-las antes de prosseguir?"
              cancelAction={() => setShowConfirmDialog(false)}
              cancelDisabled={loading}
              primaryLabel="Salvar"
              primaryAction={async () => {
                await handleSubmit();
                setShowConfirmDialog(false);
                onDialogSaveSuccess();
              }}
              primaryDisabled={loading}
            />
            <form onSubmit={handleSubmit} className={classes.container}>
              <FormWrapper handleSubmit={handleSubmit} setEditing={setEditing}>
                <div className={classes.formContainer}>
                  <GeneralForm projects={projects || []} />
                  <BasicProperties />
                  <LGPDProperties terms={terms || []} />
                  <button ref={buttonRef} type="submit" style={{ display: 'none' }}>
                    SAVE
                  </button>
                </div>
                <div className={classes.phoneContainer}>
                  <AppPreview app={appState} />
                </div>
              </FormWrapper>
            </form>
          </>
        );
      }}
    />
  );
};

export const Settings = ({ app, setMenuItems, refresh, setEditing, parentSave }) => {
  const [loading, setLoading] = useState(false);
  const buttonRef = useRef();
  const t = useTranslate();
  const appId = app?.id;
  setEditing(false);

  useEffect(() => {
    if (app) {
      setMenuItems([
        <PrimaryButton
          key="save_app_settings_primary_button"
          disabled={!!loading}
          onClick={() => {
            if (!!buttonRef.current?.click) {
              buttonRef.current.click();
            }
          }}
          label={t('actionButtons.saveChanges')}
        />,
      ]);
    }
  }, [app, setMenuItems, appId, loading, t]);

  if (!app) {
    return <div></div>;
  }
  return (
    <SettingsView
      refresh={refresh}
      app={app}
      buttonRef={buttonRef}
      setLoading={setLoading}
      setEditing={setEditing}
      parentSave={parentSave}
      loading={loading}
    />
  );
};
