import React, { useCallback, useState } from 'react';
import { TextInput, SimpleForm, SelectInput } from 'ra-ui-materialui';
import { useTranslate } from 'ra-core';
import { defaultsDeep } from 'lodash';
import { requiredIf } from 'components';
import ExpandPanel from 'resources/components/ExpandPanel';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import SchemaInput from 'resources/Common/SchemaInput';
import { useStyles } from './ChannelExpandPanel.model';
import {
  AlertChannelConfig,
  BroadcastChannelConfig,
  BubbleChannelConfig,
  GroupChannelConfig,
  IntercomChannelConfig,
  PDFChannelConfig,
  SurveyChannelConfig,
  URLChannelConfig,
  PhoneChannelConfig,
  WhatsAppChannelConfig,
} from './GeneralChannelConfigs';

export default ({ record, readonly, onUpdate, groupChannels }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const disclaimer = translate('disclaimer');

  const { channelType = 'alert' } = record;
  const inputProps = {
    className: classes.input,
    resource: 'channels',
    translate,
    disabled: readonly,
    options: { disabled: readonly },
  };

  const data = defaultsDeep(record, {
    config: {
      textColor: '#FFFFFF',
      bgColor1: '#4E4E4E',
      bgColor2: '#424242',
      icon: null,
      notifySurveyResult: false,
      alert: false,
    },
  });

  const [alert, setAlert] = useState(record.config.alert);
  const [value, setValue] = useState(record.config.disclaimer || disclaimer);
  const [intercomMode, setIntercomMode] = useState(record.config.disclaimer || disclaimer);
  const [allowGps, setAllowGps] = useState(record.config.allowGps);

  const changeAlert = useCallback((event) => setAlert(event.target.value), [setAlert]);
  const changeValue = useCallback((event) => setValue(event.target.value), [setValue]);
  const changeMode = useCallback((event) => setIntercomMode(event.target.value), [setIntercomMode]);
  const disclaimerClass = alert ? classes.input : classes.dsHidden;

  const filteredGroupChannels = !!groupChannels ? groupChannels.filter((channel) => channel.id !== data.id) : [];

  return (
    <ExpandPanel
      readonly={readonly}
      record={data}
      resource="channels"
      parentProps={{}}
      form={`channel_form_${record.id}`}
      onComplete={onUpdate}
    >
      <SimpleForm
        resource="channels"
        initialValues={{
          ...record,
          config: {
            ...record.config,
            disclaimer: value,
          },
        }}
        submitOnEnter={false}
      >
        <>
          <div className={classes.root}>
            <Accordion defaultExpanded={channelType !== 'alert'}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.column}>
                  <Typography className={classes.heading}>{translate('tabs.summary')}</Typography>
                </div>
                {channelType === 'alert' && (
                  <div className={classes.column}>
                    <Typography className={classes.secondaryHeading}>{translate('tabs.subChannels')}</Typography>
                  </div>
                )}
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div className={classes.column}>
                  <div className="ra-input">
                    <SelectInput source="config.parentId" choices={filteredGroupChannels} allowEmpty {...inputProps} />
                    {channelType === 'alert' && (
                      <AlertChannelConfig
                        inputProps={inputProps}
                        setAllowGps={setAllowGps}
                        allowGps={allowGps}
                        changeAlert={changeAlert}
                        changeValue={changeValue}
                        disclaimerClass={disclaimerClass}
                        value={value}
                      />
                    )}
                    {channelType === 'broadcast' && <BroadcastChannelConfig inputProps={inputProps} />}
                    {channelType === 'group' && <GroupChannelConfig inputProps={inputProps} />}
                    {channelType === 'intercom' && (
                      <IntercomChannelConfig
                        inputProps={inputProps}
                        changeMode={changeMode}
                        intercomMode={intercomMode}
                      />
                    )}
                    {channelType === 'pdf' && <PDFChannelConfig inputProps={inputProps} setAllowGps={setAllowGps} />}
                    {channelType === 'survey' && (
                      <SurveyChannelConfig inputProps={inputProps} setAllowGps={setAllowGps} allowGps={allowGps} />
                    )}
                    {channelType === 'url' && <URLChannelConfig inputProps={inputProps} />}
                    {channelType === 'phone' && <PhoneChannelConfig inputProps={inputProps} />}
                    {channelType === 'whatsapp' && <WhatsAppChannelConfig inputProps={inputProps} />}
                    {channelType === 'bubble' && <BubbleChannelConfig inputProps={inputProps} />}
                  </div>
                </div>
                {channelType === 'alert' && (
                  <div className={classes.column}>
                    <div className="ra-input">
                      <TextInput
                        source="config.sendNowTitle"
                        {...inputProps}
                        validate={requiredIf('config.sendNowText')}
                      />
                      <TextInput
                        source="config.sendNowText"
                        {...inputProps}
                        validate={requiredIf('config.sendNowTitle')}
                      />
                    </div>
                    <div className="ra-input">
                      <TextInput source="config.sendOtherTitle" {...inputProps} />
                    </div>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
            {channelType === 'alert' && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>{translate('titles.structure')}</AccordionSummary>
                <AccordionDetails className={classes.structureDetails}>
                  <div className="ra-input">
                    <TextInput
                      source="config.schema.title"
                      {...inputProps}
                      label="resources.channels.schema.schemaTitle"
                      placeholder={translate('resources.channels.schema.schemaTitlePlaceholder')}
                      helperText={translate('resources.channels.schema.schemaTitleHelperText')}
                    />
                  </div>
                  <div className="ra-input">
                    <SchemaInput source="config.schema.fields" {...inputProps} label={''} readonly={readonly} />
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </>
      </SimpleForm>
    </ExpandPanel>
  );
};
