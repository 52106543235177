import React, { useRef } from "react"
import { Show, DateField, DefaultActions,  } from "components"
import { TextField, BooleanField, TabbedShowLayout, Tab, ReferenceManyField, Datagrid, Pagination } from "ra-ui-materialui";
import ActionsForTab from "resources/Common/ActionsForTab"
import { useRecord, useTranslate } from "hooks";
import { makeStyles } from "@material-ui/styles"
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ProfileImage from "layout/components/ProfileImage"
import ExportButton, { ExportProgress } from "components/ExportButton"
import {  get, map, fromPairs } from 'lodash';
import DynamicValueField from "components/DynamicValueField";

const schema = [
  { id: "createdAt", title: "Survey Received" },
  { id: "name", title: "Contact Name" },
  { id: "phone", title: "Contact Phone" },
  { id: "email", title: "Contact  Email" },
  { id: "job", title: "Contact Job" },
  { id: "surveyName", title: "Survey" },  
]
function exporter(dataSchema) {
  return items => {
    const fields = map(dataSchema, "id")
    return {
      schema: [...schema, ...dataSchema],
      data: map(items, item => ({
        createdAt: item.createdAt.replace("Z", ""),
        name: get(item, "MobileProfile.name"),
        phone: get(item, "MobileProfile.phone"),
        email: get(item, "MobileProfile.email"),
        job: get(item, "MobileProfile.job"),
        surveyName: get(item.Survey, "name"),        
        ...fromPairs(fields.map(name => [name, get(item.data, name)]))
      }))
    }
  }
}

const useStyles = makeStyles({
  card: {
    display: 'inline-flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 250
  },
  content: {
    flex: '1 0 auto',
  },
})
const ContactCard = ({ record: { MobileProfile: record } }) => {
  const classes = useStyles();

  return <Card className={classes.card}>
    <div className={classes.details}>
      <CardContent className={classes.content}>
        <Typography component="h6" variant="h6">{record.name}</Typography>
        <Typography variant="subtitle1" color="textSecondary">{record.email}</Typography>
        {record.phone && <Typography variant="subtitle1" color="textSecondary">{record.phone}</Typography>}
        {record.job && <Typography variant="subtitle1" color="textSecondary">{record.job}</Typography>}
        {record.integrationId && <Typography variant="subtitle1" color="textSecondary">{record.integrationId}</Typography>}
      </CardContent>
    </div>
    <ProfileImage profile={{ email: record.email || "noemail@example.com" }} variant="card" />
  </Card>
}

export default ({permissions, ...props}) => {
  const translate = useTranslate()
  const containerRef = useRef()
  const progressRef = useRef({})
  const {id, schema = {}} = useRecord("surveys") || {}  
  const fields = Object.keys(schema || {})
  const dataSchema = fields.map(field => ({ id: field, title: field }))
  const fileName = translate("resources.survey-results.name", { smart_count: 2 })

  return (<>
    <ExportProgress progressRef={progressRef} />
    <Show {...props} titleSource="name" actions={<DefaultActions hasList={false} hasEdit={false} portalRef={containerRef} />} >
      <TabbedShowLayout>
        <Tab label="tabs.summary" >
          <ActionsForTab containerRef={containerRef} hasList={true} hasEdit={true} />            
          <TextField source="name" />
          <BooleanField source="active" />
        </Tab>       
        <Tab label="tabs.results" >
          <ActionsForTab containerRef={containerRef}>
            <ExportButton fileName={fileName} resource="survey-results" filter={{ SurveyId: id}} progressRef={progressRef} exporter={exporter(dataSchema)} />
          </ActionsForTab>
          <ReferenceManyField addLabel={false}  reference="survey-results" target="SurveyId" pagination={<Pagination />}>
            <Datagrid expand={ContactCard}>
              <DateField source="createdAt" label="fields.date" />
              <TextField source="MobileProfile.name" label="resources.maps.fields.name" />
              {fields.map(field => <DynamicValueField key={field} source={`data.${field}`} label={field} />)}
            </Datagrid>
          </ReferenceManyField>    
        </Tab>      
      </TabbedShowLayout>
    </Show>
    </>
  )
}
