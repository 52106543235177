import React from 'react';

import { makeStyles } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { required } from 'lib/formValidators';
import { LabeledSwitchField } from 'components/designSystem/LabeledSwitch';
import { ColorPickFormField } from 'components/designSystem/ColorPickField';
import { ImageFileDragDropFormField } from 'components/designSystem/ImageFileDragDropField';
import { DropdownFormField } from 'components/designSystem/Dropdown';
import { genericErrorHandler } from 'lib/Errors';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    '&:first-of-type': {
      marginTop: theme.spacing(3),
    },
    '&:last-of-type': {
      marginBottom: theme.spacing(3),
    },
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.body1.fontSize,
    margin: 0,
    marginBottom: theme.spacing(2.25),
  },
}));

const Title = ({ children }) => {
  const classes = useStyles();

  return <h2 className={classes.title}>{children}</h2>;
};

const useGeneralStyles = makeStyles((theme) => ({
  container: {},
  inputsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    '& input': {
      background: theme.palette.background.surface.primary,
    },

    '& > *': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
  },
  nameSwitchContainer: {
    position: 'relative',
    alignItems: 'flex-end',
  },
  isDefaultSwitch: {
    position: 'absolute',
    top: '105%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: theme.spacing(0.8) * -1,
    marginTop: theme.spacing(0.5),

    '& > div': {
      left: 0,
    },
    '& > span': {
      left: 13,
    },
  },
  descriptionInput: {
    marginRight: theme.spacing(2),
    marginTop: 10,
  },
  descriptionContainer: {
    marginTop: theme.spacing(5),
  },
  dropdownContainer: {
    // marginTop: theme.spacing(2),
  },
}));

export const GeneralForm = ({ projects }) => {
  const globalClasses = useStyles();
  const classes = useGeneralStyles();
  const t = useTranslate();

  const mapProject = (project) => {
    let name = project.name;
    if (project.name === 'default' && project.isDefault) {
      name = t('resources.projects.default');
    }
    return { id: project.id, name };
  };

  return (
    <div className={`${globalClasses.container} ${classes.container}`}>
      <Title>{t('resources.apps.fields.config.general')}</Title>
      <div className={classes.inputsContainer}>
        <div className={classes.nameSwitchContainer}>
          <TextFormField
            name="label"
            validate={required(t)}
            size="full"
            label={t('resources.apps.fields.config.name')}
            placeholder={t('resources.apps.fields.config.namePlaceholder')}
          />
          <LabeledSwitchField
            className={classes.isDefaultSwitch}
            name="isDefault"
            label={t('resources.apps.fields.config.default')}
          />
        </div>
        <TextFormField
          name="config.nickname"
          size="full"
          label={t('resources.apps.fields.config.nickname')}
          placeholder={t('resources.apps.fields.config.nicknamePlaceholder')}
        />
        <div className={classes.dropdownContainer}>
          <DropdownFormField
            name="projectId"
            full
            items={projects.map(mapProject)}
            label={t('resources.apps.fields.config.projeto')}
            light
          />
        </div>
      </div>
      <div className={`${classes.inputsContainer} ${classes.descriptionContainer}`}>
        <TextFormField
          className={classes.descriptionInput}
          name="description"
          size="full"
          label={t('resources.apps.fields.config.description')}
          placeholder={t('resources.apps.fields.config.descriptionPlaceholder')}
          light
        />
      </div>
    </div>
  );
};

const useBasicPropertiesStyles = makeStyles((theme) => ({
  themeContainer: {
    width: '50%',
    boxSizing: 'border-box',
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(5),
  },
  colorsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    '& > div': {
      flex: 1,
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  bannerContainer: {
    boxSizing: 'border-box',
    paddingRight: theme.spacing(2),
  },
  switchContainer: {
    width: '50%',
  },
}));

const themes = [
  { id: 'sasi', name: 'SASI' },
  { id: 'business', name: 'Business' },
];

export const BasicProperties = () => {
  const globalClasses = useStyles();
  const classes = useBasicPropertiesStyles();
  const t = useTranslate();

  return (
    <div className={globalClasses.container}>
      <Title>{t('resources.apps.fields.config.properties')}</Title>
      <div className={classes.colorsContainer}>
        <div>
          <ColorPickFormField label={t('resources.apps.fields.config.textColor')} name="config.textColor" />
        </div>
        <div>
          <ColorPickFormField
            label={t('resources.apps.fields.config.backgroundColor')}
            name="config.backgroundColor"
            helpertext="Em breve"
          />
        </div>
        <div>
          <ColorPickFormField label={t('resources.apps.fields.config.headerColor')} name="config.headerColor" />
        </div>
        <div>
          <ColorPickFormField
            label={t('resources.apps.fields.config.secondaryBackgroundColor')}
            name="config.secondaryBackgroundColor"
          />
        </div>
      </div>
      <div className={classes.themeContainer}>
        <DropdownFormField
          name="config.themeName"
          full
          items={themes}
          label={t('resources.apps.fields.config.themeName')}
          light
        />
      </div>

      <Title>{t('resources.apps.fields.config.banner')}</Title>
      <div className={classes.bannerContainer}>
        <ImageFileDragDropFormField name="config.banner" label={t('resources.apps.fields.config.bannerBase')} />
        <div className={classes.switchContainer}>
          <LabeledSwitchField
            label={t('resources.apps.fields.config.bannerNickname')}
            className={globalClasses.isDefaultSwitch}
            name="config.showBannerNickname"
          />
        </div>
      </div>
    </div>
  );
};

const useLGPDStyles = makeStyles((theme) => ({
  dropdownContainer: {
    display: 'flex',
    paddingRight: theme.spacing(2),
    '& > *': {
      flex: 1,
    },
  },
}));

export const LGPDProperties = ({ terms }) => {
  const globalClasses = useStyles();
  const classes = useLGPDStyles();
  const t = useTranslate();

  return (
    <div className={globalClasses.container}>
      <Title>LGPD</Title>
      <div className={classes.dropdownContainer}>
        <DropdownFormField
          name="termId"
          allowEmpty
          full
          items={terms.map((term) => ({ id: term.id, name: term.title }))}
          label={t('resources.apps.fields.config.terms')}
          light
        />
        <div></div>
      </div>
    </div>
  );
};

export const handleSave =
  ({ dataProvider, app, setLoading, refresh, notify }) =>
  async (formData) => {
    const { label, projectId, termId, isDefault, config, description } = formData;

    setLoading(true);

    try {
      await dataProvider.update('apps', {
        id: app.id,
        data: { isDefault, clientId: app.clientId, description, termId, projectId },
      });

      await dataProvider.patch(`apps/${app.id}/schema`, {
        config: config || {},
        label,
      });
      notify('resources.channels.fields.config.notifySucess');
    } catch (err) {
      setLoading(false);
      genericErrorHandler(notify)(err);
      notify('Falta campos obrigatórios');
    }

    setLoading(false);

    if (refresh) {
      refresh();
    }
  };
