import React from 'react';
import { SimpleForm, TextInput, ArrayInput, SimpleFormIterator } from "ra-ui-materialui";
import { DateInput } from "components"
import { useDataProvider, useNotify } from 'ra-core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  fields: {
    display: "flex",
    "& .ra-input": {
      marginRight: 10,
      display: "flex",
    },
  },
  fieldsActions: {
    display: "flex",
    padding: 0
  },
  fieldsInput: {
    width: theme.spacing(16),
    marginRight: 5,
  },

}))
const format = "Y-MM-dd HH:mm:ss"
export default function ApiTokens({record, resource}){
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const notify = useNotify()
  const onSave = values => {
    dataProvider
      .update(resource, { id: values.id, data: values })
      .then(() => notify("Updated"))
      .catch(error => notify(error.message, "error"))
  }
  return <SimpleForm resource={resource} record={record} save={onSave} >
    <ArrayInput source="apiTokens" margin="normal" >
      <SimpleFormIterator classes={{ form: classes.fields, action: classes.fieldsActions }} disableAdd>
        <TextInput label="fields.name" source="name" className={classes.fieldsInput} />
        <DateInput label="fields.createdAt" source="createdAt" options={{ disabled: true, format }} className={classes.fieldsInput} />
        <DateInput label="fields.updatedAt" source="updatedAt" options={{ disabled: true, format }} className={classes.fieldsInput} />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
}
