import React from 'react';
import { Icon } from '@material-ui/core';
import { SimpleForm } from 'ra-ui-materialui';
import { QuickEditButton } from 'components';
import ReactMultiQueryBuilder from 'components/ReactMultiQueryBuilder';

export default function VisibilityConfig({ record, fieldValues, readonly, ...props }) {
  return (
    <QuickEditButton
      {...props}
      resource="channels"
      record={record}
      title={'titles.visibility'}
      icon={<Icon>visibility</Icon>}
      toolbar={readonly ? false : undefined}
    >
      <SimpleForm resource="channels" record={record}>
        <ReactMultiQueryBuilder source={props.source} fieldValues={fieldValues} readonly={readonly} />
      </SimpleForm>
    </QuickEditButton>
  );
}
