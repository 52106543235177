import React from "react"
import {  TextInput, SimpleForm } from "ra-ui-materialui"
import { QuickCreateButton, required } from "components"
import { email} from "ra-core"

export default (props) => {
  return <QuickCreateButton {...props} resource="users" >
    <SimpleForm toolbar={null} resource="users" record={props.record}>
      <TextInput source="name" validate={required()} />
      <TextInput source="username" validate={required()} />
      <TextInput source="email" validate={email()} />
      <TextInput source="password" autoComplete="off" type="password" />
      <TextInput source="passwordConfirm" autoComplete="off" type="password" />
    </SimpleForm>
  </QuickCreateButton>
}