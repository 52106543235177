import React from 'react';
import TokenIcon from '@material-ui/icons/Fingerprint';
import { Button } from 'ra-ui-materialui';
import { useDataProvider, useCallback } from 'hooks';
import { useNotify } from 'ra-core';
import { genericErrorHandler } from 'lib/Errors';

export default function GenerateTokenButton({ record = {}, v2 }) {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const handleClick = useCallback(() => {
    const url = v2
      ? `monitoring-providers/v2/${record.id}/authenticate`
      : `monitoring-providers/${record.id}/authenticate`;

    dataProvider
      .execute(url, {})
      .then(({ data }) => notify(data.token))
      .catch((err) => genericErrorHandler(notify)(err));
  }, [dataProvider, record.id, notify, v2]);

  const label = v2 ? 'titles.generateTokenV2' : 'titles.generateToken';

  return record && record.id ? (
    <Button color="primary" onClick={handleClick} label={label}>
      <TokenIcon />
    </Button>
  ) : (
    <span />
  );
}
