import React, { useEffect }  from "react"
import { useCompare } from "hooks"
import { FormTab} from "ra-ui-materialui"

const ActiveTabComponent = ({ hidden, intent, onActivated }) => {
  const active = intent === "content" && !hidden
  const changed = useCompare(active)
  useEffect(() => {
    if(active && changed) {
      onActivated()
    }    
  }, [active, changed, onActivated])    
  return <></>
}
const ActiveTab = ({ children, onActivated, ...props }) => {
  return <FormTab {...props} >
    <ActiveTabComponent {...props} onActivated={onActivated} />
    {children}
  </FormTab>
}

export default ActiveTab