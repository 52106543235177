import React, { useCallback, useRef, useState } from "react";
import { useAccount, useEffect, useTimer, useTranslate } from "../../../hooks";
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useAuthProvider, useDataProvider, useNotify } from "ra-core";
import { ErrorCard } from "./ErrorCard";

const useStyles = makeStyles(theme => ({
  textContainer: {
    textAlign: 'center',
  },
  timerText: {
    fontSize: '1.3rem',
    display: 'block',
    padding: '.5rem 0',
  },
  timerTextDisabled: {
    fontSize: '1.3rem',
    display: 'block',
    padding: '.5rem 0',
    color: 'gray',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1.5rem 0',
  },
  codeInput: {
    fontSize: '2rem',
    width: '8.5rem',
    fontFamily: "'Roboto Mono', monospace",
    padding: '0.5rem',
    borderRadius: '5px',
    border: '1px solid #b6b7b9',
    outline: 'none',
    transition: '.2s box-shadow',
    '&:focus': {
      boxShadow: '0px 0px 0px 3px ' + theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '.5rem',
  },
}));

export const PropertyVerificationDialog = ({ close, property, alreadySent, handleVerifyCode }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const account = useAccount();
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const { time, restartTimer, stopTimer } = useTimer(150);
  const [code, setCode] = useState('');
  const [codeHasNotBeenSent, setCodeHasNotBeenSent] = useState(true);
  const inputRef = useRef(null);

  const [errorRequestId, setErrorRequestId] = useState(null);

  const handleClose = () => {
    setCode('');
    setErrorRequestId(null);
    close();
  }

  const getFormattedTimeString = useCallback(time => {
    const minutes = Math.floor(time / 60);
    const seconds = (time % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return `${minutes}:${seconds}`;
  }, []);

  useEffect(() => {
    if (property) {
      restartTimer();
      if (alreadySent) { return; }

      stopTimer();
      dataProvider.execute(`users/${property}/send-verifying-code`)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            const err = new Error(response.statusText || `Status ${response.status}`)
            err.status = response.status
            throw err
          }

          setCodeHasNotBeenSent(false);
          restartTimer();
        })
        .catch(err => {
          const reqId = err.message.split(' ---= ').length > 1 ? err.message.split(' ---= ')[1] : 'NULL';
          setErrorRequestId(reqId);
        })
    }
  }, [property]); // eslint-disable-line

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]); // eslint-disable-line

  const verifyCode = () => {
    if (handleVerifyCode) {
      handleVerifyCode({ code });
      return;
    }
    dataProvider.execute(`users/${property}/verify`, { code })
      .then(() => {
        account[`${property}Verified`] = true;
        authProvider.updateProfile(account);
        handleClose();
      })
      .catch(err => {
        notify('mfaCodeMistake', 'error');
        console.error(err);
      });
  }

  return (
    <Dialog fullWidth open={!!property} onClose={handleClose}>
      <DialogTitle>{translate('titles.' + property + 'Verification')}</DialogTitle>
      <DialogContent>
        <div className={classes.textContainer}>
          {
            (codeHasNotBeenSent && !alreadySent)
              ? translate('mfaCodeSending')
              : `${translate('propertyVerificationDescription', { platform: translate('resources.users.' + property) })} ${translate('mfaCodeWillExpire')}`
          }
          <br/>
          <span className={(codeHasNotBeenSent && !alreadySent) ? classes.timerTextDisabled : classes.timerText}>
            {getFormattedTimeString(time)}
          </span>
        </div>
        <div className={classes.inputContainer}>
          <input
            ref={inputRef}
            type="text"
            value={code}
            className={classes.codeInput}
            disabled={!!errorRequestId}
            onChange={evt => {
              if (evt.target.value.length <= 7) {
                setCode(evt.target.value);
              }
            }}
          />
        </div>
        {errorRequestId && (
          <ErrorCard message={(
            <>
              {translate('errorSendingMfaCode')}
              {errorRequestId !== 'NULL' && (
                <>
                  <br />
                  {translate('errorCode')}: {errorRequestId}
                </>
              )}
            </>)
          }/>
        )}
        <div className={classes.submitButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => verifyCode()}
            disabled={!code || code.length < 7 || !!errorRequestId}
          >
            {translate('confirm')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
