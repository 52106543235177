import React from 'react';
import { BooleanInput, FileField, FileInput, SelectInput, TextInput } from 'ra-ui-materialui';
import { checkPermissions, required } from 'components';
import { intercomChoices, messageTypeChoices } from './ChannelExpandPanel.model';
import { useTranslate } from 'hooks';
import { usePermissions } from 'ra-core';

export const AlertChannelConfig = ({
  inputProps,
  setAllowGps,
  allowGps,
  changeAlert,
  changeValue,
  disclaimerClass,
  value,
}) => {
  const { permissions } = usePermissions();

  return (
    <>
      <BooleanInput type="checkbox" source="config.anonymous" {...inputProps} />
      <BooleanInput type="checkbox" source="config.allowGps" {...inputProps} onChange={setAllowGps} />
      {allowGps && <BooleanInput type="checkbox" source="config.allowGeocoding" {...inputProps} />}
      <BooleanInput type="checkbox" source="config.notifyManager" {...inputProps} />
      <BooleanInput type="checkbox" source="config.siteManagerOnly" {...inputProps} />
      {permissions && checkPermissions(permissions, 'channels:update:data-collection') && (
        <BooleanInput type="checkbox" source="config.dataChannel" {...inputProps} defaultValue={false} />
      )}
      <SelectInput
        source="config.alert"
        translateChoice
        label="fields.messageType"
        choices={messageTypeChoices}
        onChange={changeAlert}
        {...inputProps}
      />
      <TextInput
        value={value}
        onChange={changeValue}
        multiline
        source="config.disclaimer"
        {...inputProps}
        className={disclaimerClass}
      />
    </>
  );
};

export const BroadcastChannelConfig = ({ inputProps }) => (
  <>
    <BooleanInput source="config.requireConfirm" defaultValue={false} {...inputProps} />
    <BooleanInput
      type="checkbox"
      source="config.notifyManager"
      {...inputProps}
      label="fields.recieveTestNotifications"
    />
    <BooleanInput type="checkbox" source="config.siteManagerOnly" {...inputProps} />
  </>
);

export const GroupChannelConfig = ({ inputProps }) => (
  <>
    <BooleanInput type="checkbox" source="config.siteManagerOnly" {...inputProps} />
  </>
);

export const IntercomChannelConfig = ({ changeMode, inputProps, intercomMode }) => (
  <>
    <SelectInput
      source="config.intercomMode"
      translateChoice
      label="fields.intercomMode"
      defaultValue="web"
      choices={intercomChoices}
      onChange={changeMode}
      {...inputProps}
    />
    <TextInput source="config.applicationId" {...inputProps} validate={required()} />
    {intercomMode === 'messenger' && <TextInput source="config.androidSdkKey" {...inputProps} validate={required()} />}
    {intercomMode === 'messenger' && <TextInput source="config.iosSdkKey" {...inputProps} validate={required()} />}
    <BooleanInput type="checkbox" source="config.siteManagerOnly" {...inputProps} />
    <BooleanInput source="config.notifyOpened" defaultValue={false} {...inputProps} />
  </>
);

export const PDFChannelConfig = ({ inputProps }) => {
  const translate = useTranslate();
  const urlValidation = (value, values) => {
    if (values['pdfFile']) {
      return null;
    }
    return required()(value, values);
  };

  return (
    <>
      <TextInput validate={urlValidation} source="config.url" {...inputProps} />
      <FileInput
        source="pdfFile"
        label={translate('titles.pdfFileLabel')}
        placeholder={translate('actions.dragOrClickPDF')}
        multiple={false}
        accept="application/pdf"
        {...inputProps}
      >
        <FileField source="source" title="title" />
      </FileInput>
      <BooleanInput source="config.notifyOpened" defaultValue={false} {...inputProps} />
      <BooleanInput type="checkbox" source="config.siteManagerOnly" {...inputProps} />
    </>
  );
};

export const SurveyChannelConfig = ({ inputProps, setAllowGps, allowGps }) => {
  const { permissions } = usePermissions();

  return (
    <>
      <BooleanInput type="checkbox" source="config.siteManagerOnly" {...inputProps} />
      <BooleanInput type="checkbox" source="config.notifySurveyResult" {...inputProps} defaultValue={false} />
      <BooleanInput type="checkbox" source="config.allowGps" {...inputProps} onChange={setAllowGps} />
      {allowGps && <BooleanInput type="checkbox" source="config.allowGeocoding" {...inputProps} />}
      {permissions && checkPermissions(permissions, 'channels:update:data-collection') && (
        <BooleanInput type="checkbox" source="config.dataChannel" {...inputProps} defaultValue={false} />
      )}
    </>
  );
};

export const URLChannelConfig = ({ inputProps }) => (
  <>
    <TextInput validate={required()} source="config.url" {...inputProps} />
    <BooleanInput source="config.notifyOpened" defaultValue={false} {...inputProps} />
    <BooleanInput source="config.usePassport" defaultValue={false} {...inputProps} />
    <BooleanInput source="config.useWebclientAuth" defaultValue={false} {...inputProps} />
    <BooleanInput type="checkbox" source="config.siteManagerOnly" {...inputProps} />
  </>
);

export const PhoneChannelConfig = ({ inputProps }) => (
  <>
    <TextInput validate={required()} source="config.phone" {...inputProps} />
    <BooleanInput source="config.notifyOpened" defaultValue={false} {...inputProps} />
    <BooleanInput source="config.usePassport" defaultValue={false} {...inputProps} />
    <BooleanInput source="config.useWebclientAuth" defaultValue={false} {...inputProps} />
    <BooleanInput type="checkbox" source="config.siteManagerOnly" {...inputProps} />
  </>
);

export const WhatsAppChannelConfig = ({ inputProps }) => (
  <>
    <TextInput validate={required()} source="config.whatsapp" {...inputProps} />
    <BooleanInput source="config.notifyOpened" defaultValue={false} {...inputProps} />
    <BooleanInput source="config.usePassport" defaultValue={false} {...inputProps} />
    <BooleanInput source="config.useWebclientAuth" defaultValue={false} {...inputProps} />
    <BooleanInput type="checkbox" source="config.siteManagerOnly" {...inputProps} />
  </>
);

export const BubbleChannelConfig = ({ inputProps }) => (
  <>
    <TextInput validate={required()} source="config.url" {...inputProps} />
    <BooleanInput source="config.notifyOpened" defaultValue={false} {...inputProps} />
    <BooleanInput source="config.usePassport" defaultValue={false} {...inputProps} />
    <BooleanInput source="config.useWebclientAuth" defaultValue={false} {...inputProps} />
    <BooleanInput source="config.useWebclientAuthRefresh" defaultValue={false} {...inputProps} />
    <BooleanInput type="checkbox" source="config.siteManagerOnly" {...inputProps} />
  </>
);
