import { AuthenticationError, HttpError, BadRequestError, ForbiddenError } from "./Errors"
import authProvider from "lib/authProvider"
import "whatwg-fetch"
import { getDefaultLocale } from "../config/i18n";

export const fetchJson = async (url, options = {}) => {
  const locale = getDefaultLocale();
  const { token, expiredAt } = authProvider.getProfile() || options || {};

  const handleRefresh = async () => {
    const { authentication } = await authProvider.refresh();
    authProvider.setActiveAccount(authentication.token, { ...authentication })
    return await fetchJson(url, options);
  }

  if (token && expiredAt && expiredAt < Date.now()) {
    return await handleRefresh();
  }

  const requestHeaders = new Headers({
    Accept: "application/json",
    Locale: locale,
  });

  if (options.method && options.method !== "GET" && !options.multipart) {
    requestHeaders.set("Content-Type", "application/json")
  }

  if (token) {
    requestHeaders.set("Authorization", `Bearer ${token}`);
  }

  const response = await fetch(url, { ...options, headers: requestHeaders });
  const bodyText = await response.text();
  const status = response.status;
  const statusText = response.statusText;
  const headers = response.headers;
  const requestId = headers.get('X-Server-Request-Id');

  let json;
  try {
    json = JSON.parse(bodyText);
  } catch (e) {
    // not json, no big deal
  }

  if (status < 200 || status >= 300) {
    let error = json && json.error;
    let message = statusText;

    if (error) {
      if (error.errors && error.errors.length > 0) {
        error = error.errors[0]
      }
      if (error && error.message) {
        message = error.message
      }
    }

    switch (status) {
      case 400:
        throw new BadRequestError(message, requestId, status);
      case 401:
        if (message === "JWT_EXPIRED") {
          return await handleRefresh();
        }
        throw new AuthenticationError(message, requestId, status);
      case 403:
        throw new ForbiddenError(message, requestId, status);
      case 500:
      default:
        throw new HttpError(message, requestId, status);
    }
  }

  return { status, headers, body: bodyText, json };
}

export const queryParameters = data => Object.keys(data)
  .map(key => [key, Array.isArray(data[key]) ? data[key] : [data[key]]])
  .reduce((res, [key, value]) => res.concat(value.map(v => [key, v])), [])
  .map(pair => pair.map(encodeURIComponent).join("="))
  .join("&")
