import React, { useState } from 'react';

import { BackButtonWithWrapper } from 'components/designSystem/BackButton';
import { ShowTitle } from 'components/designSystem/ShowTitle';
import { useNotify, useShowController, useTranslate } from 'ra-core';
import { useDataProvider } from 'ra-core';
import { makeStyles, Menu, MenuItem, Tab, Tabs } from '@material-ui/core';
import { MoreHoriz, Link } from '@material-ui/icons';
//import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
//import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
//import EditRoundedIcon from '@material-ui/icons/EditRounded';

import { getShareToken, ShowAppTabOrder } from './Show.model';
import { useTabs } from 'hooks/use-tabs';

import { Channels } from './views/Channels';
import { Settings } from './views/Settings';

import { TemplateUserInfo } from './views/TemplateUserInfo';
import { CreateAppDialog } from 'resources/App/components/CreateAppDialog';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { useHistory } from 'react-router';
//import { Button } from 'ra-ui-materialui';
// import { PublishAppDialog } from './components/PublishAppDialog';
//import { CreateTemplateDialog } from 'resources/Templates/components/CreateTemplateDialog';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
//import { useClipboard } from '../../Clipboard';
import { useEffect } from 'react';

const useSubHeaderStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  description: {
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.tertiary,
    margin: 0,
    marginRight: theme.spacing(1),
    wordBreak: 'break-word',
  },
  icon: {
    marginRight: theme.spacing(1),
    //marginInline: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 42,
  },
  primaryContainer: {
    '& > button': {
      fontSize: theme.typography.body1.fontSize,
    },
  },
}));

export const SubHeader = ({ template, openCreateAppDialog }) => {
  const classes = useSubHeaderStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  //const copyText = useClipboard();
  const [anchorEl, setAnchorEl] = useState(null);
  const t = useTranslate();
  const [shareToken, setShareToken] = useState();

  useEffect(() => {
    getShareToken({ dataProvider, templateId: template.id, notify, setClipboard: setShareToken });
  }, [template.id, notify, dataProvider]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className={classes.container}>
        <p className={classes.description}>{template?.description}</p>
        <div className={classes.primaryContainer}>
          <PrimaryButton onClick={openCreateAppDialog} disabled={!template} label={t('templates.useButton')} />
        </div>
        <div>
          <TertiaryButton className={classes.button} disabled={!template} icon={<MoreHoriz />} onClick={handleOpen} />
          <Menu id="template" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
            <MenuItem
              onClick={async () => {
                await navigator.clipboard.writeText(`${window.location.href}?token=${shareToken}`);
                notify(t('templates.notify'));
              }}
            >
              <Link className={classes.icon} />
              {t('templates.copyLink')}
            </MenuItem>
            {/*<MenuItem>
              <FileCopyRoundedIcon className={classes.icon} />
              {t('templates.duplicate')}
            </MenuItem>
            <MenuItem>
              <EditRoundedIcon className={classes.icon} />
              {t('templates.edit')}
            </MenuItem>
            <MenuItem>
              <DeleteRoundedIcon className={classes.icon} />
              {t('templates.delete')}
            </MenuItem>*/}
          </Menu>
        </div>
      </div>
      <TemplateUserInfo template={template} />
    </div>
  );
};

export const Show = (props) => {
  const showController = useShowController(props);
  const history = useHistory();
  const t = useTranslate();

  const template = showController.record;

  const [tab, setTab] = useTabs(ShowAppTabOrder, { defaultTab: 'settings' });
  const [isCreateOpen, setCreateOpen] = useState(false);

  const handleTabChange = (tab) => {
    setTab(tab);
  };

  const goToAppPage = (app) => {
    // Little timeout in order for react to properly clean anything related to state pending
    setTimeout(() => history.push(`/apps/${app.id}/show`), 200);
  };

  return (
    <div>
      <CreateAppDialog
        isOpen={isCreateOpen}
        close={() => setCreateOpen(false)}
        onCreate={goToAppPage}
        template={template}
      />
      <BackButtonWithWrapper />
      <ShowTitle name={template?.name} />
      {template?.id && <SubHeader template={template} openCreateAppDialog={() => setCreateOpen(true)} />}
      <Tabs value={tab} onChange={(event, value) => handleTabChange(value)}>
        <Tab value={ShowAppTabOrder['settings']} label={t('templates.optionsButton')} />
        <Tab value={ShowAppTabOrder['channels']} label={t('templates.channels')} />
      </Tabs>

      {tab === ShowAppTabOrder['channels'] && <Channels template={template} readOnly />}
      {tab === ShowAppTabOrder['settings'] && <Settings template={template} />}
    </div>
  );
};
