import React from 'react';

import { Field } from 'components/designSystem/Form';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'block',
  },
  label: {
    display: 'block',
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  tagsContainer: {
    background: theme.palette.background.surface.pressed,
    color: theme.palette.text.secondary,
    borderRadius: theme.borderRadius,
    paddingTop: theme.spacing(.1875),
    paddingBottom: theme.spacing(.1875),
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  tag: {
    boxSizing: 'border-box',
    display: 'inline-block',
    border: '1px solid black',
    borderColor: theme.palette.text.secondary,
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: theme.borderRadius,
    userSelect: 'none',
  },
}));

export const TagsField = ({ input, ...props }) => {
  const { value, label } = { ...(input || {}), ...props };

  const classes = useStyles();

  return (
    <label className={classes.container}>
      {label && (
        <span className={classes.label}>
          {label}
        </span>
      )}

      <div className={classes.tagsContainer}>
        {(value || []).map(tag => (
          // TODO: better key
          <span key={`tags_field_items_${tag}`} className={classes.tag}>{tag}</span>
        ))}
      </div>
    </label>
  )
}

export const TagsFormField = (props) => <Field {...props} component={TagsField} />;
