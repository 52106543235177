import React from "react"
import classnames from "classnames"
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginRight: 10
  },
}))
const Wrapper = ({ wrapperType, className, children, style, ...props }) => { 
  const classes = useStyles()
  return <div className={classnames(classes[wrapperType], className)} style={style}>
    {React.Children.map(children, element => React.isValidElement(element) ? React.cloneElement(element, { ...props, ...element.props}) : element)}
  </div>
}
const Col = props => <Wrapper wrapperType="col" {...props}/>
const Row = props => <Wrapper wrapperType="row" {...props} />
export {Col, Row}