import React from "react"
import { BooleanInput, SimpleForm, TextInput} from "ra-ui-materialui"
import { Edit, DefaultToolbar, required, }    from "components"
import RichTextInput from "ra-input-rich-text"
import { useAutoRedirect } from "hooks"

export default props => {    
  const redirect = useAutoRedirect("::back")
  return <Edit {...props} titleSource="name" >    
    <SimpleForm toolbar={<DefaultToolbar />} redirect={redirect} >
      <TextInput source="title" />
      <RichTextInput rows={4} multiline source="content" validate={required()} />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
}