import React from 'react';

import { makeStyles } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { required } from 'lib/formValidators';
import { ColorPickFormField } from 'components/designSystem/ColorPickField';
import { genericErrorHandler } from 'lib/Errors';
import { TagsFormField } from '../components/TagsField';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:first-of-type': {
      marginTop: theme.spacing(2),
    },
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.body1.fontSize,
    margin: 0,
    marginBottom: theme.spacing(1.25),
  },
}));

const Title = ({ children }) => {
  const classes = useStyles();

  return <h2 className={classes.title}>{children}</h2>;
};

const useGeneralStyles = makeStyles((theme) => ({
  container: {},
  inputsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),

    '& input': {
      background: theme.palette.background.surface.primary,
    },

    '& > *': {
      flex: 1,
      marginRight: theme.spacing(2),
    },
  },
}));

export const GeneralForm = () => {
  const globalClasses = useStyles();
  const classes = useGeneralStyles();
  const t = useTranslate();

  return (
    <div className={`${globalClasses.container} ${classes.container}`}>
      <Title>Geral</Title>
      <div className={classes.inputsContainer}>
        <TextFormField name="name" validate={required(t)} size="full" label="Nome" />
      </div>
      <div className={classes.inputsContainer}>
        <TextFormField name="description" size="full" label="Descrição do aplicativo" light />
      </div>
      <div className={classes.inputsContainer}>
        <TagsFormField name="tags" label="Tags" />
      </div>
    </div>
  );
};

const useBasicPropertiesStyles = makeStyles((theme) => ({
  themeContainer: {
    width: '50%',
    boxSizing: 'border-box',
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  colorsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    '& > div': {
      flex: 1,
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  bannerContainer: {
    boxSizing: 'border-box',
    paddingRight: theme.spacing(2),
  },
  switchContainer: {
    width: '50%',
  },
}));

export const BasicProperties = () => {
  const globalClasses = useStyles();
  const classes = useBasicPropertiesStyles();

  return (
    <div className={globalClasses.container}>
      <Title>Propriedades Básicas</Title>
      <div className={classes.colorsContainer}>
        <div>
          <ColorPickFormField label="Cor do subtítulo" name="config.textColor" />
        </div>
        <div>
          <ColorPickFormField label="Cor do plano de fundo" name="config.backgroundColor" />
        </div>
        <div>
          <ColorPickFormField label="Cor do cabeçalho" name="config.headerColor" />
        </div>
        <div>
          <ColorPickFormField label="Plano de fundo" name="config.secondaryBackgroundColor" />
        </div>
      </div>
    </div>
  );
};

export const handleSave =
  ({ dataProvider, app, setLoading, refresh, setApp, notify, setCurrentApp }) =>
  async (formData) => {
    const { label, projectId, termId, isDefault, config, description } = formData;

    const newApp = { ...app, ...formData };

    setLoading(true);
    setCurrentApp(newApp);
    setApp(newApp);

    try {
      await dataProvider.update('apps', {
        id: app.id,
        data: { isDefault, clientId: app.clientId, description, termId, projectId },
      });

      await dataProvider.patch(`apps/${app.id}/schema`, {
        config: config || {},
        label,
      });
    } catch (err) {
      setApp(app);
      setLoading(false);
      genericErrorHandler(notify)(err);
    }

    setLoading(false);

    if (refresh) {
      refresh();
    }
  };
