import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ForgotPasswordButton from "../components/ForgotPasswordButton";
import { useTimer } from "../../hooks";
import { Input } from '../../components/Input';
import { makeStyles } from "@material-ui/styles";
import { useTranslate } from "ra-core";
import { Link } from "ra-ui-materialui";

const useStyles = makeStyles(theme => ({
  loading: {
    position: 'absolute',
    bottom: '-2.6rem',
  },
  loadingMFA: {
    position: 'absolute',
    bottom: '-1rem',
  },
  setupForm: {
    padding: '2rem 0',
  },
  descriptionContainer: {
    padding: '0 2em 0 2em',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  footerContainer: {
    padding: '2rem 2rem 0 2rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  setupContainer: {
    padding: '0 2rem 1rem 2rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  selectMfaMethod: {
    width: '10rem',
  },
  buttonLoginWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: 'relative',
  },
  buttonRequest: {
    padding: '.2rem 1rem',
    textTransform: 'inherit',
    fontSize: '1rem',
    backgroundColor: '#16181D',
    borderRadius: '4px',
    "&:hover": {
      backgroundColor: "#26292d",
    },
    "&:disabled": {
      backgroundColor: "#858891",
    },
  },
  buttonLogin: {
    width: '100%',
    marginBottom: 10,
    backgroundColor: '#16181D',
    borderRadius: '4px',
    "&:hover": {
      backgroundColor: "#26292d",
    },
    "&:disabled": {
      backgroundColor: "#858891",
    },
  },
  buttonPassword: {
    textAlign: "center",
    display: "block",
    color: "#999",
    textDecoration: "underline",
  },
  buttonSwitchMFAMethodContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonSwitchMFAMethod: {
    textAlign: "center",
    display: "inline-block",
    color: "#999",
    textDecoration: "underline",
    marginTop: '1rem',
  },
  buttonSwitchMFAMethodDisabled: {
    color: '#bebebe',
    cursor: 'inherit',
    textDecoration: 'inherit',
  },
  downloads: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-evenly",
    padding: 10,
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  confirmSetupAction: {
    padding: '0 2rem 0 2rem',
  },
  mfaDescription: {
    margin: '0',
    marginBottom: '0.5rem',
    padding: '0',
  },
}))

const validate = translate => (values) => {
  const errors = { username: undefined, password: undefined };
  if (!values.username) {
    errors.username = translate('ra.validation.required');
  }
  if (!values.password) {
    errors.password = translate('ra.validation.required');
  }
  return errors;
};

// Username and Password Form
export const LoginForm = ({ submit, loading }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      <Form onSubmit={submit} validate={validate(translate)} render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <div>
              <Field
                id="username"
                name="username"
                component={Input}
                label={translate('ra.auth.username')}
                disabled={loading}
              />
            </div>
            <div>
              <Field
                id="password"
                name="password"
                component={Input}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
              />
            </div>
          </div>
          <div>
            <div className={classes.buttonLoginWrapper}>
              <Button type="submit" disabled={loading} className={classes.buttonLogin}>
                {translate("ra.auth.sign_in")}
              </Button>
              {loading && (
                <CircularProgress
                  className={classes.loading}
                  size={18}
                  thickness={2}
                />
              )}
            </div>
          </div>
        </form>
      )}
      />
      <ForgotPasswordButton className={classes.buttonPassword}/>
    </>
  );
}

// When a user already has set up MFA methods correctly
export const MFAForm = ({ submit, loading, handleNewCodeRequest, mfaValue, otherMethods, handleSwitchMethod }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { time, restartTimer, stopTimer } = useTimer(120);
  const { time: switchTime, restartTimer: restartSwitchTime } = useTimer(15);
  const [recentlySwitched, setRecentlySwitched] = useState(false);

  const handleNewCode = async () => {
    await handleNewCodeRequest()
    restartTimer();
  }

  const handleSwitch = async method => {
    if (recentlySwitched || loading || !handleSwitchMethod) {
      return;
    }
    setRecentlySwitched(true);
    stopTimer();
    await handleSwitchMethod(method);
    restartTimer();
    restartSwitchTime();
  }

  useEffect(() => {
    if (switchTime === 0 && recentlySwitched) {
      setRecentlySwitched(false);
    }
  }, [switchTime]); //eslint-disable-line

  return (
    <>
      <div className={classes.descriptionContainer}>
        <p className={classes.mfaDescription}>
          {translate('mfaCodeDescription', { mfaValue })}
        </p>
        <p className={classes.mfaDescription}>
          {translate('mfaCodeWillExpire')}
        </p>
        <div>
          {Math.floor(time / 60)}:
          {(time % 60).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </div>
      </div>
      <Form onSubmit={submit} validate={(values) => {
        const errors = { code: undefined };
        if (!values.code) {
          errors.code = translate('ra.validation.required');
        }
        return errors;
      }} render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <div>
            <div>
              <Field
                autoFocus
                id="code"
                name="code"
                component={Input}
                label="Code"
                disabled={loading}
              />
            </div>
          </div>
          <div>
            <div className={classes.buttonLoginWrapper}>
              <Button type="submit" disabled={!mfaValue || loading} className={classes.buttonLogin}>
                {translate("confirm")}
              </Button>
              {loading && (
                <CircularProgress
                  className={classes.loadingMFA}
                  size={18}
                  thickness={2}
                />
              )}
            </div>

            {time <= 0 && (
              <div className={classes.footerContainer}>
                <p className={classes.mfaDescription}>
                  {translate(time > 0 ? 'mfaCodeWillExpire' : 'didntReceiveCode')}
                </p>
                <Button onClick={handleNewCode} disabled={loading} className={classes.buttonRequest}>
                  {translate('requestNewCode')}
                </Button>
              </div>
            )}

            {otherMethods.map(methodValue => (
              <div key={`alternative_mfa_method_${methodValue}`} className={classes.buttonSwitchMFAMethodContainer}>
                <Link
                  to="#"
                  onClick={() => handleSwitch(methodValue)}
                  className={`${classes.buttonSwitchMFAMethod} ${(recentlySwitched || loading) ? classes.buttonSwitchMFAMethodDisabled : ''}`}
                >
                  Não consegue receber seu código? Receba em {methodValue}!
                </Link>
              </div>
            ))}
          </div>
        </form>
      )}
      />
    </>
  );
}
