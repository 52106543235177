import React, { useState, useEffect } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
// import { CheckCircle } from '@material-ui/icons';

import { Form } from 'react-final-form';
import { required } from 'lib/formValidators';
import { useTranslate, useDataProvider, useNotify, useLocale } from 'ra-core';
import { DropdownFormField } from 'components/designSystem/Dropdown';
import { loadTemplateVisibility, handleCreateTemplate } from './CreateTemplateDialog.model';
import { useAccount } from 'hooks';

const useStyles = makeStyles((theme) => ({
  borderContainer: {
    border: '1px solid',
    borderBottom: 'none',
    borderColor: theme.palette.text.hint,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '16px',
  },

  bottomContainer: {
    display: 'flex',
    // alignItems: 'center',
    marginTop: theme.spacing(3),
    justifyContent: 'end',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    alignItems: 'center',

    '& button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginLeft: 16,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  setDefaultContainer: {
    justifyContent: 'flex-end',
    position: 'relative',
    // top: theme.spacing(3) * -1,
    left: theme.spacing(1) * -1,

    '& > div': {
      color: theme.palette.text.tertiary,
    },
  },
}));

export const CreateTemplateDialog = ({ app, schema, isOpen, close }) => {
  const t = useTranslate();
  const locale = useLocale();
  const classes = useStyles();
  const account = useAccount();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [templateVisibility, setTemplateVisibility] = useState([]);

  useEffect(() => {
    loadTemplateVisibility({ dataProvider, setTemplateVisibility, locale });
  }, [locale]); // eslint-disable-line

  return (
    <Dialog isOpen={isOpen} close={close} title={t('templates.create')}>
      <Form
        initialValues={{
          visibility: templateVisibility[0]?.id,
          createdBy: account.name,
          ...(schema && schema.status !== 'draft' ? { schemaName: `${schema.versionName}+${schema.versionId}` } : {}),
        }}
        onSubmit={handleCreateTemplate({ dataProvider, app, schema, notify, setLoading, close })}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.content}>
              <TextFormField
                name="name"
                validate={required(t)}
                dense
                size="full"
                label={t('templates.name')}
                placeholder={t('templates.namePlaceholder')}
                required
                disabled={loading}
                iconPosition="right"
              />
            </div>
            <div className={classes.content}>
              <TextFormField
                name="description"
                full
                dense
                validate={required(t)}
                size="full"
                label={t('templates.description')}
                placeholder={t('templates.descriptionPlaceholder')}
                required
                disabled={loading}
                iconPosition="right"
              />
            </div>

            <div className={classes.content}>
              <TextFormField
                name="tags"
                full
                dense
                size="full"
                label={t('templates.tags')}
                placeholder={t('templates.tagsPlaceholder')}
                disabled={loading}
                iconPosition="right"
              />
            </div>

            <div className={classes.content}>
              <DropdownFormField
                name="visibility"
                validate={required(t)}
                full
                items={templateVisibility}
                label={t('templates.visibility')}
                required
                disabled={loading}
                translate
              />
            </div>

            {schema && schema.status !== 'draft' && (
              <div className={classes.content}>
                <TextFormField name="schemaName" full dense size="full" label="Versão do app" disabled />
              </div>
            )}
            <div className={classes.content}>
              <TextFormField name="createdBy" full dense size="full" label={t('templates.createFor')} disabled />
            </div>

            <div className={classes.bottomContainer}>
              <TertiaryButton disabled={loading} label={t('actionButtons.cancel')} onClick={close} />
              <PrimaryButton
                disabled={loading}
                type="submit"
                label={t('actionButtons.save')}
                onClick={handleSubmit}
                // icon={<CheckCircle />}
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};
