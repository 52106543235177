import React, { useState, useEffect } from "react"
import { get } from 'lodash';
import SampleButton from "./SampleButton"
import { useForm } from "hooks"
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({  
  sample: {
    display: "flex",
    minHeight: 200,
    alignItems: "center",
    justifyContent: "center"
  }  
}))
export default function SampleView({ config}) {
  const sampleViewBoxStyle = {
    color: get(config, "textColor", "#FFFFFF"),
    backgroundColor: get(config, "backgroundColor", "#007DD8") 
  }
  const classes = useStyles()
  const form = useForm()
  const [sample, setSample] = useState({config: {}})
  useEffect(() => form.subscribe(({ values }) => {
    setSample({
      "name": get(values, "name"),
      "primary": get(values, "primary"),
      "config.icon": get(values, "config.icon"),
      "config.textColor": get(values, "config.textColor"),
      "config.bgColor1": get(values, "config.bgColor1"),
      "config.bgColor2": get(values, "config.bgColor2"),
    })
  }, { values: true }), [form, setSample])

  return <div className={classes.sample} style={sampleViewBoxStyle}>
    <SampleButton channel={sample} config={config} />
  </div>
}