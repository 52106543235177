export const genericErrorHandler = (notify) => (err) => {
  switch (err.name) {
    case 'AuthenticationError':
      notify(`httpError.authentication.${err.message}`, 'error', { requestId: err.requestId });
      break;
    case 'HttpError':
    default:
      notify(`httpError.${err.message}`, 'error', { requestId: err.requestId });
  }
};

export class HttpError extends Error {
  constructor(message, requestId, httpStatus) {
    super(message);
    this.requestId = requestId;
    this.httpStatus = httpStatus;
    this.name = 'HttpError';
  }
}

export class AuthenticationError extends Error {
  constructor(message, requestId, httpStatus) {
    super(message);
    this.requestId = requestId;
    this.httpStatus = httpStatus;
    this.name = 'AuthenticationError';
  }
}

export class BadRequestError extends Error {
  constructor(message, requestId, httpStatus) {
    super(message);
    this.requestId = requestId;
    this.httpStatus = httpStatus;
    this.name = 'BadRequestError';
  }
}

export class ForbiddenError extends Error {
  constructor(message, requestId, httpStatus) {
    super(message);
    this.requestId = requestId;
    this.httpStatus = httpStatus;
    this.name = 'ForbiddenError';
  }
}
