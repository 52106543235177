import React, { useState, useCallback } from 'react';
import { SimpleForm, TextInput, SelectInput, TextField, ReferenceInput } from 'ra-ui-materialui';
import { email, useRedirect } from 'ra-core';
import {
  Create,
  DefaultToolbar,
  checkPermissions,
  required,
  ReferenceMultiGrid,
  passwordValidate,
  passwordConfirmValidate,
} from 'components';
import { roles } from 'lib/consts';
import { useAutoRedirect, useAccount } from 'hooks';
import { FormDependency } from 'components/FormDependency';
import { Col, Row } from 'resources/components/Grid';

export default ({ permissions, ...props }) => {
  const redirect = useAutoRedirect('list');
  const redirectTo = useRedirect();

  const account = useAccount() || {};

  const userType = account.userType;
  const rolesEnabled = checkPermissions(permissions, 'users:update:role');

  const ClientId = account.ClientId;
  const [role, setRole] = useState();

  const onFormInit = useCallback(({ values }) => setRole(values.role), [setRole]);
  const onChangeRole = useCallback((event) => setRole(event.target.value), [setRole]);

  const hasSite = userType === 'client' && !account.SiteId && role === 'manager';
  const hasClients = userType === 'sasi' && role === 'clientManager';

  const canCreate = props.hasCreate;
  if (permissions && !canCreate) {
    redirectTo('list', '/users');
    return <span />;
  }
  return (
    <Create {...props} titleSource="email">
      <SimpleForm
        toolbar={<DefaultToolbar />}
        redirect={redirect}
        initialValues={{ userType: account.userType, ClientId: account.ClientId }}
      >
        <Row>
          <Col>
            <TextInput source="name" validate={required()} />
            <TextInput source="username" validate={required()} />
            <TextInput source="email" validate={email()} />
            <TextInput source="password" autoComplete="off" type="password" validate={passwordValidate} />
            <TextInput source="passwordConfirm" autoComplete="off" type="password" validate={passwordConfirmValidate} />
            <FormDependency onInit={onFormInit}>
              <SelectInput
                source="role"
                validate={required()}
                choices={roles[userType]}
                allowEmpty
                disabled={!rolesEnabled}
                onChange={onChangeRole}
              />
            </FormDependency>
            {ClientId && (
              <ReferenceInput source="ClientId" reference="clients" validate={required()} disabled={true}>
                <SelectInput optionText="name" />
              </ReferenceInput>
            )}
            {hasSite && (
              <ReferenceInput
                label="fields.siteTeam"
                source="SiteId"
                reference="sites"
                validate={required()}
                filter={{ ClientId }}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )}
          </Col>
          <Col>
            {hasClients && (
              <ReferenceMultiGrid reference="clients" source="ClientIds" withFilter>
                <TextField source="id" />
                <TextField source="name" />
              </ReferenceMultiGrid>
            )}
          </Col>
        </Row>
      </SimpleForm>
    </Create>
  );
};
