import React, { useState, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Dialog, DialogTitle, DialogContent, IconButton, Icon, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  opacity: isDragging ? 0.3 : 1,
  ...draggableStyle,
});

const getListStyle = () => ({
  paddingLeft: 8,
});

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  loadingProgress: {
    position: 'relative',
    left: theme.spacing(1),
    top: '3px',
  },
  draggable: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

const Reoder = ({ items: defaultItems, render: itemRender, onReorder, onReorderAsync, loading, setLoading }) => {
  const [items, setItems] = useState(defaultItems);
  const classes = useStyles();

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const reordered = reorder(items, result.source.index, result.destination.index);
    setItems(reordered);
    if (onReorder) {
      onReorder(reordered);
    }
    if (onReorderAsync) {
      setLoading(true);
      const newItems = await onReorderAsync(reordered);
      if (newItems) {
        setItems(newItems);
      }
      setLoading(false);
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {items.map((item, index) => (
              <div className={classes.draggable}>
                <Draggable
                  key={item.id || item.name}
                  draggableId={`${item.id || item.name}`}
                  index={index}
                  isDragDisabled={loading}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <Icon>drag_indicator</Icon>
                      {itemRender(item)}
                    </div>
                  )}
                </Draggable>
              </div>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const ReorderDialog = ({
  noButton,
  title,
  items,
  render: ReorderItemRender,
  onReorder,
  onReorderAsync,
  isOpen,
  setIsOpen,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  const open = useCallback(() => setIsOpen(true), [setIsOpen]);

  return (
    <>
      {noButton || (
        <Button aria-label="close" onClick={open}>
          <Icon>reorder</Icon>
        </Button>
      )}
      <Dialog fullWidth open={isOpen} onClose={close}>
        <DialogTitle title={title}>
          {title}
          {loading && <CircularProgress color="primary" className={classes.loadingProgress} size={20} />}
          <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Reoder
            items={items}
            render={ReorderItemRender}
            onReorder={onReorder}
            onReorderAsync={onReorderAsync}
            loading={loading}
            setLoading={setLoading}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export { ReorderDialog };
export default Reoder;
