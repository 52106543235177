import React from 'react';
import GroupsIcon from '@material-ui/icons/List';
import TeamsIcon from '@material-ui/icons/SupervisorAccountOutlined';
import ClientsIcon from '@material-ui/icons/Work';
import SitesIcon from '@material-ui/icons/Place';
import AlertPanelIcon from '@material-ui/icons/Smartphone';
import MonitoringProviderIcon from '@material-ui/icons/Security';
import MessagesIcon from '@material-ui/icons/Message';
// import NotificationIcon from '@material-ui/icons/NotificationsActive';
import PinIcon from '@material-ui/icons/FiberPin';
import SettingsIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Description, Person, Bookmarks } from '@material-ui/icons';

const sasiItems = [
  // { name: 'alerts', permissions: ['alerts:view'], icon: <NotificationIcon /> },
  {
    name: 'public-codes',
    permissions: ['public-codes:view'],
    icon: <PinIcon />,
  },
  { name: 'clients', permissions: ['clients:view'], icon: <ClientsIcon /> },

  {
    name: 'mobile-users',
    permissions: ['mobile-users:view'],
    icon: <Person />,
  },
  { name: 'maps', permissions: ['maps:view'], icon: <AlertPanelIcon /> },
  {
    name: 'apps/templates',
    permissions: ['apps:view'],
    icon: <Bookmarks />,
  },
  {
    name: 'monitoring-providers',
    permissions: ['monitoring-providers:view:global'],
    icon: <MonitoringProviderIcon />,
  },
  { name: 'users', permissions: ['users:view:admins'], icon: <Person /> },
  {
    name: 'broadcast-messages',
    permissions: ['broadcast-messages:view'],
    icon: <MessagesIcon />,
  },
  {
    name: 'license-comments/sasi',
    permissions: ['license:comments:view'],
    icon: <AssignmentIcon />,
    scope: 'sasi',
  },
  {
    name: 'documents',
    permissions: ['documents:view'],
    icon: <Description />,
    group: 'config',
  },
  {
    name: 'terms',
    label: 'titles.terms',
    permissions: ['terms:view'],
    icon: <Description />,
    scope: 'client',
    group: 'config',
  },
];

const getClientsItems = (useRegHandlerV2) => [
  // { name: 'alerts', permissions: ['alerts:view'], icon: <NotificationIcon /> },
  {
    name: 'sites',
    permissions: ['sites:view'],
    icon: <SitesIcon />,
    scope: 'client',
  },
  {
    name: 'teams',
    permissions: ['sites:view'],
    icon: <TeamsIcon />,
    scope: 'client',
  },
  { name: 'maps', permissions: ['maps:view'], icon: <AlertPanelIcon /> },
  { name: 'users', permissions: ['users:view'], icon: <Person /> },
  {
    name: 'monitoring-providers',
    permissions: ['monitoring-providers:view'],
    icon: <MonitoringProviderIcon />,
    scope: 'client',
  },
  {
    name: 'apps',
    permissions: ['apps:view'],
    icon: <GroupsIcon />,
    scope: 'client',
  },
  {
    name: 'apps/templates',
    permissions: ['apps:view'],
    icon: <Bookmarks />,
    scope: 'client',
  },
  {
    name: 'registration-data/schemas',
    label: 'titles.registrationData',
    permissions: ['registration-data:view'],
    icon: <SettingsIcon />,
    scope: 'client',
    group: 'config',
  },
  {
    name: 'registration-forms',
    label: 'titles.registrationForms',
    permissions: ['registration-forms:view'],
    icon: <SettingsIcon />,
    scope: 'client',
    group: 'config',
  },
  {
    name: `${useRegHandlerV2 ? 'v2/' : ''}registration-handlers`,
    label: 'titles.registrationHandlers',
    permissions: ['registration-handlers:view'],
    icon: <SettingsIcon />,
    scope: 'client',
    group: 'config',
  },
  {
    name: 'registration-codes',
    label: 'titles.registrationCodes',
    permissions: ['registration-codes:view'],
    icon: <SettingsIcon />,
    scope: 'client',
    group: 'config',
  },
  {
    name: 'client-terms',
    label: 'titles.clientTerms',
    permissions: ['client:terms:view'],
    icon: <SettingsIcon />,
    scope: 'client',
    group: 'config',
  },
];

const mapType = (items, checkAccount) =>
  items.map((item) => ({
    ...item,
    checkAccount: (account) => checkAccount(account, item),
  }));

export const getItems = (useRegHandlerV2) => [
  ...mapType(getClientsItems(useRegHandlerV2), (account, item) => {
    return account.userType === 'client' && (!item.scope || (item.scope === 'client' && account.SiteId == null));
  }),
  ...mapType(sasiItems, (account) => account.userType === 'sasi'),
];
