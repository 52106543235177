import React, { useState, useEffect, useCallback } from 'react';
import { useDataProvider, useTranslate, useNotify, required } from 'ra-core';
import Drawer from '@material-ui/core/Drawer';
import Card from '@material-ui/core/Card';
import { Icon, ColorInput } from 'components';
import { get } from 'lodash';
import CardHeader from '@material-ui/core/CardHeader';
import { Col, Row } from 'resources/components/Grid';
import PhoneView from 'resources/components/PhoneView';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import {
  FileInput,
  SimpleForm,
  BooleanInput,
  ImageField,
  SelectInput,
  TextInput,
  ReferenceInput,
} from 'ra-ui-materialui';
import { useLazyState } from 'hooks';
import { CardActions, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { SaveButton } from 'ra-ui-materialui/lib/button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { Form } from 'react-final-form';

const Title = ({ record, onClose }) => (
  <>
    <Typography variant="h5">{get(record, 'name')}</Typography>
    <Typography variant="caption">{get(record, 'id')}</Typography>
    <IconButton style={{ position: 'absolute', right: 8, top: 8 }} onClick={onClose}>
      <Icon>close</Icon>
    </IconButton>
  </>
);

const useStyles = makeStyles(() => ({
  paperDrop: {
    width: 800,
    padding: 10,
  },
  card: {
    width: '100%',
  },
  cardHeader: {
    paddingBottom: 0,
  },
  form: {
    padding: 0,
  },
  actions: {
    flexDirection: 'row-reverse',
  },
  short: {
    maxWidth: 128,
  },
  phoneView: {
    width: 320,
    margin: 0,
    marginRight: 5,
  },
  formPanel: {
    width: 475,
    margin: 0,
  },
  accordionDetails: {
    display: 'block',
  },
  banner: {
    width: 100,
  },
  panel: {
    width: '100%',
  },
  bigFormContainer: {
    width: '100%',
    '& > div': {
      width: '100%',
    },
  },
}));

const useFileStyles = makeStyles({
  root: {
    width: 256,
  },
  preview: {
    width: 256,
    '& img': {
      width: '100%',
    },
  },
});

const ExternalToolbar = ({ readonly, saving, handleSubmit, publish }) => {
  const classes = useStyles();
  return (
    <CardActions className={classes.actions}>
      {!readonly && (
        <>
          <SaveButton saving={saving} handleSubmitWithRedirect={handleSubmit} />
          {/* <IconButton style={{ fontSize: 18 }} onClick={publish}>
            <Icon>airplanemode_active</Icon>
            Publicar
          </IconButton> */}
        </>
      )}
    </CardActions>
  );
};

const themes = [
  { id: 'sasi', name: 'SASI' },
  { id: 'business', name: 'Business' },
];

const DrawerGroup = ({ title, resource, record, version, onClose, onUpdate, readonly }) => {
  const [app, setApp] = useLazyState(record);

  const translate = useTranslate();
  const notify = useNotify();
  const fileClasses = useFileStyles();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [items, setItems] = useState([]);
  const [projects] = useState([]);
  const [selectedProjectId, selectProjectId] = useState(undefined);

  // useEffect(() => {
  //   dataProvider
  //     .fetch('projects', { ClientId: record.clientId })
  //     .then(({ data }) => {
  //       setProjects(
  //         data.data.map(({ id, name, isDefault }) => ({
  //           id,
  //           name: isDefault ? translate('resources.projects.default') : name,
  //         })),
  //       );
  //     });
  // }, [record.id]); //eslint-disable-line

  useEffect(() => {
    const projectId = app.Project ? app.Project.id : 0;
    const project = (projects || []).find((p) => p.id === projectId);
    if (!selectedProjectId && project && projectId !== 0) {
      selectProjectId(project.id);
    }
  }, [projects, app.Project, selectedProjectId]);

  const [expanded, setExpanded] = useState('general');
  const handleExpanded = (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false);

  const [showBannerNickname, setShowBannerNickname] = useLazyState(get(app, 'config.showBannerNickname'));
  const [textColor, setTextColor] = useLazyState(get(app, 'config.textColor'));
  const [backgroundColor, setBackgroundColor] = useLazyState(get(app, 'config.backgroundColor'));
  const [themeName, setThemeName] = useLazyState(get(app, 'config.themeName'));
  // const [generatePassportKey, setGeneratePassportKey] = useState(false);

  const setBannerNickname = useCallback((value) => setShowBannerNickname(value), [setShowBannerNickname]);
  const changeTheme = useCallback(({ target: { value } }) => setThemeName(value), [setThemeName]);

  const config = {
    ...app.config,
    showBannerNickname,
    textColor,
    backgroundColor,
    themeName,
  };
  useEffect(() => {
    if (app.id) {
      dataProvider.getList(
        resource,
        { sort: {}, pagination: {}, filter: { appId: app.id } },
        {
          onSuccess: ({ data }) => setItems(data),
        },
      );
    }
  }, [resource, app.id, dataProvider, setItems, version]);
  const save = useCallback(
    (values) => {
      const { id, name, TermId, isDefault, generatePassportKey, config } = values;
      dataProvider
        .update('apps', {
          id,
          data: {
            name,
            TermId,
            isDefault,
            generatePassportKey,
            config,
            ProjectId: selectedProjectId,
          },
        })
        .then(({ data }) => {
          setApp(data);
          onUpdate && onUpdate();
        });
      // setGeneratePassportKey(false);
      notify('notifications.updated');
    },
    [notify, dataProvider, onUpdate, setApp, selectedProjectId],
  );

  const publish = async () => {
    try {
      await dataProvider.create(`apps/${app.id}/schema`, {});
      notify('notifications.appPublished');
    } catch (err) {
      console.error('error on publish:', err);
    }
  };

  if (!app.id) {
    return <span />;
  }

  const accordeonProps = (name) => ({
    className: classes.panel,
    expanded: expanded === name,
    onChange: handleExpanded(name),
  });

  const inputProps = {
    resource: 'apps',
    record: app,
    translate,
    disabled: readonly,
    options: { disabled: readonly },
  };

  return (
    <Drawer
      key={`drawer_${app.id}`}
      version={version}
      anchor="right"
      open={!!app.id}
      onClose={onClose}
      classes={{ paper: classes.paperDrop }}
    >
      <Card className={classes.card}>
        <CardHeader className={classes.cardHeader} title={<Title record={app} onClose={onClose} />} subheader={''} />
        <Row>
          <Col className={classes.formPanel}>
            <SimpleForm
              resource="apps"
              record={app}
              toolbar={<ExternalToolbar readonly={readonly} publish={publish} />}
              save={save}
              className={classes.form}
            >
              <>
                <Accordion {...accordeonProps('general')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>{translate('titles.general')}</AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Row>
                      <Col>
                        <TextInput source="name" validate={required()} {...inputProps} />
                      </Col>
                    </Row>
                    {/* <Row style={{ alignItems: 'center' }}>
                      <Col style={{ flex: 1 }}>
                        <TextField
                          label="Passport Key"
                          source="config.passportKey"
                          emptyText="Usar chave de passaporte"
                          style={{ whiteSpace: 'nowrap' }}
                          {...inputProps}
                        />
                      </Col>
                      <Col>
                        <div>
                          <Checkbox
                            checked={generatePassportKey}
                            onChange={(ev) => {
                              setGeneratePassportKey(ev.target.checked);
                              app.generatePassportKey = ev.target.checked;
                            }}
                          />
                        </div>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col>
                        <TextInput source="config.nickname" {...inputProps} />
                      </Col>
                    </Row>
                    {/* <Row>
                      <div className={classes.bigFormContainer}>
                      <Form
                        initialValues={{
                          ProjectId: record.Project ? record.Project.id : 0,
                        }}
                        onSubmit={({ ProjectId }) => selectProjectId(ProjectId)}
                        render={({ handleSubmit }) => (
                          <SelectInput
                            label={translate(
                              'resources.apps.fields.Project.name',
                            )}
                            variant="outlined"
                            source="ProjectId"
                            allowEmpty
                            FormHelperTextProps={{
                              style: { display: 'none' },
                            }}
                            onChange={handleSubmit}
                            inputProps={{
                              style: { background: 'white' },
                            }}
                            choices={projects}
                          />
                        )}
                      />
                      </div>
                    </Row> */}
                    <Row>
                      <Col>
                        <BooleanInput source="isDefault" {...inputProps} />
                      </Col>
                    </Row>
                  </AccordionDetails>
                </Accordion>
                <Accordion {...accordeonProps('base')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>{translate('titles.baseProps')}</AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <Row>
                      <Col>
                        <ColorInput
                          source="config.textColor"
                          onChange={setTextColor}
                          className={classes.short}
                          {...inputProps}
                        />
                      </Col>
                      <Col>
                        <ColorInput
                          source="config.backgroundColor"
                          onChange={setBackgroundColor}
                          className={classes.short}
                          {...inputProps}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ColorInput source="config.headerColor" className={classes.short} {...inputProps} />
                      </Col>
                      <Col>
                        <ColorInput
                          source="config.secondaryBackgroundColor"
                          className={classes.short}
                          {...inputProps}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <SelectInput
                          source="config.themeName"
                          choices={themes}
                          {...inputProps}
                          onChange={changeTheme}
                        />
                      </Col>
                    </Row>
                  </AccordionDetails>
                </Accordion>
                <Accordion {...accordeonProps('banner')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>{translate('titles.banner')}</AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    {!readonly && (
                      <FileInput
                        classes={fileClasses}
                        source="config.banner"
                        name="config.banner"
                        placeholder={<p>{translate('titles.uploadBanner')}</p>}
                        {...inputProps}
                      >
                        <ImageField source="url" title={'url'} />
                      </FileInput>
                    )}
                    {readonly && <img src={get(record, 'config.banner.url')} width={300} alt="" />}
                    <BooleanInput source="config.bannerNickname" onChange={setBannerNickname} {...inputProps} />
                  </AccordionDetails>
                </Accordion>
                <Accordion {...accordeonProps('lgpd')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>{translate('titles.lgpd')}</AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <ReferenceInput source="TermId" reference="terms" filter={{ ClientId: record.ClientId }} allowEmpty>
                      <SelectInput optionText="title" />
                    </ReferenceInput>
                  </AccordionDetails>
                </Accordion>
              </>
            </SimpleForm>
          </Col>
          <Col className={classes.phoneView}>
            <PhoneView
              readonly={readonly}
              resource={resource}
              title={title}
              config={config}
              version={version}
              items={items}
              onUpdate={onUpdate}
            />
          </Col>
        </Row>
      </Card>
    </Drawer>
  );
};
export default DrawerGroup;
