import config from 'config';
import { findIndex, get, reject } from 'lodash';
import jwt from 'jsonwebtoken';
import { getDefaultLocale } from '../config/i18n';

import EventEmmitter from './EventEmitter';
import { AuthenticationError, HttpError } from './Errors';

class AuthProvider extends EventEmmitter {
  mfaToken = undefined;
  tempProp = undefined;

  /**
   * @typedef AuthProviderRequestCustomProp
   * @property {Object | undefined} customHeaders - Custom header properties to be spread along default headers,
   * defaults to {};
   * @property {string | undefined} method - HTTP Method, defaults to 'POST';
   * @property {Object | undefined} body - HTTP Body, defaults to {};
   */

  /**
   * HTTP Request builder;
   * @param {string} url - Request's URL;
   * @param {AuthProviderRequestCustomProp | undefined} customProps - Custom HTTP props, defaults to
   * { customHeaders: {}, method: 'POST', body: {} };
   * @returns {Request}
   */
  static buildHTTPRequest(url, customProps = {}) {
    const { customHeaders = {}, method = 'POST', body = {} } = customProps;
    return new Request(url, {
      method,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Locale: getDefaultLocale(),
        ...customHeaders,
      },
    });
  }

  /**
   * HTTP Request execution - For every http request inside this class, please, use this as a client;
   * @param {Request} request - Request's details, usually something generated by buildHTTPRequest;
   * @throws {HttpError, AuthenticationError}
   * @returns {Object} - It returns whatever comes in the request body (this only works for JSON responses!);
   */
  async execHTTPRequest(request) {
    const response = await fetch(request);
    const requestId = response.headers.get('X-Server-Request-Id');
    const status = response.status;
    const jsonResponse = await response.json();

    if (status === 500) {
      throw new HttpError(jsonResponse.error.message, requestId, status);
    }

    if (status === 401 && jsonResponse.error.message === 'JWT_EXPIRED') {
      const { authentication } = await this.refresh();
      this.setActiveAccount(authentication.token, { ...authentication });
      return this.execHTTPRequest(request);
    }

    if (status === 401 || status < 200 || status >= 300 || !jsonResponse.success) {
      throw new AuthenticationError(jsonResponse.error.message, requestId, status);
    }

    return jsonResponse;
  }

  /**
   * ===================================================================================================================
   * ??? REACT-ADMIN AUTH PROVIDER INTERFACE ???
   * ===================================================================================================================
   **/
  async login({ username, password }) {
    if (this.getProfiles()[0] && !username && !password) {
      try {
        return await this.refresh();
      } catch (err) {
        await this.logout();
        throw err;
      }
    }

    const useRoutesV2 = localStorage.getItem('useRoutesV2');
    const useV2 = useRoutesV2 && JSON.parse(useRoutesV2);
    const request = AuthProvider.buildHTTPRequest(`${config.apiUrl}${useV2 ? '/v2' : ''}/authentication/login`, {
      body: { username, password },
    });

    const response = await this.execHTTPRequest(request);
    if (response.profile && response.token && response.refreshToken) {
      return { authentication: { ...response } };
    }

    this.mfaToken = response.token;
    return { mfaFlow: { ...response } };
  }

  /**
   * Check if a dataProvider error is an authentication error;
   * @param error - Error triggered by the dataProvider;
   */
  checkError(error) {
    const { status } = error;
    if (status === 401) {
      return this.refresh();
    }
    return Promise.resolve();
  }

  /**
   * Check credentials before moving to a new route;
   * @returns {Promise<void>|Promise<never>}
   */
  checkAuth() {
    return this.getToken() ? Promise.resolve() : Promise.reject('notSignedIn');
  }

  /**
   * Log a user out
   * @returns {Promise<string | false | void>} - route to 'redirect to' after logout, defaults to '/login';
   */
  logout() {
    this.removeAccount(this.getProfile());
    setTimeout(() => {
      if (document.location.pathname !== '/login') {
        document.location = '/login';
      }
    }, 2000);
    return Promise.resolve('/login');
  }

  /**
   * Get the current user identity;
   * @returns {Promise<Object>}
   */
  getIdentity() {}

  /**
   * Get the current user credentials;
   * @returns {Promise<unknown>|Promise<never>}
   */
  getPermissions() {
    const permissions = get(this.getProfile(), 'permissions', ['guest:guest']);
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  }

  /**
   * ===================================================================================================================
   * ??? CUSTOM AUTH METHODS ???
   * ===================================================================================================================
   **/
  setTempProp = (prop) => {
    this.tempProp = prop;
  };

  getTempProp = () => this.tempProp;

  resendMFACodeWithToken = async (methodValue) => {
    if (!this.mfaToken) {
      throw new AuthenticationError('MISSING_MFA_TOKEN', 0);
    }
    const useRoutesV2 = localStorage.getItem('useRoutesV2');
    const useV2 = useRoutesV2 && JSON.parse(useRoutesV2);
    const request = AuthProvider.buildHTTPRequest(`${config.apiUrl}${useV2 ? '/v2' : ''}/authentication/mfa/resend`, {
      customHeaders: { Authorization: `Bearer ${this.mfaToken}` },
      body: { methodValue },
    });
    await this.execHTTPRequest(request);
  };

  verifyMFACode = async (code) => {
    if (!this.mfaToken) {
      throw new AuthenticationError('MISSING_MFA_TOKEN', 0);
    }
    const useRoutesV2 = localStorage.getItem('useRoutesV2');
    const useV2 = useRoutesV2 && JSON.parse(useRoutesV2);
    const request = AuthProvider.buildHTTPRequest(`${config.apiUrl}${useV2 ? '/v2' : ''}/authentication/mfa/verify`, {
      body: { code },
      customHeaders: { Authorization: `Bearer ${this.mfaToken}` },
    });

    const jsonResponse = await this.execHTTPRequest(request);
    return { ...jsonResponse };
  };

  saveUpdateMFAToken = (token) => {
    const profile = this.getProfile();
    profile.updateMFAToken = token;
    this.updateProfile(profile);
  };

  getUpdateMfaToken = () => {
    const token = this.getProfile().updateMFAToken;
    if (!token || jwt.decode(token).exp * 1000 - Date.now() <= 0) {
      return undefined;
    }
    return token;
  };

  getProfiles() {
    const profiles = JSON.parse(localStorage.getItem('profiles') || '[]');
    if (!profiles.length) {
      this.setProfiles(Object.values(profiles));
      return Object.values(profiles);
    }
    return profiles;
  }

  setProfiles(profiles) {
    localStorage.setItem('profiles', JSON.stringify(profiles || []));
  }

  getToken() {
    return get(this.getProfiles()[0], 'token');
  }

  getProfile() {
    return this.getProfiles()[0];
  }

  updateProfile(profile) {
    const profiles = this.getProfiles();
    profiles[0] = { ...profiles[0], ...profile };
    this.setProfiles(profiles);
    this.emit('profiles', profiles);
  }

  getRefreshToken() {
    const refreshToken = get(this.getProfiles()[0], 'refreshToken');
    if (!refreshToken) {
      throw new AuthenticationError('NOT_AUTHENTICATED');
    }

    const { exp } = jwt.decode(refreshToken);
    if (exp * 1000 - Date.now() <= 0) {
      throw new AuthenticationError('EXPIRED_REFRESH_TOKEN');
    }
    return refreshToken;
  }

  async switchAccount(token) {
    const decode = jwt.decode(token);
    const { exp, sub } = decode;

    let finalToken = token;

    const expiredAt = exp * 1000;
    const now = Date.now();
    const refreshIn = expiredAt - now;
    const profiles = this.getProfiles();
    const index = findIndex(profiles, ['id', Number(sub)]);

    if (index === -1) {
      return;
    }

    const account = profiles[index];
    account.expiredAt = expiredAt;
    account.token = token;

    profiles.splice(index, 1);
    profiles.unshift(account);
    this.setProfiles(profiles);

    if (refreshIn <= 0) {
      const { authentication } = await this.refresh();

      const decode = jwt.decode(authentication.token);
      const { exp } = decode;
      const permissions = decode['admin.sasi.com.br/role-claims'];

      const newAccount = {
        token: authentication.token,
        refreshToken: account.refreshToken,
        expiredAt: exp * 1000,
        permissions,
        ...authentication.profile,
      };

      profiles.splice(0, 1);
      profiles.unshift(newAccount);

      this.setProfiles(profiles);

      finalToken = authentication.token;
    }

    if (index !== 0) {
      this.emit('profiles', profiles);
    }
    this.emit('token', finalToken);
  }

  setActiveAccount(token, { refreshToken, profile } = {}) {
    const decode = jwt.decode(token);
    const { exp, sub } = decode;
    const permissions = decode['admin.sasi.com.br/role-claims'];

    const expiredAt = exp * 1000; // Refresh 15secs before expiring;
    const now = Date.now();
    const refreshIn = expiredAt - now;
    const profiles = this.getProfiles();
    const index = findIndex(profiles, ['id', Number(sub)]);

    if (index !== -1) {
      const account = { ...profiles[index], ...profile };
      account.refreshToken = refreshToken || account.refreshToken;
      account.permissions = permissions || account.permissions;
      account.expiredAt = expiredAt;
      account.token = token;

      profiles.splice(index, 1);
      profiles.unshift(account);
      this.setProfiles(profiles);

      if (index !== 0) this.emit('profiles', profiles);
    } else {
      profiles.unshift({
        ...profile,
        permissions,
        token,
        refreshToken,
        expiredAt,
      });
      this.setProfiles(profiles);
      this.emit('profiles', profiles);
    }
    if (refreshIn <= 0) {
      return this.refresh();
    }
    this.emit('token', token);
    return profiles[0];
  }

  removeAccount(profile) {
    if (profile) {
      const profiles = reject(this.getProfiles(), ({ id }) => id === profile.id);
      this.setProfiles(profiles);
    }
    this.emit('profiles', this.getProfiles());
  }

  async loginAs(id) {
    const useRoutesV2 = localStorage.getItem('useRoutesV2');
    const useV2 = useRoutesV2 && JSON.parse(useRoutesV2);
    const request = AuthProvider.buildHTTPRequest(
      `${config.apiUrl}${useV2 ? '/v2/sasi' : ''}/users/${id}/impersonate`,
      {
        customHeaders: { Authorization: `bearer ${this.getToken()}` },
      },
    );

    const userInfo = await this.execHTTPRequest(request);
    this.setActiveAccount(userInfo.token, { ...userInfo });
  }

  async refresh() {
    let refreshToken;
    try {
      refreshToken = this.getRefreshToken();
    } catch (err) {
      console.log('No refresh token or expired. Logging out');
      // await this.logout();
      throw err;
    }

    const useRoutesV2 = localStorage.getItem('useRoutesV2');
    const useV2 = useRoutesV2 && JSON.parse(useRoutesV2);
    const request = AuthProvider.buildHTTPRequest(
      `${config.apiUrl}${useV2 ? '/v2' : ''}/authentication/refresh-token`,
      {
        body: { refreshToken },
      },
    );

    // TODO: Implement exponential backoff
    const maxTries = 5;
    for (let i = 0; i < maxTries; i++) {
      try {
        const response = await this.execHTTPRequest(request);
        if (response.profile && response.token) {
          return { authentication: { ...response } };
        }
      } catch (err) {
        console.error(err);
        // TODO: better error handling
        console.log('Could not refresh token');
        if (i <= maxTries - 1) {
          console.log(`Trying again (${i + 1}/${maxTries})`);
        }
      }
    }

    // await this.logout();
  }
}

const authProvider = new AuthProvider();
export default authProvider;
