import React, { useState } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
// import { CheckCircle } from '@material-ui/icons';

import { Form } from 'react-final-form';
import { useDataProvider, useTranslate, useNotify } from 'ra-core';
import { handleDeleteProject } from './DeleteProjectDialog.model';

const useStyles = makeStyles((theme) => ({
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(3),

    '& button': {
      padding: `${theme.spacing(1.03)}px ${theme.spacing(1.03)}px`,
    },
  },
}));

export const DeleteProjectDialog = ({ project, isOpen, close, refresh }) => {
  const t = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog isOpen={isOpen} close={close} title={t('resources.projects.deleteModal.title', project)}>
      <Form
        initialValues={{ ...project }}
        onSubmit={handleDeleteProject({ dataProvider, close, setLoading, refresh, notify })}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.bottomContainer}>
              <TertiaryButton disabled={loading} label={t('resources.projects.buttons.no')} onClick={close} />
              <PrimaryButton
                disabled={loading}
                type="submit"
                label={t('resources.projects.buttons.yes')}
                onClick={handleSubmit}
                // icon={<CheckCircle />}
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};
