import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Popover from '@material-ui/core/Popover';
import { SimpleForm, SelectInput, Button} from "ra-ui-materialui"
import Card from '@material-ui/core/Card';
import { useGetList, required, useDataProvider, useNotify} from "ra-core"
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Icon, Typography} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 350,
  },
  actions: {
    flexDirection: "row-reverse"
  },
}))
const ExternalToolbar = ({ saving, handleSubmit}) => {
  const classes = useStyles()
  return <CardActions className={classes.actions}>
    <Button label="actions.send" onClick={handleSubmit} disabled={saving}>
      <Icon>send</Icon>
    </Button>
  </CardActions>

}
export default ({ label, record, anchorEl, onClose }) => {
  const { id: ChannelId} = record.Channel || {}
  const {ClientId} = record

  const classes = useStyles()
  const notify = useNotify()
  const { data, loaded } = useGetList("channels", { perPage: 50 }, { field: "id", order: "ASC" }, { channelType: "broadcast", ClientId})
  const dataProvider = useDataProvider()
  const [saving, setSaving] = useState(false)
  
  const choices = loaded ? Object.values(data)
    .filter(channel => channel.config.notifyManager)
    .map(channel => ({ ...channel, disabled: channel.id === ChannelId})) : []

  const handleSubmit = ({ id, ChannelId}) => {
    setSaving(true)
    dataProvider.update("broadcast-messages", { id, data: {ChannelId, testAt: (new Date().toJSON())}})
      .then(() => {
        setSaving(false)
        onClose()
      })    
      .catch(error => {
        setSaving(false)
        notify(error.message)
      })
  }
  
  return <Popover id="sendMessage" open={Boolean(anchorEl)} anchorEl={anchorEl}
    placement="bottom"
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5">{label}</Typography>
        {record && <SimpleForm  resource="broadcast-messages" record={record} 
          toolbar={<ExternalToolbar saving={saving} onClose={onClose} />} save={handleSubmit}>
          <SelectInput source="ChannelId" choices={choices} allowEmpty validate={required()}/>
        </SimpleForm>}        
      </CardContent>
      
    </Card>
  </Popover>
}
