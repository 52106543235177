import React from 'react';

import { makeStyles, TableCell, TableHead as MuiTableHead, TableRow, TableSortLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'relative',
    top: 20,
    width: 1,
  },
}));

export const TableHead = ({ columns, onSort, order, orderBy }) => {
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    onSort(property, event);
  };

  return (
    <MuiTableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell key={column.id || column.key} sortDirection={orderBy === column.id ? order : false}>
            {column.sortable ? (
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                {column.header}
                {orderBy === column.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              column.header
            )}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};
