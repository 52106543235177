import React from 'react';
import Downshift from 'downshift';
import { FieldTitle, useInput, useDataProvider, useNotify } from 'ra-core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, MenuItem, Popper } from '@material-ui/core';

import { useCallback, useEffect, useRef, useState } from 'hooks';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { get } from 'lodash';
import storage from '../lib/storage';
import { required as validationRequired } from 'lib/formValidators';

const useStyles = makeStyles({
  menuRoot: {
    position: 'absolute',
    zIndex: 4,
    maxHeight: 300,
  },
  input: {
    width: 255,
  },
  fullInput: {
    width: '100%',
  },
});

const DownshiftInput = ({
  source,
  resource,
  reference,
  filter = {},

  dense,
  margin,
  fullInput,
  required,

  label,
  placeholder,

  optionText,
  optionValue,

  storageKey,
  initialValueProp,
  record,

  ...props
}) => {
  const isMounted = useRef(true);
  const isLoading = useRef(0);
  const anchorEl = useRef(null);
  const { input, meta } = useInput({ source, resource, ...props });
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const getOptionText = (item) => {
    return typeof optionText === 'function' ? optionText(item) : get(item, optionText);
  };

  const getOptionValue = (item) => {
    return typeof optionValue === 'function' ? optionValue(item) : get(item, optionValue);
  };

  const getInitialValueText = useCallback(() => {
    if (storageKey) {
      const data = storage.getObject(storageKey);
      if (data && data.value === input.value) {
        return data;
      }
    }
    if (initialValueProp && record) {
      const data = get(record, initialValueProp);
      return { value: getOptionValue(data), text: getOptionText(data) };
    }
    return { value: '', text: '' };
    // eslint-disable-next-line
  }, [storageKey, input.value, initialValueProp, record]);

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(getInitialValueText().value);
  const [text, setText] = useState(getInitialValueText().text);
  const [items, setItems] = useState([]);

  const LoadItems = useCallback(async () => {
    const params = {
      pagination: { page: 0, perPage: 15 },
      filter: { q: text, ...filter },
      sort: { field: 'createdAt', order: 'ASC' },
    };

    try {
      const response = await dataProvider.getList(reference, params);
      if (isMounted.current) {
        setItems(response.data);
      }
    } catch (err) {
      console.error(err);
      notify('couldNotLoadData', 'warning');
    }
    // eslint-disable-next-line
  }, [text]); // The eslint-disable-next-line is necessary due to useEffect requiring props to be inside the dependency
  // array, but this causes the useCallback to trigger again; This is not a final solution, but it is
  // required in order to this component to work properly for now;

  useEffect(() => {
    isMounted.current = true;
    clearTimeout(isLoading.current);

    isLoading.current = setTimeout(() => LoadItems(), 400);

    return () => {
      isMounted.current = false;
    };
  }, [LoadItems]);

  const onChange = (item) => {
    const v = getOptionValue(item);
    const t = v ? getOptionText(item) : '';
    setValue(v || '');
    setText(t);
    setIsOpen(false);
    input.onChange(v);
    if (storageKey) {
      storage.setObject(storageKey, { value: v || '', text: t });
    }
  };

  return (
    <FormControl error={meta && meta.touched && !!meta.error} margin={margin || (dense ? 'dense' : 'normal')} required>
      <Downshift isOpen={isOpen} {...input} onChange={onChange} itemToString={(item) => getOptionValue(item)}>
        {({ getInputProps, getItemProps, isOpen, highlightedIndex }) => {
          const inputProps = getInputProps({
            placeholder,
            onChange: (event) => {
              setText(event.currentTarget.value);
              if (!isOpen) {
                setIsOpen(true);
              }
            },
            type: 'text',
          });

          return (
            <div style={{ position: 'relative' }}>
              <TextField
                label={<FieldTitle label={label} source={source} resource={resource} />}
                inputRef={anchorEl}
                {...inputProps}
                value={text}
                className={fullInput ? classes.fullInput : classes.input}
                onFocus={() => {
                  setIsOpen(true);
                }}
                onBlur={() => {
                  setIsOpen(false);
                }}
                validate={required && validationRequired()}
              />

              <TextField
                {...getInputProps({
                  name: input.name,
                  type: 'hidden',
                })}
                onChange={() => input.onChange(value)}
                value={input.value}
              />

              <Popper
                id={isOpen ? 'app-popper' : undefined}
                open={isOpen}
                anchorEl={anchorEl.current}
                placement="bottom-start"
                className={classes.menuRoot}
                modifiers={{
                  flip: {
                    enabled: true,
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'viewport',
                  },
                }}
              >
                <Paper className={classes.menuRoot} style={{ overflow: 'auto' }}>
                  {!required && (
                    <MenuItem
                      {...getItemProps({
                        key: '',
                        index: 0,
                        item: {},
                      })}
                      selected={highlightedIndex === 0}
                    >
                      &nbsp;
                    </MenuItem>
                  )}

                  {items.map((item, index) => (
                    <MenuItem
                      {...getItemProps({
                        key: getOptionValue(item),
                        index: index + 1,
                        item: item,
                      })}
                      selected={highlightedIndex === index + 1}
                    >
                      {getOptionText(item)}
                    </MenuItem>
                  ))}
                </Paper>
              </Popper>
            </div>
          );
        }}
      </Downshift>
    </FormControl>
  );
};

export default DownshiftInput;
