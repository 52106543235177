import React, { useState } from 'react';

import { useLocale, useTranslate } from 'ra-core';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import ProfileImage from 'layout/components/ProfileImage';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    borderBottom: `1px solid ${theme.palette.background.surface.pressed}`,
  },
  infoWrapper: {
    flex: '1',
  },
  menuWrapper: {
    margin: `0 ${theme.spacing(1)}px`,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
    margin: 0,
    marginBottom: theme.spacing(0.5),
  },
  updated: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.tertiary,
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  userInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.tertiary,
    marginBottom: theme.spacing(1),
  },
  userName: {
    marginLeft: theme.spacing(1),
  },
  status_published: {
    margin: 0,
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'inline-block',
    borderRadius: theme.borderRadius,
  },
  status_draft: {
    margin: 0,
    textTransform: 'capitalize',
    color: theme.palette.common.yellow.darker,
    backgroundColor: theme.palette.common.yellow.light,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'inline-block',
    borderRadius: theme.borderRadius,
  },
  status_unpublished: {
    margin: 0,
    textTransform: 'capitalize',
    color: theme.palette.text.tertiary,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'inline-block',
  },
}));

export const Version = ({ schema, onVersionSelect, /*onDelete,*/ onRename, handleNewTemplate }) => {
  const t = useTranslate();
  const classes = useStyles();
  const locale = useLocale();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { versionName, updatedAt, user, status, versionId, versionComment } = schema;

  return (
    <div className={classes.container}>
      <div className={classes.infoWrapper}>
        <h2 className={classes.title}>
          {versionName}+{versionId}
        </h2>
        <p className={classes.updated}>
          {t('resources.channels.fields.config.updated')}{' '}
          {new Date(updatedAt).toLocaleDateString(locale, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })}
        </p>
        {user && (
          <div className={classes.userInfoContainer}>
            <ProfileImage profile={user} variant="menu" />
            <span className={classes.userName}>{user.name}</span>
          </div>
        )}
        {versionComment && versionComment !== '' && <p className={classes.updated}>{versionComment}</p>}
        <p className={classes[`status_${status}`] || classes.status_unpublished}>
          {t(`resources.apps.fields.status.${status}`)}
        </p>
      </div>
      <div className={classes.menuWrapper}>
        <TertiaryButton icon={<MenuIcon />} onClick={handleOpen} />
        <Menu id="app-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              onVersionSelect(schema);
              handleClose();
            }}
          >
            {status === 'published' && t('resources.apps.fields.status.publishVersion')}
            {status === 'unpublished' && t('resources.apps.fields.status.unpublishedVersion')}
            {status === 'draft' && t('resources.apps.fields.status.draftVersion')}
          </MenuItem>
          {status === 'draft' && (
            <MenuItem
              onClick={() => {
                onRename(schema);
                handleClose();
              }}
            >
              {/* {t('resources.apps.fields.renameVersion')} */}
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleClose();
              handleNewTemplate(schema);
            }}
          >
            {t('resources.apps.fields.salveasTemplate')}
          </MenuItem>
          {/* {status === 'unpublished' && (
            <MenuItem
              onClick={() => {
                onDelete(schema);
                handleClose();
              }}
            >
              Deletar
            </MenuItem>
          )} */}
        </Menu>
      </div>
    </div>
  );
};
