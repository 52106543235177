import React from 'react';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    border: '2px solid #1f1f1f',
    width: '18px',
    height: '18px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    transition: 'background .2s',
  },
  container_filled: {
    background: '#1f1f1f',
  },
  container_disabled: {
    borderColor: '#b2b2b2',
  },
}));

export const CheckIcon = ({ filled, disabled }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${filled ? classes.container_filled : ''} ${disabled ? classes.container_disabled : ''}`}>
      <svg width="14" height="12" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 1.4L10.6 0L4 6.6L1.4 4L0 5.4L4 9.4L12 1.4Z" fill="white"/>
      </svg>
    </div>
  );
}

