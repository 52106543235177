import React, { useState } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
// import { CheckCircle } from '@material-ui/icons';

import { Form } from 'react-final-form';
import { useDataProvider, useTranslate, useNotify } from 'ra-core';
import { deleteApp } from './DeleteAppDialog.model';

const useStyles = makeStyles((theme) => ({
  bottomContainer: {
    display: 'flex',
    // alignItems: 'center',
    marginTop: theme.spacing(3),
    justifyContent: 'end',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    alignItems: 'center',

    '& button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginLeft: 16,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  setDefaultContainer: {
    justifyContent: 'flex-end',
    position: 'relative',
    top: theme.spacing(3) * -1,
    left: theme.spacing(1) * -1,

    '& > div': {
      color: theme.palette.text.tertiary,
    },
  },
}));

export const DeleteAppDialog = ({ app, isOpen, close, refresh }) => {
  const t = useTranslate();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);

  return (
    <Dialog isOpen={isOpen} close={loading ? () => {} : close} title={t('actions.delete_title', app)}>
      <Form
        onSubmit={deleteApp(dataProvider, app, setLoading, close, refresh, notify)}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {t('resources.apps.deleteAppWarning')}
            <div className={classes.bottomContainer}>
              <TertiaryButton disabled={loading} label={t('actionButtons.cancel')} onClick={close} />
              <PrimaryButton
                disabled={loading}
                type="submit"
                label={t('actionButtons.delete')}
                onClick={handleSubmit}
                // icon={<CheckCircle />}
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};
