import React from 'react';

import { makeStyles, MenuItem, Select, Tooltip } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { Field } from 'components/designSystem/Form';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import { useInputStyles } from './inputs';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    // marginBottom: 8,
    // marginRight: 2,
    // marginLeft: 2,
    display: 'inline-block',
  },

  input: {
    boxSizing: 'border-box',
    border: 'none',
    fontSize: 14,
    padding: '8px 16px',
    fontFamily: 'Inter, sans-serif',
    background: theme.palette.background.surface.secondary,
    color: theme.palette.text.secondary,
    borderRadius: theme.borderRadius,
    transition: '.2s background, .1s box-shadow',
    outline: 'none',

    '&:focus': {
      background: theme.palette.background.surface.primary,
      boxShadow: `0 0 0 2px  ${theme.palette.primary.main}`,
    },
  },

  label: {
    display: 'block',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '13px',
    marginRight: 3,
    // marginTop: 8,
    marginBottom: 10,
  },
  helperIcon: {
    color: theme.palette.text.hint,
    fontSize: theme.spacing(2.2),
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.3) * -1,
    position: 'relative',
    top: 1,
    cursor: 'hover',
  },

  error: {
    boxSizing: 'border-box',
    outline: 'none',
    background: theme.palette.error.lighter,
    color: theme.palette.text.primary,

    '&:focus': {
      background: theme.palette.background.surface.primary,
      boxShadow: `0 0 0 2px  ${theme.palette.primary.main}`,
    },
  },
  light: {
    '& > div > div': {
      backgroundColor: theme.palette.background.surface.primary,
    },
  },
  errorMessage: {
    position: 'absolute',
    top: 'calc(100% + 2px)',
    left: 2,
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.error.main,
    marginTop: 2,
  },
  tiny: { width: 90 },
  small: { width: 180 },
  medium: { width: 350 },
  full: { width: '150%' },
  emptyLabel: {
    color: theme.palette.text.tertiary,
  },
  requiredFlag: {
    color: theme.palette.error.main,
  },
}));

export const Dropdown = ({ input, ...props }) => {
  const {
    value,
    onChange,
    items,
    translate,
    full,
    label,
    condensed,
    allowEmpty,
    light,
    emptyLabel,
    required,
    disabled,
    helperText,
    meta = {},
  } = { ...(input || {}), ...props };
  const classes = useStyles();
  const t = useTranslate();
  const errorMessage = meta.error && meta.touched ? meta.error : undefined;

  return (
    <label className={`${classes.container} ${light ? classes.light : ''}`}>
      {label && (
        <span className={classes.label}>
          {label}
          {helperText && (
            <Tooltip className={classes.helperInfo} title={helperText}>
              <InfoOutlinedIcon className={classes.helperIcon} />
            </Tooltip>
          )}
          {required && <span className={classes.requiredFlag}>*</span>}
        </span>
      )}
      <Select
        disabled={disabled}
        fullWidth={!!full}
        value={value}
        onChange={(evt) => (onChange || (() => {}))(evt.target.value, evt)}
        variant="outlined"
        className={errorMessage ? classes.error : ''}
        style={condensed ? { marginBottom: 0 } : {}}
        error={!!errorMessage}
        displayEmpty
      >
        {allowEmpty &&
          (emptyLabel ? (
            <MenuItem value={undefined}>
              <span className={classes.emptyLabel}>{emptyLabel}</span>
            </MenuItem>
          ) : (
            <MenuItem value={undefined}>&#8203;</MenuItem>
          ))}
        {(items || []).map(({ id, name }) => (
          <MenuItem key={`dropdown_${name}_${id}`} value={id}>
            {translate ? t(name) : name}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <span className={classes.errorMessage}>{errorMessage}</span>}
    </label>
  );
};

export const DropdownFormField = (props) => <Field {...props} component={Dropdown} />;
