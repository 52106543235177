import { genericErrorHandler } from 'lib/Errors';

export const handleCreateProject =
  ({ dataProvider, clientId, close, setLoading, refresh, notify }) =>
  async (formData) => {
    setLoading(true);

    try {
      const data = { ...formData, clientId };
      await dataProvider.create('projects', { data });
      if (refresh) {
        refresh();
      }
    } catch (err) {
      genericErrorHandler(notify)(err);
    } finally {
      setLoading(false);
      close();
    }
  };
