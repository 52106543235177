import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { History } from '@material-ui/icons';
import { useLocale } from 'ra-core';

const useDashboardStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 16,
    gap: 8,

    width: '100%',
    minWidth: 247,
    height: 112,

    background: '#FCFCFD',
    border: '1 solid rgba(226, 228, 233, 0.4)',
    borderRadius: 8
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
    gap: '10px',

    width: '36px',
    height: '36px',

    background: '#CCDEFF',
    borderRadius: '16px',
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '28px',
    height: '28px',
    justifyContent: 'center',
    color: '#005BFF',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '12px',
    paddingTop: '10px',
  },
  infoName: {
    display: 'flex',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#7F889C',
  },
  infoNumber: {
    display: 'flex',
    fontStyle: 'normal',
    fontSize: '24px',
    fontWeight: 700,
    color: '#414958',
    paddingTop: '4px',
  },
  hitoryWrapper: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hisroryIcon: {
    width: '16px',
    height: '16px',
    paddingRight: '4px',
    color: '#7F889C',
  },
  historyText: {
    display: 'flex',
    fontWeight: 400,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '10px',
    color: '#7F889C',
  },

}));

const prepareDate = (date, locale) => {
  return new Date(date).toLocaleDateString(locale, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'})
}

export const InfoCard = ({icon, infoName, infoNumber, date}) => {
  const classes = useDashboardStyles();

  const locale = useLocale()

  return (
    <div className={classes.container}>
      <div style={{display: 'flex', alignItems: 'start'}}>
        <div className={classes.iconWrapper}>
          <div className={classes.icon}>
            {icon}
          </div>
        </div>
        <div className={classes.infoWrapper}>
          <div className={classes.infoName}>{infoName}</div>
          <div className={classes.infoNumber}>{infoNumber}</div>
        </div>
      </div>
      <div className={classes.hitoryWrapper}>
        <History className={classes.hisroryIcon}/>
        <div className={classes.historyText}>{prepareDate(date, locale)}</div>
      </div>
    </div>
  );
}