import React, { useState } from 'react';
import { Field, Form } from "react-final-form";
import { useHistory } from 'react-router';
import { useTranslate, useDataProvider, useNotify } from 'ra-core';
import { Link } from 'ra-ui-materialui';
import { Modal } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { Input } from "../../components/Input";
import { Button } from '../../components/Button';

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    padding: '1.5rem 2rem',
    borderRadius: '4px',
    boxSizing: 'border-box',
    width: '472px',
    maxWidth: '100vw',
  },
  text: {
    fontFamily: 'Nunito, sans-serif',
    fontSize: '1.125rem',
    margin: '0',
    marginBottom: '1.6rem',
  },
  title: {
    margin: '0',
    padding: '0',
    fontFamily: 'Nunito, sans-serif',
    marginBottom: '1.5rem',
    fontWeight: '400',
    fontSize: '1.5rem',
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default ({ className }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const history = useHistory();

  const translate = useTranslate();
  const classes = useStyles();

  const open = () => setDialogOpen(true);
  const close = () => {
    if (!loading) {
      setDialogOpen(false);
    }
  }

  const submit = ({ username }) => {
    setLoading(true);

    dataProvider.execute("authentication/restore-password/request", { username })
      .then(({ data: { sentTo }}) => {
        if (sentTo === 'email') {
          notify("notifications.forgotSent", "info");
          close();
        } else {
          history.push(`/password-reset/code?user=${username}`);
        }
      })
      .catch(err => {
        switch (err.name) {
          case 'AuthenticationError':
            notify(`httpError.authentication.${err.message}`, 'error', { requestId: err.requestId });
            break;
          case 'HttpError':
          default:
            notify(`httpError.${err.message}`, 'error', { requestId: err.requestId });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const validate = () => values => {
    if (!values.username) {
      return translate('ra.validation.required');
    }
  }

  const label = translate("titles.forgotPassword");

  return (
    <>
      <Link to="#" onClick={open} className={className}>
        {label}
      </Link>

      <Modal open={dialogOpen} onClose={close}>
        <div className={classes.root}>
          <Form onSubmit={submit} validate={validate()} render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <h2 className={classes.title}>{label}</h2>
              <p className={classes.text}>{translate('authentication.forgotPassword')}</p>
              <Field
                id="username"
                name="username"
                component={Input}
                label={translate('ra.auth.username')}
                disabled={loading}
              />
              <div className={classes.footerContainer}>
                <Button
                  label={translate("actions.send")}
                  type={"submit"}
                  disabled={loading}
                  fullSize
                  variant="primary"
                />
              </div>
            </form>
          )}/>
        </div>
      </Modal>
    </>
  )
}
