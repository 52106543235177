import React from 'react';
import { Datagrid, TextField } from 'ra-ui-materialui';
import { List } from 'components';
import { useClient } from 'hooks/use-record';

export default function RegistrationHandlersList({ permissions, ...props }) {
  const [client] = useClient();
  const filter = { clientId: client.id, type: 'internal' };

  return (
    <List {...props} filter={filter}>
      <Datagrid>
        <TextField source="name" />
        <TextField source="description" />
      </Datagrid>
    </List>
  );
}
