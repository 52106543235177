import React from 'react';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { LoginForm } from './LoginForm/LoginForm';
import themeLogin from 'themes/login';
import { useTranslate } from 'hooks';
import { Snack } from './Snack';

export const useLoginStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: "#DFDFDF",
    fontFamily: "avenir",
    color: "#373435",
  },
  footer: {
    padding: 20,
    textAlign: "center",
    "& a": {
      padding: "0 5px",
      color: "#999",
      textDecoration: "underline",
    },
  },
  cardContainer: {
    width: 320,
    marginTop: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    maxWidth: '412px',
    borderRadius: '4px',
    boxShadow: 'none',
    width: '80vw',
    padding: '2rem 1.5rem',
  },
  icon: {
    backgroundColor: theme.palette.secondary[500],
  },
  logo: {
    width: 350,
    margin: "auto",
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    "& img": {
      width: 290,
      padding: "0 5px",
    },
    "& span": {
      fontSize: "20pt",
      display: "block",
      margin: "25px auto",
      textAlign: "center",
      padding: "0 5px",
    },
  },
}))

export default ({ className, staticContext, ...rest }) => {
  const classes = useLoginStyles();
  const translate = useTranslate();

  return <MuiThemeProvider theme={themeLogin}>
    <div className={classnames(classes.main, className)} {...rest}>
      <div className={classes.cardContainer}>
        <div className={classes.logo}>
          <img src="/assets/logo.png" className={classes.logo} alt="logo"/>
          <span>{translate("titles.logoText")}</span>
        </div>
        <Card className={classes.card}>
          <LoginForm/>
        </Card>
      </div>
      <Snack/>
      <div className={classes.footer}>
        <a href="/">Distribuidores</a>
        <a href="https://play.google.com/store/apps/details?id=br.com.sasi.app" rel="noopener noreferrer"
           target="_blank">Android APP</a>
        <a href="https://apps.apple.com/us/app/sasi/id1467748272?ls=1" rel="noopener noreferrer" target="_blank">IOS
          APP</a>
        <a href="/">Ajuda</a>
        <a href="/">Brasil</a>
        <a href="/">Sobre</a>
        <a href="/terms.html">Termos</a>
        <a href="/privacy.html">Política de Privacidade</a>
      </div>
    </div>
  </MuiThemeProvider>
}
