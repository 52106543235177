import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import { useDataProvider, useNotify } from 'ra-core';
import Card from '@material-ui/core/Card';
import { MuiThemeProvider } from '@material-ui/core/styles';
import themeLogin from '../themes/login';
import { Snack } from './Snack';
import { useTranslate } from '../hooks';
import { useLoginStyles } from './Login';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { MFAForm } from './LoginForm/Forms';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '1.5rem',
  },
}));

export const ForgotPasswordWithCode = ({ history }) => {
  const query = useQuery();
  const username = query.get('user');

  const [token, setToken] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const loginClasses = useLoginStyles();
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (!username) {
      history.push('/login');
    }
  }, [username]); // eslint-disable-line

  const sendNewCode = () => {
    setLoading(true);

    dataProvider
      .execute('authentication/restore-password/request', { username })
      .catch((err) => {
        switch (err.name) {
          case 'AuthenticationError':
            notify(`httpError.authentication.${err.message}`, 'error', { requestId: err.requestId });
            break;
          case 'HttpError':
          default:
            notify(`httpError.${err.message}`, 'error', { requestId: err.requestId });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = ({ code }) => {
    setLoading(true);

    dataProvider
      .execute('authentication/restore-password/validate-code', { username, code })
      .then(({ data: { token } }) => {
        setToken(token);
      })
      .catch((err) => {
        switch (err.name) {
          case 'AuthenticationError':
            notify(`httpError.authentication.${err.message}`, 'error', { requestId: err.requestId });
            break;
          case 'HttpError':
          default:
            notify(`httpError.${err.message}`, 'error', { requestId: err.requestId });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSuccess = () => {
    notify(`resources.users.passwordUpdated`, 'info');
    history.replace('/login');
  };

  return (
    <MuiThemeProvider theme={themeLogin}>
      <div className={loginClasses.main}>
        <div className={loginClasses.cardContainer}>
          <div className={loginClasses.logo}>
            <img src="/assets/logo.png" className={loginClasses.logo} alt="logo" />
            <span>{translate('titles.logoText')}</span>
          </div>
          <Card className={`${loginClasses.card} ${classes.container}`}>
            {token ? (
              <ForgotPasswordForm token={token} onSuccess={handleSuccess} />
            ) : (
              <MFAForm
                submit={handleSubmit}
                loading={loading}
                mfaValue={'phone'}
                handleNewCodeRequest={sendNewCode}
                otherMethods={[]}
              />
            )}
          </Card>
        </div>
        <Snack />
      </div>
    </MuiThemeProvider>
  );
};
