import React, { useCallback, useState } from 'react';
import { AutocompleteInput } from "ra-ui-materialui"
import Icon from './Icon';
import { get } from "lodash"
import { makeStyles } from '@material-ui/styles';
const Item = record => {
    return record && record.id ? <span key={record.id} record={record} >
        <Icon>{record.id}</Icon>
        {record.id}
    </span> : <span record={{ id: "", name: "" }} >{"\u00A0"}</span>
}
const useStyles = makeStyles({
    suggestionsContainer: {
        zIndex: 99222         
    },
})
export default ({noPreview, readonly, ...props}) => {
    const classes = useStyles()
    const matchSuggestion = useCallback((filterValue, choice) => filterValue === "" || choice.id.match(filterValue), [])
    const [icon, setIcon] = useState()
    const inputText = useCallback(record => {
        const icon = get(record, "id")
        setIcon(icon)
        return get(record, "id")
    }, [setIcon])
    const helperText = !noPreview && icon ? <Icon>{icon}</Icon> : null
    return <AutocompleteInput  {...props} options={{ suggestionsContainerProps: { className: classes.suggestionsContainer }, InputProps: { helperText, disabled: readonly}}}  limitChoicesToValue={true}  translateChoice={false} suggestionLimit={props.maxItems} inputText={inputText} optionText={Item} matchSuggestion={matchSuggestion}  />
}