import { genericErrorHandler } from 'lib/Errors';

export const handleCreateLicense =
  ({ dataProvider, clientId, close, setLoading, refresh, notify }) =>
  async (formData) => {
    setLoading(true);

    try {
      const data = { ...formData, ClientId: clientId };
      await dataProvider.create('license-comments/sasi', { data });
      if (refresh) {
        refresh();
      }
    } catch (err) {
      genericErrorHandler(notify)(err);
    } finally {
      setLoading(false);
      close();
    }
  };
