import React from 'react';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderRadius: theme.borderRadius,
  },
  iconContainer: {
    display: 'inline-flex',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 100,
    padding: 3,
    marginRight: theme.spacing(1),
    '& svg': {
      color: theme.palette.primary.main,
      maxWidth: 17,
      maxHeight: 17,
    },
  },
  label: {
    flex: 1,
    color: theme.palette.text.tertiary,
    fontSize: theme.typography.body2.fontSize,
  },
}));

export const Tip = ({ label, icon }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {icon && <div className={classes.iconContainer}>{icon}</div>}
      <span className={classes.label}>{label}</span>
    </div>
  );
};
