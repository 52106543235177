import React from 'react';
import Icon from '@material-ui/core/Icon';
import SvgIcon from '@material-ui/core/SvgIcon';
import * as sasi from 'assets/icons/sasi'
import * as business from 'assets/icons/business'

export default (props) => {
  const { size = 24, children, className} = props
  let match = children.match(/^(\w+):(\w+)$/)
  if(match) {
    const [, prefix, icon] = match
    const source = ({
      sasi: sasi,
      business: business      
    })[prefix]

    if(source) {
      const viewBox = "0 0 64 64"
      return <SvgIcon
          xmlns="http://www.w3.org/2000/svg"
    			xmlnsXlink="http://www.w3.org/1999/xlink"
          width={size}
          height={size}
          style={{fontSize: size}}
          viewBox={viewBox}
          className={className}
          >
        <use xlinkHref={`${source[icon]}#${icon}`} viewBox={viewBox} />
      </SvgIcon>
    }
  }
  return <Icon className={className} style={{ fontSize: size }}>{children}</Icon>
}
