import React from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),

    '& button': {
      padding: `${theme.spacing(1.1)}px ${theme.spacing(1.1)}px`,
    },
  },
  textContainer: {},
}));

export const ConfirmDialog = ({
  isOpen,
  close,
  primaryLabel,
  primaryAction,
  primaryDisabled,
  cancelLabel,
  cancelAction,
  cancelDisabled,
  title,
  text,
}) => {
  const classes = useStyles();

  return (
    <Dialog isOpen={isOpen} close={close} title={title}>
      <div className={classes.textContainer}>
        <p>{text}</p>
      </div>

      <div className={classes.bottomContainer}>
        <TertiaryButton disabled={cancelDisabled} label={cancelLabel || 'Cancelar'} onClick={cancelAction} />
        <PrimaryButton disabled={primaryDisabled} label={primaryLabel || 'Confirmar'} onClick={primaryAction} />
      </div>
    </Dialog>
  );
};
