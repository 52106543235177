import { useEffect } from 'react';
import { useSafeSetState, useDataProvider } from 'ra-core';

const useFetch = (path, params) => {
  const [state, setState] = useSafeSetState({
    data: undefined,
    error: null,
    loading: true,
    loaded: false,
  });
  const dataProvider = useDataProvider();

  useEffect(() => {
    setState((prevState) => ({ ...prevState, loading: true }));
    /* eslint-disable react-hooks/exhaustive-deps */
    dataProvider
      .fetch(path, params)
      .then(({ data }) => {
        setState({
          data,
          loading: false,
          loaded: true,
        });
      })
      .catch((error) => {
        setState({
          error,
          loading: false,
          loaded: false,
        });
      });
  }, [path, JSON.stringify(params), dataProvider, setState]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return state;
};
export { useFetch };
