import React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconExpand from '@material-ui/icons/ChevronRight';
import { Icon } from 'components';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles({
  sampleButton: {
    width: 300,
    maxWidth: 300,
    minWidth: 300,
    margin: 10,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
    fontWeight: 500,
  },
  icon: {
    marginRight: 20,
  },
  expandIcon: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  emptyIcon: {
    width: '1em',
    height: '1em',
  },
});
const buttonsThemes = {
  sasi: {
    buttonsBorderRadius: 0,

    primaryButtonsRatio: 2.5,
    primaryIconSize: 32,
    primaryButtonsFontSize: 20,
    primaryButtonsPadding: 20,

    secondaryButtonsRatio: 5.2,
    secondaryIconSize: 24,
    secondaryButtonsFontSize: 16,
    secondaryButtonsPadding: 16,

    showExpand: true,
    primaryFlow: 'column',
    secondaryFlow: 'row',
    primaryJustifyContent: 'space-evenly',
    secondaryJustifyContent: 'left',
  },
  business: {
    buttonsBorderRadius: 10,

    primaryButtonsRatio: 4.04,
    primaryIconSize: 32,
    primaryButtonsFontSize: 20,
    primaryButtonsPadding: 20,

    secondaryButtonsRatio: 6.06,
    secondaryIconSize: 20,
    secondaryButtonsFontSize: 16,
    secondaryButtonsPadding: 16,

    primaryFlow: 'row',
    secondaryFlow: 'row',
    primaryJustifyContent: 'left',
    secondaryJustifyContent: 'center',
    showExpand: false,
  },
};
function getTheme(config) {
  const themeName = get(config, 'themeName', 'sasi');
  return buttonsThemes[themeName] || buttonsThemes.sasi;
}
function buttonStyle(channel, config) {
  const validateColor = (color, propName, defaultValue) => {
    let value = get(color, propName, defaultValue);
    return value.match(/^#([\dABCDEFabcdef][\dABCDEFabcdef][\dABCDEFabcdef]){1,2}$/) ? value : defaultValue;
  };

  const theme = getTheme(config);
  const color = validateColor(channel, 'config.textColor', '#FCDD3E');
  const bg1 = validateColor(channel, 'config.bgColor1', '#2B2B2B');
  const bg2 = validateColor(channel, 'config.bgColor2', '#424242');

  const height = 300.0 / (config.primary ? theme.primaryButtonsRatio : theme.secondaryButtonsRatio);

  return {
    color,
    background: `linear-gradient(103.25deg, ${bg1} 0%, ${bg2} 100%)`,
    borderRadius: theme.buttonsBorderRadius,
    height,
    padding: config.primary ? theme.primaryButtonsPadding : theme.secondaryButtonsPadding,
    fontSize: config.primary ? theme.primaryButtonsFontSize : theme.secondaryButtonsFontSize,
    flexFlow: config.primary ? theme.primaryFlow : theme.secondaryFlow,
    justifyContent: config.primary ? theme.primaryJustifyContent : theme.secondaryJustifyContent,
  };
}

export default ({ channel, config, className, onClick, ...props }) => {
  const classes = useStyles();
  const theme = getTheme(config);

  const viewConfig = {
    showExpand: theme.showExpand && !channel.primary,
    primary: channel.primary,
    ...config,
  };
  const iconName = get(channel, 'config.icon') || '';
  const iconSize = viewConfig.primary ? theme.primaryIconSize : theme.secondaryIconSize;

  return (
    <ButtonBase
      className={classNames(classes.sampleButton, className)}
      style={buttonStyle(channel, viewConfig)}
      onClick={onClick}
    >
      {iconName && (
        <Icon size={iconSize} className={classes.icon}>
          {iconName}
        </Icon>
      )}
      <span>{channel.label || channel.name}</span>
      {channel && viewConfig.showExpand && <IconExpand className={classes.expandIcon} />}
    </ButtonBase>
  );
};
