import React from 'react';
import { ShowLink, List, DateField } from "../../../components";
import { Datagrid, Filter, TextField } from "ra-ui-materialui";
import ActiveSwitch from "../../../resources/components/ActiveSwitch";
import { useRefresh } from "ra-core";
import FilterSwitch from "../../../resources/components/FilterSwitch";
import { TranslateField } from "../../../components/TranslateField";

const LicenseFilters = props => (
  <Filter {...props} >
    <FilterSwitch source="showInactive" label="fields.showInactive" alwaysOn/>
  </Filter>
);

export const ListLicenses = (props) => {
  const refresh = useRefresh();

  return (
    <List {...props} filters={<LicenseFilters/>}>
      <Datagrid>
        <ShowLink source="title" label="resources.license-comments/sasi.fields.title"/>
        <TextField source="responsible" label="resources.license-comments/sasi.fields.responsible" />
        <TranslateField
          namespace='resources.license-comments/sasi.phases'
          source="phase"
          label="resources.license-comments/sasi.fields.phase"
        />
        <TextField source="Client.name" label="resources.license-comments/sasi.client" />
        <DateField source="updatedAt" label="resources.license-comments/sasi.fields.updatedAt"/>
        <ActiveSwitch
          source="active"
          label="resources.license-comments/sasi.fields.active"
          sortable={false}
          onUpdate={refresh}
          readonly={!props.hasEdit}
        />
      </Datagrid>
    </List>
  )
}
