import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({  
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& button": {
      padding: "0 10px"
    }
  }
})


export default ({ header, contact, loading = false}) => {
  const { name, phone, email } = contact || {}
  const classes = useStyles()
  return <>
    <div className={classes.header}>
      {<Typography variant="h6">{header}</Typography>}     
    </div>
    <List style={{ width: "100%" }}  >
      {(loading || name) && <ListItem disableGutters={true}>
        <ListItemIcon><Icon>person</Icon></ListItemIcon>
        <ListItemText primary={loading ? <LinearProgress /> : name } />
      </ListItem>}
      {(loading || phone) && <ListItem disableGutters={true}>
        <ListItemIcon><Icon>phone</Icon></ListItemIcon>
        <ListItemText primary={loading ? <LinearProgress /> : <a href={`tel:${phone}`}>{phone}</a>} />
      </ListItem>}
      {(loading || email) && <ListItem disableGutters={true}>
        <ListItemIcon><Icon>email</Icon></ListItemIcon>
        <ListItemText primary={loading ? <LinearProgress /> : email} />
      </ListItem>}
    </List>
  </>
}
