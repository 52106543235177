import React, { useState, useEffect } from 'react';

import { useDataProvider, useTranslate } from 'ra-core';
import { AddCircle, GetApp, Publish } from '@material-ui/icons';
import { LabeledSwitch } from 'components/designSystem/LabeledSwitch';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { ListFilter } from 'components/designSystem/ListFilter';
import { Table } from 'components/designSystem/Table';
import { loadSites, sitesColumns, handleCreate } from './Sites.model';
import { useListFilters } from 'hooks';
import { useHistory } from 'react-router';
import { useSites } from 'hooks/use-resource-hooks';
import { ExportSiteDialog } from 'resources/Site/components/ExportSiteDialog';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { ImportSiteDialog } from 'resources/Site/components/ImportSiteDialog';

const SitesList = ({ client, setHandleRefresh }) => {
  const t = useTranslate();
  const dataProvider = useDataProvider();
  const history = useHistory();

  const { sites: stateSites, dispatch } = useSites(client.id);
  const [loading, setLoading] = useState(false);

  const filters = useListFilters();
  const [sites, setSites] = useState(stateSites);
  const [exportSiteDialogOpen, setExportSiteDialogOpen] = useState(false);
  const [importSiteDialogOpen, setImportSiteDialogOpen] = useState(false);
  const [totalSites, setTotalSites] = useState(undefined);

  const reload = () => loadSites(dataProvider, setSites, setTotalSites, client.id, filters, dispatch, setLoading);
  useEffect(() => setHandleRefresh(() => reload), []); //eslint-disable-line

  useEffect(() => {
    reload();
  }, filters.propList); //eslint-disable-line

  return (
    <>
      <ExportSiteDialog
        clientId={client.id}
        isOpen={exportSiteDialogOpen}
        close={() => setExportSiteDialogOpen(false)}
      />
      <ImportSiteDialog
        clientId={client.id}
        isOpen={importSiteDialogOpen}
        reload={reload}
        close={() => setImportSiteDialogOpen(false)}
      />
      <div>
        <ListFilter
          setQSearch={filters.set.qSearch}
          actions={[
            <TertiaryButton
              key="client_export_site_button"
              label={t('resources.sites.exportSites')}
              icon={<GetApp />}
              style={{ marginRight: 8 }}
              onClick={() => setExportSiteDialogOpen(true)}
            />,
            <TertiaryButton
              key="client_import_site_button"
              label={t('resources.sites.importSites')}
              icon={<Publish />}
              style={{ marginRight: 8 }}
              onClick={() => setImportSiteDialogOpen(true)}
            />,
            <PrimaryButton
              key="client_create_site_button"
              label={t('resources.sites.createSite')}
              icon={<AddCircle />}
              onClick={handleCreate(client.id, history)}
            />,
          ]}
        >
          <LabeledSwitch
            label={t('actions.showInactive')}
            onChange={filters.set.showInactive}
            value={filters.showInactive}
            reverse
          />
        </ListFilter>
        <Table
          loading={loading && sites.length === 0}
          onRowClick={(row) => history.push(`/sites/${row.id}/show`)}
          data={sites}
          columns={sitesColumns(dataProvider, reload)}
          onSort={filters.handleRequestSort}
          order={filters.order}
          orderBy={filters.orderBy}
          totalRecords={totalSites}
          rowsPerPage={filters.limit}
          page={filters.offset / filters.limit}
          onChangePage={(value) => filters.set.offset(value * filters.limit)}
          onChangeRowsPerPage={filters.set.limit}
          noDataMessage={t('resources.sites.noSites')}
        />
      </div>
    </>
  );
};

export const Sites = ({ client, setHandleRefresh }) => {
  if (!client) {
    return <div></div>;
  }
  return <SitesList client={client} setHandleRefresh={setHandleRefresh} />;
};
