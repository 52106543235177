import PhoneNumber from 'awesome-phonenumber';
import jsonExport from 'jsonexport/dist';

export const availableSiteExportFormats = [
  { id: 'CSV', name: 'CSV' },
  { id: 'TSV', name: 'TSV' },
];

const exportFormatOptions = {
  CSV: {
    extension: '.csv',
    separator: ',',
  },
  TSV: {
    extension: '.tsv',
    separator: '\t',
  },
};

async function loadSites({ page, dataProvider, clientId, withInactive, siteType }) {
  const filter = {
    exportMode: true,
    showInactive: false,
    siteType,
  };
  if (clientId) filter.ClientId = clientId;
  if (withInactive) filter.showInactive = true;

  return await dataProvider.getList('sites', { filter, pagination: { page, perPage: 500 } });
}

export async function loadAllSites({ dataProvider, ...filters }) {
  let page = 1;

  const { total, data } = await loadSites({ page, dataProvider, ...filters });

  let sites = [...(data || [])];
  page += 1;

  while (sites.length < total) {
    const { data } = await loadSites({ page, dataProvider, ...filters });
    sites = [...sites, ...(data || [])];
    page += 1;
  }

  return sites;
}

function getPhone(phoneNumber) {
  if (phoneNumber) {
    const phone = new PhoneNumber(phoneNumber, 'BR');
    let [country, area, ...other] = phone.getNumber('international').split(' ');
    return {
      smTelCountryCode: country.replace('+', ''),
      smTelAreaCode: other.length > 0 ? area : '',
      smTelNum: other.length > 0 ? other.join('') : area,
    };
  }
  return {};
}

const schema = (t) => [
  { id: 'siteName', title: t('resources.sites.export.siteName') },
  { id: 'siteAddress', title: t('resources.sites.export.siteAddress') },
  { id: 'siteZipcode', title: t('resources.sites.export.siteZipcode') },
  { id: 'siteCity', title: t('resources.sites.export.siteCity') },
  { id: 'siteRadius', title: t('resources.sites.export.siteRadius') },
  { id: 'siteTag', title: t('resources.sites.export.siteTag') },
  { id: 'siteState', title: t('resources.sites.export.siteState') },
  { id: 'siteReferencePoint', title: t('resources.sites.export.siteReferencePoint') },
  { id: 'siteApp', title: t('resources.sites.export.siteApp') },
  { id: 'siteAppCode', title: t('resources.sites.export.siteAppCode') },
  { id: 'siteCountry', title: t('resources.sites.export.siteCountry') },
  { id: 'siteLatitude', title: t('resources.sites.export.siteLatitude') },
  { id: 'siteLongitude', title: t('resources.sites.export.siteLongitude') },
  { id: 'siteManagerName', title: t('resources.sites.export.siteManagerName') },
  { id: 'smTelCountryCode', title: t('resources.sites.export.smTelCountryCode') },
  { id: 'smTelAreaCode', title: t('resources.sites.export.smTelAreaCode') },
  { id: 'smTelNum', title: t('resources.sites.export.smTelNum') },
  { id: 'smEmail', title: t('resources.sites.export.smEmail') },
  { id: 'smJobTitle', title: t('resources.sites.export.smJobTitle') },
];

function exporter(sites, t) {
  return {
    schema: schema(t),
    data: sites.map((site) => ({
      siteName: site.name,
      siteAddress: site.location?.address,
      siteZipcode: site.location?.zip,
      siteCity: site.location?.city,
      siteState: site.location?.state,
      siteCountry: site.location?.country,
      siteApp: site.App?.name,
      siteAppCode: site.registrationCodes?.map((e) => e.code)?.join(';'),
      siteReferencePoint: site.referencePoint,
      siteLatitude: site.lat,
      siteLongitude: site.lng,
      siteRadius: site.radius,
      siteTag: site.tag,
      siteManagerName: site.MobileProfiles?.[0]?.name,
      smJobTitle: site.MobileProfiles?.[0]?.job,
      smEmail: site.MobileProfiles?.[0]?.email,
      ...getPhone(site.MobileProfiles?.[0]?.phone),
    })),
  };
}

const exportJson = (data, options = {}) =>
  new Promise((resolve, reject) => {
    jsonExport(data, { ...options, includeHeaders: false }, (err, csvData) => {
      if (err) reject(err);
      resolve(csvData);
    });
  });

const downloadCSV = (csv, filename, ext = '.csv') => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);

  // added UTF-8 BOM
  csv = '\ufeff' + csv;
  const blob = new Blob([csv], { type: 'text/csv' });

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    window.navigator.msSaveOrOpenBlob(blob, `${filename}${ext}`);
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob));
    fakeLink.setAttribute('download', `${filename}${ext}`);
    fakeLink.click();
  }
};

export function exportSites({ dataProvider, setLoading, closeDialog, clientId, siteType, t }) {
  return async ({ format, withInactive }) => {
    if (setLoading) setLoading(true);

    const sites = await loadAllSites({ dataProvider, clientId, withInactive, siteType: siteType || 'site' });
    const { schema, data: exportData } = exporter(sites, t);

    const fileOptions = exportFormatOptions[format] || exportFormatOptions['CSV'];
    const headers = schema.map((s) => s.title).join(fileOptions.separator);
    const csvData = await exportJson(exportData, {
      headers: schema.map((s) => s.id),
      rowDelimiter: fileOptions.separator,
    });

    const csv = [headers, csvData].join('\n');
    const csvFileName = [siteType || 'site', clientId || '', new Date().getTime()].join('-');
    downloadCSV(csv, csvFileName, fileOptions.extension);

    if (setLoading) setLoading(false);
    if (closeDialog) closeDialog();
  };
}
