import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ChannelPreviewButton } from '../ChannelPreviewButton';
import { ColorPickFormField } from 'components/designSystem/ColorPickField';
import { IconDropdownFormField } from 'components/designSystem/IconDropdown';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { useTranslate } from 'ra-core';
import { useListFilters } from 'hooks';
// import { Search } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  previewContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginRight: theme.spacing(4),

    background: theme.palette.background.surface.pressed,
  },
  fieldsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 3,
    marginTop: 12,

    '& > label': {
      // flex: 1,
      marginRight: 37,
      // marginTop: theme.spacing(1.3),
    },
  },
  fieldsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  subtext: {
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(0.2),
    marginRight: theme.spacing(2.5),
    alignItems: 'flex-end',
    fontWeight: 500,

    marginTop: theme.spacing(1),
    color: theme.palette.text.tertiary,
  },
  textField: {
    marginTop: theme.spacing(0.65) * -1,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  field: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  iconDropdown: {
    '& > div': {
      maxWidth: 175, // TODO: think of a better way
    },

    '& > svg': {
      marginLeft: 193,
      marginTop: -300,
      marginBottom: 20,
      color: theme.palette.text.tertiary,
    },
  },
}));

export const StylePanel = ({ channel, app, loading, originalChannel }) => {
  const classes = useStyles();
  const t = useTranslate();
  const filters = useListFilters();

  return (
    <div className={classes.container}>
      <div className={classes.previewContainer}>
        <ChannelPreviewButton
          channel={{ ...channel, label: channel.label || originalChannel.label || '' }}
          app={app}
          noPrimary
          style={{ padding: '24px 0 24px 16px', width: 279, maxWidth: 279, height: 72, margin: 0 }}
        />
      </div>
      <div className={classes.fieldsWrapper}>
        <TextFormField
          className={classes.textField}
          placeholder={originalChannel.label}
          name="label"
          size="full"
          full
          disabled={loading}
          label={t('resources.channels.fields.config.channelTitle')}
          // helperText="Adicionar o título do canal"
        />
        <div className={classes.fieldsContainer}>
          <ColorPickFormField
            name="config.bgColor1"
            label={t('resources.channels.fields.config.bgColor1')}
            // helperText="Em breve"
            surface
            disabled={loading}
            className={classes.field}
          />
          <ColorPickFormField
            name="config.bgColor2"
            label={t('resources.channels.fields.config.bgColor2')}
            // helperText="Em breve"
            disabled={loading}
            surface
            // className={classes.field}
          />
        </div>

        <div className={classes.fieldsContainer}>
          <ColorPickFormField
            name="config.textColor"
            label={t('resources.channels.fields.config.textColor')}
            // helperText="Em breve"
            surface
            disabled={loading}
            className={classes.field}
          />
          <IconDropdownFormField
            allowEmpty
            setQSearch={filters.set.qSearch}
            name="config.icon"
            label={t('resources.channels.fields.config.icon')}
            placeholder="Selecionar"
            // icon={<Search />}
            // iconPosition="right"
            // helperText="Adicionar o ícone do canal"
            disabled={loading}
            className={classes.iconDropdown}
          />
        </div>
      </div>
    </div>
  );
};
