import React, { useState } from 'react';
import { useAuthProvider, useDataProvider, useNotify, useTranslate } from 'ra-core';
import { useAccount } from '../../hooks';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MultiStepFormModal } from '../../components/MultiStepFormModal';
import { ProfilePropField } from './components/ProfilePropField';

import {
  validatePasswordStep,
  verifyPhoneStep,
  verifyEmailStep,
  addEmailSteps,
  addPhoneSteps,
  updateNameStep,
  updatePasswordStep,
  setupEmailStep,
  setupPhoneStep,
} from './Profile.model';
import { CheckIcon } from '../../assets/Profile/Icons/Check';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '1.25rem 1.75rem',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '600',
    fontFamily: 'Nunito, sans-serif',
    margin: '0',
    padding: '0',
    marginBottom: '1rem',
  },
  separator: {
    borderTop: '1px solid #C4C4C4',
    margin: '1rem 0',
  },
  suffixPropButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    width: '145px',
    justifyContent: 'flex-end',
  },
  suffixPropButton: {
    fontSize: '.75rem',
    outline: 'none',
    border: '1px solid #000000',
    background: 'white',
    width: '70px',
    minWidth: '70px',
    height: '27px',
    minHeight: '27px',
    borderRadius: '4px',
    transition: '.2s background',
    cursor: 'pointer',
    '&:hover': {
      background: '#E2E4E9',
    },
    '&:disabled': {
      background: 'white',
      cursor: 'inherit',
      color: '#989797',
      borderColor: '#989797',
    },
  },
  prefixCheckboxContainer: {
    marginRight: '1rem',
    cursor: 'pointer',
  },
  prefixCheckboxContainer_loading: {
    cursor: 'inherit',
  },
}));

const getButtonProps = (field, handlers, t) => {
  const fieldProps = {
    label: t('resources.profiles.add'),
    onClick: handlers.onAdd || (() => undefined),
  };
  if (field) {
    fieldProps.label = t('resources.profiles.config');
    fieldProps.onClick = handlers.onSetup || (() => undefined);
  }
  return fieldProps;
};

export const Profile = () => {
  const notify = useNotify();
  const classes = useStyles();
  const history = useHistory();

  const account = useAccount() || {};
  const authProvider = useAuthProvider();
  const dataProvider = useDataProvider();
  const t = useTranslate();

  const [isMultiStepModalOpen, setMultiStepModalOpen] = useState(false);
  const [selectedSteps, setSelectedSteps] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const stepsWithPasswordValidation = (_steps) => {
    let steps = [..._steps];
    const token = authProvider.getUpdateMfaToken();
    if (!token) {
      steps = [validatePasswordStep(account, authProvider, dataProvider), ...steps];
    }
    return steps;
  };

  const openModalWithSteps = (_steps, withPassword) => {
    const steps = withPassword ? stepsWithPasswordValidation(_steps) : _steps;
    setSelectedSteps(steps);
    setMultiStepModalOpen(true);
  };

  const emailButtonProps = getButtonProps(
    account.email,
    {
      onAdd: () => openModalWithSteps(addEmailSteps(account, authProvider, dataProvider, notify), true),
      onSetup: () => {
        let steps = [setupEmailStep(account)];
        const token = authProvider.getUpdateMfaToken();
        if (!token) {
          steps = [...steps, validatePasswordStep(account, authProvider, dataProvider)];
        }
        openModalWithSteps([...steps, ...addEmailSteps(account, authProvider, dataProvider, notify)]);
      },
    },
    t,
  );

  const phoneButtonProps = getButtonProps(
    account.phone,
    {
      onAdd: () => openModalWithSteps(addPhoneSteps(account, authProvider, dataProvider, notify), true),
      onSetup: () => {
        let steps = [setupPhoneStep(account)];
        const token = authProvider.getUpdateMfaToken();
        if (!token) {
          steps = [...steps, validatePasswordStep(account, authProvider, dataProvider)];
        }
        openModalWithSteps([...steps, ...addPhoneSteps(account, authProvider, dataProvider, notify)]);
      },
    },
    t,
  );

  const saveMfaMethod = async (mfaMethod, disable) => {
    const token = authProvider.getUpdateMfaToken();
    const userMfaMethods = account.mfaMethod;

    const handleSave = async (token) => {
      setLoading(true);
      try {
        const { data } = await dataProvider.execute(`users/${account.id}/select-mfa`, { mfaMethod, disable, token });
        authProvider.updateProfile({ ...data.profile, permissions: data.permissions });
        const { authentication } = await authProvider.refresh();
        authProvider.setActiveAccount(authentication.token, { ...authentication });

        if ((disable && userMfaMethods.length === 1) || (!disable && userMfaMethods.length === 0)) {
          history.go(0);
        }
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
      }
    };

    if (!token) {
      openModalWithSteps([validatePasswordStep(account, authProvider, dataProvider, handleSave)]);
    } else {
      await handleSave(token);
    }
  };

  const handleOpenModal = (steps) => {
    setSelectedSteps(steps);
    setMultiStepModalOpen(true);
  };

  const hasEmailMfaMethod = (account.mfaMethod || []).find((m) => m === 'email');
  const hasPhoneMfaMethod = (account.mfaMethod || []).find((m) => m === 'phone');

  return (
    <>
      <Paper className={classes.paper}>
        <h1 className={classes.title}>{t('resources.profiles.title')}</h1>
        <ProfilePropField
          label={t('resources.profiles.fields.name')}
          value={account.name}
          suffixComponent={
            <button
              onClick={() => handleOpenModal([updateNameStep(account, authProvider, dataProvider, notify)])}
              className={classes.suffixPropButton}
              disabled={isLoading}
            >
              {t('resources.profiles.update')}
            </button>
          }
        />
        <ProfilePropField label={t('resources.profiles.fields.user')} value={account.username} disabled />
        <ProfilePropField
          label={t('resources.profiles.fields.password')}
          value="******"
          suffixComponent={
            <button
              onClick={() => handleOpenModal([updatePasswordStep(account, authProvider, dataProvider, notify)])}
              className={classes.suffixPropButton}
              disabled={isLoading}
            >
              {t('resources.profiles.update')}
            </button>
          }
        />
        <div className={classes.separator} />
        <h1 className={classes.title}>{t('resources.profiles.multiFactorAuth')}</h1>
        <ProfilePropField
          disabled
          label={t('resources.profiles.fields.email')}
          value={account.email || t('resources.profiles.fields.emailNotFound')}
          prefixComponent={
            <div
              className={`${classes.prefixCheckboxContainer} ${
                isLoading ? classes.prefixCheckboxContainer_loading : ''
              }`}
              onClick={() => {
                if (account.emailVerified) {
                  saveMfaMethod('email', hasEmailMfaMethod);
                }
              }}
            >
              <CheckIcon filled={hasEmailMfaMethod} disabled={!account.emailVerified} />
            </div>
          }
          suffixComponent={
            <div className={classes.suffixPropButtonContainer}>
              {!account.emailVerified && account.email && (
                <button
                  className={classes.suffixPropButton}
                  style={{ marginRight: '.5rem' }}
                  onClick={() => {
                    setLoading(true);
                    dataProvider.execute(`users/email/send-verifying-code`).then(() => {
                      setLoading(false);
                      openModalWithSteps([
                        verifyEmailStep(account, authProvider, dataProvider, notify, true, async () => {
                          setLoading(true);
                          await dataProvider.execute(`users/email/send-verifying-code`);
                          setLoading(false);
                        }),
                      ]);
                    });
                  }}
                  disabled={isLoading}
                >
                  {t('resources.users.fields.verify')}
                </button>
              )}
              <button className={classes.suffixPropButton} onClick={emailButtonProps.onClick} disabled={isLoading}>
                {emailButtonProps.label}
              </button>
            </div>
          }
        />
        <ProfilePropField
          label={t('resources.profiles.fields.phone')}
          value={account.phone || t('resources.profiles.fields.phoneNotFound')}
          disabled
          prefixComponent={
            <div
              className={`${classes.prefixCheckboxContainer} ${
                isLoading ? classes.prefixCheckboxContainer_loading : ''
              }`}
              onClick={() => {
                if (account.phoneVerified) {
                  saveMfaMethod('phone', hasPhoneMfaMethod);
                }
              }}
            >
              <CheckIcon filled={hasPhoneMfaMethod} disabled={!account.phoneVerified} />
            </div>
          }
          suffixComponent={
            <div className={classes.suffixPropButtonContainer}>
              {!account.phoneVerified && account.phone && (
                <button
                  className={classes.suffixPropButton}
                  style={{ marginRight: '.5rem' }}
                  onClick={() => {
                    setLoading(true);
                    dataProvider.execute(`users/phone/send-verifying-code`).then(() => {
                      setLoading(false);
                      openModalWithSteps([
                        verifyPhoneStep(account, authProvider, dataProvider, notify, true, async () => {
                          setLoading(true);
                          await dataProvider.execute(`users/phone/send-verifying-code`);
                          setLoading(false);
                        }),
                      ]);
                    });
                  }}
                  disabled={isLoading}
                >
                  {t('resources.users.fields.verify')}
                </button>
              )}
              <button className={classes.suffixPropButton} onClick={phoneButtonProps.onClick} disabled={isLoading}>
                {phoneButtonProps.label}
              </button>
            </div>
          }
        />
      </Paper>
      <MultiStepFormModal
        isOpen={isMultiStepModalOpen}
        handleClose={() => setMultiStepModalOpen(false)}
        steps={selectedSteps}
      />
    </>
  );
};

export default Profile;
