export const defaults = {
  banner: {},
  showBannerNickname: false,
  backgroundColor: '#007DD8',
  textColor: '#FFFFFF',
  headerColor: '#007DD8',
  secondaryColor: '#363435',
  secondaryBackgroundColor: '#FAFAFA',
  themeName: 'sasi',
};
