import React, { useState, useEffect } from 'react';

import { useDataProvider, useTranslate } from 'ra-core';
import { AddCircle } from '@material-ui/icons';
import { LabeledSwitch } from 'components/designSystem/LabeledSwitch';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { ListFilter } from 'components/designSystem/ListFilter';
import { Table } from 'components/designSystem/Table';
import { licensesColumns, loadLicenses } from './Licenses.model';
import { useListFilters } from 'hooks';
import { useHistory } from 'react-router';
import { useLicenses } from 'hooks/use-resource-hooks';
import { CreateLicenseDialog } from 'scopes/Admin/License/components/CreateLicenseDialog';

const LicenseList = ({ client, setHandleRefresh }) => {
  const t = useTranslate();
  const dataProvider = useDataProvider();
  const history = useHistory();
  const { licenses: licensesState, dispatch } = useLicenses(client.id);

  const filters = useListFilters();
  const [licenses, setLicense] = useState(licensesState);
  const [totalLicense, setTotalLicenses] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);

  const reload = () =>
    loadLicenses(dataProvider, setLicense, setTotalLicenses, client.id, filters, dispatch, setLoading);
  useEffect(() => setHandleRefresh(() => reload), []); //eslint-disable-line

  useEffect(() => {
    reload();
  }, filters.propList); //eslint-disable-line

  return (
    <div>
      <CreateLicenseDialog
        clientId={client.id}
        clientName={client.name}
        isOpen={isCreateDialogOpen}
        refresh={reload}
        close={() => setCreateDialogOpen(false)}
      />
      <ListFilter
        actions={[
          <PrimaryButton
            key="client_create_license_button"
            label={t('resources.license-comments/sasi.create')} //atualizar
            icon={<AddCircle />}
            onClick={() => setCreateDialogOpen(true)}
          />,
        ]}
      >
        <LabeledSwitch
          label={t('fields.showInactive')}
          onChange={filters.set.showInactive}
          value={filters.showInactive}
          reverse
        />
      </ListFilter>
      <Table
        loading={loading && licenses.length === 0}
        onRowClick={(row) => history.push(`/license-comments/sasi/${row.id}/show`)}
        data={licenses}
        columns={licensesColumns(dataProvider, reload)}
        onSort={filters.handleRequestSort}
        order={filters.order}
        orderBy={filters.orderBy}
        totalRecords={totalLicense}
        rowsPerPage={filters.limit}
        page={filters.offset / filters.limit}
        onChangePage={(value) => filters.set.offset(value * filters.limit)}
        onChangeRowsPerPage={filters.set.limit}
        noDataMessage={t('resources.license-comments/sasi.noCommercialMessage')}
      />
    </div>
  );
};

export const Licenses = ({ client, setHandleRefresh }) => {
  if (!client) {
    return <div></div>;
  }
  return <LicenseList client={client} setHandleRefresh={setHandleRefresh} />;
};
