import React, { useState } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
// import { CheckCircle } from '@material-ui/icons';

import { Form } from 'react-final-form';
import { passwordValidate, passwordConfirmValidate, required, requiredEmailField } from 'lib/formValidators';
import { useDataProvider, useTranslate, useNotify } from 'ra-core';
import { handleCreateUser } from './CreateUserDialog.model';
import { CheckCircle } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },

  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    justifyContent: 'end',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),

    '& button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginLeft: 16,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  content: {
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const CreateUserDialog = ({ clientId, clientName, isOpen, close, refresh }) => {
  const t = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      isOpen={isOpen}
      close={close}
      title={`${t(`resources.users.titleUser`)} ${clientName ? `"${clientName}"` : ''}`}
    >
      <Form
        onSubmit={handleCreateUser({ dataProvider, clientId, close, setLoading, refresh, notify })}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <div className={classes.content}>
              <TextFormField
                name="name"
                validate={required(t)}
                full
                dense
                size="full"
                label={t('resources.users.nameUser')}
                required
                iconError={<WarningRoundedIcon />}
                iconPosition="right"
                placeholder={t('resources.users.placeholderName')}
              />
            </div>

            <div className={classes.content}>
              <TextFormField
                name="username"
                validate={required(t)}
                full
                dense
                size="full"
                label={t('resources.users.usernameUser')}
                required
                iconError={<WarningRoundedIcon />}
                iconPosition="right"
                placeholder={t('resources.users.placeholderUsername')}
              />
            </div>

            <div className={classes.content}>
              <TextFormField
                name="email"
                validate={requiredEmailField(t)}
                full
                dense
                size="full"
                label={t('resources.users.emailUser')}
                required
                iconError={<WarningRoundedIcon />}
                iconPosition="right"
                placeholder={t('resources.users.placeholderEmail')}
              />
            </div>

            <div className={classes.content}>
              <TextFormField
                name="password"
                validate={passwordValidate(t)}
                full
                dense
                required
                password
                size="full"
                label={t('resources.users.password')}
                iconError={<WarningRoundedIcon />}
                iconSucess={<CheckCircle />}
                iconPosition="right"
                placeholder={t('resources.users.placeholderPassword')}
              />
            </div>

            <div className={classes.content}>
              <TextFormField
                name="passwordConfirm"
                validate={passwordConfirmValidate(t)}
                full
                dense
                password
                size="full"
                required
                label={t('resources.users.passwordConfirm')}
                iconError={<WarningRoundedIcon />}
                iconPosition="right"
                placeholder={t('resources.users.placeholderConfirmPassword')}
              />
            </div>

            <div className={classes.bottomContainer}>
              <TertiaryButton disabled={loading} label={t('actionButtons.cancel')} onClick={close} />
              <PrimaryButton
                disabled={loading}
                type="submit"
                label={t('actionButtons.create')}
                onClick={handleSubmit}
                // icon={<CheckCircle />}
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};
