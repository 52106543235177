import React from 'react';

import { Toggler } from 'components/designSystem/Toggler';
import { TableRowActionButton } from 'components/designSystem/TableRowActionButton';

export const providersColumns = (translate, dataProvider, onEdit, refresh) => [
  {
    key: 'client_providers_table_column_name',
    id: 'name',
    field: 'name',
    header: translate('resources.monitoring-providers.fields.name'),
  },
  {
    key: 'client_providers_table_column_provider_type',
    id: 'providerType',
    field: (record) => translate(`providerTypes.${record.providerType}`),
    default: '-',
    header: translate('resources.monitoring-providers.fields.providerType'),
  },
  {
    key: 'client_providers_table_column_active',
    id: 'active',
    field: (record) => (
      <Toggler
        key={JSON.stringify(record)}
        checked={record.active}
        onClick={(evt) => {
          evt.stopPropagation();
          toggleProviderActive(dataProvider, record, refresh);
        }}
      />
    ),
    header: translate('resources.projects.buttons.active'),
  },
  {
    key: 'client_providers_table_column_edit_action',
    id: 'action',
    field: (record) => <TableRowActionButton action="edit" record={record} onClick={onEdit} />,
  },
];

const toggleProviderActive = async (dataProvider, provider, refresh) => {
  const { id, ...data } = provider;
  await dataProvider.update('monitoring-providers', { id, data: { ...data, active: !provider.active } });

  if (refresh) {
    refresh();
  }
};

export const loadProviders = (
  dataProvider,
  setProviders,
  setTotalProviders,
  clientId,
  filters,
  dispatch,
  setLoading,
) => {
  setLoading(true);
  dataProvider
    .getList('monitoring-providers', {
      filter: {
        ClientId: clientId,
        showInactive: filters.showInactive,
        limit: filters.limit,
        offset: filters.offset,
        ...(filters.order && filters.orderBy
          ? {
              order: `${filters.orderBy} ${filters.order.toUpperCase()}`,
            }
          : {}),
      },
    })
    .then(({ data, total }) => {
      setLoading(false);
      dispatch(data);
      setProviders(data);
      setTotalProviders(total);
    })
    .catch((err) => {
      setLoading(false);
      throw err;
    });
};
