export const deleteApp = (dataProvider, app, setLoading, handleClose, refresh, notify) => async () => {
  setLoading(true);
  try {
    await dataProvider.delete('apps', app);
    notify('resources.apps.deleteAppSuccess', 'success');
    if (refresh) {
      refresh();
    }
  } catch (err) {
    notify(`resources.apps.errors.${err.message}`, 'error');
  }
  setLoading(false);
  handleClose();
};
