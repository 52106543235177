import React from 'react';
import { required } from 'ra-core';
import {
  BooleanInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'ra-ui-materialui';
import { QuickCreateButton } from '../../../components';
import { channelTypes } from '../../../lib/consts';

const CreateChannelButton = (props) => (
  <QuickCreateButton {...props} resource="channels">
    <SimpleForm toolbar={null} resource="channels">
      <TextInput source="name" validate={required()} />
      <SelectInput
        source="channelType"
        choices={channelTypes}
        validate={required()}
      />
      <BooleanInput
        type="checkbox"
        source="primary"
        validate={required()}
        defaultValue={false}
      />
    </SimpleForm>
  </QuickCreateButton>
);

export default CreateChannelButton;
