import React, { useContext } from 'react';
import { useRef } from 'react';

export const ClipboardContext = React.createContext();

export const Clipboard = ({ children }) => {
  const inputRefs = useRef();
  const buttonRefs = useRef();

  const handleCopy = async () => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    try {
      await navigator.clipboard.writeText(inputRefs.current.value); //await e writeText
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      return false;
    }
  };
  const fillClipboard = (text) => {
    inputRefs.current.value = text;
    buttonRefs.current.click();
  };

  return (
    <>
      <ClipboardContext.Provider value={fillClipboard}>{children}</ClipboardContext.Provider>
      <input style={{ display: 'none' }} type="text" ref={inputRefs} />
      <button style={{ display: 'none' }} onClick={handleCopy} ref={buttonRefs}></button>
    </>
  );
};

export const useClipboard = () => {
  return useContext(ClipboardContext);
};
