import { makeStyles } from "@material-ui/styles";
import { useTranslate } from "../../../hooks";
import { WarningRounded } from "@material-ui/icons";
import React from "react";

const useStyle = makeStyles(() => ({
  container: {
    padding: '6px 16px',
    display: 'flex',
    background: '#ffa92b',
    borderRadius: '4px',
    fontSize: '0.875rem',
    color: '#fff',
    fontWeight: '500',
    cursor: 'pointer',
  },
  textWrapper: {
    padding: '0.5rem 0.3rem',
    margin: '0',
    cursor: 'inherit',
    userSelect: 'none',
  },
}));

export const NotVerified = ({ method, account, setPropBeingVerified }) => {
  const classes = useStyle();
  const translate = useTranslate();

  if (!account[method] || account[`${method}Verified`]) {
    return <></>
  }

  return (
    <div className={classes.container} onClick={() => setPropBeingVerified(method)}>
      <div className={classes.textWrapper}>
        <WarningRounded/>
      </div>
      <div className={classes.textWrapper}>
        {translate(`${method}NotVerified`)} {' '} {translate('clickHereToVerify')}
      </div>
    </div>
  );
}
