import { DEFAULT_ROWS_PER_PAGE } from 'lib/consts';
import { useState } from 'react';

export const useListFilters = (filters) => {
  const { qSearch, showInactive, orderBy, order, limit, offset } = filters || {};
  const [_orderBy, setOrderBy] = useState(orderBy || undefined);
  const [_order, setOrder] = useState(order || 'asc');
  const [_qSearch, setQSearch] = useState(qSearch || '');
  const [_showInactive, setShowInactive] = useState(!!showInactive);
  const [_limit, setLimit] = useState(limit === undefined ? DEFAULT_ROWS_PER_PAGE : limit);
  const [_offset, setOffset] = useState(offset || 0);

  const handleRequestSort = (property) => {
    if (_orderBy === property && _order === 'desc') {
      setOrderBy(undefined);
      return;
    }
    const isAsc = _orderBy === property && _order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return {
    orderBy: _orderBy,
    order: _order,
    qSearch: _qSearch,
    showInactive: _showInactive,
    limit: _limit,
    offset: _offset,
    set: {
      orderBy: setOrderBy,
      order: setOrder,
      qSearch: setQSearch,
      showInactive: setShowInactive,
      limit: (limit) => {
        setLimit(limit);
        setOffset(0);
      },
      offset: setOffset,
    },
    handleRequestSort,
    propList: [_orderBy, _order, _qSearch, _showInactive, _limit, _offset],
  };
};
