// in src/MyLayout.js
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Snack } from '../../layout/Snack';
import { setSidebarVisibility } from 'ra-core';
import AppBar from './AppBar';
import { Sidebar } from './Sidebar';
import { Portal } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    height: '100vh',
    backgroundColor: theme.palette.background.default,
    position: 'relative',
  },
  appFrame: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    marginTop: 46,
  },
  contentWithSidebar: {
    display: 'flex',
    flex: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

let appFrameRef = {};
export const getAppFrameRef = () => appFrameRef;

const MyLayout = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const appRef = React.useRef();
  // const open = useSelector((state) => state.admin.ui.sidebarOpen);

  useEffect(() => {
    if (appRef.current) {
      appFrameRef = appRef.current;
    }
  }, [appRef.current]); //eslint-disable-line

  useEffect(() => {
    dispatch(setSidebarVisibility(true));
  }, [setSidebarVisibility]); // eslint-disable-line

  const bodyComponent = React.useRef(document.querySelector('body'));

  return (
    <div className={classes.root}>
      <div className={classes.appFrame} ref={appRef}>
        <AppBar />
        <main className={classes.contentWithSidebar}>
          <Sidebar />
          <div className={classes.content}>{children}</div>
        </main>
        <Portal container={bodyComponent.current}>
          <Snack />
        </Portal>
      </div>
    </div>
  );
};

export default MyLayout;
