import React, { useState } from 'react';

import { ChannelPreviewButton } from 'resources/Channel/components/ChannelPreviewButton';
import { makeStyles } from '@material-ui/core';
import { defaults } from 'resources/App/components/AppPreview.model';
import { AppPreviewHeader } from 'resources/App/components/AppPreviewHeader';

const useStyles = makeStyles(theme => ({
  // TODO: handle big screens
  container: {
    width: 130,
    height: 220,

    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  bigContainer: {
    minWidth: 325,
    width: '100%',
    maxWidth: 'calc((90vh - 1.5rem) / 2)',
    height: 900,
    maxHeight: 'calc(90vh - 1.5rem)', // TODO: better height. (3.5rem => navbar height)
    overflow: 'hidden',
    display: 'flex',
    background: 'green',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: theme.borderRadius * 2,
  },
  channelList: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',

    '& > button': {
      width: '93%',
    }
  },
  secondaryChannelsTray: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#16181D',
    borderRadius: `${theme.borderRadius * 2}px ${theme.borderRadius * 2}px 0 0`,
    height: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  secondaryChannelsTrayHeader: {
    display: 'flex',
    alignItems: 'center',
    color: '#C4C9D4',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '.5rem',
    paddingBottom: '1rem',
  },
  secondaryChannelsTrayHandler: {
    display: 'inline-block',
    width: '2rem',
    height: '2px',
    borderRadius: '100px',
    backgroundColor: 'white',
    marginBottom: '.5rem',
  },


  bigSecondaryChannelsTray: {
    position: 'absolute',
    top: '100%',
    transform: 'translateY(-3rem)',
    transition: '.5s transform',
    width: '100%',
    backgroundColor: '#16181D',
    borderRadius: `${theme.borderRadius * 4}px ${theme.borderRadius * 4}px 0 0`,
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  bigSecondaryChannelsTrayOpen: {
    transform: 'translateY(-100%)',
    cursor: 'inherit',
  },
  bigSecondaryChannelsTrayHeader: {
    display: 'flex',
    alignItems: 'center',
    color: '#C4C9D4',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '.5rem',
    paddingBottom: '1rem',
  },
  bigSecondaryChannelsTrayHandler: {
    display: 'inline-block',
    width: '2rem',
    height: '4px',
    borderRadius: '100px',
    backgroundColor: 'white',
    marginBottom: '.5rem',
  },
  bigTrayOverlay: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
    cursor: 'pointer',
  },
}))

export const TemplatePreview = ({ template, big }) => {
  const classes = useStyles();

  const [openTray, setOpenTray] = useState(false);

  if (template.channels) {
    template.channels.sort((a, b) => a.schema.displayOrder - b.schema.displayOrder);
  }

  const [primaryChannels] = useState(
    template.channels?.filter((channel) => {
      const channelConfig = channel.schema?.config || channel.config;
      const channelPrimary = channel.schema?.primary || channel.primary;
      return (channelPrimary && !channelConfig?.parentId);
    }) || []
  );

  const [secondaryChannels] = useState(
    template.channels?.filter((channel) => {
      const channelConfig = channel.schema?.config || channel.config;
      const channelPrimary = channel.schema?.primary || channel.primary;
      return (!channelPrimary && !channelConfig?.parentId);
    }) || []
  );

  const showPrimaryChannels = primaryChannels.length > 0;

  const { backgroundColor, textColor } = template.config ?? {};

  return (
    <div className={`${big ? classes.bigContainer : classes.container}`}
      style={{
        backgroundColor: backgroundColor || defaults.backgroundColor,
        color: textColor || defaults.textColor,
      }}
    >
      <AppPreviewHeader app={template} minify={!big} />
      <div className={classes.channelList}>
        {showPrimaryChannels
          ? primaryChannels.map((channel, idx) => (
            <ChannelPreviewButton
              channel={channel}
              app={template}
              key={`template_preview_channels_primary_${template.id}_${idx}_${JSON.stringify(channel)}`}
              minify={!big}
            />
          ))
          : secondaryChannels.map((channel, idx) => (
            <ChannelPreviewButton
              channel={channel}
              app={template}
              key={`template_preview_channels_secondary+_${template.id}_${idx}_${JSON.stringify(channel)}`}
              noPrimary
              minify={!big}
            />
          ))}
      </div>

      {showPrimaryChannels && secondaryChannels.length > 0 && (
        big
          ? (
            <>
              {openTray && <div className={classes.bigTrayOverlay} onClick={() => setOpenTray(false)}></div>}
              <div className={`${classes.bigSecondaryChannelsTray} ${openTray ? classes.bigSecondaryChannelsTrayOpen : ''}`}>
                <div className={classes.bigSecondaryChannelsTrayHeader} onClick={() => setOpenTray((open) => !open)}>
                  <div className={classes.bigSecondaryChannelsTrayHandler}></div>
                  <span>Mais Opções</span>
                </div>
                <div className={classes.channelList}>
                  {secondaryChannels.map((channel) => (
                    <ChannelPreviewButton
                      channel={channel}
                      app={template}
                      key={`app_preview_channels_secondary_${channel.id}`}
                    />
                  ))}
                </div>
              </div>
            </>
          )
          : (
            <div className={`${classes.secondaryChannelsTray}`}>
              <div className={classes.secondaryChannelsTrayHeader}>
                <div className={classes.secondaryChannelsTrayHandler}></div>
              </div>
            </div>
          )
      )}
    </div >
  );
}
