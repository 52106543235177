import React from 'react';
import { RefreshIconButton } from 'ra-ui-materialui';
import { makeStyles, useTheme } from '@material-ui/styles';
import UserMenu from './UserMenu';
import LanguageMenu from './LanguageMenu';
import { SasiAppbarLogo } from '../../assets/SasiAppbarLogo';
import { useSelector } from 'react-redux';
import { useRefreshWhenVisible } from 'ra-core';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: '3.5rem',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    maxWidth: '100vw',
    background: '#414958',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0 1rem',
    zIndex: 1000,
  },
  logo: {
    height: '90px',
  },
  userLangMenu: {
    display: 'flex',
    alignItems: 'center',
  },
  spacer: {
    flex: 1,
  },
  loader: {
    margin: theme.spacing(2),
  },
  languageButton: {
    padding: '12px',
    width: '32px',
    height: '24px',
    boxSizing: 'content-box',
    '& > span': {
      width: 'inherit',
    },
  },
  loadedIcon: {},
}));

const LoadingIndicator = (props) => {
  const { classes: classesOverride, className, ...rest } = props;
  useRefreshWhenVisible();
  const loading = useSelector((state) => state.admin.loading > 0);
  const classes = useStyles(props);
  const theme = useTheme();

  return loading ? (
    <CircularProgress
      className={classes.loader}
      color="inherit"
      size={theme.spacing(2)}
      thickness={6}
      {...rest}
    />
  ) : (
    <RefreshIconButton className={classes.loadedIcon} />
  );
};

const UserLangMenu = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.userLangMenu}>
      <LoadingIndicator />
      <LanguageMenu {...props} className={classes.languageButton} />
      <UserMenu {...props} />
    </div>
  );
};

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.appBar}>
      <SasiAppbarLogo />
      <UserLangMenu />
    </div>
  );
};
