import React, { useEffect, useState, useCallback } from "react"
import { makeStyles } from "@material-ui/styles"
import CardMedia from '@material-ui/core/CardMedia';
import { Icon, IconButton } from "@material-ui/core"
import SampleButton from "resources/components/SampleButton"
import classnames from "classnames"
import { get, merge} from "lodash"
import SampleEditPopover from "resources/components/SampleEditPopover"

const useStyles = makeStyles({
  header: {
    //boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.2)",
    width: 320,
    display: "flex",
    marginBottom: 10
  },
  banner: {
    width: 320,
    height: 107,
    backgroundSize: "contain",
    display: "flex"
  },
  sampleView: {
    width: 320,
    height: 640,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "scroll",
    border: "1px solid #DDD",
    borderRadius: 10
  },
  menuIcon: {
    position: "absolute",
    top: 20,
    left: 10,
  },

  sampleButton: {
    width: "auto"
  },
  sampleButtonPrimary: {
    width: "auto",
    height: 120
  },
  iconSecondary: {
    position: "absolute",
    bottom: 10,
    left: 130
  },
  iconPrimary: {
    margin: "0 auto",
  },
  title: {
    margin: "0 auto",
    fontWeight: "bold",
    fontSize: "0.8em"
  },
  titleBanner: {
    margin: "75px auto 0"
  },
  titleNoBanner: {
    marginTop: 25,
    marginBottom: 25,
  }
})

const defaultConfig = {
  banner: { },
  showBannerNickname: false,
  backgroundColor: "#007DD8",
  textColor: "#FFFFFF",
  headerColor: "#007DD8",
  secondaryColor: "#363435",
  secondaryBackgroundColor: "#FAFAFA",
  primaryButtonsRatio: 2.5,
  secondaryButtonsRatio: 5.2,
  buttonsBorderRadius: 0
}
const defaultState = { anchorEl: null, channel: {} }
export default function PhoneView({  resource, title, config, items, onUpdate, readonly }) {
  const classes = useStyles()
  const { textColor, backgroundColor, nickname, banner, showBannerNickname} = merge({}, {title}, defaultConfig, config)
  
  const primaryItems = items.filter(item => item.primary)
  const secondaryItems = items.filter(item => !item.primary)

  const [showPrimary, setPrimary] = useState(true)
  const bannerUrl = get(banner, "url" )   
  const showBanner = bannerUrl
  
  const primaryToggle = useCallback(() => setPrimary(!showPrimary), [showPrimary, setPrimary])
  const [current, setCurrent] = useState(defaultState)

  useEffect(() => {    
    setPrimary(primaryItems.length > 0 || secondaryItems.length === 0)    
  }, [setPrimary, primaryItems.length, secondaryItems.length])
  
  return <>
  <SampleEditPopover readonly={readonly} resource={resource} channel={current.channel} config={config} anchorEl={current.anchorEl} onSubmit={onUpdate} onClose={() => setCurrent(defaultState)} />
  <div className={classes.sampleView} style={{ color: textColor, backgroundColor }}>
    <div className={classes.header}>
      {showBanner && <>
        <CardMedia image={bannerUrl} className={classes.banner} >
          <Icon className={classes.menuIcon}>view_headline</Icon>
            {showBannerNickname && <div className={classnames(classes.titleBanner, classes.title)}>{nickname}</div>}
        </CardMedia>
      </>}
      {!showBanner && <>
        <Icon className={classes.menuIcon}>view_headline</Icon>
        <div className={classnames(classes.titleNoBanner, classes.title)}>{nickname}</div>
      </>}
    </div>
      {showPrimary && primaryItems.map(channel => <SampleButton key={channel.id} className={classes.sampleButtonPrimary} channel={channel} config={config} onClick={event => setCurrent({ channel, anchorEl: event.target })}/>)}
      {!showPrimary && secondaryItems.map(channel => <SampleButton key={channel.id} className={classes.sampleButton} channel={channel} config={config} onClick={event => setCurrent({ channel, anchorEl: event.target })} />)}    
      {primaryItems.length > 0 && secondaryItems.length > 0 && 
        <IconButton className={classes.iconSecondary} style={{ color: textColor }} onClick={primaryToggle}>
          <Icon> {showPrimary ? "keyboard_arrow_down" : "keyboard_arrow_up"}</Icon>
        </IconButton>
      }     
  </div>
  </>
}