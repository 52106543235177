import { SET_CHANNELS } from '../actions/channels';

export const channelsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CHANNELS:
      return {
        ...state,
        channels: payload,
      };
    default:
      return undefined;
  }
};
