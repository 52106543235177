import React from "react"
import AdminDashboard     from "../../scopes/Admin/Dashboard/Dashboard"
import ClientDashboard       from "scopes/Client/Dashboard"
import { useAccount } from "hooks"


export default props => {
  const account = useAccount()
  if (account) {
    switch (account.userType) {
      case "sasi":                return <AdminDashboard {...props} />
      case "client":              return <ClientDashboard {...props} />
      default:
        return <span />
    }
  }
  return <span />
} 
