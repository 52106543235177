import { genericErrorHandler } from 'lib/Errors';

export const handleEditProvider = (dataProvider, close, setLoading, refresh, notify) => async (formData) => {
  setLoading(true);

  try {
    const { id, ...data } = formData;
    await dataProvider.update('monitoring-providers', { id, data });
    if (refresh) {
      refresh();
    }
  } catch (err) {
    genericErrorHandler(notify)(err);
  } finally {
    setLoading(false);
    close();
  }
};
