import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useCloseButtonStyles = makeStyles((theme) => ({
  button: {
    background: 'transparent',
    border: 'none',
    outline: 'none',
    transition: 'background .2s',
    cursor: 'pointer',
    borderRadius: theme.borderRadius,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(3),
    '&:hover': {
      background: theme.palette.background.surface.pressed,
    },
  },
}));

export const CloseButton = ({ onClick }) => {
  const classes = useCloseButtonStyles();

  return (
    <button className={classes.button} onClick={onClick}>
      <Close />
    </button>
  );
};
