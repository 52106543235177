import React, { useState, useEffect } from 'react';

import { useDataProvider, useTranslate } from 'ra-core';
import { AddCircle } from '@material-ui/icons';
import { LabeledSwitch } from 'components/designSystem/LabeledSwitch';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { ListFilter } from 'components/designSystem/ListFilter';
import { Table } from 'components/designSystem/Table';
import { loadUsers, usersColumns } from './Users.model';
import { useListFilters } from 'hooks';
import { useHistory } from 'react-router';
import { useUsers } from 'hooks/use-resource-hooks';
import { CreateUserDialog } from 'resources/User/components/CreateUserDialog';
import { EditUserDialog } from 'resources/User/components/EditUserDialog';

const UserList = ({ client, setHandleRefresh }) => {
  const dataProvider = useDataProvider();
  const t = useTranslate();
  const history = useHistory();
  const { users: stateUsers, dispatch } = useUsers(client.id);
  const [loading, setLoading] = useState(false);

  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);

  const filters = useListFilters();
  const [users, setUsers] = useState(stateUsers);
  const [totalUsers, setTotalUsers] = useState(undefined);

  const reload = () => loadUsers(dataProvider, setUsers, setTotalUsers, client.id, filters, dispatch, setLoading);
  useEffect(() => setHandleRefresh(() => reload), []); //eslint-disable-line

  useEffect(() => {
    reload();
  }, filters.propList); //eslint-disable-line

  return (
    <div>
      <CreateUserDialog
        clientId={client.id}
        clientName={client.name}
        isOpen={isCreateDialogOpen}
        refresh={reload}
        close={() => setCreateDialogOpen(false)}
      />
      <EditUserDialog
        close={() => setSelectedUser(undefined)}
        isOpen={!!selectedUser}
        refresh={reload}
        user={selectedUser}
      />
      <ListFilter
        actions={[
          <PrimaryButton
            key="client_create_user_button"
            label={t('resources.users.create')}
            onClick={() => setCreateDialogOpen(true)}
            icon={<AddCircle />}
          />,
        ]}
      >
        <LabeledSwitch
          label={t('fields.showInactive')}
          onChange={filters.set.showInactive}
          value={filters.showInactive}
          reverse
        />
      </ListFilter>
      <Table
        loading={loading && users.length === 0}
        onRowClick={(row) => history.push(`/users/${row.id}/show`)}
        data={users}
        columns={usersColumns(dataProvider, setSelectedUser, reload)}
        onSort={filters.handleRequestSort}
        order={filters.order}
        orderBy={filters.orderBy}
        totalRecords={totalUsers}
        rowsPerPage={filters.limit}
        page={filters.offset / filters.limit}
        onChangePage={(value) => filters.set.offset(value * filters.limit)}
        onChangeRowsPerPage={filters.set.limit}
        noDataMessage="Ainda não existem usuários criados para este cliente."
      />
    </div>
  );
};

export const Users = ({ client, setHandleRefresh }) => {
  if (!client) {
    return <div></div>;
  }
  return <UserList client={client} setHandleRefresh={setHandleRefresh} />;
};
