import { genericErrorHandler } from 'lib/Errors';

export const deleteChannel =
  ({ dataProvider, handleCloseOptionsMenu, refresh, notify, channelId }) =>
  async () => {
    try {
      await dataProvider.delete('channels', { id: channelId });
      if (refresh) {
        refresh();
      }
    } catch (err) {
      genericErrorHandler(notify)(err);
    } finally {
      handleCloseOptionsMenu();
    }
  };
