import React from 'react';
import { Datagrid, Filter, TextField, SelectField } from 'ra-ui-materialui';
import { List, ShowLink, checkPermissions } from 'components';
import ActiveSwitch from 'resources/components/ActiveSwitch';
import FilterSwitch from 'resources/components/FilterSwitch';
import { providerTypes } from 'lib/consts';
import EditButton from './components/EditButton';
import { useRefresh } from 'ra-core';

const MonitoringProviderListFilter = (props) => (
  <Filter {...props}>
    <FilterSwitch source="showInactive" label="fields.showInactive" alwaysOn />
  </Filter>
);

export default ({ permissions, ...props }) => {
  const refresh = useRefresh();

  const editable = checkPermissions(permissions, 'monitoring-providers:update');
  return (
    <List
      {...props}
      filters={<MonitoringProviderListFilter />}
      filter={{ ClientId: 1 }}
    >
      <Datagrid>
        <ShowLink source="name" />
        <SelectField source="providerType" choices={providerTypes} />
        <TextField source="email" />
        <ActiveSwitch
          source="active"
          sortable={false}
          onUpdate={refresh}
          readonly={!editable}
        />
        {editable && <EditButton />}
      </Datagrid>
    </List>
  );
};
