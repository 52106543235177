import React from "react";
import IconUpdate from "@material-ui/icons/Update";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx"
import { useTranslate } from "ra-core";
import { makeStyles } from "@material-ui/core"
import styles from "./assets/dashboard.jsx"
import {formatTime} from "components"
const useStyles = makeStyles(styles)

export default ({ color, title, subTitle, footer, time, ...props}) => {
  const translate = useTranslate()
  const classes   = useStyles()
  const timeStr = time ? formatTime(time) : null
  return <Card>
    <CardHeader color={color || "primary"} stats icon>
      <CardIcon color={color || "primary"}>{props.icon}</CardIcon>
      <p className={classes.cardCategory}>{title}</p>
      <h3 className={classes.cardTitle}>{subTitle}</h3>          
    </CardHeader>
    <CardFooter stats>
      {footer ||
        <div className={classes.stats}>
          <IconUpdate />
        {timeStr || translate("titles.justUpdated")}
        </div>
      }
    </CardFooter>
  </Card>
}
