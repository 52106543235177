import { appsReducer } from './apps';
import { appSchemasReducer } from './appSchemas';
import { channelsReducer } from './channels';
import { clientsReducer } from './clients';
import { growthbookReducer } from './growthbook';
import { licensesReducer } from './licenses';
import { projectsReducer } from './projects';
import { providersReducer } from './providers';
import { sitesReducer } from './sites';
import { teamsReducer } from './teams';
import { termsReducer } from './terms';
import { usersReducer } from './users';

export const reducer = (state, action) => {
  const growthBookState = growthbookReducer(state, action);
  if (growthBookState) {
    return growthBookState;
  }

  const appsState = appsReducer(state, action);
  if (appsState) {
    return appsState;
  }

  const appSchemasState = appSchemasReducer(state, action);
  if (appSchemasState) {
    return appSchemasState;
  }

  const clientsState = clientsReducer(state, action);
  if (clientsState) {
    return clientsState;
  }

  const channelsState = channelsReducer(state, action);
  if (channelsState) {
    return channelsState;
  }

  const licensesState = licensesReducer(state, action);
  if (licensesState) {
    return licensesState;
  }

  const projectsState = projectsReducer(state, action);
  if (projectsState) {
    return projectsState;
  }

  const providersState = providersReducer(state, action);
  if (providersState) {
    return providersState;
  }

  const sitesState = sitesReducer(state, action);
  if (sitesState) {
    return sitesState;
  }

  const teamsState = teamsReducer(state, action);
  if (teamsState) {
    return teamsState;
  }

  const termsState = termsReducer(state, action);
  if (termsState) {
    return termsState;
  }

  const usersState = usersReducer(state, action);
  if (usersState) {
    return usersState;
  }

  return state;
};
