import React from 'react';

export const DocumentFileIcon = () => (
  <svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="45.4998" cy="45.5" r="36.1111" fill="#C4C9D4" />
    <ellipse cx="45.8615" cy="67.5278" rx="32.1389" ry="3.25" fill="#E2E4E9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.535 26.7222C59.7657 26.7222 59.1422 27.3458 59.1422 28.115V29.5078H25.561C24.4313 29.5078 23.5097 30.4157 23.489 31.5491L22.9304 62.1818C22.888 64.508 24.7557 66.4167 27.0745 66.4167H69.0162C70.9145 66.4167 72.463 64.8962 72.4976 62.9982L73.0697 31.6252C73.0909 30.4621 72.157 29.5078 70.9977 29.5078H69.5881V28.115C69.5881 27.3458 68.9645 26.7222 68.1953 26.7222H60.535Z"
      fill="#E2E4E9"
    />
    <path
      d="M18.0768 34.5306C17.8742 32.9755 19.0875 31.597 20.6589 31.597H65.5487C66.8569 31.597 67.962 32.5657 68.1307 33.8603L71.7848 61.8998C72.0968 64.2939 70.2327 66.4167 67.8183 66.4167H24.5193C23.2111 66.4167 22.106 65.448 21.9372 64.1533L18.0768 34.5306Z"
      fill="#FCFCFD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.6753 6.9909C46.1334 6.33636 45.1161 6.33637 44.5743 6.99091L35.6947 17.7175C34.9753 18.5866 35.6049 19.8867 36.7452 19.8867H41.8337V28.478C41.8337 29.3595 42.5611 30.0741 43.4584 30.0741H47.5203C48.4177 30.0741 49.1451 29.3595 49.1451 28.478V19.8867H54.5044C55.6447 19.8867 56.2743 18.5866 55.5549 17.7175L46.6753 6.9909Z"
      fill="#E2E4E9"
    />
  </svg>
);
