import React, { useState } from 'react';

import { Dialog } from 'components/designSystem/Dialog';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { TertiaryButton } from 'components/designSystem/TertiaryButton';
import { PrimaryButton } from 'components/designSystem/PrimaryButton';
import { makeStyles } from '@material-ui/core';
// import { CheckCircle } from '@material-ui/icons';

import { Form } from 'react-final-form';
import { required } from 'lib/formValidators';
import { useDataProvider, useTranslate, useNotify } from 'ra-core';
import { handleEditProject } from './EditProjectDialog.model';

const useStyles = makeStyles((theme) => ({
  bottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),

    '& button': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginLeft: 16,
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '10px',
  },
}));

export const EditProjectDialog = ({ project, isOpen, close, refresh }) => {
  const t = useTranslate();
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog isOpen={isOpen} close={close} title={t('resources.projects.editModal.title', project)}>
      <Form
        initialValues={{ ...project }}
        onSubmit={handleEditProject({ dataProvider, close, setLoading, refresh, notify })}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.content}>
              <TextFormField
                name="name"
                validate={required(t)}
                full
                dense
                size="full"
                label={t('resources.projects.name')}
                placeholder={t('resources.projects.fields.namePlaceholder')}
              />
            </div>
            <div className={classes.content}>
              <TextFormField
                name="description"
                validate={required(t)}
                full
                dense
                size="full"
                label={t('resources.projects.description')}
                placeholder={t('resources.projects.fields.descriptionPlaceholder')}
              />
            </div>

            <div className={classes.bottomContainer}>
              <TertiaryButton disabled={loading} label={t('resources.projects.buttons.cancel')} onClick={close} />
              <PrimaryButton
                disabled={loading}
                type="submit"
                label={t('resources.projects.buttons.save')}
                onClick={handleSubmit}
                // icon={<CheckCircle />}
              />
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};
