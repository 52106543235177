import React, { useEffect, useRef, useState } from 'react';

import { makeStyles, Tooltip } from '@material-ui/core';
import { ChromePicker } from 'react-color';
import { Field } from 'components/designSystem/Form';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'inline-block',
    cursor: 'pointer',
  },
  label: {
    display: 'block',
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  helperIcon: {
    color: theme.palette.text.hint,
    fontSize: theme.spacing(2.2),
    marginLeft: theme.spacing(0.5),
    position: 'relative',
    top: 1,
    cursor: 'hover',
  },
  colorInput: {
    display: 'flex',
    background: theme.palette.background.surface.primary,
    width: theme.spacing(20),
    padding: theme.spacing(1),
    borderRadius: theme.borderRadius,
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.body2.fontSize,
    alignItems: 'center',
    transition: '.4s background, .1s outline',
  },
  surfaceColorInput: {
    background: theme.palette.background.surface.secondary,
  },
  colorInputSelected: {
    // outline: `2px solid ${theme.palette.primary.main}`,
    boxShadow: `0 0 0 2px  ${theme.palette.primary.main}`,
  },
  colorLabel: {
    textTransform: 'uppercase',
  },
  colorPreview: {
    width: theme.spacing(3),
    height: theme.spacing(2),
    borderRadius: theme.borderRadius,
    marginRight: theme.spacing(1.25),
  },
  colorPickerContainer: {
    position: 'absolute',
    zIndex: 1500,
  },
  pickerOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    cursor: 'default',
  },
  colorInputField: {
    position: 'absolute',
    cursor: 'default',
    bottom: '100%',
  },
  colorInputFieldBottom: {
    position: 'absolute',
    cursor: 'default',
    top: '100%',
  },
  disabledInput: {
    background: theme.palette.background.surface.pressed,
    cursor: 'default',
  },
}));

export const ColorPickField = ({ input, ...props }) => {
  const { value, onChange, label, surface, className, disabled, helperText } = {
    ...(input || {}),
    ...props,
  };
  const classes = useStyles();
  const [selectedColor, setSelectedColor] = useState(value || '#FFFFFF');
  const [showPicker, setShowPicker] = useState(false);
  const [trueShowPicker, setTrueShowPicker] = useState(false);
  const [colorPickerClass, setColorPickerClass] = useState(classes.colorInputField);
  const colorPickerInputRef = useRef();

  const handleChange = (color) => {
    if (disabled) return;

    setSelectedColor(color);
    if (onChange) {
      onChange(color);
    }
  };

  // Needed because of safari
  useEffect(() => {
    setSelectedColor(value);
  }, [value]);

  // This is a little hardcoded, but I plan on refactor later;
  // This effect is responsible for setting the picker bottom or top depending on its top coordinates.
  // The default is "the color picker is above the field".
  useEffect(() => {
    if (!showPicker) {
      setTrueShowPicker(false);
      return;
    }

    const rect = colorPickerInputRef.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }

    // 315 => arbitrary offset, think about this later
    if (rect.top < 315 && colorPickerClass !== classes.colorInputFieldBottom) {
      setColorPickerClass(classes.colorInputFieldBottom);
    } else if (rect.top >= 315 && colorPickerClass === classes.colorInputFieldBottom) {
      setColorPickerClass(classes.colorInputField);
    }
    setTimeout(() => {
      setTrueShowPicker(true);
    }, 50);
  }, [showPicker, setTrueShowPicker, setColorPickerClass]); //eslint-disable-line

  return (
    <label
      className={`${classes.container} ${className || ''}`}
      style={disabled ? { cursor: 'default' } : {}}
      onClick={() => {
        if (!disabled) {
          setShowPicker(true);
        }
      }}
    >
      <span className={classes.label}>{label}</span>
      {helperText && (
        <Tooltip className={classes.helperInfo} title={helperText}>
          <InfoOutlinedIcon className={classes.helperIcon} />
        </Tooltip>
      )}

      <div
        className={`
          ${classes.colorInput} 
          ${surface ? classes.surfaceColorInput : ''} 
          ${trueShowPicker ? classes.colorInputSelected : ''} 
          ${disabled ? classes.disabledInput : ''}
        `}
        ref={colorPickerInputRef}
      >
        <div className={classes.colorPreview} style={{ backgroundColor: selectedColor }}></div>
        <span className={classes.colorLabel}>{(selectedColor || '#FFFFFF').split('#').join('')}</span>
      </div>
      {trueShowPicker && (
        <div
          className={classes.colorPickerContainer}
          style={{ ...(colorPickerClass === classes.colorInputFieldBottom ? { bottom: -8 } : { top: 0 }) }}
        >
          <div
            className={classes.pickerOverlay}
            onClick={(evt) => {
              evt.stopPropagation();
              setShowPicker(false);
            }}
          ></div>
          <ChromePicker className={colorPickerClass} onChange={({ hex }) => handleChange(hex)} color={value} />
        </div>
      )}
    </label>
  );
};

export const ColorPickFormField = (props) => <Field {...props} component={ColorPickField} />;
