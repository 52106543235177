import React, { useState, useRef, useEffect } from 'react';
import { Field } from 'components/designSystem/Form';

import Downshift from 'downshift';
import { get } from 'lodash';
import { Popper, MenuItem, makeStyles } from '@material-ui/core';

import { TextField } from './inputs/TextField';
import { Debounce } from 'lib/debounce';

const useStyles = makeStyles((theme) => ({
  menuRoot: {
    position: 'absolute',
    zIndex: 4,
    maxHeight: 300,
    top: `${theme.spacing(0.375)}px !important`,
  },
  tiny: { width: 90 },
  small: { width: 170 },
  medium: { width: 350 },
}));

const debounceValue = 500;

export const AutocompleteDropdown = ({ input, ...props }) => {
  const {
    value: _value,
    searchValue: _searchValue,
    onChange: _onChange,
    items,
    loadItems,

    required,

    optionText = 'name',
    optionValue = 'id',

    inputProps,
    // meta = {},
  } = { ...(input || {}), ...props };

  // const errorMessage = meta.error && meta.touched ? error : undefined;

  const getOptionText = (item) => {
    return typeof optionText === 'function' ? optionText(item) : get(item, optionText);
  };

  const getOptionValue = (item) => {
    return typeof optionValue === 'function' ? optionValue(item) : get(item, optionValue);
  };

  const classes = useStyles();
  const anchorEl = useRef(null);
  const initializedOnce = useRef(false);
  const debounce = useRef(new Debounce());

  const [isOpen, setIsOpen] = useState(true);
  const [searchValue, setSearchValue] = useState(_searchValue);
  const [value, setValue] = useState(_value);

  const onChange = (item) => {
    setValue(getOptionValue(item));
    setSearchValue(getOptionText(item));
    _onChange(getOptionValue(item));
    setIsOpen(false);
  };

  useEffect(() => {
    if (initializedOnce.current) {
      return;
    }

    if (!value && items.length > 0) {
      onChange(items[0]);
      initializedOnce.current = true;
      return;
    }

    const item = (items || []).find((item) => getOptionValue(item) === value);
    if (value && required && item) {
      onChange(item);
      initializedOnce.current = true;
    }
  }, [items]); //eslint-disable-line

  useEffect(() => {
    debounce.current.debounced(() => {
      if (loadItems) {
        loadItems(searchValue);
      }
    }, debounceValue);
  }, [searchValue]); //eslint-disable-line

  const itemSize = inputProps?.size || 'small';

  return (
    <Downshift value={value} isOpen={isOpen} onChange={onChange} itemToString={(item) => item?.name || item?.id}>
      {({ getItemProps, isOpen, highlightedIndex }) => {
        return (
          <div style={{ position: 'relative' }}>
            <TextField
              {...inputProps}
              ref={anchorEl}
              onFocus={() => setIsOpen(true)}
              onClick={() => setIsOpen(true)}
              onBlur={() => setIsOpen(false)}
              onChange={(evt) => setSearchValue(evt.target.value)}
              value={searchValue}
            />

            <Popper
              id={isOpen ? 'app-popper' : undefined}
              open={isOpen}
              anchorEl={anchorEl.current}
              placement="bottom-start"
              className={classes.menuRoot}
              modifiers={{
                flip: {
                  enabled: true,
                },
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'viewport',
                },
              }}
            >
              {!required && (
                <MenuItem
                  className={classes[itemSize]}
                  {...getItemProps({
                    key: '',
                    index: 0,
                    item: {},
                  })}
                  selected={highlightedIndex === 0}
                >
                  &nbsp;
                </MenuItem>
              )}
              {items.map((item, index) => (
                <MenuItem
                  className={classes[itemSize]}
                  key={`autocomplete_dropdown_${index}_${JSON.stringify(item)}`}
                  {...getItemProps({
                    key: getOptionValue(item),
                    index: index + 1,
                    item: item,
                  })}
                  selected={highlightedIndex === index + 1}
                >
                  {getOptionText(item)}
                </MenuItem>
              ))}
            </Popper>
          </div>
        );
      }}
    </Downshift>
  );
};

export const AutocompleteDropdownFormField = (props) => <Field {...props} component={AutocompleteDropdown} />;
