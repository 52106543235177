import { useState, useCallback, useEffect, useRef } from 'react';
import useTheme, { useThemeContext } from './use-theme';
import useScript from 'react-script-hook';
import { useDeepCompareEffect } from 'ra-core/esm/util/hooks';
import useRecord from './use-record';
import useRefreshWithVersion from './use-record';
import useRouter, { useURLQueryParams } from './use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate, useQuery, useLocale, useSafeSetState, useDataProvider, useAuthProvider } from 'ra-core';
import { useForm, useFormState } from 'react-final-form';
import useLocationProps from './use-location-props';
import useCopyPaste from './use-copy-paste';
import { useFetch } from './use-data-hooks';
import { useHistory } from 'react-router';
import { useTimer } from './use-timer';
import { useListFilters } from './use-list-filter';

const useLazyState = (defaultValue) => {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue, setValue]);
  return [value, setValue];
};

const useAccount = () => {
  const authProvider = useAuthProvider();
  const [account, setAccount] = useState(authProvider.getProfile());
  const updateState = useCallback(() => setAccount(authProvider.getProfile()), [authProvider, setAccount]);

  useEffect(() => {
    authProvider.addListener('profiles', updateState);
    return () => authProvider.removeListener('profiles', updateState);
  }, [authProvider, updateState]);

  return account;
};

const useProfiles = () => {
  const authProvider = useAuthProvider();
  const [profiles, setProfiles] = useState(authProvider.getProfiles());

  useEffect(() => {
    authProvider.addListener('profiles', setProfiles);
    return () => authProvider.removeListener('profiles', setProfiles);
  }, [authProvider, setProfiles]);

  return profiles;
};

const useAutoRedirect = (defaultValue) => {
  const [, , redirect] = document.location.href.match(/(\?|&)redirect=(.+)(&|$)/) || [];
  const [path] = useState(redirect || defaultValue || '');
  const history = useHistory();
  return path === '::back' ? () => history.goBack() : path;
};

const useStateToggle = (defaultValue) => {
  const [value, setValue] = useState(defaultValue);
  const toggle = useCallback(() => setValue(!value), [value, setValue]);
  return [value, toggle];
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useCompare = (val) => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

export {
  useAccount,
  useProfiles,
  useCopyPaste,
  useState,
  useCallback,
  useEffect,
  useRef,
  usePrevious,
  useRecord,
  useRouter,
  useDispatch,
  useSelector,
  useLazyState,
  useForm,
  useFormState,
  useTranslate,
  useQuery,
  useStateToggle,
  useTheme,
  useThemeContext,
  useCompare,
  useFetch,
  useLocationProps,
  useAutoRedirect,
  useDataProvider,
  useSafeSetState,
  useLocale,
  useScript,
  useRefreshWithVersion,
  useDeepCompareEffect,
  useTimer,
  useURLQueryParams,
  useListFilters,
};
