import React from 'react';
import { Datagrid, TextField, ReferenceField, DeleteWithConfirmButton, Filter, SelectInput } from 'ra-ui-materialui';
import { List, DateField, checkPermissions } from 'components';
import RoleButton from './components/RoleButton';
import StatusUpdateButton from './components/StatusUpdateButton';
import { mapStatuses } from 'lib/consts';
import { useRouter, useAccount } from 'hooks';
import DownshiftInput from 'components/DownshiftInput';
import { makeStyles } from '@material-ui/styles';
import ReferenceSite from 'resources/Common/ReferenceSite';
import ContactCard from './components/ContactCard';
import { SearchFormField } from 'components/designSystem/inputs/TextField';

const useStyles = makeStyles({
  statusFilter: {
    display: 'none',
  },
});

const MapsFilter = ({ hasClient, ...props }) => {
  const { ClientId } = props.filterValues;
  const classes = useStyles();
  return (
    <Filter {...props} key={ClientId} margin={'normal'}>
      <SearchFormField name="q" alwaysOn light iconPosition="right" placeholder="Buscar" />
      <SelectInput source="status" choices={mapStatuses} FormHelperTextProps={{ className: classes.statusFilter }} />
      {hasClient && (
        <DownshiftInput
          type="text"
          source="ClientId"
          reference="clients"
          optionValue="id"
          optionText="name"
          storageKey={'maps_view_clients'}
        />
      )}
      <DownshiftInput
        type="text"
        source="SiteId"
        reference="sites"
        optionValue="id"
        optionText="name"
        filter={{ ClientId }}
        storageKey={'maps_view_sites'}
      />
    </Filter>
  );
};

export const MobileProfileList = ({ hasShow, hasList, hasEdit, hasCreate, permissions, ...props }) => {
  const { location, match } = useRouter();
  const account = useAccount() || {};

  return (
    <List
      resource="maps"
      basePath="/maps"
      location={location}
      match={match}
      {...props}
      filters={<MapsFilter hasClient={!account.ClientId} />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid expand={ContactCard}>
        {!account.ClientId && (
          <ReferenceField
            basePath="/clients"
            source="ClientId"
            reference="clients"
            link="show"
            allowEmpty
            sortBy="Client.name"
          >
            <TextField source="name" />
          </ReferenceField>
        )}

        {!account.SiteId && <ReferenceSite source="SiteId" label="fields.siteTeam" />}
        <TextField source="name" />
        {checkPermissions(permissions, 'maps:role:approve') && <RoleButton source="siteManager" sortable={false} />}
        {checkPermissions(permissions, 'maps:approve') && <StatusUpdateButton source="status" sortable={false} />}
        <TextField source="appVersion" />
        <DateField source="createdAt" />
        {checkPermissions(permissions, 'maps:update') && <DeleteWithConfirmButton />}
      </Datagrid>
    </List>
  );
};
